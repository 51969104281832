import React from "react";
import ModalBasic from "../../../../components/modal/ModalBasic";
import FormDetail from "../../Form/FormDetail";

export default function ModalDetailPt({
	getFieldValue,
	isOpenFormItem,
	setIsOpenFormItem,
	dataDetailItem,
	handleManagerDataItem,
}) {
	return (
		<div>
			<ModalBasic
				id={"ModalDetailPt-edit"}
				title={"Thu theo khách hàng"}
				open={isOpenFormItem === "edit" ? true : false}
				onCancel={() => {
					setIsOpenFormItem(false);
				}}
				footer={null}
				handleManager={(data) => {
					setIsOpenFormItem(false);
					handleManagerDataItem({
						...dataDetailItem,
						...data,
					});
				}}
				data={{
					...dataDetailItem,
					customer_data:
						(dataDetailItem.ten_kh || "") +
						" - " +
						(dataDetailItem.ma_kh || ""),
				}}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
			>
				<FormDetail pt1={true} getFieldValueAll={getFieldValue} />
			</ModalBasic>

			<ModalBasic
				id={"ModalDetailPt"}
				title={"Thu theo khách hàng"}
				open={isOpenFormItem === "create" ? true : false}
				onCancel={() => {
					setIsOpenFormItem(false);
				}}
				footer={null}
				handleManager={(data) => {
					setIsOpenFormItem(false);
					handleManagerDataItem({
						...data,
					});
				}}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				data={{
					tien_nt: 0,
					customer_data:
						(getFieldValue("ten_kh") || "") +
						" - " +
						(getFieldValue("ma_kh") || ""),
					ten_kh: getFieldValue("ten_kh"),
					ma_kh: getFieldValue("ma_kh"),
					tk_co: "131",
					ten_tk_co: "Phải thu khách hàng",
				}}
			>
				<FormDetail pt1={true}  getFieldValueAll={getFieldValue}/>
			</ModalBasic>
		</div>
	);
}
