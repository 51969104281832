import { Button, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
	handleExportFile,
	handleGetData,
	handleGetDataById,
	handleUpdate,
} from "../../../utils/utilsApi";
import FilterReport from "../../components/filter/FilterReport";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import "../managerReports.scss";
import ModalPtVoucher from "../../ManagerVouchers/components/Modal/ModalPtVoucher";
import ModalHd1Voucher from "../../ManagerServices/components/modal/ModalHd1Voucher";
import FormInfomation from "../../ManagerVouchers/components/Form/FormInfomation";
import ItemDetailPt from "../../ManagerVouchers/components/Item/ItemDetailPt";
import ItemTdttnosPt from "../../ManagerVouchers/components/Item/ItemTdttnosPt";
import { handleTotal } from "../../../utils/utilsFunc";
import ModalPcVoucher from "../../ManagerVouchers/components/Modal/ModalPcVoucher";
import ItemDetailPc from "../../ManagerVouchers/components/Item/ItemDetailPc";
import ItemTdttcosPc from "../../ManagerVouchers/components/Item/ItemTdttcosPc";
import ModalPn2 from "../../ManagerServices/components/modal/ModalPn2";

export default function HoaDonReportChi() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: "Số c.từ",
			dataIndex: "so_ct",
			width: 50,
			render: (so_ct, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{so_ct}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{so_ct}
							</span>
						);
				} else {
					return <div>{so_ct}</div>;
				}
			},
		},
		{
			title: "Loại c.từ",
			dataIndex: "ma_ct",
			width: 60,
			render: (ma_ct, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{ma_ct}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{ma_ct}
							</span>
						);
				} else {
					if (ma_ct === "PC1") return <span>Chi</span>;
					else if (ma_ct === "PT1") return <span>Thu</span>;
					else return <span>{ma_ct}</span>;
				}
			},
		},
		{
			title: "Ngày c.từ",
			dataIndex: "ngay_ct",
			width: 60,
			render: (ngay_ct, record) => {
				if (record.bold)
					if (!ngay_ct) return null;
					else {
						if (record.con_lai_nt !== 0)
							return (
								<span className="font-bold text-[red]">
									{dayjs(ngay_ct).format("DD/MM/YYYY")}
								</span>
							);
						else
							return (
								<span className="font-bold text-[green]">
									{dayjs(ngay_ct).format("DD/MM/YYYY")}
								</span>
							);
					}
				else {
					if (!ngay_ct) return null;
					else return dayjs(ngay_ct).format("DD/MM/YYYY");
				}
			},
		},
		{
			title: "Số hoá đơn",
			dataIndex: "so_hd",
			width: 60,
			render: (so_hd, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{so_hd}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{so_hd}
							</span>
						);
				} else {
					return <div>{so_hd}</div>;
				}
			},
		},
		{
			title: "Ngày hoá đơn",
			dataIndex: "ngay_hd",
			width: 80,
			render: (ngay_hd, record) => {
				if (record.bold)
					if (!ngay_hd) return null;
					else {
						if (record.con_lai_nt !== 0)
							return (
								<span className="font-bold text-[red]">
									{dayjs(ngay_hd).format("DD/MM/YYYY")}
								</span>
							);
						else
							return (
								<span className="font-bold text-[green]">
									{dayjs(ngay_hd).format("DD/MM/YYYY")}
								</span>
							);
					}
				else {
					if (!ngay_hd) return null;
					else return dayjs(ngay_hd).format("DD/MM/YYYY");
				}
			},
		},
		{
			title: "Bác sĩ/NCC",
			dataIndex: "ten_kh",
			width: 100,
			render: (ten_kh, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{ten_kh}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{ten_kh}
							</span>
						);
				} else {
					return <div>{ten_kh}</div>;
				}
			},
		},
		{
			title: "Ngoại tệ",
			dataIndex: "ma_nt_hd",
			width: 80,
			render: (ma_nt_hd, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{ma_nt_hd}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{ma_nt_hd}
							</span>
						);
				} else {
					return <div>{ma_nt_hd}</div>;
				}
			},
		},
		{
			title: "Phải chi",
			dataIndex: "tien_hd_nt",
			width: 80,
			render: (tien_hd_nt, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{tien_hd_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{tien_hd_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
				} else {
					return tien_hd_nt?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Đã chi",
			dataIndex: "da_thanh_toan_nt",
			width: 80,
			render: (da_thanh_toan_nt, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{da_thanh_toan_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{da_thanh_toan_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
				} else {
					return da_thanh_toan_nt?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Còn nợ",
			dataIndex: "con_lai_nt",
			width: 80,
			render: (con_lai_nt, record) => {
				if (record.bold) {
					if (record.con_lai_nt !== 0)
						return (
							<span className="font-bold text-[red]">
								{con_lai_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
					else
						return (
							<span className="font-bold text-[green]">
								{con_lai_nt?.toLocaleString("vi-VN", {
									style: "currency",
									currency: "VND",
								})}
							</span>
						);
				} else {
					return con_lai_nt?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
	];

	const [dataFilter, setDataFilter] = useState({});
	const [ngaythieplap, setNgaythieplap] = useState({
		tu_ngay: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		den_ngay: new Date(),
	});
	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataTable = async ({ newParams }) => {
		const data = await handleGetData({
			api: "chitietchitientheohoadon",
			params: {
				...newParams,
			},
			setLoading,
		});
		if (data) {
			// const sortedData = data.data.sort((a, b) => {
			// 	const dateA = new Date(a.ngay_ct);
			// 	const dateB = new Date(b.ngay_ct);
			// 	return dateB - dateA;
			// });

			setDataTable([
				{
					ma_ct: "Tổng cộng",
					con_lai_nt: handleTotal({
						arr: data.data,
						name: "con_lai_nt",
					}),
					bold: true,
				},
				...data.data,
			]);
		}
	};

	const handleManagerFilter = (value) => {
		console.log(value);
		if (value?.ma_kh === undefined && value?.ten_kh === undefined) {
			handleGetDataTable({
				newParams: {
					tu_ngay: ngaythieplap.tu_ngay,
					den_ngay: ngaythieplap.den_ngay,
				},
			});
			return;
		} else {
			setDataFilter({
				ma_kh: value?.ma_kh,
				ten_kh: value?.ten_kh,
			});
			handleGetDataTable({
				newParams: {
					ma_kh: value?.ma_kh,
					ten_kh: value?.ten_kh,
					tu_ngay: ngaythieplap.tu_ngay,
					den_ngay: ngaythieplap.den_ngay,
				},
			});
		}
	};
	const handleGetDataDefault = async () => {
		const dataRpt = await handleGetData({
			api: "rpt",
			q: {
				ma_cn: "CHITIETCHITIENTHEOHOADON",
			},
			setLoading,
		});
		if (dataRpt) {
			setDataDefault({
				...dataDefault,
				dataRpt: { ...dataRpt.data[0] },
			});
		}
	};

	useEffect(() => {
		handleGetDataTable({
			newParams: {
				tu_ngay: ngaythieplap.tu_ngay,
				den_ngay: ngaythieplap.den_ngay,
			},
		});
		handleGetDataDefault();
	}, []);

	const [isOpenFormItemVoucher, setIsOpenFormItemVoucher] = useState(false);
	const [dataItemVoucher, setDataItemVoucher] = useState({});
	const [loadingItem, setLoadingItem] = useState(false);
	const handleOnRowClick = async (record) => {
		const dataVoucher = await handleGetDataById({
			api: record.ma_ct.toLowerCase(),
			id: record.id_ct,
			setLoading: setLoadingItem,
		});
		setDataItemVoucher(dataVoucher);
		setIsOpenFormItemVoucher(record.ma_ct.toLowerCase());
	};

	const handleExportExcel = async () => {
		try {
			const response = await handleExportFile({
				api: "chitietchitientheohoadon",
				params: {
					id_rpt: dataDefault?.dataRpt?._id,
					...dataFilter,
					tu_ngay: ngaythieplap.tu_ngay,
					den_ngay: ngaythieplap.den_ngay,
				},
				messageApi,
			});

			const fileType =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";

			if (response) {
				const blob = new Blob([response], { type: fileType });
				const url = window.URL.createObjectURL(blob);

				// Create a hidden <a> element
				const a = document.createElement("a");
				a.href = url;
				a.download =
					`ChiTietHoaDon-${dayjs(new Date()).format("DD-MM-YYYY")}` +
					fileExtension;

				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);

				window.URL.revokeObjectURL(url);
			} else {
				console.error("No data found in the response");
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleManagerData = async (data) => {
		await handleUpdate({
			api: data?.collection_name,
			data,
			messageApi,
			setLoading: setLoadingItem,
		});
		await handleGetDataTable({
			newParams: {
				...dataFilter,
				tu_ngay: ngaythieplap.tu_ngay,
				den_ngay: ngaythieplap.den_ngay,
			},
		});
		setIsOpenFormItemVoucher(false);
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
										shape="circle"
										size="small"
										className="btn_ternary !border-none"
									>
										<TfiReload />
									</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[250px]">
									<FilterReport
										ngaythieplap={ngaythieplap}
										setNgaythieplap={setNgaythieplap}
										handleManager={handleManagerFilter}
										ncc={true}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<Button onClick={handleExportExcel} className="btn_ternary">
						<CiExport />
						<span className="max-lg:!hidden">Export</span>
					</Button>
					<span>
						Kỳ đang xem:{" "}
						{dayjs(ngaythieplap.tu_ngay).format("DD/MM/YYYY")} -{" "}
						{dayjs(ngaythieplap.den_ngay).format("DD/MM/YYYY")}
					</span>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Hoá đơn chi{" "}
						{dataFilter?.ten_kh ? (
							<span className="text-[#186b9e] underline">
								{dataFilter?.ten_kh}
							</span>
						) : (
							""
						)}{" "}
						(VND)
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1150,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
					rowSelection: null,
				}}
				onRow={(record) => ({
					onClick: () => {
						handleOnRowClick(record);
					},
				})}
			/>
			<ModalPcVoucher
				SoQuy={true}
				dataDetail={dataItemVoucher}
				handleManagerData={handleManagerData}
				isOpenForm={isOpenFormItemVoucher === "pc1" ? "edit" : false}
				setIsOpenForm={setIsOpenFormItemVoucher}
				formSections={[
					{
						id: "thongtin1",
						title: "Thông tin",
						content: <FormInfomation pt1={true} />,
					},
					{
						id: "chitheokhachhang",
						title: "Chi theo khách hàng",
						content: <ItemDetailPc />,
					},
					{
						id: "chitheohoadon",
						title: "Chi theo hoá đơn",
						content: <ItemTdttcosPc messageApi={messageApi} />,
					},
				]}
			/>
			<ModalPn2
				isOpenForm={isOpenFormItemVoucher === "pn2" ? "edit" : false}
				setIsOpenForm={setIsOpenFormItemVoucher}
				isTabletOrMobile={isTabletOrMobile}
				dataDefault={dataDefault}
				dataDetail={dataItemVoucher}
				handleUpdateData={handleManagerData}
			/>
		</div>
	);
}
