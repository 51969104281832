import { Table, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import "./tableManager.scss";

const TableManager = ({
	id = "table-manager",
	data,
	columns,
	expandable,
	loading,
	scroll,
	rowKey,
	onRow,
	handleDataSelect,
	handleDataSelectKey,
	tableProps,
	// pagination,
	isPage,
	isLimit,
	totalRows,
	handlePaginationChange,
}) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const onSelectChange = (newSelectedRowKeys, selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		handleDataSelect && handleDataSelect(selectedRows);
		handleDataSelectKey && handleDataSelectKey(newSelectedRowKeys);
	};
	const newRowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const tableProp = {
		size: "small",
		...tableProps,
	};

	//Pagination
	const [page, setPage] = useState(isPage || 1);
	const [pageSize, setPageSize] = useState(isLimit || 50);

	useEffect(() => {
		setPage(isPage);
		setPageSize(isLimit);
	}, [isPage, isLimit]);

	return (
		<>
			<Table
				id={id}
				pagination={false}
				loading={loading}
				columns={columns}
				dataSource={data || []}
				scroll={scroll}
				rowKey={rowKey || "_id"}
				rowSelection={newRowSelection}
				preserveSelectedRowKeys={true}
				expandable={{
					...expandable,
				}}
				onRow={onRow}
				{...tableProp}
			/>
			<div className="w-full flex justify-end my-4">
				<Pagination
					pageSizeOptions={[10, 20, 50, 100]}
					total={totalRows}
					size="small"
					defaultPageSize={pageSize}
					current={page}
					onChange={(page, pageSize) => {
						handlePaginationChange(page, pageSize);
					}}
				/>
			</div>
		</>
	);
};
export default TableManager;
