import React from "react";
import ModalBasic from "../../../../components/modal/ModalBasic";
import FormTdttnosPt from "../../Form/FormTdttnosPt";

export default function ModalTdttcosPc({
	getFieldValue,
	isOpenFormItem,
	setIsOpenFormItem,
	dataDetailItem,
	handleManagerDataItem,
}) {
	return (
		<div>
			<ModalBasic
				id={"ModalTdttcosPc-edit"}
				title={"Chi theo hoá đơn"}
				open={isOpenFormItem === "edit" ? true : false}
				onCancel={() => {
					setIsOpenFormItem(false);
				}}
				footer={null}
				handleManager={(data) => {
					setIsOpenFormItem(false);
					handleManagerDataItem({
						...dataDetailItem,
						...data,
						ma_nt_hd: getFieldValue("ma_nt"),
						ty_gia_hd: getFieldValue("ty_gia"),
					});
				}}
				data={{
					...dataDetailItem,
				}}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
			>
				<FormTdttnosPt getFieldValueAll={getFieldValue} />
			</ModalBasic>
		</div>
	);
}
