import { Button, message } from "antd";
import moment from "moment";
import "moment/locale/vi";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import FormBooking from "../../ManagerServices/components/form/FormBooking";
import ItemDetail from "../../ManagerServices/components/item/ItemDetail";
import ModalBasic from "../../components/modal/ModalBasic";
import "./calendarBooking.scss";
import { randomChars } from "../../../utils/utilsFunc";

const localizer = momentLocalizer(moment);

const messages = {
	today: "Hôm nay",
	previous: "Quay lại",
	next: "Tiếp theo",
	month: "Tháng",
	week: "Tuần",
	day: "Ngày",
	agenda: "Lịch biểu",
	date: "Ngày",
	time: "Thời gian",
	event: "Sự kiện",
	allDay: "Cả ngày",
	showMore: (total) => `Xem thêm (${total})`,
};

export default function CalendarBooking() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [trangthai, setTrangthai] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 0,
				...q,
			},
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
		}
	};

	useEffect(() => {
		handleGetDataTable({});
	}, []);

	const handleSlotSelect = ({ start, end }) => {
		// Log the selected start and end dates
		// console.log("Selected start date:", start);
		// console.log("Selected end date:", end);
		setIsOpenForm("create");
		setDataDetail({});
	};

	const handleEventSelect = (event) => {
		console.log("Selected event:", event);
		setDataDetail(event.description);
		setIsOpenForm("edit");
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		const dataPTTT = await handleGetDataAndRows({
			api: "ptthanhtoan",
			q: {
				status: true,
				stt: 0,
			},
			setLoading,
		});
		const dataDVCS = await handleGetDataAndRows({
			api: "dvcs",
			q: {
				status: true,
				ten_dvcs: "Công ty",
			},
			setLoading,
		});
		if (dataPTTT) {
			setDataDefault({
				...dataDefault,
				ptthanhtoan: { ...dataPTTT.data[0] },
				dvcs: { ...dataDVCS.data[0] },
			});
		}
	};
	useEffect(() => {
		handleGetDataDefault();
	}, []);

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
		setTrangthai(0);
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
		setTrangthai(0);
	};

	const myEventsList = dataTable?.map((item) => ({
		id: item._id,
		title: item.ten_kh + " - " + (item.dien_thoai || ""),
		start: new Date(new Date(item?.exfields?.thoi_gian_hen)),
		end: new Date(new Date(item?.exfields?.thoi_gian_hen)),
		description: item,
		trang_thai_hen: item?.exfields?.trang_thai_hen,
	}));

	const eventPropGetter = (event, start, end, isSelected) => {
		let color;
		switch (event.trang_thai_hen) {
			case "Chưa đến":
				color = "#3174ad";
				break;
			case "Đã làm":
				color = "green";
				break;
			default:
				color = "red";
		}

		return {
			style: {
				backgroundColor: color,
			},
		};
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center">
				{contextHolder}
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Lịch hẹn danh sách đặt lịch
					</span>
				</div>
			</div>

			<div>
				<Calendar
					localizer={localizer}
					events={myEventsList}
					eventPropGetter={eventPropGetter}
					startAccessor="start"
					endAccessor="end"
					style={{
						height: "calc(100vh - 104px)",
						minHeight: 500,
					}}
					selectable
					onSelectSlot={handleSlotSelect}
					onSelectEvent={handleEventSelect}
					messages={messages}
				/>
			</div>

			<ModalBasic
				zIndex={1035}
				id={"Booking-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen || "Chưa đến",
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							trang_thai_hd: "Lập chứng từ",
						},
						pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
						ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
						ma_kho: "KCTY",
						ten_kho: "Kho công ty",
						ma_dvcs: dataDefault?.dvcs?._id,
						tk_cn_thanhtoan: dataDefault?.ptthanhtoan?.tk_cn,
						trang_thai: trangthai,
					});
					setIsOpenForm(false);
				}}
				data={{
					ma_lich_hen: randomChars(4),
					thoi_gian_hen: new Date().toISOString(),
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"Booking-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						trang_thai: trangthai,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: data?.nguon_tao || "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							// ten_dvcs: data?.ten_dvcs,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					ptthanhtoan_data: dataDetail?.ten_pt_thanh_toan,
					customer_kh:
						(dataDetail?.ten_kh || "") +
						" - " +
						(dataDetail?.ma_kh || ""),
					dmkho_data: dataDetail?.ten_kho,
					dvcs_data: dataDetail?.exfields?.ten_dvcs,
					trang_thai_hen:
						dataDetail?.exfields?.trang_thai_hen || "Chưa đến",
					loai_lich_hen: dataDetail?.exfields?.loai_lich_hen,
					nguoi_dat: dataDetail?.exfields?.nguoi_dat,
					nguon_tao: dataDetail?.exfields?.nguon_tao,
					ho_ten_dem: dataDetail?.exfields?.ho_ten_dem,
					ma_lich_hen:
						dataDetail?.exfields?.ma_lich_hen || randomChars(4),
					ten_dvcs: dataDetail?.exfields?.ten_dvcs,
					thoi_gian_hen: dataDetail?.exfields?.thoi_gian_hen || null,
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>
		</div>
	);
}
