import { Col, DatePicker, Form, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../../components/inputAnt/selectAdd";
import ItemMedicine from "../../item/ItemMedicine";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function Prescription({ setFieldsValue, getFieldValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [ngayToaThuoc, setNgayToaThuoc] = useState();

	useEffect(() => {
		if (getFieldValue("ngay") === null) {
			setNgayToaThuoc(null);
		} else {
			setNgayToaThuoc(
				dayjs(getFieldValue("ngay")).format(dateFormatList[0])
			);
		}
	}, [getFieldValue("ngay")]);

	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* nguồn - tạo ngày */}
			<Form.Item name="ngay"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Ngày"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<DatePicker
						placeholder="chọn ngày"
						value={
							ngayToaThuoc
								? dayjs(ngayToaThuoc, dateFormatList[0])
								: null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNgayToaThuoc(
								dayjs(date).format(dateFormatList[0])
							);
							setFieldsValue("ngay", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dmnv"
					fieldsAdd={["ma_nv", "ten_nv"]}
					label="Bác sĩ"
					name="bac_si"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn bác sĩ"
				/>
			</Col>

			<Form.Item name="medicines"></Form.Item>
			<ItemMedicine
				setFieldsValue={setFieldsValue}
				getFieldValue={getFieldValue}
			/>
		</Row>
	);
}
