import { Button, Popconfirm, Image } from "antd";
import React, { useState } from "react";
import { LuEye, LuPencil, LuX } from "react-icons/lu";

export default function ItemImage({ key, data, handleDelete }) {
	const [visible, setVisible] = useState(false);
	return (
		<div
			key={key}
			className="flex flex-col justify-start items-start gap-2"
		>
			<div className="w-[200px] h-[100px] pb-1 border-b-2 border-dashed">
				<img
					src={data}
					alt="image_1"
					className="w-full h-full object-contain"
				/>
			</div>
			<div className="flex justify-center w-full">
				<Button
					className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
					shape="circle"
					size="small"
					onClick={() => setVisible(true)}
				>
					<LuEye size={18} />
				</Button>
				{/* <Button
					className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
					shape="circle"
					size="small"
					onClick={() => {
						alert(
							"Vui lòng xoá và tải ảnh mới!!! Chức năng đang phát triển."
						);
					}}
				>
					<LuPencil size={18} />
				</Button> */}
				<Popconfirm
					title={`Bạn muốn xoá hình này`}
					onConfirm={() => handleDelete(data)} // Sử dụng item.onClick thay vì handleDelete(row._id)
					okText="Có"
					cancelText="Không"
				>
					<Button
						className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
						shape="circle"
						size="small"
					>
						<LuX size={18} />
					</Button>
				</Popconfirm>
				<Image
					width={0}
					style={{
						display: "none",
					}}
					src=""
					preview={{
						zIndex: 100000,
						visible,
						scaleStep: 0.1,
						src: data,
						onVisibleChange: (value) => {
							setVisible(value);
						},
					}}
				/>
			</div>
		</div>
	);
}
