import { message } from "antd";
import React, { useEffect, useState } from "react";
import {
	handleCreate,
	handleGetDataNoId,
	handleUpdate,
} from "../../../utils/utilsApi";
import SelectAdd from "../../components/inputAnt/selectAdd";
import "../managerServices.scss";
import ExtraScreen from "./Form/ExtraScreen";
import InfomationScreen from "./Form/InfomationScreen";
import ModalScreen from "./ModalScreen/ModalScreen";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import DrawerModel from "../../components/modal/drawerModel";

export default function ScreenServices() {
	const [messageApi, contextHolder] = message.useMessage();

	useEffect(() => {
		setIsOpenScreen(true);
	}, []);

	const [dataCustomer, setDataCustomer] = useState({});
	const [loading, setLoading] = useState(false);

	const formSections = [
		{
			id: "thongtin",
			title: "Thông tin",
			content: (
				<InfomationScreen
					dataModal={{
						ngay_sinh: dataCustomer?.ngay_sinh,
						ngay_kham_dau: dataCustomer?.ngay_kham_dau,
						ngay_kham_moi: dataCustomer?.ngay_kham_moi,
						ma_kh: dataCustomer?.ma_kh,
						tieu_su_benh: dataCustomer?.exfields?.tieu_su_benh,
						tsb_dien_giai: dataCustomer?.exfields?.tsb_dien_giai,
					}}
				/>
			),
		},
		{
			id: "trichxuat",
			title: "Trích xuất",
			content: (
				<ExtraScreen
					dataModal={dataCustomer?.exfields?.trich_xuat_bn}
				/>
			),
		},
	];

	const handleOnRowClick = async (record) => {
		const itemsCustomer = await handleGetDataNoId({
			api: "customer",
			q: {
				trang_thai: 1,
				status: true,
				isbuyer: true,
				kh_yn: true,
				loai_kh: {
					$nin: ["Vendor", "Staff"],
				},
				ma_kh: record?.ma_kh,
				ten_kh: record?.ten_kh,
			},
			setLoading: setLoading,
		});
		setDataCustomer(itemsCustomer[0]);
	};

	const handleSaveData = async (data) => {
		const dataModal = await handleCreate({
			api: "customer",
			data,
			messageApi,
			setLoading,
		});
		await handleOnRowClick(dataModal?.data);
	};

	const handleUpdateData = async (data) => {
		const dataModal = await handleUpdate({
			api: "customer",
			data,
			messageApi,
			setLoading,
		});
		setDataCustomer(dataModal?.data);
	};

	const handleManagerData = async (data) => {
		if (data?._id) {
			await handleUpdateData(data);
		} else {
			await handleSaveData(data);
		}
	};

	const [isOpenScreen, setIsOpenScreen] = useState(false);

	return (
		<div>
			<div id="fullscreen2" className="relative">
				{contextHolder}
				<div
					onClick={() => {
						setIsOpenScreen(!isOpenScreen);
					}}
					className="absolute top-2 right-0 hover:text-[#186b9e] cursor-pointer bg-white"
				>
					{isOpenScreen ? (
						<AiOutlineFullscreenExit className="w-8 h-8" />
					) : (
						<AiOutlineFullscreen className="w-8 h-8" />
					)}
				</div>
				<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
					<div className="flex justify-center items-center max-lg:pb-2 gap-2">
						<span className="text-[#186b9e] font-semibold uppercase">
							Hồ sơ bệnh nhân
						</span>
					</div>
					<div className="flex justify-end items-center gap-6">
						<div className="w-[300px] max-sm:w-[200px] style-form-item">
							<SelectAdd
								api="customer"
								q={{
									trang_thai: 1,
									status: true,
									isbuyer: true,
									kh_yn: true,
									loai_kh: {
										$nin: ["Vendor", "Staff"],
									},
								}}
								name="customer_data"
								lableOption={["ten_kh", "ma_kh"]}
								valueOption={["ma_kh", "ten_kh", "dien_thoai"]}
								placeholder="nhập ID, SĐT tìm khách hàng"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										handleOnRowClick(parsedValue);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<ModalScreen
					idModal={"ModalScreen-edit"}
					formSections={formSections}
					handleManager={(data) => {
						handleManagerData({
							...dataCustomer,
							...data,
							exfields: {
								...dataCustomer?.exfields,
								trich_xuat_bn: data?.trich_xuat_bn,
								tieu_su_benh: data?.tieu_su_benh,
								tsb_dien_giai: data?.tsb_dien_giai,
							},
						});
					}}
					data={{
						...dataCustomer,
						trich_xuat_bn: dataCustomer?.exfields?.trich_xuat_bn,
						tieu_su_benh: dataCustomer?.exfields?.tieu_su_benh,
						tsb_dien_giai: dataCustomer?.exfields?.tsb_dien_giai,
						...dataCustomer?.exfields?.tsb_dien_giai,
					}}
				/>
			</div>
			<DrawerModel
				title={null}
				footer={null}
				placement={"top"}
				open={isOpenScreen}
				onClose={() => {
					setIsOpenScreen(false);
				}}
				width={"100%"}
				height={"100%"}
				className="drawerModal-screen-services"
			>
				<div id="fullscreen2" className="relative">
					{contextHolder}
					<div
						onClick={() => {
							setIsOpenScreen(!isOpenScreen);
						}}
						className="absolute top-2 right-0 hover:text-[#186b9e] cursor-pointer bg-white"
					>
						{isOpenScreen ? (
							<AiOutlineFullscreenExit className="w-8 h-8" />
						) : (
							<AiOutlineFullscreen className="w-8 h-8" />
						)}
					</div>
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span className="text-[#186b9e] font-semibold uppercase">
								Hồ sơ bệnh nhân
							</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<div className="w-[300px] max-sm:w-[200px] style-form-item">
								<SelectAdd
									api="customer"
									q={{
										trang_thai: 1,
										status: true,
										isbuyer: true,
										kh_yn: true,
										loai_kh: {
											$nin: ["Vendor", "Staff"],
										},
									}}
									name="customer_data"
									lableOption={["ten_kh", "ma_kh"]}
									valueOption={[
										"ma_kh",
										"ten_kh",
										"dien_thoai",
									]}
									placeholder="nhập ID, SĐT tìm khách hàng"
									onChange={(value) => {
										if (value) {
											const parsedValue =
												JSON.parse(value);
											handleOnRowClick(parsedValue);
										}
									}}
								/>
							</div>
						</div>
					</div>
					<ModalScreen
						idModal={"ModalScreen-edit"}
						formSections={formSections}
						handleManager={(data) => {
							handleManagerData({
								...dataCustomer,
								...data,
								exfields: {
									...dataCustomer?.exfields,
									trich_xuat_bn: data?.trich_xuat_bn,
									tieu_su_benh: data?.tieu_su_benh,
									tsb_dien_giai: data?.tsb_dien_giai,
								},
							});
						}}
						data={{
							...dataCustomer,
							trich_xuat_bn:
								dataCustomer?.exfields?.trich_xuat_bn,
							tieu_su_benh: dataCustomer?.exfields?.tieu_su_benh,
							tsb_dien_giai:
								dataCustomer?.exfields?.tsb_dien_giai,
							...dataCustomer?.exfields?.tsb_dien_giai,
						}}
					/>
				</div>
			</DrawerModel>
		</div>
	);
}
