import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
	Divider,
	Input,
	Select,
	Space,
	Button,
	Form,
	Row,
	Col,
	Checkbox,
} from "antd";
import { request } from "../../../utils/request";
import { debounce } from "lodash";

const SelectAddStaffVendor = ({
	label = "",
	name,
	labelCol,
	wrapperCol,
	rules,
	disabled = false,
	api = "dmnv",
	q,
	IdApp = true,
	dataAdd,
	lableOption = ["ten_nv"],
	valueOption = ["ten_nv"],
	searchOption = ["ten_nv"],
	placeholder = "custom dropdown render",
	style,
	dropdownHeight = 300,
	onChange,
}) => {
	// const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const selectRef = useRef(null);
	// const [page, setPage] = useState(1);

	useEffect(() => {
		// setPage(1);
	}, [q]);

	const handelGetData = async ({ endpoint, params }) => {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		try {
			// setLoading(true);
			const response = await request.get(`api/${newIdApp}${endpoint}`, {
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
				params: {
					page: 1,
					limit: 100,
					q: JSON.stringify(params.q),
				},
			});
			const data = response.data.map((item) => item);
			setItems(data);
			// setItems(page === 1 ? data : [...items, ...data]);
			// setPage(page + 1);
			// setLoading(false);
			return response;
		} catch (error) {
			console.log(error.message);
			// setLoading(false);
		}
	};

	const handleAddData = async (data) => {
		try {
			const response = await request.post(
				`api/${localStorage.getItem("id_app")}/${api}`,
				data,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setItems([response.data, ...items]);
			return response;
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleDropdownVisibleChange = async (open) => {
		const query = {
			...q,
		};
		if (open) {
			await handelGetData({ endpoint: api, params: { q: query } });
		}
	};

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = async (value) => {
		const query = {
			...q,
		};

		if (value) {
			if (!query.$or) query.$or = [];
			searchOption.forEach((fieldName) => {
				const fieldQuery = {};
				fieldQuery[fieldName] = {
					$regex: value,
					$options: "i",
				};
				query.$or.push(fieldQuery);
			});
		}

		await handelGetData({ endpoint: api, params: { q: query } });
	};

	const handleScroll = () => {
		const selectNode = selectRef.current;
		if (selectNode) {
			const { scrollTop, scrollHeight, clientHeight } = selectNode;
			if (scrollTop + clientHeight === scrollHeight) {
				// Đã cuộn đến cuối, gọi hàm để load thêm dữ liệu
				handelGetData({ endpoint: api, params: q });
			}
		}
	};

	const formSpeedRef = useRef(null);

	const onFinish = (values) => {
		handleAddData({
			ten_kh: values.ten_kh,
			kh_yn: false,
			ncc_yn: !values.Staff,
			iscarrier: values.Staff,
			loai_kh: values.Staff ? "Staff" : "Vendor",
			...dataAdd,
		});
		onReset();
	};
	const onReset = () => {
		formSpeedRef.current?.resetFields();
	};

	const setFieldsValue = (name, value) => {
		formSpeedRef.current?.setFieldsValue({ [name]: value });
	};

	return (
		<Form.Item
			name={name}
			label={label}
			labelCol={labelCol}
			wrapperCol={wrapperCol}
			rules={rules}
		>
			<Select
				allowClear
				disabled={disabled}
				showSearch
				style={style}
				placeholder={placeholder}
				dropdownRender={(menu) => (
					<>
						<div
							ref={selectRef}
							style={{
								maxHeight: dropdownHeight,
								overflowY: "auto",
								overflowX: "hidden",
							}}
							onScroll={handleScroll}
						>
							{/* {loading && <div>Loading...</div>} */}
							{menu}
						</div>
						<Divider
							style={{
								margin: "8px 0",
							}}
						/>
						<Space
							style={{
								padding: "0 8px 4px",
								backgroundColor: "#fafafa",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
							}}
						>
							<Form
								id={"speed_add"}
								ref={formSpeedRef}
								name="control-ref"
								onFinish={onFinish}
							>
								<Row className="!m-0" gutter={[4, 4]}>
									{/* Tên customer - loại */}
									<Col span={18}>
										<Form.Item
											name="ten_kh"
											wrapperCol={{
												span: 24,
											}}
										>
											<Input
												placeholder="Nhập tên..."
												allowClear
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											name="Staff"
											wrapperCol={{
												span: 24,
											}}
										>
											<Checkbox
												onChange={(e) =>
													setFieldsValue(
														"Staff",
														e.target.checked
													)
												}
											>
												Bác sĩ
											</Checkbox>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Button
											type="text"
											icon={<PlusOutlined />}
											htmlType="submit"
										>
											Tạo nhanh
										</Button>
									</Col>
								</Row>
							</Form>
						</Space>
					</>
				)}
				options={items.map((item) => ({
					value:
						valueOption && valueOption.length > 1
							? JSON.stringify(
									valueOption.reduce((acc, option) => {
										acc[option] = item[option];
										return acc;
									}, {})
							  )
							: item[valueOption[0]],
					label:
						lableOption && lableOption.length > 1
							? lableOption
									.map((option) => item[option])
									.join(" - ")
							: item[lableOption[0]],
				}))}
				onDropdownVisibleChange={handleDropdownVisibleChange}
				onSearch={(value) => {
					DebouncedSearch(value);
				}}
				s
				onChange={onChange}
			/>
		</Form.Item>
	);
};

export default SelectAddStaffVendor;
