import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormDmImplant from "../Form/FormDmImplant";

export default function ModalDMImplant({
	dataImage,
	isTabletOrMobile,
	dataDetail,
	handleManagerData,
	isOpenForm,
	setIsOpenForm,
}) {
	return (
		<div>
			<ModalBasic
				// zIndex={1035}
				id={"implant-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Hãng Implant</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleManagerData({
						...data,
						ma_dvt: "Bộ",
						exfields: {
							hang_implant: data?.hang_implant,
							do_cung: data?.do_cung,
							quoc_gia: data?.quoc_gia,
							so_nam_bh: data?.so_nam_bh,
							gia_uu_dai: data?.gia_uu_dai,
							dien_giai: data?.dien_giai,
							loai_vat_tu: "Implant",
						},
					});
					setIsOpenForm(false);
				}}
			>
				<FormDmImplant />
			</ModalBasic>

			<ModalBasic
				// zIndex={1035}
				id={"implant-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Hãng Implant</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleManagerData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							hang_implant: data?.hang_implant,
							do_cung: data?.do_cung,
							quoc_gia: data?.quoc_gia,
							so_nam_bh: data?.so_nam_bh,
							gia_uu_dai: data?.gia_uu_dai,
							dien_giai: data?.dien_giai,
							loai_vat_tu: "Implant",
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					picture: dataDetail?.picture,
					ten_vt: dataDetail?.ten_vt,
					gia_ban_le: dataDetail?.gia_ban_le,
					hang_implant: dataDetail?.exfields?.hang_implant,
					do_cung: dataDetail?.exfields?.do_cung,
					quoc_gia: dataDetail?.exfields?.quoc_gia,
					so_nam_bh: dataDetail?.exfields?.so_nam_bh,
					gia_uu_dai: dataDetail?.exfields?.gia_uu_dai,
					dien_giai: dataDetail?.exfields?.dien_giai,
				}}
			>
				<FormDmImplant dataImage={dataImage} />
			</ModalBasic>
		</div>
	);
}
