import { Button } from "antd";
import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormChietKhau from "../form/formItem/FormChietKhau";

export default function ModalChietKhau({
	isOpenForm,
	setIsOpenForm,
	handleManager,
	data,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"chietkhau-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chiết khấu tổng hoá đơn</span>
					</div>
				}
				open={isOpenForm === "chietkhau" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={handleManager}
				data={data}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormChietKhau />
			</ModalBasic>
		</div>
	);
}
