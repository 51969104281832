import { Button, Input, Radio, Select, Space, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import unidecode from "unidecode";
import { request } from "../../../../utils/request";
import EditorDraftWysiwyg from "../../../components/editorDraftWysiwyg/editorDraftWysiwyg";
import PopoverModel from "../../../components/modal/popoverModel";
import "./ManagerServices.scss";
import ItemUploadFile from "../../components/itemUploadFile";
import InputNumber from "../../../components/inputAnt/inputNumber";

export default function ManagerServices() {
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [dataServices, setDataServices] = useState({
		ten_vt: "Services Name",
		exfields: {
			status_post: "0",
			slug: "services-name",
			tags: [],
			mo_ta_SEO: "",
		},
		gia_ban_le: 0,
		mieu_ta: "",
	});
	const [loading, setLoading] = useState(false);

	const handleGetDataItemServices = async (idService) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/dmvt/${idService}`,
				{
					params: {
						q: JSON.stringify({
							is_service: true,
							status: true,
							"exfields.loai_vat_tu": "Services",
						}),
					},
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "application/json",
					},
				}
			);
			setDataServices(response?.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			//throw error;
		}
	};

	const href = window.location.href;
	const idService = href.split("/")[href.split("/").length - 1];
	useEffect(() => {
		if (idService !== "create") {
			handleGetDataItemServices(idService);
		} else return;
	}, [idService]);

	//editor content
	const handleGetHtml = (contentState, contentHtml) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				editorhtml: contentState,
				testContent: document.querySelector('[data-contents="true"]')
					.innerHTML,
			},
			mieu_ta: contentHtml,
		});
	};

	//Hình ảnh
	const [dataImageChoose, setDataImageChoose] = useState({
		picture: dataServices?.picture,
	});

	useEffect(() => {
		setDataImageChoose({
			picture: dataServices?.picture,
		});
	}, [dataServices?.picture]);

	//Hình ảnh banner
	const [dataBannerChoose, setDataBannerChoose] = useState({
		picture: dataServices?.exfields?.banner,
	});

	useEffect(() => {
		setDataBannerChoose({
			picture: dataServices?.exfields?.banner,
		});
	}, [dataServices?.exfields?.banner]);

	//Trạng thái hiển thị của post
	const dataStatus = [
		{
			value: "0",
			label: "Công khai",
			description: "Hiển thị tới tất cả.",
		},
		{
			value: "1",
			label: "Riêng tư",
			description: "Chỉ hiển thị cho quản trị viên và biên tập viên",
		},
		{
			value: "2",
			label: "Mật khẩu",
			description:
				"Chỉ những người có mật khẩu mới có thể xem bài viết này.",
		},
	];
	const onChangeStatus = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				status_post: e.target.value,
			},
		});
	};

	//Url
	const onChangeUrl = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				slug: e.target.value?.trim().replace(/ /g, "-"),
			},
		});
	};

	//Giá bán lẻ
	const onChangeGiabanle = (value) => {
		setDataServices({
			...dataServices,
			gia_ban_le: value,
		});
	};

	//Gắn tags
	const options = [];
	const handleChangeTags = (value) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				tags: value,
			},
		});
	};

	//Gắn mandates
	const optionsMandates = [];
	const handleChangeMandates = (value) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				mandates: value,
			},
		});
	};

	//Mô tả banner
	const onChangeDesBanner = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				mo_ta_banner: e.target.value,
			},
		});
	};

	//Tiêu đề
	const onChangeTitle = (e) => {
		setDataServices({
			...dataServices,
			ten_vt: e.target.value,
			exfields: {
				...dataServices.exfields,
				slug: _.chain(unidecode(e.target.value))
					.trim() // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
					.deburr() // Loại bỏ dấu
					.toLower() // Chuyển thành chữ thường
					.replace(/ /g, "-") // Thay thế khoảng trắng bằng dấu -
					.replace(/\?/g, "") // Loại bỏ ký tự "?"
					.value(), // Lấy giá trị cuối cùng của chuỗi xử lý,
			},
		});
	};

	//Gắn keywords seo
	const optionsKeySEO = [];
	const handleChangeKeySEO = (value) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				keywords_seo: value,
			},
		});
	};

	// Title SEO
	const onChangeTitleSEO = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				title_seo: e.target.value,
			},
		});
	};

	//Mô tả 2
	const onChangeDes = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				mo_ta_ngan2: e.target.value,
			},
		});
	};

	//Mô tả SEO
	const onChangeDesSeo = (e) => {
		setDataServices({
			...dataServices,
			exfields: {
				...dataServices.exfields,
				mo_ta_SEO: e.target.value,
			},
		});
	};

	//Manager services
	const handManagerServices = async (link) => {
		if (dataServices?.ten_vt === "" || dataServices?.ten_vt === undefined) {
			messageApi.open({
				type: "error",
				content: "Nhập Tên trước khi lưu!!!",
			});
		} else {
			if (idService !== "create") {
				setLoading(true);
				try {
					await request.put(
						`api/${localStorage.getItem(
							"id_app"
						)}/dmvt/${idService}`,
						{
							...dataServices,
							picture: dataImageChoose.picture,
							exfields: {
								...dataServices.exfields,
								banner: dataBannerChoose.picture,
							},
						},
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Thay đổi thành công",
					});
					link && navigate(link);
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Thay đổi thất bại",
					});
					console.log(error);
				} finally {
					setLoading(false);
				}
			} else {
				setLoading(true);
				try {
					const response = await request.post(
						`api/${localStorage.getItem("id_app")}/dmvt`,
						{
							...dataServices,
							picture: dataImageChoose.picture,
							exfields: {
								...dataServices.exfields,
								banner: dataBannerChoose.picture,
								loai_vat_tu: "Services",
							},
							is_service: true,
							ma_dvt: "Chiếc",
							ten_dvt: "Chiếc",
						},
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
					setLoading(false);
					messageApi.open({
						type: "success",
						content: "Tạo thành công",
					});
					link
						? navigate(link)
						: navigate(
								`/services/managerServices/${response?.data?._id}`
						  );
				} catch (error) {
					setLoading(false);
					messageApi.open({
						type: "error",
						content: "Tạo thất bại",
					});
					console.log(error);
				} finally {
					setLoading(false);
				}
			}
		}
	};

	return (
		<Spin spinning={loading}>
			<div className="box_create_service flex h-[calc(100vh-54px)] max-h-[calc(100vh-54px)]">
				{contextHolder}
				<div className="w-[calc(100vw-350px)] h-full bg-slate-50">
					<EditorDraftWysiwyg
						handleGetHtml={handleGetHtml}
						data={dataServices?.exfields?.editorhtml}
					/>
				</div>
				<div className="w-[350px] h-full min-w-[350px]">
					<div className="flex justify-between items-center px-2 py-[6px] border-b mb-4">
						<span className="font-bold text-lg text-[#326b97]">
							Thông tin
						</span>
						<div className="flex justify-end items-center">
							<Button
								title="Save"
								type="submit"
								onClick={() => {
									handManagerServices();
								}}
								className="!border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave />
							</Button>
							<hr className="w-[1px] h-4 bg-slate-600" />
							<Button
								title="Save And Out"
								type="submit"
								onClick={() => {
									handManagerServices("/services");
								}}
								className="flex items-center gap-2 !border-none hover:!bg-slate-50 hover:!border-[#09395e] hover:!text-[#09395e] text-[#326b97] font-medium shadow-none"
							>
								<FiSave /> & <HiOutlineLogout />
							</Button>
						</div>
					</div>
					<div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-4 border-b pb-4 h-[calc(100%-60px)] max-h-[calc(100%-60px)] overflow-auto">
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Tên dịch vụ
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Input
										placeholder="Tên dịch vụ"
										defaultValue={dataServices.ten_vt}
										onChange={onChangeTitle}
									/>
								}
								title={
									<span className="text-xs font-normal">
										Tên dịch vụ
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{dataServices?.ten_vt}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Hiển thị
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<Radio.Group
										der
										name="radiogroup"
										defaultValue={
											dataServices?.exfields?.status_post
										}
										size="small"
										onChange={onChangeStatus}
									>
										<Space direction="vertical">
											{dataStatus.map((item, index) => {
												return (
													<div key={index}>
														<Radio
															value={item.value}
														>
															<span className="text-[#326b97] text-[10px]">
																{item.label}
															</span>
														</Radio>
														<span className="text-[10px] pl-6">
															{item.description}
														</span>
													</div>
												);
											})}
										</Space>
									</Radio.Group>
								}
								title={
									<span className="text-xs font-normal">
										Hiển thị
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span className="text-[#09395e] text-xs cursor-pointer hover:underline">
									{
										dataStatus[
											dataServices?.exfields?.status_post
										]?.label
									}
								</span>
							</PopoverModel>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Đăng
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<span className="text-[#09395e] text-xs">
								{moment(dataServices.date_created).format(
									"DD/MM/YYYY"
								)}
							</span>
						</div>
						<div className="flex justify-start items-start text-left px-2 gap-1 w-full">
							<span className="text-[#326b97] text-xs min-w-[30%]">
								Url
							</span>
							<span className="text-[#326b97] text-xs">:</span>
							<PopoverModel
								content={
									<div className="flex flex-col">
										<TextArea
											rows={3}
											placeholder="path page"
											defaultValue={dataServices?.exfields?.slug
												?.trim()
												.replace(/-/g, " ")}
											onChange={onChangeUrl}
										/>
										<span className="uppercase text-[10px] mt-3 pl-1">
											Xem trước:
										</span>
										<a
											href={`${localStorage.getItem(
												localStorage.getItem("id_app")
											)}services/${
												dataServices?.exfields?.slug
											}`}
											target="_blank"
											rel="noreferrer"
										>
											<div className="pl-1 text-[#09395e] text-[10px] hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer w-[300px]">
												{`${localStorage.getItem(
													localStorage.getItem(
														"id_app"
													)
												)}services/` +
													dataServices?.exfields
														?.slug}
											</div>
										</a>
									</div>
								}
								title={
									<span className="text-xs font-normal">
										Đường dẫn tĩnh
									</span>
								}
								trigger="click"
								arrow={true}
							>
								<span
									className={
										"text-[#09395e] text-xs hover:underline overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
									}
								>
									{`${localStorage.getItem(
										localStorage.getItem("id_app")
									)}services/` + dataServices?.exfields?.slug}
								</span>
							</PopoverModel>
						</div>

						<div className="flex flex-col gap-3 px-2 w-full">
							<span className="text-[#326b97] text-xs uppercase text-left">
								Giá bán lẻ
							</span>{" "}
							<InputNumber
								onChange={onChangeGiabanle}
								value={dataServices?.gia_ban_le}
								placeholder={"Nhập giá bán lẻ"}
							/>
							<span className="text-[#326b97] text-xs uppercase text-left">
								Tags
							</span>
							<Select
								mode="tags"
								style={{
									width: "100%",
								}}
								onChange={handleChangeTags}
								tokenSeparators={[","]}
								options={options}
								value={dataServices?.exfields?.tags}
							/>
							<hr className="w-full h-1 mt-3" />
							<span className="text-[#326b97] text-xs uppercase text-left flex flex-col justify-start items-start gap-1">
								Hình ảnh
								<ItemUploadFile
									setData={setDataImageChoose}
									data={dataImageChoose}
								/>
							</span>
							<span className="text-[#326b97] text-xs uppercase text-left">
								mô tả
							</span>{" "}
							<TextArea
								rows={3}
								onChange={onChangeDes}
								value={dataServices?.exfields?.mo_ta_ngan2}
							/>
							<span className="text-[#326b97] text-xs uppercase text-left">
								Chức năng
							</span>
							<Select
								mode="tags"
								style={{
									width: "100%",
								}}
								onChange={handleChangeMandates}
								tokenSeparators={[","]}
								options={optionsMandates}
								value={dataServices?.exfields?.mandates}
							/>
							<hr className="w-full h-1 mt-3" />
							<span className="text-[#326b97] text-xs uppercase text-left flex flex-col justify-start items-start gap-1">
								Hình ảnh banner
								<ItemUploadFile
									setData={setDataBannerChoose}
									data={dataBannerChoose}
								/>
							</span>
							<span className="text-[#326b97] text-xs uppercase text-left">
								mô tả banner
							</span>{" "}
							<TextArea
								rows={3}
								onChange={onChangeDesBanner}
								value={dataServices?.exfields?.mo_ta_banner}
							/>
							<hr className="w-full h-1 mt-3" />
							<div className="flex flex-col justify-start items-start gap-3">
								<span className="text-[#326b97] text-xs uppercase text-left">
									Từ khoá SEO
								</span>
								<Select
									mode="tags"
									style={{
										width: "100%",
									}}
									onChange={handleChangeKeySEO}
									tokenSeparators={[","]}
									options={optionsKeySEO}
									value={dataServices?.exfields?.keywords_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Tiêu đề SEO
								</span>{" "}
								<TextArea
									rows={2}
									onChange={onChangeTitleSEO}
									value={dataServices?.exfields?.title_seo}
								/>
								<span className="text-[#326b97] text-xs uppercase text-left">
									Mô tả SEO
								</span>{" "}
								<TextArea
									rows={3}
									onChange={onChangeDesSeo}
									value={dataServices?.exfields?.mo_ta_SEO}
								/>
							</div>
							<hr className="w-full h-1 mt-3" />
							<Button
								type="submit"
								// onClick={handManagerPost}
								className="!border-[#1249c0] hover:!bg-slate-50 hover:!border-[#1249c0] hover:!text-[#1249c0] text-[#3e6bce] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Lưu bản nháp
							</Button>
							<Button
								type="submit"
								// onClick={handManagerPost}
								className="!border-[#c02612] hover:!bg-slate-50 hover:!border-[#c02612] hover:!text-[#c02612] text-[#c44b3b] font-medium shadow-none flex justify-center items-center gap-1 w-full"
							>
								Di chuyển đến thùng rác
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Spin>
	);
}
