import { Col, Form, Input, InputNumber, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { useMediaQuery } from "react-responsive";
import UploadImageAuto from "../../../components/upLoad/uploadImageAuto";
dayjs.extend(customParseFormat);

export default function FormDmImplant({
	dataImage,
	setFieldsValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const handleSetFieldsValue = (value) => {
		setFieldsValue("picture", value);
	};

	return (
		<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
			{/* Tên Implant - Hãng Implant */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Tên Implant"
					name="ten_vt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="Nhập tên Implant" allowClear />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Hãng Implant"
					name="hang_implant"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="Nhập hãng Implant" allowClear />
				</Form.Item>
			</Col>

			{/* Độ cứng - Quốc gia */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Độ cứng"
					name="do_cung"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="nhập độ cứng" allowClear />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Quốc gia"
					name="quoc_gia"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="Nhập quốc gia" allowClear />
				</Form.Item>
			</Col>

			{/* Giá niêm yết - Số năm BH */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Giá niêm yết"
					name="gia_ban_le"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Số năm BH"
					name="so_nam_bh"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="Nhập số năm BH" allowClear />
				</Form.Item>
			</Col>

			{/* (Giá ưu đãi - Ghi chú khác) - Hình ảnh */}

			<Col span={isMobile ? 24 : 12}>
				<Col span={24}>
					<Form.Item
						label="Giá ưu đãi"
						name="gia_uu_dai"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<InputNumber
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
							style={{ width: "100%" }}
							addonAfter={
								<div className="w-[30px] text-center">VND</div>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name="dien_giai"
						label="Ghi chú khác"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input.TextArea rows={5} placeholder="nhập ghi chú" />
					</Form.Item>
				</Col>
			</Col>
			<Form.Item name="picture"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<div className="w-full flex flex-col justify-center items-center gap-2">
					<span className="w-full text-center mr-2">
						Hình ảnh đại diện
					</span>
					<UploadImageAuto
						dataImage={dataImage}
						handleSetFieldsValue={handleSetFieldsValue}
					/>
				</div>
			</Col>
		</Row>
	);
}
