import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import TableManagerCus from "../../../components/table/tableManagerCus";
import ModalDetailPt from "../Modal/Item/ModalDetailPt";

export default function ItemDetailPt({ setFieldsValue, getFieldValue }) {
	const [dataDetailItem, setDataDetailItem] = useState({});
	const [isOpenFormItem, setIsOpenFormItem] = useState(false);

	const [dataModalDetail, setDataModalDetail] = useState([]);
	useEffect(() => {
		setDataModalDetail(getFieldValue("details") || []);
	}, [getFieldValue("details")]);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenFormItem("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenFormItem("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (data) => {
				handleDeleteData(data);
			},
			label: "Delete",
		},
	];
	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Khách hàng",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: `Tiền ${getFieldValue("ten_nt")}`,
			dataIndex: "tien_nt",
			width: 150,
			render: (tien_nt) => {
				if (!tien_nt) {
					return "";
				} else return tien_nt.toLocaleString();
			},
			align: "right",
		},
		{
			title: "Tiền (VND)",
			dataIndex: "tien",
			width: 150,
			render: (tien) => {
				if (!tien) {
					return "";
				} else
					return tien.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
			},
			align: "right",
		},
		{
			title: "Diễn giải",
			dataIndex: "dien_giai",
			width: 150,
		},
	];

	const handleSaveData = (dataModal) => {
		setDataModalDetail([...dataModalDetail, dataModal]);
		setFieldsValue("details", [...dataModalDetail, dataModal]);
	};

	const handleUpdateData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) =>
					JSON.stringify(item) === JSON.stringify(dataDetailItem)
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	const handleManagerDataItem = (dataModal) => {
		if (isOpenFormItem === "create") {
			handleSaveData(dataModal);
		} else {
			handleUpdateData(dataModal);
		}
	};

	const handleDeleteData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => JSON.stringify(item) === JSON.stringify(dataModal)
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	return (
		<div className="relative">
			<TableManagerCus
				id="dataItemDetailPt-tab"
				data={dataModalDetail}
				columns={columns}
				scroll={{ x: 600, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenFormItem("edit");
						setDataDetailItem(record);
					},
				})}
			/>
			<Button
				onClick={() => {
					setIsOpenFormItem("create");
				}}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Thêm
			</Button>
			<ModalDetailPt
				getFieldValue={getFieldValue}
				isOpenFormItem={isOpenFormItem}
				setIsOpenFormItem={setIsOpenFormItem}
				dataDetailItem={dataDetailItem}
				handleManagerDataItem={handleManagerDataItem}
			/>
		</div>
	);
}
