import React from "react";
import LeftMenu from "../menu/leftMenu";
import Header from "../header/Header";
import IdApp from "./IdApp";

function LayoutDefault({ children, name }) {
	return (
		<IdApp>
			<div className="flex justify-end">
				<div
					id="left_menu"
					className="w-[80px] transition-all fixed z-20 left-0 h-full"
				>
					<LeftMenu />
				</div>
				<div
					id="body_content"
					className="w-[calc(100%-80px)] transition-all"
				>
					<div className="flex flex-col bg-[whitesmoke]">
						<Header />
						<div
							component="main"
							style={{
								minWidth: "360px",
								flexGrow: 1,
							}}
							className="pt-12 bg-[#fafafa] px-[28px] max-lg:px-2"
						>
							{children}
						</div>
					</div>
				</div>
			</div>
		</IdApp>
	);
}

export default LayoutDefault;
