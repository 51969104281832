import { Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../components/inputAnt/selectAdd";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function FormInfoPxkImplant({
	setFieldsValue,
	getFieldValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isMobile ? 24 : isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [ngayChungTu, setNgayChungTu] = useState();

	useEffect(() => {
		if (getFieldValue("ngay_ct") === null) {
			setNgayChungTu(null);
		} else {
			setNgayChungTu(
				dayjs(getFieldValue("ngay_ct")).format(dateFormatList[0])
			);
		}
	}, [getFieldValue("ngay_ct")]);

	return (
		<Row className="!m-0" gutter={[8, 8]}>
			{/* Trạng thái - Ngoại tệ */}
			<Form.Item name="details"></Form.Item>
			<Form.Item name="ten_trang_thai"></Form.Item>
			<Form.Item name="trang_thai"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="trangthai"
					q={{ status: true, ma_ct: "pt1" }}
					IdApp={false}
					label="Trạng thái"
					name="trangthai_data"
					valueOption={["ma_trang_thai", "ten_trang_thai"]}
					lableOption={["ten_trang_thai"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn trạng thái",
						},
					]}
					placeholder="chọn trạng thái"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue(
								"trang_thai",
								parsedValue?.ma_trang_thai
							);
							setFieldsValue(
								"ten_trang_thai",
								parsedValue?.ten_trang_thai
							);
						}
					}}
				/>
			</Col>
			<Form.Item name="ma_nt"></Form.Item>
			<Form.Item name="ten_nt"></Form.Item>
			<Form.Item name="ty_gia"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="currency"
					q={{ status: true }}
					label="Ngoại tệ"
					name="currency_data"
					valueOption={["ma_nt", "ten_nt", "ty_gia"]}
					lableOption={["ma_nt", "ty_gia"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn ngoại tệ",
						},
					]}
					placeholder="chọn ngoại tệ"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_nt", parsedValue?.ma_nt);
							setFieldsValue("ten_nt", parsedValue?.ten_nt);
							setFieldsValue("ty_gia", parsedValue?.ty_gia);
						}
					}}
				/>
			</Col>

			{/* Số chứng từ - Ngày chứng từ */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Số chứng từ"
					name="so_ct"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="SO_CT" />
				</Form.Item>
			</Col>
			<Form.Item name="ngay_ct"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Ngày chứng từ"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<DatePicker
						placeholder="chọn ngày chứng từ"
						value={
							ngayChungTu
								? dayjs(ngayChungTu, dateFormatList[0])
								: null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNgayChungTu(
								dayjs(date).format(dateFormatList[0])
							);
							setFieldsValue("ngay_ct", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
			</Col>

			{/* TK du - Khách hàng  */}
			<Form.Item name="tk_du"></Form.Item>
			<Form.Item name="ten_tk_du"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="account"
					q={{ status: true, loai_tk: 1 }}
					label="TK đối ứng"
					name="tk_du_data"
					valueOption={["tk", "ten_tk"]}
					lableOption={["tk", "ten_tk"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn TK đối ứng",
						},
					]}
					placeholder="nhập tên tài khoản"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ten_tk_du", parsedValue?.ten_tk);
							setFieldsValue("tk_du", parsedValue?.tk);
						}
					}}
				/>
			</Col>
			<Form.Item name="ten_kh"></Form.Item>
			<Form.Item name="ma_kh"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="customer"
					q={{
						status: true,
						kh_yn: true,
						loai_kh: {
							$nin: ["Vendor", "Staff"],
						},
					}}
					label="Khách hàng"
					name="customer_data"
					lableOption={["ten_kh", "ma_kh"]}
					valueOption={["ma_kh", "ten_kh"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn khách hàng"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ten_kh", parsedValue?.ten_kh);
							setFieldsValue("ma_kh", parsedValue?.ma_kh);
						}
					}}
				/>
			</Col>

			{/* Người giao dịch - Nhân viên */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					name="ong_ba"
					label="Người giao dịch"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="nhập tên" />
				</Form.Item>
			</Col>

			{/* Đơn vị - chi nhánh */}
			<Form.Item name="ten_dvcs"></Form.Item>
			<Form.Item name="ma_dvcs"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dvcs"
					q={{ status: true }}
					label="Đơn vị"
					name="dvcs_data"
					lableOption={["ten_dvcs"]}
					valueOption={["ten_dvcs", "_id"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn đợn vị",
						},
					]}
					placeholder="chọn đơn vị"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvcs", parsedValue?._id);
							setFieldsValue("ten_dvcs", parsedValue?.ten_dvcs);
						}
					}}
				/>
			</Col>
			<Form.Item name="ten_kho"></Form.Item>
			<Form.Item name="ma_kho"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dmkho"
					label="Chi nhánh"
					name="dmkho_data"
					lableOption={["ten_kho", "ma_kho"]}
					valueOption={["ten_kho", "ma_kho"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn chi nhánh"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ten_kho", parsedValue?.ten_kho);
							setFieldsValue("ma_kho", parsedValue?.ma_kho);
						}
					}}
				/>
			</Col>

			{/* ghi chú */}
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
						span: 3,
					}}
					wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
