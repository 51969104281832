import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { LoaiDatHen, TrangThai } from "../../../../utils/utilsOptionSelect";
import SelectAddCus from "../../../components/inputAnt/selectAddCus";
dayjs.extend(customParseFormat);

export default function FormBooking({
	children,
	getFieldValue,
	setFieldsValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [thoiGianHen, setThoiGianHen] = useState();
	useEffect(() => {
		if (getFieldValue("thoi_gian_hen") === null) {
			setThoiGianHen(null);
		} else {
			setThoiGianHen(
				dayjs(
					new Date(new Date(getFieldValue("thoi_gian_hen")))
				).format("DD/MM/YYYY HH:mm:ss")
			);
		}
	}, [getFieldValue("thoi_gian_hen")]);

	const [ngayTao, setNgayTao] = useState();

	useEffect(() => {
		if (getFieldValue("date_created") === null) {
			setNgayTao(null);
		} else {
			setNgayTao(
				dayjs(getFieldValue("date_created")).format("DD/MM/YYYY")
			);
		}
	}, [getFieldValue("date_created")]);

	return (
		<div>
			<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
				{/* field hidden */}
				<Form.Item name="details"></Form.Item>
				<Form.Item name="ten_kh"></Form.Item>
				<Form.Item name="ma_kh"></Form.Item>
				<Form.Item name="ho_ten_dem"></Form.Item>
				{/* Trạng thái - Mã đặt hẹn */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Trạng thái"
						name="trang_thai_hen"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Select
							defaultValue={"Chưa đến"}
							allowClear
							placeholder="Chọn trạng thái"
						>
							{TrangThai.map((item) => (
								<Option key={item.key} value={item.value}>
									{item.value}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Mã đặt hẹn"
						name="ma_lich_hen"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input placeholder="Mã chạy tự động" disabled />
					</Form.Item>
				</Col>

				{/* Tên khách - Loại lịch hẹn */}
				<Col span={isMobile ? 24 : 12}>
					<SelectAddCus
						api="customer"
						q={{
							status: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
						}}
						label="Tên khách"
						name="customer_kh"
						valueOption={[
							"ho_ten_dem",
							"ten_kh",
							"ma_kh",
							"dien_thoai",
						]}
						lableOption={["ten_kh", "ma_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng chọn khách",
							},
						]}
						placeholder="nhập tên khách"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
								setFieldsValue(
									"ho_ten_dem",
									parsedValue?.ho_ten_dem
								);
								setFieldsValue(
									"dien_thoai",
									parsedValue?.dien_thoai ||
										getFieldValue("dien_thoai")
								);
							}
						}}
					/>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Loại lịch hẹn"
						name="loai_lich_hen"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Select allowClear placeholder="Chọn loại">
							{LoaiDatHen.map((item) => (
								<Option key={item.key} value={item.value}>
									{item.value}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				{/* Điện thoại - Thời gian hẹn */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Điện thoại"
						name="dien_thoai"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						// rules={[
						// 	{
						// 		validator: (_, value, callback) => {
						// 			if (
						// 				value === undefined ||
						// 				value === null ||
						// 				value === ""
						// 			) {
						// 				// Cho phép ô nhập liệu để rỗng
						// 				callback();
						// 			} else if (
						// 				/[0-9]{10,11}/.test(value.toString())
						// 			) {
						// 				// Kiểm tra điều kiện cho số điện thoại (10 hoặc 11 chữ số)
						// 				callback();
						// 			} else {
						// 				callback("Số điện thoại không hợp lệ");
						// 			}
						// 		},
						// 	},
						// ]}
					>
						<Input allowClear placeholder="nhập điện thoại" />
					</Form.Item>
				</Col>
				<Form.Item name="thoi_gian_hen"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Thời gian hẹn"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<DatePicker
							placeholder="chọn thời gian"
							showTime
							value={
								thoiGianHen
									? dayjs(thoiGianHen, "DD/MM/YYYY HH:mm:ss")
									: null
							}
							format="DD/MM/YYYY HH:mm:ss"
							onChange={(date, dateString) => {
								setThoiGianHen(
									dayjs(date).format("DD/MM/YYYY HH:mm:ss")
								);
								setFieldsValue(
									"thoi_gian_hen",
									new Date(new Date(date)).toISOString()
								);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>

				{/* (Người đặt - Ghi chú khác) - (Nguồn tạo - Người tạo - Ngày tạo) */}
				<Col span={isMobile ? 24 : 12}>
					<Col span={24}>
						<Form.Item
							label="Người đặt"
							name="nguoi_dat"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input placeholder="nhập tên người đặt" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="dien_giai"
							label="Ghi chú khác"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input.TextArea
								rows={3}
								placeholder="nhập ghi chú"
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Col span={24}>
						<Form.Item
							label="Nguồn tạo"
							name="nguon_tao"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input
								bordered={false}
								disabled
								defaultValue={"Lập phiếu tay"}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Người tạo"
							name="name_user_created"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input bordered={false} disabled />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Ngày tạo"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<DatePicker
								value={
									ngayTao
										? dayjs(ngayTao, "DD/MM/YYYY")
										: null
								}
								format={"DD/MM/YYYY"}
								style={{
									width: "100%",
								}}
								disabled
								bordered={false}
							/>
						</Form.Item>
					</Col>
				</Col>
			</Row>
			{React.cloneElement(children, { getFieldValue, setFieldsValue })}
		</div>
	);
}
