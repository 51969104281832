import React from "react";
import { Col, Form, Row, InputNumber } from "antd";
import { useMediaQuery } from "react-responsive";

export default function FormChietKhau({ getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 18 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			<Col span={24}>
				<Form.Item
					label="Tổng tiền hoá đơn"
					name="t_tien_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						disabled
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Chiết khấu (%)"
					name="ty_le_ck_hd"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_ck_hd",
								(value * (getFieldValue("t_tien_nt") || 0)) /
									100
							);
							setFieldsValue(
								"t_tien_ck_hd",
								((100 - value) *
									(getFieldValue("t_tien_nt") || 0)) /
									100
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">%</div>
						}
						min={0}
						max={100}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Chiết khấu (tiền)"
					name="tien_ck_hd"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"t_tien_ck_hd",
								(getFieldValue("t_tien_nt") || 0) - value
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Tổng tiền chiết khấu"
					name="t_tien_ck_hd"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						disabled
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
