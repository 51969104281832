import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../components/inputAnt/selectAdd";
import SelectAddStaffVendor from "../../../components/inputAnt/selectAddStaffVendor";
dayjs.extend(customParseFormat);
export default function FormPn2({ children, getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 22 : 18 },
	};

	const [ngayChungTu, setNgayChungTu] = useState();
	useEffect(() => {
		if (getFieldValue("ngay_ct") === null) {
			setNgayChungTu(null);
		} else {
			setNgayChungTu(
				dayjs(getFieldValue("ngay_ct")).format("DD/MM/YYYY")
			);
		}
	}, [getFieldValue("ngay_ct")]);

	return (
		<div>
			<Row className="!m-0" gutter={[8, 8]}>
				{/* Trạng thái - Ngoại tệ */}
				<Form.Item name="details"></Form.Item>

				{/* Số chứng từ - Ngày chứng từ */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Số chứng từ"
						name="so_ct"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input placeholder="SO_CT" />
					</Form.Item>
				</Col>
				<Form.Item name="ngay_ct"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Ngày chứng từ"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<DatePicker
							placeholder="chọn ngày chứng từ"
							value={
								ngayChungTu
									? dayjs(ngayChungTu, "DD/MM/YYYY")
									: null
							}
							format={"DD/MM/YYYY"}
							onChange={(date, dateString) => {
								setNgayChungTu(
									dayjs(date).format("DD/MM/YYYY")
								);
								setFieldsValue("ngay_ct", date);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>

				{/* TK có - Khách hàng  */}
				<Form.Item name="tk_co"></Form.Item>
				<Form.Item name="ten_tk_co"></Form.Item>

				<Form.Item name="ten_kh"></Form.Item>
				<Form.Item name="ma_kh"></Form.Item>

				<Col span={isMobile ? 24 : 12}>
					<SelectAddStaffVendor
						api="customer"
						q={{
							status: true,
							kh_yn: false,
							$or: [
								{
									loai_kh: {
										$in: ["Vendor", "Staff"],
									},
								},
								{
									iscarrier: true,
								},
								{
									ncc_yn: true,
								},
							],
						}}
						label="Nhà cung cấp"
						name="customer_data"
						lableOption={["ten_kh", "ma_kh"]}
						valueOption={["ma_kh", "ten_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng chọn NCC",
							},
						]}
						placeholder="chọn NCC"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>

				{/* Đơn vị - ngoại tệ- chi nhánh */}
				<Form.Item name="ten_dvcs"></Form.Item>
				<Form.Item name="ma_dvcs"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="dvcs"
						q={{ status: true }}
						label="Đơn vị"
						name="dvcs_data"
						lableOption={["ten_dvcs"]}
						valueOption={["ten_dvcs", "_id"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn đơn vị"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ma_dvcs", parsedValue?._id);
								setFieldsValue(
									"ten_dvcs",
									parsedValue?.ten_dvcs
								);
							}
						}}
					/>
				</Col>
				<Form.Item name="ma_nt"></Form.Item>
				<Form.Item name="ty_gia"></Form.Item>
				<Form.Item name="ten_nt"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="currency"
						q={{
							status: true,
						}}
						label="Ngoại tệ"
						name="currency_data"
						lableOption={["ma_nt", "ty_gia"]}
						valueOption={["ma_nt", "ty_gia", "ten_nt"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn ngoại tệ"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ma_nt", parsedValue?.ma_nt);
								setFieldsValue("ty_gia", parsedValue?.ty_gia);
								setFieldsValue("ten_nt", parsedValue?.ten_nt);
							}
						}}
						disabled={getFieldValue("so_ct") ? true : false}
					/>
				</Col>
				<Form.Item name="ten_kho"></Form.Item>
				<Form.Item name="ma_kho"></Form.Item>
				<Col span={isMobile ? 24 : 12} className="!p-0">
					<Col span={24}>
						<SelectAdd
							api="dmkho"
							label="Chi nhánh"
							name="dmkho_data"
							lableOption={["ten_kho", "ma_kho"]}
							valueOption={["ten_kho", "ma_kho"]}
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
							placeholder="chọn chi nhánh"
							onChange={(value) => {
								if (value) {
									const parsedValue = JSON.parse(value);
									setFieldsValue(
										"ten_kho",
										parsedValue?.ten_kho
									);
									setFieldsValue(
										"ma_kho",
										parsedValue?.ma_kho
									);
								}
							}}
						/>
					</Col>
				</Col>

				{/* ghi chú   */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						name="dien_giai"
						label="Ghi chú khác"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input.TextArea rows={3} placeholder="nhập ghi chú" />
					</Form.Item>
				</Col>
			</Row>
			{React.cloneElement(children, { getFieldValue, setFieldsValue })}
		</div>
	);
}
