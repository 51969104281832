import { Button } from "antd";
import dayjs from "dayjs";
import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormPnImplant from "../Form/FormPnImplant";
import ItemDetailPn from "../Item/ItemDetailPn";

export default function ModalPnImplant({
	isOpenForm,
	setIsOpenForm,
	handleManager,
	dataDetail,
	dataDefault,
	isTabletOrMobile,
	isMobile,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"hd1-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleManager({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ma_don_hang_ncc: data?.ma_don_hang_ncc,
							loai_vat_tu: "Implant",
						},
						tk_co: dataDefault?.account?.tk,
						ten_tk_co: dataDefault?.account?.ten_tk,
						ma_kho: dataDefault?.dmkho?.ma_kho,
						ten_kho: dataDefault?.dmkho?.ten_kho,
						ma_dvcs: dataDefault?.dvcs?._id,
						ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
						ma_nt: dataDefault?.currency?.ma_nt,
						ty_gia: dataDefault?.currency?.ty_gia,
					});
					setIsOpenForm(false);
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>
								{
									JSON.parse(
										localStorage.getItem("current_user")
									)?.name
								}
							</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(new Date()).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormPnImplant
					isTabletOrMobile={isTabletOrMobile}
					isMobile={isMobile}
				>
					<ItemDetailPn isTabletOrMobile={isTabletOrMobile} />
				</FormPnImplant>
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"hd1-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleManager({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ma_don_hang_ncc: data?.ma_don_hang_ncc,
							loai_vat_tu: "Implant",
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					customer_ncc:
						dataDetail?.ten_kh + " - " + dataDetail?.ma_kh,
					ma_don_hang_ncc: dataDetail?.exfields?.ma_don_hang_ncc,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 max-md:ml-0 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>{dataDetail?.name_user_created}</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(
									dataDetail?.date_created,
									"YYYY-MM-DD"
								).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormPnImplant
					isTabletOrMobile={isTabletOrMobile}
					isMobile={isMobile}
				>
					<ItemDetailPn isTabletOrMobile={isTabletOrMobile} />
				</FormPnImplant>
			</ModalBasic>
		</div>
	);
}
