import { Button, Input, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";
import { TiPrinter } from "react-icons/ti";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleDelete,
	handleDeleteArray,
	handleGetData,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import FormInfomation from "../components/Form/FormInfomation";
import ItemDetailPt from "../components/Item/ItemDetailPt";
import ModalPtVoucher from "../components/Modal/ModalPtVoucher";
import "../managerVouchers.scss";
import { debounce } from "lodash";
import ItemTdttnosPt from "../components/Item/ItemTdttnosPt";
import { request } from "../../../utils/request";
import FilterReport from "../../components/filter/FilterReport";
const { Search } = Input;

export default function PtVoucher() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [printFrame, setPrintFrame] = useState(null);

	const printDefault = (voucher) => {
		setLoading(true);
		let stopRunning = () => {
			setLoading(false);
		};
		let domain = window.location.origin;
		let urlPrintDefault = `${domain}/#/print/${
			voucher.id_app
		}/${localStorage.getItem("token")}/${voucher._id}`;
		console.log(urlPrintDefault);
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				<iframe
					onLoad={() => {
						setTimeout(() => {
							let fr = window.frames["printframe"];
							fr.focus();
							stopRunning();
							fr.print();
							resolve();
						}, 3000);
					}}
					name="printframe"
					title="Print Frame"
					style={{ display: "none", width: "100%", height: "100%" }}
					src={urlPrintDefault}
				></iframe>
			);
			setPrintFrame(printFrame);
		});
	};
	const printLocal = async (url) => {
		setLoading(true);
		let stopRunning = () => {
			setLoading(false);
		};
		let content;
		try {
			const resp = await request.get(url);
			if (resp && resp.status === 200) {
				content = resp.data;
			}
		} catch (e) {
			return alert("info", e.message || "Server error");
		}

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				// eslint-disable-next-line jsx-a11y/iframe-has-title
				<iframe
					onLoad={() => {
						let fr = window.frames["printframe"];
						fr.focus();
						stopRunning();
						fr.print();
						setTimeout(() => {
							resolve();
						}, 10);
					}}
					name="printframe"
					style={{ display: "none", width: "100%", height: "100%" }}
					srcDoc={content}
				></iframe>
			);
			console.log(printFrame);
			setPrintFrame(printFrame);
		});
	};
	const print = async (
		data,
		loai_bill,
		onSuccess,
		onError,
		useDefaultTempleteIfNotFound = true
	) => {
		setLoading(true);
		let printers = ((khoSelected[0] || {}).printers || []).filter(
			(printer) =>
				printer.id_mau_in &&
				(printer.loai_bill == undefined ||
					printer.loai_bill == loai_bill) &&
				printer.ma_cn === "pt1"
		);
		if (printers.length > 0) {
			await Promise.all(
				printers.map((printer) => {
					let url =
						"/api/" +
						localStorage.getItem("id_app") +
						"/pt1/excel/" +
						printer.id_mau_in +
						"?_id=" +
						data._id +
						`&print=1&access_token=` +
						localStorage.getItem("token");
					const print_service_url = printer.dia_chi_may_in;

					return (async () => {
						if (print_service_url) {
							let url_print = `${print_service_url}?url=${btoa(
								url
							)}&printer=${printer.ten_may_in}&width=${
								printer.do_rong_kho_giay || 0
							}&height=${printer.chieu_dai_kho_giay || 0}`;
							try {
								// await asyncGetList({ endpoint: url_print });
							} catch (e) {
								await printLocal(url);
							}
						} else {
							await printLocal(url);
						}
					})();
				})
			);
			setLoading(false);
			if (onSuccess) onSuccess();
		} else {
			//default template print
			if (useDefaultTempleteIfNotFound) {
				try {
					await printDefault(data);
					if (onSuccess) onSuccess();
				} catch (e) {
					if (onError) {
						alert("error", e.message || "Server error");
					} else {
						alert("error", e.message || "Server error");
					}
				}
			} else {
				if (onError) {
					alert("error", "Không tìm thấy mẫu in phù hợp");
				} else {
					if (onSuccess) onSuccess();
				}
			}
		}
	};
	const tables = () => {
		return;
	};

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},

		{
			key: "4",
			icon: <TiPrinter />,
			onClick: (data) => {
				print(
					data,
					1,
					() => {
						tables();
					},
					() => {
						tables();
					}
				);
			},
			label: "Print",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key === "3" ? (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								) : item.key === "4" ? (
									<Popconfirm
										title={`In hoá đơn số ${record?.so_ct} ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								) : (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Ngày chứng từ",
			dataIndex: "ngay_ct",
			width: 150,
			render: (ngay_ct) => {
				return ngay_ct
					? new Date(ngay_ct).toLocaleDateString("vi-VN")
					: null;
			},
		},
		{
			title: "Số chứng từ",
			dataIndex: "so_ct",
			width: 100,
		},
		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 100,
		},
		{
			title: "Khách hàng",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Tiền (Ngoại tệ)",
			dataIndex: "t_tt_nt",
			width: 150,
			render: (t_tt_nt) => {
				return t_tt_nt?.toLocaleString();
			},
			align: "right",
		},
		{
			title: "Ngoại tệ",
			dataIndex: "ten_nt",
			width: 100,
		},
		{
			title: "Tiền (VND)",
			dataIndex: "t_tt",
			width: 150,
			render: (t_tt) => {
				return t_tt?.toLocaleString("vi-VN", {
					style: "currency",
					currency: "VND",
				});
			},
			align: "right",
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return <span>{record?.exfields?.ten_trang_thai_moi}</span>;
			},
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const [khoSelected, setKhoSelected] = useState(null);
	const handleGetDataDmkho = async () => {
		const data = await handleGetData({
			api: "dmkho",
			q: { status: true },
		});
		if (data) {
			console.log(data.data);
			setKhoSelected(data.data);
		}
	};

	const [ngaythieplap, setNgaythieplap] = useState({
		tu_ngay: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		den_ngay: new Date(),
	});

	const handleManagerFilter = async () => {
		await setParams({
			...params,
			page: 1,
		});
		await handleGetDataTable();
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "pt1",
			q: {
				...q,
				ngay_ct: {
					$gte: ngaythieplap.tu_ngay,
					$lte: ngaythieplap.den_ngay,
				},
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "pt1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "pt1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleManagerData = async (data) => {
		if (isOpenForm === "create") {
			await handleSaveData(data);
		} else {
			await handleUpdateData(data);
		}
		await setIsOpenForm(false);
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "pt1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "pt1",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataTable({});
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		try {
			const [dataDvcs, dataPTTT] = await Promise.all([
				handleGetData({
					api: "dvcs",
					q: {
						status: true,
						ten_dvcs: "Công ty",
					},
					setLoading,
				}),
				handleGetData({
					api: "ptthanhtoan",
					q: {
						status: true,
						stt: 0,
					},
					setLoading,
				}),
			]);

			const logData = {};

			if (dataDvcs && dataDvcs.data.length > 0) {
				logData.dvcs = { ...dataDvcs.data[0] };
			}

			if (dataPTTT && dataPTTT.data.length > 0) {
				logData.ptthanhtoan = { ...dataPTTT.data[0] };
			}

			setDataDefault(logData);
		} catch (error) {
			// Handle errors
			console.error("An error occurred:", error);
		}
	};

	useEffect(() => {
		handleGetDataDefault();
	}, []);

	useEffect(() => {
		handleGetDataTable(params.q);
		handleGetDataDmkho();
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ ma_kh: { $regex: value, $options: "i" } },
					{ ten_kh: { $regex: value, $options: "i" } },
					{ so_ct: { $regex: value, $options: "i" } },
					{ ten_trang_thai: { $regex: value, $options: "i" } },
					{ tk_no: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataTable({
			$or: [
				{ ma_kh: { $regex: value, $options: "i" } },
				{ ten_kh: { $regex: value, $options: "i" } },
				{ so_ct: { $regex: value, $options: "i" } },
				{ ten_trang_thai: { $regex: value, $options: "i" } },
				{ tk_no: { $regex: value, $options: "i" } },
			],
		});
	};

	const formSections = [
		{
			id: "thongtin",
			title: "Thông tin",
			content: <FormInfomation pt1={true} />,
		},
		{
			id: "thutheokhachhang",
			title: "Thu theo khách hàng",
			content: <ItemDetailPt />,
		},
		{
			id: "thutheohoadon",
			title: "Thu theo hoá đơn",
			content: <ItemTdttnosPt messageApi={messageApi} />,
		},
	];

	return (
		<div>
			{printFrame}
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
										shape="circle"
										size="small"
										className="btn_ternary !border-none"
									>
										<TfiReload />
									</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[250px]">
									<FilterReport
										ngaythieplap={ngaythieplap}
										setNgaythieplap={setNgaythieplap}
										handleManager={handleManagerFilter}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenForm("create");
								setDataDetail({});
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayId(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, hoặc tên, số ct,..."
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Phiếu thu
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1050,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setIsOpenForm("edit");
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>

			<ModalPtVoucher
				setPrintFrame={setPrintFrame}
				dataDetail={dataDetail}
				khoSelected={khoSelected}
				handleManagerData={handleManagerData}
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				formSections={formSections}
				dataDefault={dataDefault}
			/>
		</div>
	);
}
