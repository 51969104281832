const TrangThai = [
	{
		key: "Chưa đến",
		value: "Chưa đến",
	},
	{
		key: "Đang đợi",
		value: "Đang đợi",
	},
	{
		key: "Đang làm",
		value: "Đang làm",
	},
	{
		key: "Đã làm",
		value: "Đã làm",
	},
	{
		key: "Không đến",
		value: "Không đến",
	},
	{
		key: "Huỷ",
		value: "Huỷ",
	},
	{
		key: "Hẹn lại",
		value: "Hẹn lại",
	},
];

const LoaiDatHen = [
	{
		key: "Khách cũ đến tái khám",
		value: "Khách cũ đến tái khám",
	},
	{
		key: "Đã đến và làm dịch vụ",
		value: "Đã đến và làm dịch vụ",
	},
	{
		key: "Đặt lịch online",
		value: "Đặt lịch online",
	},
	{
		key: "Đặt lịch sếp",
		value: "Đặt lịch sếp",
	},
];

const ListMonths = [
	{
		key: 1,
		value: "Tháng 1",
	},
	{
		key: 2,
		value: "Tháng 2",
	},
	{
		key: 3,
		value: "Tháng 3",
	},
	{
		key: 4,
		value: "Tháng 4",
	},
	{
		key: 5,
		value: "Tháng 5",
	},
	{
		key: 6,
		value: "Tháng 6",
	},
	{
		key: 7,
		value: "Tháng 7",
	},
	{
		key: 8,
		value: "Tháng 8",
	},
	{
		key: 9,
		value: "Tháng 9",
	},
	{
		key: 10,
		value: "Tháng 10",
	},
	{
		key: 11,
		value: "Tháng 11",
	},
	{
		key: 12,
		value: "Tháng 12",
	},
];

const ListDays = [
	{
		key: 1,
		value: "Ngày 1",
	},
	{
		key: 2,
		value: "Ngày 2",
	},
	{
		key: 3,
		value: "Ngày 3",
	},
	{
		key: 4,
		value: "Ngày 4",
	},
	{
		key: 5,
		value: "Ngày 5",
	},
	{
		key: 6,
		value: "Ngày 6",
	},
	{
		key: 7,
		value: "Ngày 7",
	},
	{
		key: 8,
		value: "Ngày 8",
	},
	{
		key: 9,
		value: "Ngày 9",
	},
	{
		key: 10,
		value: "Ngày 10",
	},
	{
		key: 11,
		value: "Ngày 11",
	},
	{
		key: 12,
		value: "Ngày 12",
	},
	{
		key: 13,
		value: "Ngày 13",
	},
	{
		key: 14,
		value: "Ngày 14",
	},
	{
		key: 15,
		value: "Ngày 15",
	},
	{
		key: 16,
		value: "Ngày 16",
	},
	{
		key: 17,
		value: "Ngày 17",
	},
	{
		key: 18,
		value: "Ngày 18",
	},
	{
		key: 19,
		value: "Ngày 19",
	},
	{
		key: 20,
		value: "Ngày 20",
	},
	{
		key: 21,
		value: "Ngày 21",
	},
	{
		key: 22,
		value: "Ngày 22",
	},
	{
		key: 23,
		value: "Ngày 23",
	},
	{
		key: 24,
		value: "Ngày 24",
	},
	{
		key: 25,
		value: "Ngày 25",
	},
	{
		key: 26,
		value: "Ngày 26",
	},
	{
		key: 27,
		value: "Ngày 27",
	},
	{
		key: 28,
		value: "Ngày 28",
	},
	{
		key: 29,
		value: "Ngày 29",
	},
	{
		key: 30,
		value: "Ngày 30",
	},
	{
		key: 31,
		value: "Ngày 31",
	},
];

const ListTotalMonths = [
	{
		key: 1,
		value: "1 Tháng",
	},
	{
		key: 3,
		value: "3 Tháng",
	},
	{
		key: 6,
		value: "6 Tháng",
	},
	{
		key: 12,
		value: "1 năm",
	},
	{
		key: 36,
		value: "3 năm",
	},
	{
		key: 60,
		value: "5 năm",
	},
];

const NewCustomerWithTime = [
	{
		key: 1,
		value: "Trong ngày",
	},
	{
		key: 2,
		value: "Trong tuần",
	},
	{
		key: 3,
		value: "Trong tháng",
	},
];

const LoaiReports = [
	{
		key: "Chi",
		value: "Chi",
	},
	{
		key: "Thu",
		value: "Thu",
	},
];

const optionsTieuSuBenh = [
	{
		label: "Đông máu chậm",
		value: "Đông máu chậm",
	},
	{
		label: "Phản ứng thuốc",
		value: "Phản ứng thuốc",
	},
	{
		label: "Bệnh dị ứng, thấp khớp",
		value: "Bệnh dị ứng, thấp khớp",
	},
	{
		label: "Bệnh tiểu đường",
		value: "Bệnh tiểu đường",
	},
	{
		label: "Bệnh dạ dày, tiêu hoá",
		value: "Bệnh dạ dày, tiêu hoá",
	},
	{
		label: "Bệnh phổi",
		value: "Bệnh phổi",
	},
	{
		label: "Bệnh truyền nhiễm (HIV, Viêm gan siêu vi)",
		value: "Bệnh truyền nhiễm (HIV, Viêm gan siêu vi)",
	},
	{
		label: "Phụ nữ đang mang thai, cho con bú",
		value: "Phụ nữ đang mang thai, cho con bú",
	},
	{
		label: "Bệnh tim mạch",
		value: "Bệnh tim mạch",
	},
	{
		label: "Cao huyết áp",
		value: "Cao huyết áp",
	},
];

export {
	TrangThai,
	LoaiDatHen,
	ListMonths,
	ListDays,
	ListTotalMonths,
	NewCustomerWithTime,
	LoaiReports,
	optionsTieuSuBenh,
};
