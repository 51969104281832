import React from "react";
import ModalBasic from "../../../../components/modal/ModalBasic";
import FormDetailPxk from "../../Form/FormDetailPxk";
import { Button } from "antd";

export default function ModalItemDetailPxk({
	isOpenForm,
	setIsOpenForm,
	dataDetailItem,
	handleManagerDataItem,
	isTabletOrMobile,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"itemDetailpxk-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerDataItem({
						...data,
						px_gia_dd: true,
					});
					setIsOpenForm(false);
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormDetailPxk isTabletOrMobile={isTabletOrMobile} />
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"itemDetailpxk-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerDataItem({
						...dataDetailItem,
						...data,
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetailItem,
					dmvt_vt:
						dataDetailItem.ten_vt + " - " + dataDetailItem.ma_vt,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormDetailPxk isTabletOrMobile={isTabletOrMobile} />
			</ModalBasic>
		</div>
	);
}
