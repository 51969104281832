import { Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import SelectAddCus from "../../../components/inputAnt/selectAddCus";
dayjs.extend(customParseFormat);
export default function FormPnImplant({
	children,
	setFieldsValue,
	getFieldValue,
	isTabletOrMobile,
	isMobile,
}) {
	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 20 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [ngayChungTu, setNgayChungTu] = useState();
	useEffect(() => {
		if (getFieldValue("ngay_ct") === null) {
			setNgayChungTu(null);
		} else {
			setNgayChungTu(
				dayjs(getFieldValue("ngay_ct")).format("DD/MM/YYYY")
			);
		}
	}, [getFieldValue("ngay_ct")]);

	return (
		<div>
			<Row className="!m-0" gutter={[8, 8]}>
				{/* Ngày nhập - Số chứng từ */}
				<Form.Item name="details"></Form.Item>
				<Form.Item name="ngay_ct"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Ngày nhập"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<DatePicker
							placeholder="chọn thời gian"
							value={
								ngayChungTu
									? dayjs(ngayChungTu, "DD/MM/YYYY")
									: null
							}
							format="DD/MM/YYYY"
							onChange={(date, dateString) => {
								setNgayChungTu(
									dayjs(date).format("DD/MM/YYYY")
								);
								setFieldsValue("ngay_ct", date);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Số chứng từ"
						name="so_ct"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input allowClear placeholder="SO_CT" />
					</Form.Item>
				</Col>
				{/* NCC Implant - Mã đơn hàng NCC */}
				{/* field hidden */}
				<Form.Item name="ten_kh"></Form.Item>
				<Form.Item name="ma_kh"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<SelectAddCus
						api="customer"
						q={{
							status: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
						}}
						label="NCC Implant"
						name="customer_ncc"
						valueOption={["ten_kh", "ma_kh"]}
						lableOption={["ten_kh", "ma_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng NCC Implant",
							},
						]}
						placeholder="nhập tên NCC Implant"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Mã đơn hàng NCC"
						name="ma_don_hang_ncc"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input placeholder="Nhập mã đơn hàng" allowClear />
					</Form.Item>
				</Col>
				{/* Diễn giải */}
				<Col span={24}>
					<Form.Item
						name="dien_giai"
						label="Ghi chú khác"
						labelCol={{
							...layout.labelCol,
							span: 3,
						}}
						wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
					>
						<Input.TextArea rows={3} placeholder="nhập ghi chú" />
					</Form.Item>
				</Col>
			</Row>
			{React.cloneElement(children, {
				getFieldValue,
				setFieldsValue,
			})}
		</div>
	);
}
