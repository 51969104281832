import { notification } from "antd";
import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export default function SocketProvider({ children }) {
	const [api, contextHolder] = notification.useNotification();
	const [totalNotify, setTotalNotify] = useState(0);
	const [newNotify, setNewNotify] = useState();

	useEffect(() => {
		// Kết nối tới server socket
		const socket = io("https://api.goodapp.vn");

		// Xử lý sự kiện khi kết nối thành công
		socket.on("connect", () => {
			console.log("Connected to socket server");
			socket.emit("login", {
				token: localStorage.getItem("token"),
				email: JSON.parse(localStorage.getItem("current_user")).email,
			});
			socket.on("login", (data) => {
				console.log(data);
			});
		});

		// Xử lý sự kiện khi nhận được dữ liệu từ server
		socket.on("message", (data) => {
			console.log("Received data:", data);
		});

		// Xử lý sự kiện khi có lỗi kết nối
		socket.on("error", (error) => {
			console.error("Socket connection error:", error);
		});

		socket.on("notify:count", (data) => {
			console.log(data);
			setTotalNotify(data);
		});
		socket.on("notify:update", (data) => {
			console.log(data);
		});
		socket.on("notify:new", (data) => {
			console.log(data);
			if (data?.sender === "SYSTEM") {
				openNotification(data);
				setNewNotify(data);
			}
		});

		// Clean up khi component unmount
		return () => {
			socket.disconnect();
		};
	}, []);

	const openNotification = (data) => {
		api.open({
			message: data?.title,
			description: data?.content,
			duration: 0,
		});
	};

	return (
		<SocketContext.Provider value={{ totalNotify, newNotify }}>
			{contextHolder}
			{children}
		</SocketContext.Provider>
	);
}

export { SocketContext };
