import { Input, Tooltip } from "antd";
import React from "react";

const formatNumber = (value) => new Intl.NumberFormat().format(value);

const NumericInput = (props) => {
	const {
		value,
		onChange,
		placeholder = "Nhập số",
		tooltip = false,
		disabled = false,
	} = props;

	const handleChange = (e) => {
		const { value: inputValue } = e.target;
		const reg = /^-?\d*(\.\d*)?$/;
		if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
			onChange(inputValue);
		}
	};

	// '.' at the end or only '-' in the input box.
	const handleBlur = () => {
		let valueTemp = String(value); // Convert value to a string if it's not already
		if (
			valueTemp.charAt(valueTemp.length - 1) === "." ||
			valueTemp === "-"
		) {
			valueTemp = valueTemp.slice(0, -1);
		}
		onChange(valueTemp.replace(/0*(\d+)/, "$1"));
	};

	const title = value ? (
		<span className="numeric-input-title">
			{value !== "-" ? formatNumber(Number(value)) : "-"}
		</span>
	) : (
		"Nhập số"
	);

	return tooltip ? (
		<Tooltip
			trigger={["focus"]}
			title={title}
			placement="topLeft"
			overlayClassName="numeric-input"
		>
			<Input
				{...props}
				onChange={handleChange}
				onBlur={handleBlur}
				placeholder={placeholder}
				maxLength={16}
				disabled={disabled} // Thêm disabled tại đây
			/>
		</Tooltip>
	) : (
		<Input
			{...props}
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder={placeholder}
			maxLength={16}
			disabled={disabled} // Thêm disabled tại đây
		/>
	);
};

export default function InputNumber({
	value,
	onChange,
	placeholder,
	tooltip,
	disabled,
}) {
	return (
		<NumericInput
			value={value || 0}
			onChange={onChange}
			placeholder={placeholder}
			tooltip={tooltip}
			disabled={disabled} // Truyền disabled xuống NumericInput
		/>
	);
}
