import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemMenuLeft } from "../routers/menu";

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type,
	};
}
const items = [
	...ItemMenuLeft?.map((item, index) => {
		return getItem(
			item?.name,
			item?.path,
			item?.icon,
			item?.tabItem && [
				...item?.tabItem?.map((tabItem) => {
					return getItem(tabItem?.name, tabItem?.path);
				}),
			]
		);
	}),
];
export default function CustomMenu({ isTabItem }) {
	const navigate = useNavigate();
	const [current, setCurrent] = useState("1");

	const onClick = (e) => {
		navigate(e.key);
		setCurrent(e.key);
	};
	useEffect(() => {
		setCurrent(isTabItem);
	}, [isTabItem]);
	return (
		<Menu
			theme={"light"}
			onClick={onClick}
			style={{
				width: 260,
			}}
			defaultOpenKeys={["/"]}
			selectedKeys={[current]}
			mode="inline"
			items={items}
		/>
	);
}
