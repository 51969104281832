import { Col, Form, Input, Row, InputNumber } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../components/inputAnt/selectAdd";

export default function FormDetail({
	setFieldsValue,
	getFieldValueAll,
	pc1 = false,
	pt1 = false,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 20 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			<Form.Item name="tk_co"></Form.Item>
			<Form.Item name="ten_tk_co"></Form.Item>
			<Form.Item name="tk_no"></Form.Item>
			<Form.Item name="ten_tk_no"></Form.Item>

			{/* Khách hàng có - VỤ việc */}
			<Form.Item name="ten_kh"></Form.Item>
			<Form.Item name="ma_kh"></Form.Item>

			{pt1 && (
				<Col span={24}>
					<SelectAdd
						api="customer"
						q={{
							status: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
						}}
						label="Khách hàng"
						name="customer_data"
						lableOption={["ten_kh", "ma_kh"]}
						valueOption={["ma_kh", "ten_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn khách hàng"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>
			)}

			{pc1 && (
				<Col span={24}>
					<SelectAdd
						api="customer"
						q={{
							status: true,
							kh_yn: false,
							$or: [
								{
									loai_kh: {
										$in: ["Vendor", "Staff"],
									},
								},
								{
									iscarrier: true,
								},
								{
									ncc_yn: true,
								},
							],
						}}
						label="Bác sĩ"
						name="customer_data"
						lableOption={["ten_kh", "ma_kh"]}
						valueOption={["ma_kh", "ten_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn bác sĩ"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>
			)}

			<Form.Item name="tien"></Form.Item>
			<Col span={24}>
				<Form.Item
					label={`Tiền ${getFieldValueAll("ma_nt")}`}
					name="tien_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">
								{getFieldValueAll("ma_nt")}
							</div>
						}
						onChange={(value) => {
							setFieldsValue(
								"tien",
								value * getFieldValueAll("ty_gia")
							);
						}}
					/>
				</Form.Item>
			</Col>

			{getFieldValueAll("ty_gia") !== 1 && (
				<Col span={24}>
					<Form.Item
						label="Tiền VND"
						name="tien"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<InputNumber
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
							style={{ width: "100%" }}
							defaultValue={0}
							addonAfter={
								<div className="w-[30px] text-center">VND</div>
							}
						/>
					</Form.Item>
				</Col>
			)}

			{/* ghi chú */}
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
