import dayjs from "dayjs";

// Hàm tạo chuỗi ngẫu nhiên từ ký tự A-Z
export const randomChars = (length) => {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * chars.length);
		result += chars.charAt(randomIndex);
	}
	return result;
};

// Hàm tạo chuỗi ngẫu nhiên từ các số
export const randomNumbers = (length) => {
	const numbers = "0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * numbers.length);
		result += numbers.charAt(randomIndex);
	}
	return result;
};

export const handleTotal = ({ arr = [], name }) => {
	let total = arr?.reduce(function (acc, obj) {
		return acc + obj[name];
	}, 0);

	return total;
};

export const handelKhoangNgayTheo = (value) => {
	if (value?.thang_gan_nhat === undefined) {
		return;
	} else {
		const currentDate = new Date(); // Ngày hiện tại
		const khoang_ngay_theo = value?.thang_gan_nhat
			? new Date(
					currentDate.setMonth(
						currentDate.getMonth() - value?.thang_gan_nhat
					)
			  )
			: undefined;

		if (value?.so_sanh === undefined || value?.so_sanh === "=") {
			return {
				$or: [
					{ ngay_kham_moi: `${khoang_ngay_theo}` },
					{ ngay_kham_moi: khoang_ngay_theo },
				],
			};
		} else if (value?.so_sanh === ">") {
			return {
				$or: [
					{
						ngay_kham_moi: { $lt: `${khoang_ngay_theo}` },
					},
					{
						ngay_kham_moi: { $lt: khoang_ngay_theo },
					},
				],
			};
		} else if (value?.so_sanh === "<") {
			return {
				$or: [
					{
						$and: [
							{
								ngay_kham_moi: {
									$gt: `${khoang_ngay_theo}`,
								},
							},
							{
								ngay_kham_moi: {
									$lt: `${new Date()}`,
								},
							},
						],
					},
					{
						$and: [
							{
								ngay_kham_moi: {
									$gt: khoang_ngay_theo,
								},
							},
							{
								ngay_kham_moi: {
									$lt: new Date(),
								},
							},
						],
					},
				],
			};
		} else if (value?.so_sanh === ">=") {
			return {
				$or: [
					{
						ngay_kham_moi: { $lte: `${khoang_ngay_theo}` },
					},
					{
						ngay_kham_moi: { $lte: khoang_ngay_theo },
					},
				],
			};
		} else if (value?.so_sanh === "<=") {
			return {
				$or: [
					{
						$and: [
							{
								ngay_kham_moi: {
									$gte: `${khoang_ngay_theo}`,
								},
							},
							{
								ngay_kham_moi: {
									$lte: `${new Date()}`,
								},
							},
						],
					},
					{
						$and: [
							{
								ngay_kham_moi: {
									$gte: khoang_ngay_theo,
								},
							},
							{
								ngay_kham_moi: {
									$lte: new Date(),
								},
							},
						],
					},
				],
			};
		}
	}
};

function formatDateTime(dateTimeString) {
	if (!dateTimeString) {
		return "";
	}

	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: false, // Use 24-hour format
	};

	const formattedDateTime = new Date(dateTimeString).toLocaleString(
		undefined,
		options
	);

	return formattedDateTime;
}

// đầu ngày
function getStartOfDay(date) {
	const startOfDay = new Date(date);
	startOfDay.setHours(0, 0, 0, 0);

	return startOfDay;
}
// Lấy ngày đầu tuần
function getStartOfWeek(date) {
	const day = date.getDay();
	const diff = date.getDate() - day + (day === 0 ? -6 : 1);

	const startOfWeek = new Date(date.setDate(diff));
	startOfWeek.setHours(0, 0, 0, 0);

	return startOfWeek;
}
// đầu tháng
function getFirstDayOfMonth(date) {
	const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

	return firstDayOfMonth;
}

// Cộng thêm 7h thành múi giờ VN
function getVNDateTime(date) {
	const newDate = new Date(
		new Date(date).getTime() + 7 * 60 * 60 * 1000
	).toISOString();
	return newDate;
}

function theNextDay(ngay) {
	// Chuyển đổi ngày tháng từ chuỗi sang đối tượng Date
	var ngayDate = new Date(ngay);

	// Cộng thêm 1 ngày vào ngày tháng
	ngayDate.setDate(ngayDate.getDate() + 1);

	// Lấy ngày, tháng và năm mới
	var ngayMoi = ngayDate.getDate();
	var thangMoi = ngayDate.getMonth() + 1; // Lưu ý: Tháng trong JavaScript bắt đầu từ 0
	var namMoi = ngayDate.getFullYear();

	// Định dạng lại ngày, tháng và năm mới thành chuỗi "YYYY-MM-DD"
	var ngayMoiChuoi =
		namMoi +
		"-" +
		(thangMoi < 10 ? "0" : "") +
		thangMoi +
		"-" +
		(ngayMoi < 10 ? "0" : "") +
		ngayMoi;

	return new Date(ngayMoiChuoi).toISOString();
}

// Chuyển đổi định dạng ngày sang định dạng yyy-mm-dd
function convertDateFormat(strTime) {
	// Tạo một đối tượng Date từ chuỗi thời gian ban đầu
	var date = new Date(strTime);

	// Lấy các thông tin về ngày, tháng và năm từ đối tượng Date
	var year = date.getFullYear();
	var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0 nên cần +1
	var day = date.getDate().toString().padStart(2, "0");

	// Tạo chuỗi mới trong định dạng yyy-mm-dd
	var newStrTime = year + "-" + month + "-" + day;

	return newStrTime;
}

// Ktra ngày có trong tháng hay không
function isWithinCurrentMonth(dateString) {
	var date = new Date(dateString); // Chuyển đổi chuỗi thành đối tượng Date
	var currentDate = new Date(); // Lấy ngày hiện tại

	return (
		date.getFullYear() === currentDate.getFullYear() &&
		date.getMonth() === currentDate.getMonth() &&
		date.getDate() <= currentDate.getDate()
	);
}

export {
	getStartOfDay,
	getStartOfWeek,
	getFirstDayOfMonth,
	formatDateTime,
	getVNDateTime,
	theNextDay,
	convertDateFormat,
	isWithinCurrentMonth,
};
