import { Col, Form, Row, InputNumber, Input } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

export default function FormDetailPn2({
	getFieldValueAll,
	getFieldValue,
	setFieldsValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 22 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* Tiền */}

			<Col span={24}>
				<Form.Item
					label="Tiền"
					name="tien_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">
								{getFieldValueAll("ma_nt") || "VND"}
							</div>
						}
						onChange={(value) => {
							setFieldsValue(
								"tien",
								value * getFieldValueAll("ty_gia")
							);
						}}
					/>
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
