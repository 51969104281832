import { Table, Pagination } from "antd";
import React from "react";
import "./tableManager.scss";

const TableManagerCus = ({
	id = "table-manager-custom",
	data,
	columns,
	expandable,
	loading,
	scroll,
	rowKey,
	onRow,
	rowSelection,
	pagination,
	tableProps,
}) => {
	const tableProp = {
		size: "small",
		...tableProps,
	};
	return (
		<>
			<Table
				key={id}
				id={id}
				pagination={{
					position: "bottomRight",
					defaultPageSize: 20,
					pageSizeOptions: [10, 20, 50, 100],
					showSizeChanger: true,
					...pagination,
				}}
				loading={loading}
				columns={columns}
				dataSource={data || []}
				scroll={scroll}
				rowKey={rowKey || "_id"}
				rowSelection={rowSelection}
				preserveSelectedRowKeys={true}
				expandable={{
					...expandable,
				}}
				onRow={onRow}
				className="table-manager-custom"
				{...tableProp}
			/>
		</>
	);
};
export default TableManagerCus;
