import { Input } from "antd";
import React, { useEffect, useState } from "react";
import ItemCompany from "./ItemCompany";
import { request } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import isIdApp from "../../utils/isIdApp";
import { debounce } from "lodash";
const { Search } = Input;

export default function CompanyApp() {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const handleGetApp = async ({ q }) => {
		try {
			const response = await request.get(`api/app`, {
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
				params: {
					t: 1,
					page: 1,
					limit: 30,
					q: JSON.stringify(q || {}),
				},
			});
			setData(response.data);
		} catch (error) {
			console.log(error.message);
			navigate("/sign-in");
		}
	};

	useEffect(() => {
		handleGetApp({});
	}, []);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		if (value) {
			handleGetApp({ q: { name: { $regex: value, $options: "i" } } });
		} else {
			handleGetApp({});
		}
	};

	return (
		<React.Fragment>
			<div className="flex justify-start items-center p-2 max-w-sm">
				<Search
					placeholder="Nhập tên công ty"
					onChange={(e) => DebouncedSearch(e.target.value)}
					allowClear
				/>
			</div>
			<div className="flex justify-start items-center flex-wrap max-sm:justify-center">
				{data.map((item, index) => (
					<ItemCompany item={item} key={index} />
				))}
			</div>
		</React.Fragment>
	);
}
