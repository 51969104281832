import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { BsPersonPlus } from "react-icons/bs";
import { LuEye, LuPencil, LuX } from "react-icons/lu";
import { TbCornerUpRightDouble } from "react-icons/tb";
import { request } from "../../../../utils/request";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import ContactInformation from "../modal/modalItem/ContactInformation";
import { useMediaQuery } from "react-responsive";

export default function Relationship({ dataModal, messageApi }) {
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	//modal
	const [isOpenContactInformation, setIsOpenContactInformation] =
		useState(false);

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							<Popconfirm
								title={`Bạn muốn ${record.ten_lien_he} thành khách hàng tiềm năng?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleChangePotential(record);
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<TbCornerUpRightDouble size={18} />
								</Button>
							</Popconfirm>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenContactInformation("edit");
									setDetailLienHe(record);
								}}
							>
								<LuEye size={18} />
							</Button>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenContactInformation("edit");
									setDetailLienHe(record);
								}}
							>
								<LuPencil size={18} />
							</Button>
							<Popconfirm
								title={`Bạn muốn xoá ${record.ten_lien_he} ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleDeleteLienHe(record._id);
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<LuX size={18} />
								</Button>
							</Popconfirm>
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Tên",
			dataIndex: "ten_lien_he",
			width: 150,
		},
		{
			title: "Quan hệ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.quan_he}</span>;
			},
		},
		{
			title: "Giới tính",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.gioi_tinh}</span>;
			},
		},
		{
			title: "Độ tuổi",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.do_tuoi}</span>;
			},
		},
		{
			title: "Số ĐT",
			dataIndex: "dien_thoai",
			width: 100,
		},
		{
			title: "Đánh giá tiềm năng",
			dataIndex: "",
			width: 200,
			render: (record) => {
				return <span>{record?.exfields?.tiem_nang}</span>;
			},
		},
	];

	const [loading, setLoading] = useState(false);
	const [detailLienHe, setDetailLienHe] = useState({});
	const [dataLienHe, setDataLienHe] = useState([]);

	// Save data
	const handleSaveDataLienHe = async (data) => {
		setLoading(true);
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/lienhe`,
				{
					id_kh: dataModal?._id,
					ten_lien_he: data.ten_lien_he,
					dien_thoai: data.dien_thoai,
					exfields: {
						quan_he: data.quan_he,
						gioi_tinh: data.gioi_tinh,
						do_tuoi: data.do_tuoi,
						tiem_nang: data.tiem_nang,
						ghi_chu: data.ghi_chu,
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			handleGetDataLienHe({ id_kh: dataModal?._id || "" });
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	// change data
	const handleChangeDataLienHe = async (data) => {
		setLoading(true);
		try {
			const response = await request.put(
				`api/${localStorage.getItem("id_app")}/lienhe/${
					detailLienHe._id
				}`,
				{
					...detailLienHe,
					ten_lien_he: data.ten_lien_he,
					dien_thoai: data.dien_thoai,
					exfields: {
						...detailLienHe.exfields,
						quan_he: data.quan_he || null,
						gioi_tinh: data.gioi_tinh || null,
						do_tuoi: data.do_tuoi || null,
						tiem_nang: data.tiem_nang || null,
						ghi_chu: data.ghi_chu,
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			console.log(response.data);
			setDetailLienHe(response.data);
			handleGetDataLienHe({ id_kh: dataModal?._id || "" });
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		}
	};

	// potential data
	const handleChangePotential = async (data) => {
		setLoading(true);
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/customer`,
				{
					trang_thai: 0,
					ma_kh: data._id,
					dien_thoai: data.dien_thoai,
					gioi_tinh: data?.exfields?.gioi_tinh,
					ten_kh: data.ten_lien_he,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Chuyển thành công",
			});
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Chuyển thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		}
	};

	// delete data
	const handleDeleteLienHe = async (id) => {
		setLoading(true);
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/lienhe/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			handleGetDataLienHe({ id_kh: dataModal?._id || "" });
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};
	// get data
	const handleGetDataLienHe = async (q) => {
		setLoading(true);
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/lienhe`,
				{
					params: {
						q: JSON.stringify(q),
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setDataLienHe(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	useEffect(() => {
		if (dataModal?._id && dataModal?._id !== "") {
			handleGetDataLienHe({ id_kh: dataModal?._id });
		}
	}, [dataModal?._id]);

	return (
		<div className="relative">
			<TableManagerCus
				id="dataRelationship-tab"
				loading={loading}
				data={dataLienHe}
				columns={columns}
				scroll={{ x: 1000, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenContactInformation("edit");
						setDetailLienHe(record);
					},
				})}
			/>
			<Button
				onClick={() => setIsOpenContactInformation("create")}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Thêm
			</Button>
			<ModalBasic
				id={"modalRelationship"}
				title={"Liên hệ với bệnh nhân"}
				open={isOpenContactInformation === "create" ? true : false}
				onCancel={() => {
					setIsOpenContactInformation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleSaveDataLienHe(data);
					setIsOpenContactInformation(false);
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<ContactInformation />
			</ModalBasic>

			<ModalBasic
				id={"modalRelationship-edit"}
				title={"Liên hệ với bệnh nhân"}
				open={isOpenContactInformation === "edit" ? true : false}
				onCancel={() => {
					setIsOpenContactInformation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleChangeDataLienHe(data);
					setIsOpenContactInformation(false);
				}}
				data={{
					ten_lien_he: detailLienHe?.ten_lien_he,
					dien_thoai: detailLienHe?.dien_thoai,
					quan_he: detailLienHe?.exfields?.quan_he,
					gioi_tinh: detailLienHe?.exfields?.gioi_tinh,
					do_tuoi: detailLienHe?.exfields?.do_tuoi,
					tiem_nang: detailLienHe?.exfields?.tiem_nang,
					ghi_chu: detailLienHe?.exfields?.ghi_chu,
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<ContactInformation />
			</ModalBasic>
		</div>
	);
}
