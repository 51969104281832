import { Button, Input, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleDelete,
	handleDeleteArray,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import ModalBasic from "../../components/modal/ModalBasic";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import Consultation from "../../customer/components/modal/modalItem/Consultation";
import "../managerServices.scss";
import { debounce } from "lodash";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
const { Search } = Input;

export default function Consultants() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [ngaythieplap, setNgayThietLap] = useState(null);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setNgayThietLap({
					...ngaythieplap,
					ngay_kham: data.ngay_kham || new Date(),
				});
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setNgayThietLap({
					...ngaythieplap,
					ngay_kham: data.ngay_kham || new Date(),
				});
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá lịch sử tư vấn ${
											record.ten_kh || ""
										}`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Ngày",
			dataIndex: "ngay_kham",
			render: (datetime) => {
				return datetime
					? new Date(datetime).toLocaleDateString("vi-VN")
					: null;
			},
			width: 100,
		},
		{
			title: "Mã khách",
			dataIndex: "exfields",
			width: 150,
			render: (exfields) => {
				return exfields?.ma_kh || "-";
			},
		},
		{
			title: "Tên khách",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Điện thoại",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return (
					<React.Fragment>
						{record?.exfields?.dien_thoai ? (
							<a
								href={`tel:${record?.exfields?.dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{record?.exfields?.dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
		},
		{
			title: "Người tư vấn",
			dataIndex: "bac_si",
			width: 150,
		},
		{
			title: "Chẩn đoán",
			dataIndex: "chuan_doan",
			width: 150,
		},
		{
			title: "Đề xuất",
			dataIndex: "dien_giai",
			width: 150,
		},
		{
			title: "Ghi chú",
			dataIndex: "ly_do",
			width: 150,
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "salelog",
			q: q,
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "salelog",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({});
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "salelog",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({});
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "salelog",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({});
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "salelog",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataTable({});
	};

	useEffect(() => {
		handleGetDataTable(params.q);
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ "exfields.ma_kh": { $regex: value, $options: "i" } },
					{ ten_kh: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataTable({
			$or: [
				{ "exfields.ma_kh": { $regex: value, $options: "i" } },
				{ ten_kh: { $regex: value, $options: "i" } },
				// { ten_lien_he: { $regex: value, $options: "i" } },
				// { dien_thoai: { $regex: value, $options: "i" } },
				// { chuan_doan: { $regex: value, $options: "i" } },
			],
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
											shape="circle"
											size="small"
											className="btn_ternary !border-none"
										>
											<TfiReload />
										</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<p>Tìm</p>
									<Search
										placeholder="Nhập Id hoặc tên"
										onChange={(e) =>
											DebouncedSearch(e.target.value)
										}
										allowClear
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					{dataSelected.length > 0 && (
						<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
							<Popconfirm
								title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
								onConfirm={() => {
									handleDeleteArrayId(dataSelected);
								}}
								onCancel={() => {
									return;
								}}
								okText="Có"
								cancelText="Không"
							>
								<Button className="btn_error">
									<AiOutlineDelete />
								</Button>
							</Popconfirm>
						</div>
					)}
					<Search
						placeholder="Nhập Id hoặc tên"
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>

				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Lịch sử tư vấn
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setNgayThietLap({
							...ngaythieplap,
							ngay_kham: record.ngay_kham || new Date(),
						});
						setIsOpenForm("edit");
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>

			<ModalBasic
				zIndex={1035}
				id={"modalConsultant"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Lịch sử tư vấn</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						bac_si: data.nguoi_tu_van,
						chuan_doan: data.chuan_doan,
						dien_giai: data.de_xuat,
						ly_do: data.ghi_chu,
						ngay_kham: data.ngay_kham || new Date(),
					});
					setIsOpenForm(false);
				}}
			>
				<Consultation data={ngaythieplap} />
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"modalConsultant-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Lịch sử tư vấn</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						_id: dataDetail._id,
						bac_si: data.nguoi_tu_van,
						chuan_doan: data.chuan_doan,
						dien_giai: data.de_xuat,
						ly_do: data.ghi_chu,
						ten_kh: data.ten_kh,
						ngay_kham: data.ngay_kham || new Date(),
						exfields: {
							...dataDetail.exfields,
							dien_thoai: data?.dien_thoai,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					nguoi_tu_van: dataDetail?.bac_si,
					chuan_doan: dataDetail?.chuan_doan,
					de_xuat: dataDetail?.dien_giai,
					ghi_chu: dataDetail?.ly_do,
					ngay_kham: dataDetail?.ngay_kham || new Date(),
					ten_kh: dataDetail?.ten_kh,
					dien_thoai: dataDetail?.exfields?.dien_thoai,
				}}
			>
				<Consultation data={ngaythieplap} />
			</ModalBasic>
		</div>
	);
}
