import { Button, Col, Form, Row, Select, Input, DatePicker } from "antd";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineFilter } from "react-icons/hi";
import {
	ListDays,
	ListMonths,
	ListTotalMonths,
	NewCustomerWithTime,
} from "../../../utils/utilsOptionSelect";
import {
	TbMathEqualGreater,
	TbMathEqualLower,
	TbMathGreater,
	TbMathLower,
} from "react-icons/tb";
import { CgMathEqual } from "react-icons/cg";
import { TfiReload } from "react-icons/tfi";
dayjs.extend(customParseFormat);

export default function FilterCustomer({ handleManager, data = {} }) {
	const formFilterRef = useRef(null);

	const setFieldsValue = (name, value) => {
		formFilterRef.current?.setFieldsValue({ [name]: value });
	};

	useEffect(() => {
		formFilterRef?.current?.setFieldsValue(data);
	}, [data]);

	const onFinish = (values) => {
		handleManager(values);
	};
	const onReset = () => {
		formFilterRef.current?.resetFields();
	};

	return (
		<Form
			id={"form-filter-report"}
			ref={formFilterRef}
			name="control-ref"
			onFinish={onFinish}
			layout={"vertical"}
			labelCol={{
				span: 4,
			}}
		>
			<div className="flex justify-between items-center">
				<span className="font-medium">Lọc và tìm kiếm</span>
				<Button
					shape="circle"
					size="small"
					className="btn_ternary !border-none"
					onClick={() => {
						onReset();
						handleManager({});
					}}
				>
					<TfiReload />
				</Button>
			</div>
			<div className="pt-4 h-fit max-h-[calc(100vh-150px)] overflow-y-auto">
				<Row className="!m-0" gutter={[8, 8]}>
					{/* Khách mới  */}
					<Col span={24}>
						<Form.Item
							label="Khách mới"
							name="khach_moi"
							labelCol={{
								span: 24,
							}}
							wrapperCol={{ span: 24 }}
						>
							<Select allowClear placeholder="Trong thời gian">
								{NewCustomerWithTime.map((item) => (
									<Option key={item.key} value={item.key}>
										{item.value}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					{/* Tháng sinh nhật */}
					<Col span={24} className="!px-0">
						<div className="pb-2 pl-1">Ngày sinh nhật</div>
						<Col span={24} className="flex items-center !px-0">
							<Col span={12}>
								<Form.Item
									name="ngay_sinh_nhat"
									labelCol={{
										span: 24,
									}}
									wrapperCol={{ span: 24 }}
								>
									<Select allowClear placeholder="Chọn ngày">
										{ListDays.map((item) => (
											<Option
												key={item.key}
												value={item.key}
											>
												{item.value}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="thang_sinh_nhat"
									labelCol={{
										span: 24,
									}}
									wrapperCol={{ span: 24 }}
								>
									<Select allowClear placeholder="Chọn tháng">
										{ListMonths.map((item) => (
											<Option
												key={item.key}
												value={item.key}
											>
												{item.value}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Col>
					</Col>
					{/* Ngày khám gần nhất */}
					<Col span={24} className="!px-0">
						<div className="pb-2 pl-1">Ngày khám gần nhất</div>
						<Col span={24} className="flex items-center !px-0">
							<Col span={5}>
								<Form.Item
									name="so_sanh"
									labelCol={{
										span: 24,
									}}
									wrapperCol={{ span: 24 }}
								>
									<Select defaultValue="=">
										<Option
											className="flex justify-center items-center"
											value=">"
										>
											<TbMathGreater />
										</Option>
										<Option
											className="flex justify-center items-center"
											value="<"
										>
											<TbMathLower />
										</Option>
										<Option
											className="flex justify-center items-center"
											value="="
										>
											<CgMathEqual />
										</Option>
										<Option
											className="flex justify-center items-center"
											value=">="
										>
											<TbMathEqualGreater />
										</Option>
										<Option
											className="flex justify-center items-center"
											value="<="
										>
											<TbMathEqualLower />
										</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={19}>
								<Form.Item
									name="thang_gan_nhat"
									labelCol={{
										span: 24,
									}}
									wrapperCol={{ span: 24 }}
								>
									<Select allowClear placeholder="Chọn tháng">
										{ListTotalMonths.map((item) => (
											<Option
												key={item.key}
												value={item.key}
											>
												{item.value}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Col>
					</Col>
					{/* Tìm kiếm theo Implant-lab */}
					<Col span={24}>
						<Form.Item
							label="Tìm theo Lab hoặc Implant"
							name="lab_or_implant"
							labelCol={{
								span: 24,
							}}
							wrapperCol={{ span: 24 }}
						>
							<Input placeholder="nhập tên" allowClear />
						</Form.Item>
					</Col>
				</Row>
			</div>
			<div className="w-full justify-between flex items-center flex-row-reverse">
				<div className="flex justify-end items-center gap-4 p-2">
					<Button htmlType="submit" className="btn_ternary">
						<HiOutlineFilter />
						Lọc
					</Button>
				</div>
			</div>
		</Form>
	);
}
