import { Button, Input, Popconfirm, message } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleDelete,
	handleDeleteArray,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import ModalHd1 from "../components/modal/ModalHd1";
import "../managerServices.scss";
const { Search } = Input;

export default function Hd1() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Họ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ho_ten_dem;
			},
		},
		{
			title: "Tên",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return record?.exfields?.trang_thai_hd;
			},
		},
		{
			title: "Thời gian hẹn",
			dataIndex: "",
			width: 150,
			render: (record) => {
				if (!record?.exfields?.ngay_hoa_don) return null;
				else
					return dayjs(record?.exfields?.ngay_hoa_don).format(
						"DD/MM/YYYY"
					);
			},
		},
		{
			title: "Mã lịch hẹn",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ma_lich_hen;
			},
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},

		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 100,
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 8,
				...q,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	const handleSaveData = async (data) => {
		if (data?.exfields?.trang_thai_hd === "Chuyển sổ sách") {
			const newDetails = data?.details?.map((item) => {
				return {
					tk_co: "131",
					ten_tk_co: "Phải thu khách hàng",
					ten_kh: data.ten_kh,
					ma_kh: data.ma_kh,
					tien_nt: item.tien_hang_nt - (item.tien_ck_nt || 0),
					dien_giai: item.dien_giai,
				};
			});
			const newDetailsHd1 = data?.details?.map((item) => {
				return {
					tk_dt: data.tk_dt,
					ten_tk_dt: "Doanh thu bán hàng hóa",
					gia_ban_nt: item.gia_ban_nt,
					tien_nt: item.tien_hang_nt,
					ma_dvt: "Bộ",
					dien_giai: item.ten_vt,
					sl_xuat: item.sl_xuat,
					ty_le_ck: item?.ty_le_ck,
					tien_ck_nt: item?.tien_ck_nt,
				};
			});
			if (
				data?.ten_pt_thanh_toan === "Tiền mặt" ||
				data?.ten_pt_thanh_toan === "Thẻ" ||
				data?.ten_pt_thanh_toan === "Chuyển khoản"
			) {
				await Promise.all([
					handleCreate({
						api: "pt1",
						data: {
							details: newDetails,
							trang_thai: "5",
							ngay_ct: data?.date_updated,
							tk_no: "1111",
							ten_tk_no: "Tiền Việt Nam",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							exfields: {
								ten_trang_thai_moi: "Ghi sổ quỹ",
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleCreate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
			if (data?.ten_pt_thanh_toan === "Công nợ") {
				await Promise.all([
					handleCreate({
						api: "hd1",
						data: {
							details: newDetailsHd1,
							ngay_ct: data?.date_updated,
							tk_no: "131",
							ten_tk_no: "Phải thu khách hàng",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							ma_nt: data?.ma_nt || "VND",
							ty_gia: data?.ty_gia || 1,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							exfields: {
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleCreate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
		} else {
			await handleCreate({
				api: "so1",
				data,
				messageApi,
				setLoading,
			});
		}

		await handleGetDataTable();
	};

	const handleUpdateData = async (data) => {
		console.log(data?.exfields?.trang_thai_hd, data?.exfields?.ghi_so_quy);
		if (
			data?.exfields?.trang_thai_hd === "Chuyển sổ sách" &&
			data?.exfields?.ghi_so_quy === null
		) {
			console.log(data?.exfields?.ghi_so_quy);
			const newDetails = data?.details?.map((item) => {
				return {
					tk_co: "131",
					ten_tk_co: "Phải thu khách hàng",
					ten_kh: data.ten_kh,
					ma_kh: data.ma_kh,
					tien_nt: item.tien_hang_nt - (item.tien_ck_nt || 0),
					dien_giai: item.dien_giai,
				};
			});
			const newDetailsHd1 = data?.details?.map((item) => {
				return {
					tk_dt: data.tk_dt,
					ten_tk_dt: "Doanh thu bán hàng hóa",
					gia_ban_nt: item.gia_ban_nt,
					tien_nt: item.tien_hang_nt,
					ma_dvt: "Bộ",
					dien_giai: item.ten_vt,
					sl_xuat: item.sl_xuat,
					ty_le_ck: item?.ty_le_ck,
					tien_ck_nt: item?.tien_ck_nt,
				};
			});
			if (
				data?.ten_pt_thanh_toan === "Tiền mặt" ||
				data?.ten_pt_thanh_toan === "Thẻ" ||
				data?.ten_pt_thanh_toan === "Chuyển khoản"
			) {
				await Promise.all([
					handleCreate({
						api: "pt1",
						data: {
							details: newDetails,
							trang_thai: "5",
							ngay_ct: data?.date_updated,
							tk_no: "1111",
							ten_tk_no: "Tiền Việt Nam",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							exfields: {
								ten_trang_thai_moi: "Ghi sổ quỹ",
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleUpdate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}

			if (data?.ten_pt_thanh_toan === "Công nợ") {
				console.log(data?.exfields?.ghi_so_quy);
				await Promise.all([
					await handleCreate({
						api: "hd1",
						data: {
							details: newDetailsHd1,
							ngay_ct: data?.date_updated,
							tk_no: "131",
							ten_tk_no: "Phải thu khách hàng",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							dvcs_data: dataDefault?.dvcs?.ten_dvcs,
							ma_dvcs: dataDefault?.dvcs?._id,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							ma_nt: data?.ma_nt || "VND",
							ty_gia: data?.ty_gia || 1,
						},
						messageApi,
						setLoading,
					}),
					await handleUpdate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
		} else {
			await handleUpdate({
				api: "so1",
				data,
				messageApi,
				setLoading,
			});
		}
		await handleGetDataTable();
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "so1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "so1",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataTable({});
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		const dataPTTT = await handleGetDataAndRows({
			api: "ptthanhtoan",
			q: {
				status: true,
				stt: 0,
			},
			setLoading,
		});
		const dataDVCS = await handleGetDataAndRows({
			api: "dvcs",
			q: {
				status: true,
				ten_dvcs: "Công ty",
			},
			setLoading,
		});
		if (dataPTTT) {
			setDataDefault({
				...dataDefault,
				ptthanhtoan: { ...dataPTTT.data[0] },
				dvcs: { ...dataDVCS.data[0] },
			});
		}
	};
	useEffect(() => {
		handleGetDataDefault();
	}, []);

	useEffect(() => {
		handleGetDataTable(params.q);
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ ma_kh: { $regex: value, $options: "i" } },
					{ ten_kh: { $regex: value, $options: "i" } },
					// { ho_ten_dem: { $regex: value, $options: "i" } },
					{ dien_thoai: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataTable({
			$or: [
				{ ma_kh: { $regex: value, $options: "i" } },
				{ ten_kh: { $regex: value, $options: "i" } },
				// { ho_ten_dem: { $regex: value, $options: "i" } },
				{ dien_thoai: { $regex: value, $options: "i" } },
			],
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
											shape="circle"
											size="small"
											className="btn_ternary !border-none"
										>
											<TfiReload />
										</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<p>Tìm</p>
									<Search
										placeholder="Nhập Id, tên hoặc số điện thoại"
										onChange={(e) =>
											DebouncedSearch(e.target.value)
										}
										allowClear
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenForm("create");
								setDataDetail({});
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayId(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên hoặc số điện thoại"
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Hoá đơn dịch vụ
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setIsOpenForm("edit");
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>

			<ModalHd1
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				isTabletOrMobile={isTabletOrMobile}
				dataDefault={dataDefault}
				dataDetail={dataDetail}
				handleSaveData={handleSaveData}
				handleUpdateData={handleUpdateData}
			/>
		</div>
	);
}
