import { Line } from "@ant-design/plots";
import React from "react";
import "./chart.scss";

export default function LineChart({ name }) {
	const data = [
		{ year: "1991", value: 3 },
		{ year: "1992", value: 4 },
		{ year: "1993", value: 3.5 },
		{ year: "1994", value: 5 },
		{ year: "1995", value: 4.9 },
		{ year: "1996", value: 6 },
		{ year: "1997", value: 7 },
		{ year: "1998", value: 9 },
		{ year: "1999", value: 13 },
	];

	const config = {
		data,
		xField: "year",
		yField: "value",
		point: {
			size: 5,
			shape: "diamond",
		},
		// smooth: true,
		// animation: {
		// 	appear: {
		// 		animation: "path-in",
		// 		duration: 5000,
		// 	},
		// },
		height: 250,
	};
	return (
		<div className="w-full h-full shadow-md p-3">
			<div className="title_chart">{name}</div>
			<Line {...config} />
		</div>
	);
}
