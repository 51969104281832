import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import InputNumber from "../../../../components/inputAnt/inputNumber";
import { Option } from "antd/es/mentions";

export default function ContactInformation() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	return (
		<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
			{/* tên - quan hệ */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Tên"
					name="ten_lien_he"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng nhập tên",
						},
					]}
				>
					<Input placeholder="nhập tên" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Quan hệ"
					name="quan_he"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					{/* ông/ bà; cha/mẹ/; anh/chị/em;con/cháu */}
					<Select placeholder="nhập quan hệ" allowClear>
						<Option value="ông/bà" key="ông/bà">
							ông/bà
						</Option>
						<Option value="cha/mẹ" key="cha/mẹ">
							cha/mẹ
						</Option>
						<Option value="anh/chị/em" key="anh/chị/em">
							anh/chị/em
						</Option>
						<Option value="con/cháu" key="con/cháu">
							con/cháu
						</Option>
					</Select>
				</Form.Item>
			</Col>

			{/* Tiềm năng - giới tính */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Tiềm năng"
					name="tiem_nang"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Select placeholder="nhập tiềm năng" allowClear>
						<Option value="Chăm sóc sau" key="Chăm sóc sau">
							Chăm sóc sau
						</Option>
						<Option value="Chăm sóc nóng" key="Chăm sóc nóng">
							Chăm sóc nóng
						</Option>
						<Option value="Không tiềm năng" key="Không tiềm năng">
							Không tiềm năng
						</Option>
						<Option value="Rất nóng" key="Rất nóng">
							Rất nóng
						</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Giới tính"
					name="gioi_tinh"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Select placeholder="nhập giới tính" allowClear>
						<Option value="nam" key="nam">
							nam
						</Option>
						<Option value="nữ" key="nữ">
							nữ
						</Option>
					</Select>
				</Form.Item>
			</Col>

			{/* di động - độ tuổi */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Di động"
					name="dien_thoai"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng nhập SĐT",
						},
					]}
				>
					<InputNumber placeholder="nhập số điện thoại" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Độ tuổi"
					name="do_tuoi"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					{/* 1-5;5-10;10-15;15-20;20-25;25-30;30-40;40-50;>50 */}
					<Select placeholder="nhập độ tuổi" allowClear>
						<Option value="1-5" key="1-5">
							1-5
						</Option>
						<Option value="5-10" key="5-10">
							5-10
						</Option>
						<Option value="10-15" key="10-15">
							10-15
						</Option>
						<Option value="15-20" key="15-20">
							15-20
						</Option>
						<Option value="20-25" key="20-25">
							20-25
						</Option>
						<Option value="25-30" key="25-30">
							25-30
						</Option>
						<Option value="30-40" key="30-40">
							30-40
						</Option>
						<Option value="40-50" key="40-50">
							40-50
						</Option>
						<Option value="&gt;50" key="&gt;50">
							&gt;50
						</Option>
					</Select>
				</Form.Item>
			</Col>

			{/* ghi chú */}
			<Col span={24}>
				<Form.Item
					name="ghi_chu"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
						span: 3,
					}}
					wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
