import { Input, Button } from "antd";
import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ModalFull from "../../../components/modal/ModalFull";
import { request } from "../../../../utils/request";

export default function ModalCustomerPt1({
	khoSelected,
	setPrintFrame,
	dataDefault,
	dataModal,
	dataDetail,
	handleManagerData,
	isOpenForm,
	setIsOpenForm,
	formSections,
}) {
	// const [loading, setLoading] = useState(false);
	// const printDefault = (voucher) => {
	// 	setLoading(true);
	// 	let stopRunning = () => {
	// 		setLoading(false);
	// 	};
	// 	let domain = window.location.origin;
	// 	let urlPrintDefault = `${domain}/#/print/${
	// 		voucher.id_app
	// 	}/${localStorage.getItem("token")}/${voucher._id}`;
	// 	console.log(urlPrintDefault);
	// 	// eslint-disable-next-line no-async-promise-executor
	// 	return new Promise(async (resolve) => {
	// 		await setPrintFrame(null);
	// 		let printFrame = (
	// 			<iframe
	// 				onLoad={() => {
	// 					setTimeout(() => {
	// 						let fr = window.frames["printframe"];
	// 						fr.focus();
	// 						stopRunning();
	// 						fr.print();
	// 						resolve();
	// 					}, 3000);
	// 				}}
	// 				name="printframe"
	// 				title="Print Frame"
	// 				style={{ display: "none", width: "100%", height: "100%" }}
	// 				src={urlPrintDefault}
	// 			></iframe>
	// 		);
	// 		setPrintFrame(printFrame);
	// 	});
	// };
	// const printLocal = async (url) => {
	// 	setLoading(true);
	// 	let stopRunning = () => {
	// 		setLoading(false);
	// 	};
	// 	let content;
	// 	try {
	// 		const resp = await request.get(url);
	// 		if (resp && resp.status === 200) {
	// 			content = resp.data;
	// 		}
	// 	} catch (e) {
	// 		return alert("info", e.message || "Server error");
	// 	}

	// 	// eslint-disable-next-line no-async-promise-executor
	// 	return new Promise(async (resolve) => {
	// 		await setPrintFrame(null);
	// 		let printFrame = (
	// 			// eslint-disable-next-line jsx-a11y/iframe-has-title
	// 			<iframe
	// 				onLoad={() => {
	// 					let fr = window.frames["printframe"];
	// 					fr.focus();
	// 					stopRunning();
	// 					fr.print();
	// 					setTimeout(() => {
	// 						resolve();
	// 					}, 10);
	// 				}}
	// 				name="printframe"
	// 				style={{ display: "none", width: "100%", height: "100%" }}
	// 				srcDoc={content}
	// 			></iframe>
	// 		);
	// 		console.log(printFrame);
	// 		setPrintFrame(printFrame);
	// 	});
	// };
	// const print = async (
	// 	loai_bill,
	// 	onSuccess,
	// 	onError,
	// 	useDefaultTempleteIfNotFound = true
	// ) => {
	// 	setLoading(true);
	// 	let printers = ((khoSelected[0] || {}).printers || []).filter(
	// 		(printer) =>
	// 			printer.id_mau_in &&
	// 			(printer.loai_bill == undefined ||
	// 				printer.loai_bill == loai_bill) &&
	// 			printer.ma_cn === "pt1"
	// 	);
	// 	if (printers.length > 0) {
	// 		await Promise.all(
	// 			printers.map((printer) => {
	// 				let url =
	// 					"/api/" +
	// 					localStorage.getItem("id_app") +
	// 					"/pt1/excel/" +
	// 					printer.id_mau_in +
	// 					"?_id=" +
	// 					dataDetail._id +
	// 					`&print=1&access_token=` +
	// 					localStorage.getItem("token");
	// 				const print_service_url = printer.dia_chi_may_in;

	// 				return (async () => {
	// 					if (print_service_url) {
	// 						let url_print = `${print_service_url}?url=${btoa(
	// 							url
	// 						)}&printer=${printer.ten_may_in}&width=${
	// 							printer.do_rong_kho_giay || 0
	// 						}&height=${printer.chieu_dai_kho_giay || 0}`;
	// 						try {
	// 							// await asyncGetList({ endpoint: url_print });
	// 						} catch (e) {
	// 							await printLocal(url);
	// 						}
	// 					} else {
	// 						await printLocal(url);
	// 					}
	// 				})();
	// 			})
	// 		);
	// 		setLoading(false);
	// 		if (onSuccess) onSuccess();
	// 	} else {
	// 		//default template print
	// 		if (useDefaultTempleteIfNotFound) {
	// 			try {
	// 				await printDefault(dataDetail);
	// 				if (onSuccess) onSuccess();
	// 			} catch (e) {
	// 				if (onError) {
	// 					alert("error", e.message || "Server error");
	// 				} else {
	// 					alert("error", e.message || "Server error");
	// 				}
	// 			}
	// 		} else {
	// 			if (onError) {
	// 				alert("error", "Không tìm thấy mẫu in phù hợp");
	// 			} else {
	// 				if (onSuccess) onSuccess();
	// 			}
	// 		}
	// 	}
	// };
	// const tables = () => {
	// 	return;
	// };

	return (
		<div>
			<ModalFull
				idModal={"ptvoucher-edit"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu thu</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail?.exfields,
							ten_trang_thai_moi: data?.ten_trang_thai_moi,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							pt_thanh_toan: data?.pt_thanh_toan,
						},
					});
				}}
				data={{
					...dataDetail,
					trangthai_data: dataDetail?.exfields?.ten_trang_thai_moi,
					ten_trang_thai_moi:
						dataDetail?.exfields?.ten_trang_thai_moi,
					dvcs_data: dataDetail?.ten_dvcs,
					customer_data:
						dataDetail?.ten_kh + " - " + dataDetail?.ma_kh,
					dmkho_data:
						(dataDetail?.ten_kho || "") +
						" - " +
						(dataDetail?.ma_kho || ""),
					currency_data:
						(dataDetail?.ma_nt || "") +
						" - " +
						(dataDetail?.ty_gia || ""),
					ma_nt: dataDetail?.ma_nt,
					ty_gia: dataDetail?.ty_gia,
					ten_nt: dataDetail?.ten_nt,
					ptthanhtoan_data: dataDetail?.exfields?.ten_pt_thanh_toan,
					dmnv_data:
						(dataDetail?.ten_nv || "") +
						" - " +
						(dataDetail?.ma_nv || ""),
				}}
				// groupButton={[
				// 	<Button
				// 		className="btn_error"
				// 		onClick={() => {
				// 			print(
				// 				1,
				// 				() => {
				// 					tables();
				// 				},
				// 				() => {
				// 					tables();
				// 				}
				// 			);
				// 		}}
				// 	>
				// 		In hoá đơn
				// 	</Button>,
				// 	<Button htmlType="submit" className="btn_primary">
				// 		Lưu
				// 	</Button>,
				// ]}
			/>

			<ModalFull
				idModal={"ptvoucher-create"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu thu</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...data,
						exfields: {
							ten_trang_thai_moi: data?.ten_trang_thai_moi,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							pt_thanh_toan: data?.pt_thanh_toan,
						},
					});
				}}
				data={{
					trangthai_data: "Ghi sổ quỹ",
					trang_thai: "5",
					ten_trang_thai_moi: "Ghi sổ quỹ",
					currency_data: "VND - 1",
					ma_nt: "VND",
					ty_gia: 1,
					ten_nt: "VND",
					tk_no: "1111",
					ten_tk_no: "Tiền Việt Nam",
					dvcs_data: dataDefault?.dvcs?.ten_dvcs,
					ma_dvcs: dataDefault?.dvcs?._id,
					ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
					ngay_ct: new Date(),
					customer_data: dataModal?.ten_kh + " - " + dataModal?.ma_kh,
					ten_kh: dataModal?.ten_kh,
					ma_kh: dataModal?.ma_kh,
					ptthanhtoan_data: dataDefault?.ptthanhtoan?.ten,
					ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
					pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
				}}
				// groupButton={[
				// 	<Button
				// 		className="btn_error"
				// 		onClick={() => {
				// 			alert("Đang nâng cấp");
				// 		}}
				// 	>
				// 		In hoá đơn
				// 	</Button>,
				// 	<Button htmlType="submit" className="btn_primary">
				// 		Lưu
				// 	</Button>,
				// ]}
			/>
		</div>
	);
}
