import { Button, Col, DatePicker, Form, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useRef, useState } from "react";
import SelectAdd from "../inputAnt/selectAdd";
import { HiOutlineFilter } from "react-icons/hi";
const { RangePicker } = DatePicker;
dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";

export default function FilterReport({
	handleManager,
	data = {},
	ngaythieplap,
	setNgaythieplap,
	account = false,
	dvcs = false,
	dmkho = false,
	customer = false,
	ncc = false,
	dmnv = false,
}) {
	const formFilterRef = useRef(null);
	const getFieldValue = (name) => {
		return formFilterRef?.current?.getFieldValue(name);
	};

	const setFieldsValue = (name, value) => {
		formFilterRef.current?.setFieldsValue({ [name]: value });
	};

	useEffect(() => {
		formFilterRef?.current?.setFieldsValue(data);
	}, [data]);

	const onFinish = (values) => {
		handleManager(values);
	};
	const onReset = () => {
		formFilterRef.current?.resetFields();
	};

	const [tuNgay, setTuNgay] = useState();
	useEffect(() => {
		if (ngaythieplap?.tu_ngay === null) {
			setTuNgay(null);
		} else {
			setTuNgay(dayjs(ngaythieplap?.tu_ngay).format("DD/MM/YYYY"));
		}
	}, [ngaythieplap?.tu_ngay]);

	const [denNgay, setDenNgay] = useState();
	useEffect(() => {
		if (ngaythieplap?.den_ngay === null) {
			setDenNgay(null);
		} else {
			setDenNgay(dayjs(ngaythieplap?.den_ngay).format("DD/MM/YYYY"));
		}
	}, [ngaythieplap?.den_ngay]);

	return (
		<Form
			id={"form-filter-report"}
			ref={formFilterRef}
			name="control-ref"
			onFinish={onFinish}
			layout={"vertical"}
			labelCol={{
				span: 4,
			}}
		>
			<div className="pt-4 h-fit max-h-[calc(100vh-150px)] overflow-y-auto">
				<Row className="!m-0" gutter={[8, 8]}>
					{/* từ ngày - đến ngày */}
					<Col span={12}>
						<Form.Item
							label="Từ ngày"
							labelCol={{
								span: 24,
							}}
							wrapperCol={{ span: 24 }}
						>
							<DatePicker
								placeholder="chọn thời gian"
								value={
									tuNgay ? dayjs(tuNgay, "DD/MM/YYYY") : null
								}
								format="DD/MM/YYYY"
								onChange={(date, dateString) => {
									setNgaythieplap({
										...ngaythieplap,
										tu_ngay: date,
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Đến ngày"
							labelCol={{
								span: 24,
							}}
							wrapperCol={{ span: 24 }}
						>
							<DatePicker
								placeholder="chọn thời gian"
								value={
									denNgay
										? dayjs(denNgay, "DD/MM/YYYY")
										: null
								}
								format="DD/MM/YYYY"
								onChange={(date, dateString) => {
									setNgaythieplap({
										...ngaythieplap,
										den_ngay: date,
									});
								}}
							/>
						</Form.Item>
					</Col>

					{/* Tài khoản */}
					<Form.Item name="tk"></Form.Item>
					<Form.Item name="ten_tk"></Form.Item>
					{account && (
						<Col span={24}>
							<SelectAdd
								api="account"
								label="Tài khoản"
								name="tk_co_data"
								valueOption={["tk", "ten_tk"]}
								lableOption={["tk", "ten_tk"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								rules={[
									{
										required: true,
										message: "vui lòng chọn...",
									},
								]}
								placeholder="nhập tên tài khoản"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue("tk", parsedValue?.tk);
									}
								}}
							/>
						</Col>
					)}

					<Form.Item name="ten_nv"></Form.Item>
					<Form.Item name="ma_nv"></Form.Item>
					{dmnv && (
						<Col span={24}>
							<SelectAdd
								api="dmnv"
								q={{
									status: true,
								}}
								label="Nhân viên"
								name="dmnv_data"
								lableOption={["ten_nv", "ma_nv"]}
								valueOption={["ma_nv", "ten_nv"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								rules={[
									{
										required: true,
										message: "vui lòng chọn nhân viên",
									},
								]}
								placeholder="chọn nhân viên"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue(
											"ten_nv",
											parsedValue?.ten_nv
										);
										setFieldsValue(
											"ma_nv",
											parsedValue?.ma_nv
										);
									}
								}}
							/>
						</Col>
					)}

					<Form.Item name="ten_kh"></Form.Item>
					<Form.Item name="ma_kh"></Form.Item>
					{customer && (
						<Col span={24}>
							<SelectAdd
								api="customer"
								q={{
									status: true,
									kh_yn: true,
									loai_kh: {
										$nin: ["Vendor", "Staff"],
									},
								}}
								label="Khách hàng"
								name="customer_data"
								lableOption={["ten_kh", "ma_kh"]}
								valueOption={["ma_kh", "ten_kh"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								// rules={[
								// 	{
								// 		required: true,
								// 		message: "vui lòng chọn khách hàng",
								// 	},
								// ]}
								placeholder="chọn khách hàng"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue(
											"ten_kh",
											parsedValue?.ten_kh
										);
										setFieldsValue(
											"ma_kh",
											parsedValue?.ma_kh
										);
									} else {
										setFieldsValue("ten_kh", "");
										setFieldsValue("ma_kh", "");
									}
								}}
							/>
						</Col>
					)}

					{ncc && (
						<Col span={24}>
							<SelectAdd
								api="customer"
								q={{
									status: true,
									kh_yn: false,
									$or: [
										{
											loai_kh: {
												$in: ["Vendor", "Staff"],
											},
										},
										{
											iscarrier: true,
										},
										{
											ncc_yn: true,
										},
									],
								}}
								label="Bác sĩ/NCC"
								name="customer_data"
								lableOption={["ten_kh", "ma_kh"]}
								valueOption={["ma_kh", "ten_kh"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								// rules={[
								// 	{
								// 		required: true,
								// 		message: "vui lòng chọn khách hàng",
								// 	},
								// ]}
								placeholder="chọn bác sĩ/ncc"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue(
											"ten_kh",
											parsedValue?.ten_kh
										);
										setFieldsValue(
											"ma_kh",
											parsedValue?.ma_kh
										);
									} else {
										setFieldsValue("ten_kh", "");
										setFieldsValue("ma_kh", "");
									}
								}}
							/>
						</Col>
					)}

					{/* Đơn vị - chi nhánh */}
					{dvcs && (
						<Col span={24}>
							<SelectAdd
								api="dvcs"
								q={{ status: true }}
								label="Đơn vị"
								name="dvcs_data"
								lableOption={["ten_dvcs"]}
								valueOption={["ten_dvcs", "_id"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								placeholder="chọn đơn vị"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue(
											"ma_dvcs",
											parsedValue?._id
										);
										setFieldsValue(
											"ten_dvcs",
											parsedValue?.ten_dvcs
										);
									}
								}}
							/>
						</Col>
					)}
					{dmkho && (
						<Col span={24}>
							<SelectAdd
								api="dmkho"
								label="Chi nhánh"
								name="dmkho_data"
								lableOption={["ten_kho", "ma_kho"]}
								valueOption={["ten_kho", "ma_kho"]}
								labelCol={{
									span: 24,
								}}
								wrapperCol={{ span: 24 }}
								placeholder="chọn chi nhánh"
								onChange={(value) => {
									if (value) {
										const parsedValue = JSON.parse(value);
										setFieldsValue(
											"ten_kho",
											parsedValue?.ten_kho
										);
										setFieldsValue(
											"ma_kho",
											parsedValue?.ma_kho
										);
									}
								}}
							/>
						</Col>
					)}
				</Row>
			</div>
			<div className="w-full justify-between flex items-center flex-row-reverse">
				<div className="flex justify-end items-center gap-4 py-2">
					<Button htmlType="submit" className="btn_ternary">
						<HiOutlineFilter />
						Lọc
					</Button>
				</div>
			</div>
		</Form>
	);
}
