import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { BsPersonPlus } from "react-icons/bs";
import { LuEye, LuFileInput, LuPencil, LuX } from "react-icons/lu";
import { randomChars } from "../../../../utils/utilsFunc";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import { useMediaQuery } from "react-responsive";
import PatientDataExtraction from "../../../customer/components/modal/modalItem/PatientDataExtraction";
import Prescription from "../../../customer/components/modal/modalItem/Prescription";

export default function ExtraScreen({ dataModal, setFieldsValue }) {
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenPDExtra("edit");
									setDataExtra({
										...record,
									});
									setDataExtraDefault({
										...record,
									});
								}}
							>
								<LuEye size={18} />
							</Button>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenPDExtra("edit");
									setDataExtra({
										...record,
									});
									setDataExtraDefault({
										...record,
									});
								}}
							>
								<LuPencil size={18} />
							</Button>
							<Popconfirm
								title={`Bạn muốn xoá ${
									record.so_phieu || ""
								} ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									const indexToDelete =
										dataModalExtra.findIndex(
											(item) =>
												JSON.stringify(item) ===
												JSON.stringify(record)
										);

									if (indexToDelete !== -1) {
										const newDataModal = [
											...dataModalExtra,
										];
										newDataModal.splice(indexToDelete, 1);
										// handleModal(newDataModal);
										setDataModalExtra(newDataModal);
										setFieldsValue(
											"trich_xuat_bn",
											newDataModal
										);
									}
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<LuX size={18} />
								</Button>
							</Popconfirm>
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Số phiếu",
			dataIndex: "so_phieu",
			width: 100,
			render: (so_phieu) => {
				return <span className="text-[#0D32F8]">{so_phieu}</span>;
			},
		},
		{
			title: "Ngày khám",
			dataIndex: "ngay_kham",
			render: (ngay_kham) => {
				return ngay_kham
					? new Date(ngay_kham).toLocaleDateString("vi-VN")
					: null;
			},
			width: 100,
		},
		{
			title: "Lý do",
			dataIndex: "ly_do_kham",
			width: 150,
		},
		{
			title: "Chẩn đoán",
			dataIndex: "chuan_doan",
			width: 100,
		},
		{
			title: "Kế hoạch điều trị",
			dataIndex: "ke_hoach",
			width: 200,
		},
		{
			title: "Công việc điều trị",
			dataIndex: "dieu_tri",
			width: 200,
		},
		{
			title: "Hình ảnh",
			dataIndex: "picture_xquang",
			render: (picture_xquang) => {
				return (
					<div className="flex justify-start items-center w-full gap-1">
						{picture_xquang &&
							picture_xquang
								?.slice(0, 3)
								?.map((item, index) => (
									<img
										key={index}
										src={item.link}
										alt="avatar"
										className="w-8 h-8 bg-slate-100 object-contain"
									/>
								))}
					</div>
				);
			},
			width: 150,
		},
		{
			title: "Toa thuốc",
			dataIndex: "",
			render: (record) => (
				<div className="flex justify-start w-full">
					<Button
						onClick={(e) => {
							e.stopPropagation();
							setIsOpenPrescription(true);
							setDataExtra({
								...record,
							});
							setDataExtraDefault({
								...record,
							});
						}}
						className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
						shape="circle"
						size="small"
					>
						<LuFileInput size={18} />
					</Button>
				</div>
			),
			width: 80,
		},
		{
			title: "Bác sĩ",
			dataIndex: "bac_si",
			width: 100,
		},
	];

	const [dataModalExtra, setDataModalExtra] = useState([]);

	useEffect(() => {
		if (dataModal && dataModal.length > 0) {
			const sortedData = [...dataModal].sort(function (a, b) {
				return new Date(b.ngay_kham) - new Date(a.ngay_kham);
			});

			setDataModalExtra(sortedData);
		} else {
			setDataModalExtra([]);
		}
	}, [dataModal]);

	// modal
	const [isOpenPDExtra, setIsOpenPDExtra] = useState(false);
	const [isOpenPrescription, setIsOpenPrescription] = useState(false);

	const [dataExtra, setDataExtra] = useState({});
	const [dataExtraDefault, setDataExtraDefault] = useState({});

	return (
		<div className="relative">
			<TableManagerCus
				id="dataextra-edit-tab"
				data={dataModalExtra}
				columns={columns}
				scroll={{
					x: 900,
					y: "80vh",
				}}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenPDExtra("edit");
						setDataExtra({
							...record,
						});
						setDataExtraDefault({
							...record,
						});
					},
				})}
			/>
			<Button
				onClick={() => {
					setIsOpenPDExtra("create");
					setDataExtra({});
				}}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Thêm
			</Button>

			{/* modalPDExtra */}
			<ModalBasic
				zIndex={1035}
				id={"modalPDExtra-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu trích xuất bệnh nhân</span>
						<div className="flex justify-end items-center gap-6">
							<Button
								onClick={() => {
									setIsOpenPrescription(true);
								}}
								className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e] hover:!text-[#189e40]"
							>
								<LuFileInput className="w-5 h-5" />
							</Button>
						</div>
					</div>
				}
				open={isOpenPDExtra === "edit" ? true : false}
				onCancel={() => {
					setIsOpenPDExtra(false);
				}}
				footer={null}
				handleManager={(data) => {
					const updatedDataModal = [...dataModalExtra];
					const indexToUpdate = updatedDataModal.findIndex(
						(item) =>
							JSON.stringify(item) ===
							JSON.stringify(dataExtraDefault)
					);

					if (indexToUpdate !== -1) {
						updatedDataModal[indexToUpdate] = {
							...updatedDataModal[indexToUpdate],
							...data,
							toa_thuoc: {
								...dataExtra?.toa_thuoc,
							},
						};
						// handleModal(updatedDataModal);
						setDataModalExtra(updatedDataModal);
						setFieldsValue("trich_xuat_bn", updatedDataModal);
						setIsOpenPDExtra(false);
					}
				}}
				data={{
					...dataExtra,
					picture_xquang: dataExtra?.picture_xquang || [],
					implant: dataExtra?.implant || "",
					lab: dataExtra?.lab || "",
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<PatientDataExtraction />
			</ModalBasic>
			<ModalBasic
				zIndex={1035}
				id={"modalPDExtra"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu trích xuất bệnh nhân</span>
						<div className="flex justify-end items-center gap-6">
							<Button
								onClick={() => {
									setIsOpenPrescription(true);
								}}
								className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e] hover:!text-[#189e40]"
							>
								<LuFileInput className="w-5 h-5" />
							</Button>
						</div>
					</div>
				}
				open={isOpenPDExtra === "create" ? true : false}
				onCancel={() => {
					setIsOpenPDExtra(false);
				}}
				footer={null}
				handleManager={(data) => {
					setFieldsValue("trich_xuat_bn", [
						{
							...data,
							so_phieu: randomChars(4),
							ngay_kham: data?.ngay_kham,
							toa_thuoc: {
								...dataExtra?.toa_thuoc,
							},
						},
						...dataModalExtra,
					]);
					setDataModalExtra([
						{
							...data,
							toa_thuoc: {
								...dataExtra?.toa_thuoc,
							},
						},
						...dataModalExtra,
					]);
					setIsOpenPDExtra(false);
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				data={{
					ngay_kham: new Date(),
					so_phieu: randomChars(4),
					picture_xquang: [],
				}}
			>
				<PatientDataExtraction />
			</ModalBasic>

			{/* modalPrescription */}
			<ModalBasic
				zIndex={1036}
				id={"modalPrescription-edit"}
				title={"Toa thuốc"}
				open={isOpenPrescription}
				onCancel={() => {
					setIsOpenPrescription(false);
				}}
				footer={null}
				handleManager={(data) => {
					if (!isOpenPDExtra) {
						const updatedDataModal = [...dataModalExtra];
						const indexToUpdate = updatedDataModal.findIndex(
							(item) =>
								JSON.stringify(item) ===
								JSON.stringify(dataExtraDefault)
						);

						if (indexToUpdate !== -1) {
							updatedDataModal[indexToUpdate] = {
								...updatedDataModal[indexToUpdate],
								toa_thuoc: {
									...updatedDataModal[indexToUpdate]
										?.toa_thuoc,
									bac_si: data.bac_si,
									ngay: data?.ngay || new Date(),
									medicines: data?.medicines || [],
								},
							};
							setDataModalExtra(updatedDataModal);
							setFieldsValue("trich_xuat_bn", updatedDataModal);
							setIsOpenPrescription(false);
						}
					} else {
						setDataExtra({
							...dataExtra,
							toa_thuoc: {
								...data,
								ngay: data?.ngay || new Date(),
							},
						});
						setIsOpenPrescription(false);
					}
				}}
				data={
					isOpenPDExtra === "create"
						? {
								bac_si: "",
								ngay: new Date(),
								medicines: [],
						  }
						: {
								bac_si: dataExtra?.bac_si || "",
								ngay: new Date(),
								medicines: [],
								...dataExtra?.toa_thuoc,
						  }
				}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				groupButton={[
					<Button
						className="btn_error"
						onClick={() => alert("Đang nâng cấp")}
					>
						In toa
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<Prescription />
			</ModalBasic>
		</div>
	);
}
