import { Button, Input, Popconfirm, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableManager from "../../components/table/tableManager";
import "./services.scss";
import { BsPersonPlus } from "react-icons/bs";
import {
	handleCreate,
	handleDelete,
	handleDeleteArray,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import { debounce } from "lodash";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { BiCopyAlt } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import ModalDMServices from "../components/Modal/ModalDMServices";
const { Search } = Input;

export default function Services() {
	const [dataServices, setDataServices] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();
	const [dataImage, setDataImage] = useState("");
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [dataDetail, setDataDetail] = useState({});

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setDataImage(data?.picture);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setDataImage(data?.picture);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
		{
			key: "4",
			icon: <IoSettingsOutline />,
			onClick: (_id) => {
				navigate(`/services/managerServices/${_id}`);
			},
			label: "DesignUI",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key === "3" ? (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								) : item.key === "4" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record?._id);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Hình ảnh",
			dataIndex: "picture",
			render: (picture) => {
				return (
					<div className="flex justify-center items-center w-28">
						{picture && (
							<img
								src={picture}
								alt="avatar"
								className="w-4/5 mx-auto bg-slate-100 object-cover"
							/>
						)}
					</div>
				);
			},
			width: 150,
		},
		{
			title: "Sản phẩm",
			dataIndex: "ma_vt",
			width: 100,
		},
		{
			title: "Tên dịch vụ",
			dataIndex: "ten_vt",
			// render: (title, record) => (
			// 	<div className="flex flex-col field_title">
			// 		<span>{title}</span>
			// 		<div className="show_group_button">
			// 			<span>
			// 				<div
			// 					onClick={(e) => {
			// 						e.stopPropagation();
			// 						window.open(
			// 							`${localStorage.getItem(
			// 								localStorage.getItem("id_app")
			// 							)}services/${record?.exfields?.slug}`
			// 						);
			// 					}}
			// 					className="pr-1 cursor-pointer hover:underline text-[#000] hover:text-[#326b97]"
			// 				>
			// 					Xem
			// 				</div>
			// 				<div
			// 					onClick={(e) => {
			// 						e.preventDefault();
			// 						navigate(
			// 							`/services/managerServices/${record?._id}`
			// 						);
			// 					}}
			// 					className="px-1 cursor-pointer hover:underline text-[#000] hover:text-[#329737]"
			// 				>
			// 					Sửa
			// 				</div>
			// 				<Popconfirm
			// 					title={`Xoá "${record?.ten_vt}"`}
			// 					onConfirm={(e) => {
			// 						e.stopPropagation();
			// 						handleDeleteId(record?._id);
			// 					}}
			// 					onCancel={(e) => e.stopPropagation()}
			// 					okText="Có"
			// 					cancelText="Không"
			// 				>
			// 					<div
			// 						onClick={(e) => e.stopPropagation()}
			// 						className="pl-1 cursor-pointer hover:underline text-[#000] hover:text-[#973232]"
			// 					>
			// 						Xoá
			// 					</div>
			// 				</Popconfirm>
			// 			</span>
			// 		</div>
			// 	</div>
			// ),
			width: 200,
		},

		{
			title: "Giá bán lẻ",
			dataIndex: "gia_ban_le",
			render: (gia_ban_le) => (
				<div className="flex flex-col">
					<span>
						{new Intl.NumberFormat("vi-VN").format(gia_ban_le) +
							" VND"}
					</span>
				</div>
			),
			width: 200,
		},
		// {
		// 	title: "Người tạo",
		// 	dataIndex: "name_user_created",
		// 	width: 150,
		// },
		{
			title: "Tags",
			key: "exfields",
			dataIndex: "exfields",
			render: (_, record) => (
				<>
					{record?.exfields?.tags?.map((tag) => {
						let color = tag.length > 5 ? "geekblue" : "green";
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
			width: 200,
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 200,
			render: (date_created, record) => {
				return (
					<div className="flex gap-1">
						<span>
							{record?.exfields?.status_post === 1
								? "Publish"
								: "Pending"}
						</span>
						<span>{moment(date_created).format("YYYY-MM-DD")}</span>
					</div>
				);
			},
		},
	];

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "dmvt",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataServices({});
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "dmvt",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataServices({});
	};

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataServices = async (q) => {
		const data = await handleGetDataAndRows({
			api: "dmvt",
			q: {
				status: true,
				"exfields.loai_vat_tu": "Services",
				...q,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataServices(data.data);
			setTotalRows(data.total);
		}
	};
	const handleSaveData = async (data) => {
		await handleCreate({
			api: "dmvt",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataServices({ ...params.q });
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "dmvt",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataServices({ ...params.q });
	};
	const handleManagerData = async (data) => {
		if (data?._id) {
			await handleUpdateData({
				...data,
			});
		} else {
			await handleSaveData({
				...data,
			});
		}
	};

	useEffect(() => {
		handleGetDataServices({ ...params.q });
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ ten_vt: { $regex: value, $options: "i" } },
					{ ma_vt: { $regex: value, $options: "i" } },
					// { gia_ban_le: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataServices({
			$or: [
				{ ten_vt: { $regex: value, $options: "i" } },
				{ ma_vt: { $regex: value, $options: "i" } },
				// { gia_ban_le: { $regex: value, $options: "i" } },
			],
		});
	};

	const navigate = useNavigate();

	return (
		<React.Fragment>
			{contextHolder}
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				<div className="h-[48px] flex justify-start items-center gap-4 box_menu_post">
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							// onClick={() => {
							// 	navigate("/services/managerServices/create");
							// }}
							onClick={() => {
								setIsOpenForm("create");
								setDataDetail({});
								setDataImage("");
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayId(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên vật tư..."
						onChange={(e) => DebouncedSearch(e.target.value)}
						style={{
							width: 300,
						}}
						allowClear
					/>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Danh mục dịch vụ
					</span>
				</div>
			</div>
			<div className="flex flex-col gap-3">
				<div className="box_content">
					<TableManager
						data={dataServices}
						columns={columns}
						loading={loading}
						rowKey={"_id"}
						scroll={{ x: 900, y: "calc(100vh - 332px)" }}
						tableProps={{
							size: "middle",
						}}
						handleDataSelectKey={(newSelectedRowKeys) => {
							setDataSelected(newSelectedRowKeys);
						}}
						// onRow={(record) => ({
						// 	onClick: () => {
						// 		navigate(
						// 			`/services/managerServices/${record?._id}`
						// 		);
						// 	},
						// })}
						onRow={(record) => ({
							onClick: () => {
								setDataDetail(record);
								setDataImage(record?.picture);
								setIsOpenForm("edit");
							},
						})}
						isPage={params.page}
						isLimit={params.limit}
						totalRows={totalRows}
						handlePaginationChange={handlePaginationChange}
					/>
				</div>
			</div>
			<ModalDMServices
				dataImage={dataImage}
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				dataDetail={dataDetail}
				handleManagerData={handleManagerData}
				// dataDefault={dataDefault}
			/>
		</React.Fragment>
	);
}
