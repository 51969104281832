import { Col, Form, InputNumber, Row } from "antd";
import React from "react";
import SelectAdd from "../../../components/inputAnt/selectAdd";

export default function FormDetailPxk({
	isTabletOrMobile,
	getFieldValue,
	setFieldsValue,
}) {
	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 20 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* Tên Implant - Đơn vị tính */}
			{/* field hidden */}
			<Form.Item name="ma_dvt"></Form.Item>
			<Form.Item name="ten_vt"></Form.Item>
			<Form.Item name="ma_vt"></Form.Item>
			<Form.Item name="tk_vt"></Form.Item>
			<Form.Item name="ten_tk_vt"></Form.Item>
			<Col span={24}>
				<SelectAdd
					api="dmvt"
					q={{ status: true }}
					label="Tên Implant"
					name="dmvt_vt"
					valueOption={[
						"ten_vt",
						"ma_vt",
						"ma_dvt",
						"tk_vt",
						"ten_tk_vt",
					]}
					lableOption={["ten_vt", "ma_vt"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn Implant",
						},
					]}
					placeholder="nhập tên Implant"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvt", parsedValue?.ma_dvt);
							setFieldsValue("ten_vt", parsedValue?.ten_vt);
							setFieldsValue("ma_vt", parsedValue?.ma_vt);
							setFieldsValue("tk_vt", parsedValue?.tk_vt);
							setFieldsValue("ten_tk_vt", parsedValue?.ten_tk_vt)
						}
					}}
				/>
			</Col>

			{/* Số lượng xuất - đơn giá */}
			<Col span={24}>
				<Form.Item
					label="Số lượng"
					name="sl_xuat"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_xuat_nt",
								value * (getFieldValue("gia_von_nt") || 0)
							);
						}}
						defaultValue={0}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Đơn giá (VND)"
					name="gia_von_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_xuat_nt",
								(getFieldValue("sl_xuat") || 0) * value
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>

			{/* Thành tiền */}
			<Col span={24}>
				<Form.Item
					label="Thành tiền (VND)"
					name="tien_xuat_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						disabled
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
