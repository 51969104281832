import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { BsPersonPlus } from "react-icons/bs";
import { LuEye, LuPencil, LuX } from "react-icons/lu";
import ModalMedicine from "../modal/modalItem/ModalMedicine";
import TableManagerCus from "../../../components/table/tableManagerCus";

export default function ItemMedicine({ setFieldsValue, getFieldValue }) {
	const [dataMedicine, setDataMedicine] = useState([]);
	const [isOpenMedicine, setIsOpenMedicine] = useState(false);
	const [dataModalMedicine, setDataModalMedicine] = useState({});
	const [dataMedicineDefault, setDataMedicineDefault] = useState({});

	useEffect(() => {
		if (getFieldValue("medicines")) {
			setDataMedicine(getFieldValue("medicines") || []);
		}
	}, [getFieldValue("medicines")]);

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenMedicine("edit");
									setDataModalMedicine({
										...record,
									});
									setDataMedicineDefault({
										...record,
									});
								}}
							>
								<LuEye size={18} />
							</Button>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenMedicine("edit");
									setDataModalMedicine({
										...record,
									});
									setDataMedicineDefault({
										...record,
									});
								}}
							>
								<LuPencil size={18} />
							</Button>
							<Popconfirm
								title={`Bạn muốn xoá ${
									record.so_phieu || ""
								} ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									const indexToDelete =
										dataMedicine.findIndex(
											(item) =>
												JSON.stringify(item) ===
												JSON.stringify(record)
										);

									if (indexToDelete !== -1) {
										const newDataModal = [...dataMedicine];
										newDataModal.splice(indexToDelete, 1);
										setFieldsValue(
											"medicines",
											newDataModal
										);
										setDataMedicine(newDataModal);
									}
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<LuX size={18} />
								</Button>
							</Popconfirm>
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Tên thuốc",
			dataIndex: "ten_vt",
			width: 200,
		},
		{
			title: "Đvt",
			dataIndex: "ma_dvt",
			width: 50,
		},
		{
			title: "Số lượng",
			dataIndex: "t_sl",
			width: 100,
		},
		{
			title: "Cách dùng",
			dataIndex: "cach_dung",
			width: 100,
		},
		{
			title: "Số viên/ lần",
			dataIndex: "sl_lan",
			width: 150,
		},
		{
			title: "Số lần/ ngày",
			dataIndex: "sl_ngay",
			width: 150,
		},
		{
			title: "Ghi chú",
			dataIndex: "dien_giai",
			width: 150,
		},
	];

	const handleManager = (data) => {
		if (isOpenMedicine === "create") {
			setFieldsValue("medicines", [data, ...dataMedicine]);
			setDataMedicine([data, ...dataMedicine]);
			setIsOpenMedicine(false);
		} else {
			const updatedDataModal = [...dataMedicine];
			const indexToUpdate = updatedDataModal.findIndex(
				(item) =>
					JSON.stringify(item) === JSON.stringify(dataMedicineDefault)
			);

			if (indexToUpdate !== -1) {
				updatedDataModal[indexToUpdate] = {
					...updatedDataModal[indexToUpdate],
					...data,
				};
				setFieldsValue("medicines", updatedDataModal);
				setDataMedicine(updatedDataModal);
				setIsOpenMedicine(false);
			}
		}
	};

	return (
		<div className="relative w-full">
			<TableManagerCus
				id="medicine-edit-tab"
				data={dataMedicine}
				columns={columns}
				scroll={{
					x: 600,
					y: "80vh",
				}}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenMedicine("edit");
						setDataModalMedicine({
							...record,
						});
						setDataMedicineDefault({
							...record,
						});
					},
				})}
			/>
			<Button
				onClick={() => {
					setIsOpenMedicine("create");
					setDataModalMedicine({});
				}}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Thêm
			</Button>

			<ModalMedicine
				isOpenForm={isOpenMedicine}
				setIsOpenForm={setIsOpenMedicine}
				handleManager={handleManager}
				data={dataModalMedicine}
			/>
		</div>
	);
}
