import { Button, Popconfirm } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleDelete,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../../utils/utilsApi";
import { randomChars } from "../../../../utils/utilsFunc";
import FormHd1 from "../../../ManagerServices/components/form/FormHd1";
import ItemDetail from "../../../ManagerServices/components/item/ItemDetail";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import { TfiReload } from "react-icons/tfi";

export default function CustomerHd1({ dataModal, messageApi }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Họ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ho_ten_dem;
			},
		},
		{
			title: "Tên",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return record?.exfields?.trang_thai_hd;
			},
		},
		{
			title: "Thời gian hẹn",
			dataIndex: "",
			width: 150,
			render: (record) => {
				if (!record?.exfields?.ngay_hoa_don) return null;
				else
					return dayjs(record?.exfields?.ngay_hoa_don).format(
						"DD/MM/YYYY"
					);
			},
		},
		{
			title: "Mã lịch hẹn",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ma_lich_hen;
			},
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},

		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 100,
		},
	];

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 8,
				ma_kh: dataModal?.ma_kh,
				...q,
			},
			page: 1,
			limit: 100,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
		}
	};

	const handleSaveData = async (data) => {
		if (data?.exfields?.trang_thai_hd === "Chuyển sổ sách") {
			const newDetails = data?.details?.map((item) => {
				return {
					tk_co: "131",
					ten_tk_co: "Phải thu khách hàng",
					ten_kh: data.ten_kh,
					ma_kh: data.ma_kh,
					tien_nt: item.tien_hang_nt - (item.tien_ck_nt || 0),
					dien_giai: item.dien_giai,
				};
			});
			const newDetailsHd1 = data?.details?.map((item) => {
				return {
					tk_dt: data.tk_dt,
					ten_tk_dt: "Doanh thu bán hàng hóa",
					gia_ban_nt: item.gia_ban_nt,
					tien_nt: item.tien_hang_nt,
					ma_dvt: "Bộ",
					dien_giai: item.ten_vt,
					sl_xuat: item.sl_xuat,
					ty_le_ck: item?.ty_le_ck,
					tien_ck_nt: item?.tien_ck_nt,
				};
			});
			if (
				data?.ten_pt_thanh_toan === "Tiền mặt" ||
				data?.ten_pt_thanh_toan === "Thẻ" ||
				data?.ten_pt_thanh_toan === "Chuyển khoản"
			) {
				await Promise.all([
					handleCreate({
						api: "pt1",
						data: {
							details: newDetails,
							trang_thai: "5",
							ngay_ct: new Date(),
							tk_no: "1111",
							ten_tk_no: "Tiền Việt Nam",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							exfields: {
								ten_trang_thai_moi: "Ghi sổ quỹ",
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleCreate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
			if (data?.ten_pt_thanh_toan === "Công nợ") {
				await Promise.all([
					handleCreate({
						api: "hd1",
						data: {
							details: newDetailsHd1,
							ngay_ct: data?.date_updated,
							tk_no: "131",
							ten_tk_no: "Phải thu khách hàng",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							ma_nt: data?.ma_nt || "VND",
							ty_gia: data?.ty_gia || 1,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							exfields: {
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleCreate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
		} else {
			await handleCreate({
				api: "so1",
				data,
				messageApi,
				setLoading,
			});
		}

		await handleGetDataTable({});
	};

	const handleUpdateData = async (data) => {
		if (
			data?.exfields?.trang_thai_hd === "Chuyển sổ sách" &&
			data?.exfields?.ghi_so_quy === null
		) {
			const newDetails = data?.details?.map((item) => ({
				tk_co: "131",
				ten_tk_co: "Phải thu khách hàng",
				ten_kh: data.ten_kh,
				ma_kh: data.ma_kh,
				tien_nt: item.tien_hang_nt - (item.tien_ck_nt || 0),
				dien_giai: item.dien_giai,
			}));

			const newDetailsHd1 = data?.details?.map((item) => ({
				tk_dt: data.tk_dt,
				ten_tk_dt: "Doanh thu bán hàng hóa",
				gia_ban_nt: item.gia_ban_nt,
				tien_nt: item.tien_hang_nt,
				ma_dvt: "Bộ",
				dien_giai: item.ten_vt,
				sl_xuat: item.sl_xuat,
				ty_le_ck: item?.ty_le_ck,
				tien_ck_nt: item?.tien_ck_nt,
			}));

			if (
				data?.ten_pt_thanh_toan === "Tiền mặt" ||
				data?.ten_pt_thanh_toan === "Thẻ" ||
				data?.ten_pt_thanh_toan === "Chuyển khoản"
			) {
				await Promise.all([
					handleCreate({
						api: "pt1",
						data: {
							details: newDetails,
							trang_thai: "5",
							ngay_ct: new Date(),
							tk_no: "1111",
							ten_tk_no: "Tiền Việt Nam",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							ten_dvcs: data?.ten_dvcs,
							ma_dvcs: data?.ma_dvcs,
							dvcs_data: data?.ten_dvcs,
							exfields: {
								ten_trang_thai_moi: "Ghi sổ quỹ",
								ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
								pt_thanh_toan: data?.pt_thanh_toan,
							},
						},
						messageApi,
						setLoading,
					}),
					handleUpdate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}

			if (data?.ten_pt_thanh_toan === "Công nợ") {
				await Promise.all([
					handleCreate({
						api: "hd1",
						data: {
							details: newDetailsHd1,
							ngay_ct: data?.date_updated,
							tk_no: "131",
							ten_tk_no: "Phải thu khách hàng",
							ten_kh: data.ten_kh,
							ma_kh: data.ma_kh,
							dvcs_data: dataDefault?.dvcs?.ten_dvcs,
							ma_dvcs: dataDefault?.dvcs?._id,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							ma_nt: data?.ma_nt || "VND",
							ty_gia: data?.ty_gia || 1,
						},
						messageApi,
						setLoading,
					}),
					handleUpdate({
						api: "so1",
						data: {
							...data,
							exfields: {
								...data?.exfields,
								ghi_so_quy: 1,
							},
						},
						messageApi,
						setLoading,
					}),
				]);
			}
		} else {
			await handleUpdate({
				api: "so1",
				data,
				messageApi,
				setLoading,
			});
		}
		await handleGetDataTable({});
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "so1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({});
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		const dataPTTT = await handleGetDataAndRows({
			api: "ptthanhtoan",
			q: {
				status: true,
				stt: 0,
			},
			setLoading,
		});
		const dataDVCS = await handleGetDataAndRows({
			api: "dvcs",
			q: {
				status: true,
				ten_dvcs: "Công ty",
			},
			setLoading,
		});
		if (dataPTTT) {
			setDataDefault({
				...dataDefault,
				ptthanhtoan: { ...dataPTTT.data[0] },
				dvcs: { ...dataDVCS.data[0] },
			});
		}
	};

	useEffect(() => {
		if (dataModal?.ma_kh && dataModal?.ma_kh !== "") {
			handleGetDataDefault();
			handleGetDataTable({});
		}
	}, [dataModal?.ma_kh]);

	return (
		<div className="relative">
			<TableManagerCus
				id="hd1-customer"
				data={dataTable}
				columns={columns}
				loading={loading}
				scroll={{ x: 1200, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setIsOpenForm("edit");
					},
				})}
			/>

			<div className="absolute bottom-3">
				<div className="flex justify-start gap-2">
					<Button
						onClick={() => {
							setIsOpenForm("create");
						}}
						className="btn_error !text-[#F92759]"
					>
						<BsPersonPlus />
						Thêm
					</Button>{" "}
					<Button
						onClick={() => {
							handleGetDataTable({});
						}}
						className="btn_ternary !border-none"
					>
						<TfiReload />
					</Button>
				</div>
			</div>
			<ModalBasic
				zIndex={1035}
				id={"hd1-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ngay_hoa_don: data.ngay_hoa_don || new Date(),
							trang_thai_hd:
								data?.trang_thai_hd || "Chuyển sổ sách",
							ghi_so_quy: data?.ghi_so_quy || null,
							pt_thanh_toan: data?.pt_thanh_toan,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							tk_dt: data?.tk_dt,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ma_lich_hen: data?.ma_lich_hen,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							da_thanh_toan: data?.da_thanh_toan,
							t_tien_ck_hd: data?.t_tien_ck_hd,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
						},
						ma_kho: "KCTY",
						ten_kho: "Kho công ty",
						ma_dvcs: dataDefault?.dvcs?._id,
						tk_cn_thanhtoan: dataDefault?.ptthanhtoan?.tk_cn,
						trang_thai: 8,
					});
					setIsOpenForm(false);
				}}
				data={{
					ma_lich_hen: randomChars(4),
					trang_thai_hd: "Chuyển sổ sách",
					ptthanhtoan_data: dataDefault?.ptthanhtoan?.ten,
					ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
					pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
					tk_dt: dataDefault?.ptthanhtoan?.tk_dt,
					customer_kh: dataModal?.ma_kh,
					ten_kh: dataModal?.ten_kh,
					ma_kh: dataModal?.ma_kh,
					dien_thoai: dataModal?.dien_thoai,
					ho_ten_dem: dataModal?.ho_ten_dem,
					ngay_hoa_don: new Date(),
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>
								{
									JSON.parse(
										localStorage.getItem("current_user")
									)?.name
								}
							</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(new Date()).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormHd1>
					<ItemDetail />
				</FormHd1>
			</ModalBasic>
			<ModalBasic
				zIndex={1035}
				id={"hd1-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ngay_hoa_don: data.ngay_hoa_don,
							trang_thai_hd: data?.trang_thai_hd,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: data?.nguon_tao || "Lập phiếu tay",
							ghi_so_quy: data?.ghi_so_quy || null,
							ho_ten_dem: data?.ho_ten_dem,
							ma_lich_hen: data?.ma_lich_hen,
							da_thanh_toan: data?.da_thanh_toan,
							t_tien_ck_hd: data?.t_tien_ck_hd,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							// ten_dvcs: data?.ten_dvcs,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					customer_kh: dataDetail?.ma_kh,
					dmkho_data: dataDetail?.ten_kho,
					dvcs_data: dataDetail?.exfields?.ten_dvcs,
					trang_thai_hd:
						dataDetail?.exfields?.trang_thai_hd || "Chuyển sổ sách",
					ptthanhtoan_data: dataDetail?.exfields?.ten_pt_thanh_toan,
					loai_lich_hen: dataDetail?.exfields?.loai_lich_hen,
					nguoi_dat: dataDetail?.exfields?.nguoi_dat,
					nguon_tao: dataDetail?.exfields?.nguon_tao,
					ho_ten_dem: dataDetail?.exfields?.ho_ten_dem,
					ma_lich_hen: dataDetail?.exfields?.ma_lich_hen,
					ten_dvcs: dataDetail?.exfields?.ten_dvcs,
					ngay_hoa_don: dataDetail?.exfields?.ngay_hoa_don,
					da_thanh_toan: dataDetail?.exfields?.da_thanh_toan,
					t_tien_ck_hd: dataDetail?.exfields?.t_tien_ck_hd,
					ghi_so_quy: dataDetail?.exfields?.ghi_so_quy || null,
					dmnv_data:
						(dataDetail?.ten_nv || "") +
						" - " +
						(dataDetail?.ma_nv || ""),
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 max-md:ml-0 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>{dataDetail?.name_user_created}</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(
									dataDetail?.date_created,
									"YYYY-MM-DD"
								).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormHd1>
					<ItemDetail />
				</FormHd1>
			</ModalBasic>
		</div>
	);
}
