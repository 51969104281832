import React from "react";
import { Col, Form, Row, InputNumber } from "antd";
import { useMediaQuery } from "react-responsive";

export default function FormThanhToan({ getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			<Col span={24}>
				<Form.Item
					label="Tổng tiền hoá đơn"
					name="t_tt_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						disabled
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Thanh toán"
					name="da_thanh_toan"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_con_lai",
								(getFieldValue("t_tt_nt") || 0) - value
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Tổng tiền còn nợ"
					name="tien_con_lai"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						disabled
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
