import { Button, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
	handleExportFile,
	handleGetData,
	handleGetDataById,
} from "../../../utils/utilsApi";
import FilterReport from "../../components/filter/FilterReport";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import "../managerReports.scss";

export default function DoanhSoReport() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: "Sản phẩm",
			dataIndex: "ten_vt",
			width: 150,
			render: (ten_vt, record) => {
				if (record.bold) {
					return <span className="font-bold">{ten_vt}</span>;
				} else {
					return ten_vt + " - " + record.ma_vt;
				}
			},
		},
		{
			title: "SL bán",
			dataIndex: "sl_xuat",
			width: 100,
			render: (sl_xuat, record) => {
				if (record.bold) {
					return (
						<span className="font-bold">
							{sl_xuat?.toLocaleString()}
						</span>
					);
				} else {
					return sl_xuat?.toLocaleString();
				}
			},
		},
		{
			title: "Tiền hàng",
			dataIndex: "tien",
			width: 100,
			render: (tien, record) => {
				if (record.bold) {
					// if (!tien) return null;
					return (
						<span className="font-bold">
							{tien?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					// if (!tien) return null;
					return tien?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Chiết khấu",
			dataIndex: "tien_ck",
			width: 100,
			render: (tien_ck, record) => {
				if (record.bold) {
					// if (!tien_ck) return null;
					return (
						<span className="font-bold">
							{tien_ck?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					// if (!tien_ck) return null;
					return tien_ck?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Doanh thu",
			dataIndex: "doanh_thu",
			width: 100,
			render: (doanh_thu, record) => {
				if (record.bold) {
					// if (!doanh_thu) return null;
					return (
						<span className="font-bold">
							{doanh_thu?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					// if (!doanh_thu) return null;
					return doanh_thu?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Tiền lãi",
			dataIndex: "lai",
			width: 100,
			render: (lai, record) => {
				if (record.bold) {
					// if (!lai) return null;
					return (
						<span className="font-bold">
							{lai?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					// if (!lai) return null;
					return lai?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const [dataFilter, setDataFilter] = useState({});
	const [ngaythieplap, setNgaythieplap] = useState({
		tu_ngay: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		den_ngay: new Date(),
	});
	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataTable = async (newParams) => {
		const data = await handleGetData({
			api: "tonghopbanhang",
			params: {
				...newParams,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	useEffect(() => {
		handleGetDataTable({
			ten_kho: dataFilter?.ten_kho,
			ma_kho: dataFilter?.ma_kho,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
	}, [params.page, params.limit]);

	const handleManagerFilter = (value) => {
		setParams({
			...params,
			page: 1,
		});
		setDataFilter({
			ten_kho: value?.ten_kho,
			ma_kho: value?.ma_kho,
		});
		handleGetDataTable({
			ten_kho: value?.ten_kho,
			ma_kho: value?.ma_kho,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
	};
	const handleGetDataDefault = async () => {
		const dataDmkho = await handleGetData({
			api: "dmkho",
			q: {
				ma_kho: "KCTY",
			},
			setLoading,
		});
		const dataRpt = await handleGetData({
			api: "rpt",
			q: {
				ma_cn: "TONGHOPBANHANG",
			},
			setLoading,
		});
		if (dataDmkho) {
			await handleGetDataTable({
				ten_kho: dataDmkho?.data[0]?.ten_kho,
				ma_kho: dataDmkho?.data[0]?.ma_kho,
				tu_ngay: ngaythieplap.tu_ngay,
				den_ngay: ngaythieplap.den_ngay,
			});
			setDataDefault({
				...dataDefault,
				dmkho: { ...dataDmkho.data[0] },
				dataRpt: { ...dataRpt.data[0] },
			});
			setDataFilter({
				...dataFilter,
				ten_kho: dataDmkho?.data[0]?.ten_kho,
				ma_kho: dataDmkho?.data[0]?.ma_kho,
			});
		}
	};

	useEffect(() => {
		handleGetDataDefault();
	}, []);

	const [isOpenFormItemVoucher, setIsOpenFormItemVoucher] = useState(false);
	const [dataItemVoucher, setDataItemVoucher] = useState({});
	const [loadingItem, setLoadingItem] = useState(false);
	const handleOnRowClick = async (record) => {
		const dataVoucher = await handleGetDataById({
			api: record.ma_ct.toLowerCase(),
			id: record.id_ct,
			setLoading: setLoadingItem,
		});
		setDataItemVoucher(dataVoucher);
		setIsOpenFormItemVoucher(record.ma_ct.toLowerCase());
	};

	const handleExportExcel = async () => {
		try {
			const response = await handleExportFile({
				api: "tonghopbanhang",
				params: {
					id_rpt: dataDefault?.dataRpt?._id,
					ten_kho: dataFilter?.ten_kho,
					ma_kho: dataFilter?.ma_kho,
					tu_ngay: ngaythieplap.tu_ngay,
					den_ngay: ngaythieplap.den_ngay,
				},
				messageApi,
			});

			const fileType =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";

			if (response) {
				const blob = new Blob([response], { type: fileType });
				const url = window.URL.createObjectURL(blob);

				// Create a hidden <a> element
				const a = document.createElement("a");
				a.href = url;
				a.download =
					`DoanhSoSanPhamDichVu-${dayjs(new Date()).format(
						"DD-MM-YYYY"
					)}` + fileExtension;

				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);

				window.URL.revokeObjectURL(url);
			} else {
				console.error("No data found in the response");
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
										shape="circle"
										size="small"
										className="btn_ternary !border-none"
									>
										<TfiReload />
									</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[250px]">
									<FilterReport
										data={{
											dmkho_data:
												(dataFilter?.ten_kho ||
													dataDefault?.dmkho
														?.ten_kho) +
												" - " +
												(dataFilter?.ma_kho ||
													dataDefault?.dmkho?.ma_kho),
											ten_kho:
												dataFilter?.ten_kho ||
												dataDefault?.dmkho?.ten_kho,
											ma_kho:
												dataFilter?.ma_kho ||
												dataDefault?.dmkho?.ma_kho,
										}}
										ngaythieplap={ngaythieplap}
										setNgaythieplap={setNgaythieplap}
										handleManager={handleManagerFilter}
										dmkho={true}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<Button onClick={handleExportExcel} className="btn_ternary">
						<CiExport />
						<span className="max-lg:!hidden">Export</span>
					</Button>
					<span>
						Kỳ đang xem:{" "}
						{dayjs(ngaythieplap.tu_ngay).format("DD/MM/YYYY")} -{" "}
						{dayjs(ngaythieplap.den_ngay).format("DD/MM/YYYY")}
					</span>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Doanh số theo sản phẩm/dịch vụ(VND)
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 750,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
					rowSelection: null,
				}}
				onRow={(record) => ({
					onClick: () => {
						handleOnRowClick(record);
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>
		</div>
	);
}
