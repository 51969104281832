import { Button } from "antd";
import React from "react";
import ModalBasic from "../../../../components/modal/ModalBasic";
import FormMedicine from "../../form/Item/FormMedicine";

export default function ModalMedicine({
	isOpenForm,
	setIsOpenForm,
	handleManager,
	data,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1037}
				id={"medicine-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Đơn thuốc</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={handleManager}
				data={{
					cach_dung: "Uống",
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormMedicine />
			</ModalBasic>
			<ModalBasic
				zIndex={1037}
				id={"medicine-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Đơn thuốc</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={handleManager}
				data={{
					...data,
					dmvt_medicine: data?.ten_vt,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormMedicine />
			</ModalBasic>
		</div>
	);
}
