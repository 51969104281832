import React from "react";
import { Col, Form, Row, InputNumber, Select, Input } from "antd";
import { Option } from "antd/es/mentions";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../../components/inputAnt/selectAdd";

export default function FormMedicine({ getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 18 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			<Form.Item name="ma_dvt"></Form.Item>
			<Form.Item name="ten_vt"></Form.Item>
			<Form.Item name="ma_vt"></Form.Item>
			<Col span={24}>
				<SelectAdd
					api="dmvt"
					q={{
						status: true,
						"exfields.loai_vat_tu": "Medicines",
					}}
					label="Tên thuốc"
					name="dmvt_medicine"
					valueOption={["ma_dvt", "ten_vt", "ma_vt"]}
					lableOption={["ten_vt"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn",
						},
					]}
					placeholder="nhập tên thuốc"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvt", parsedValue?.ma_dvt);
							setFieldsValue("ten_vt", parsedValue?.ten_vt);
							setFieldsValue("ma_vt", parsedValue?.ma_vt);
						}
					}}
				/>
			</Col>
			<Col span={24}>
				<SelectAdd
					api="dmdvt"
					q={{
						status: true,
					}}
					label="Đvt"
					name="ma_dvt"
					valueOption={["ma_dvt"]}
					lableOption={["ma_dvt"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn",
						},
					]}
					placeholder="nhập đvt"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvt", parsedValue?.ma_dvt);
						}
					}}
				/>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Số lượng"
					name="t_sl"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Cách dùng"
					name="cach_dung"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Select
						defaultValue={"Uống"}
						allowClear
						placeholder="Chọn cách dùng"
					>
						{[
							{
								key: "Uống",
								value: "Uống",
							},
							{
								key: "Ngậm dưới lưỡi",
								value: "Ngậm dưới lưỡi",
							},
							{
								key: "Súc miệng",
								value: "Súc miệng",
							},
						].map((item) => (
							<Option key={item.key} value={item.value}>
								{item.value}
							</Option>
						))}
					</Select>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Số viên/ lần"
					name="sl_lan"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Số lần/ ngày"
					name="sl_ngay"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Ghi chú"
					name="dien_giai"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={1} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
