import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { handleGetData } from "../../../utils/utilsApi";
import TableManager from "../../components/table/tableManager";
import "./managerRole.scss";

export default function ManagerRole() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const columns = [
		{
			title: "Chọn",
			dataIndex: "",
			width: 50,
			render: (record, index) => {
				return (
					<div className="w-[50px]">
						<Checkbox
							checked={
								record?.add &&
								record?.delete &&
								record?.update &&
								record?.view &&
								record?.viewOfOther
							}
							onClick={() => {
								console.log(index);
							}}
						/>
					</div>
				);
			},
		},
		{
			title: "Chức năng",
			width: 100,
			dataIndex: "header",
		},
		{
			title: "Xem",
			width: 50,
			dataIndex: "",
			render: (record) => {
				return (
					<div className="w-[50px]">
						<Checkbox checked={record?.view} />
					</div>
				);
			},
		},
		{
			title: "Thêm mới",
			width: 100,
			dataIndex: "",
			render: (record) => {
				return (
					<div className="w-[50px]">
						<Checkbox checked={record?.add} />
					</div>
				);
			},
		},
		{
			title: "Cập nhật",
			width: 100,
			dataIndex: "",
			render: (record) => {
				return (
					<div className="w-[50px]">
						<Checkbox checked={record?.update} />
					</div>
				);
			},
		},
		{
			title: "Xoá",
			width: 50,
			dataIndex: "",
			render: (record) => {
				return (
					<div className="w-[50px]">
						<Checkbox checked={record?.delete} />
					</div>
				);
			},
		},
		{
			title: "Xem dữ liệu của người khác",
			width: 150,
			dataIndex: "",
			render: (record) => {
				return (
					<div className="w-[50px]">
						<Checkbox checked={record?.viewOfOther} />
					</div>
				);
			},
		},
	];

	const [dataRole, setDataRole] = useState([]);
	const [dataMenu, setDataMenu] = useState([]);
	const [dataTable, setDataTable] = useState([]);

	const handleGetMenuXRight = async () => {
		setDataMenu([]);
		const right = window.location.href
			.split("manager-role/")[1]
			.split("?")[0];
		console.log(right);
		try {
			const [responseMenu, responseRight] = await Promise.all([
				handleGetData({
					api: "menu",
				}),
				handleGetData({
					api: "right",
					q: { email: right },
				}),
			]);
			setDataRole(responseRight.data);
			setDataMenu(responseMenu.data.menu);
			console.log(1);
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleGetDataTable = (data) => {
		const newData = data.input
			.filter((item) => !Array.isArray(item.items))
			.map((item) => {
				const filteredArr = dataRole.filter(
					(items) => item?.path === items?.module
				);
				return { ...item, ...filteredArr[0] };
			});
		console.log(newData);
		setDataTable(newData);
	};
	const handleGetDataTable2 = (data) => {
		const newData = data?.items.map((item) => {
			const filteredArr = dataRole.filter(
				(items) => item?.path === items?.module
			);
			return { ...item, ...filteredArr[0] };
		});
		console.log(newData);
		setDataTable(data?.items);
	};

	useEffect(() => {
		handleGetMenuXRight();
	}, []);

	return (
		<div className="">
			<div className="h-12 flex justify-between items-center my-2">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Phân Quyền{" "}
					{`${decodeURIComponent(
						window.location.href.split("=")[1]
					)}`}
				</div>
			</div>
			<div className="flex h-[calc(100vh-148px)]">
				<div className="left_menu_role w-1/5 h-full overflow-auto border-r-2">
					{dataMenu.map((item, index) => {
						return (
							<div key={index}>
								<span
									className="cursor-pointer hover:text-[#09395e] hover:font-medium"
									onClick={() => handleGetDataTable(item)}
								>
									{item?.title}
								</span>
								<div className="pl-4">
									{item?.input?.map((item2, index2) => {
										if (item2?.items?.length > 0) {
											return (
												<div key={index2}>
													<span
														className="text-sm cursor-pointer hover:text-[#09395e] hover:font-medium"
														onClick={() =>
															handleGetDataTable2(
																item2
															)
														}
													>
														{item2?.header}
													</span>
												</div>
											);
										}
									})}
								</div>
							</div>
						);
					})}
				</div>
				<div className="w-4/5">
					<TableManager
						data={dataTable}
						columns={columns}
						rowKey={"_id"}
						scroll={{
							x: 1050,
							y: isTabletOrMobile
								? "calc(100vh - 248px)"
								: "calc(100vh - 220px)",
						}}
						tableProps={{
							size: "middle",
							rowSelection: null,
						}}
					/>
				</div>
			</div>
		</div>
	);
}
