import React from "react";

export default function DefaultPage() {
	return (
		<div className="w-full h-[calc(100vh-300px)] flex justify-center items-center">
			<h1 className="text-[#326b97] text-3xl text-center">
				Chức năng này đang được nâng cấp <br />&<br /> Sử dụng trong
				phiên bản tiếp theo
			</h1>
		</div>
	);
}
