import { Col, Form, Row, InputNumber, Input } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import SelectAddDmvt from "../../../../components/inputAnt/selectAddDmvt";

export default function FormDetail({ getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 22 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* Sản phẩm - Đơn vị tính */}
			{/* field hidden */}
			<Form.Item name="ma_dvt"></Form.Item>
			<Form.Item name="ten_vt"></Form.Item>
			<Form.Item name="ma_vt"></Form.Item>
			<Col span={24}>
				<SelectAddDmvt
					api="dmvt"
					q={{ status: true }}
					label="Dịch vụ/sản phẩm"
					name="dmvt_vt"
					valueOption={["ten_vt", "ma_vt", "ma_dvt", "gia_ban_le"]}
					lableOption={["ten_vt", "ma_vt"]}
					searchOption={["ten_vt", "ma_vt"]}
					dataAdd={{
						ma_dvt: "Bộ",
					}}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="nhập tên..."
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvt", parsedValue?.ma_dvt);
							setFieldsValue("ten_vt", parsedValue?.ten_vt);
							setFieldsValue("ma_vt", parsedValue?.ma_vt);
							setFieldsValue(
								"gia_ban_nt",
								parsedValue?.gia_ban_le || 0
							);
							setFieldsValue(
								"tien_hang_nt",
								(parsedValue?.gia_ban_le || 0) *
									(getFieldValue("sl_xuat") || 0)
							);
							setFieldsValue(
								"t_tt_nt",
								(parsedValue?.gia_ban_le || 0) *
									(getFieldValue("sl_xuat") || 0) -
									((parsedValue?.gia_ban_le || 0) *
										(getFieldValue("sl_xuat") || 0) *
										(getFieldValue("ty_le_ck") || 0)) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								((parsedValue?.gia_ban_le || 0) *
									(getFieldValue("sl_xuat") || 0) *
									(getFieldValue("ty_le_ck") || 0)) /
									100
							);
						}
					}}
				/>
			</Col>

			{/* Số lượng - giá bán */}
			<Col span={24}>
				<Form.Item
					label="Số lượng"
					name="sl_xuat"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								value * (getFieldValue("gia_ban_nt") || 0)
							);
							setFieldsValue(
								"t_tt_nt",
								value * (getFieldValue("gia_ban_nt") || 0) -
									((getFieldValue("gia_ban_nt") || 0) *
										value *
										(getFieldValue("ty_le_ck") || 0)) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								((getFieldValue("gia_ban_nt") || 0) *
									value *
									(getFieldValue("ty_le_ck") || 0)) /
									100
							);
						}}
						defaultValue={0}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Giá bán VNĐ"
					name="gia_ban_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								(getFieldValue("sl_xuat") || 0) * value
							);
							setFieldsValue(
								"t_tt_nt",
								(getFieldValue("sl_xuat") || 0) * value -
									(value *
										getFieldValue("sl_xuat") *
										(getFieldValue("ty_le_ck") || 0)) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								(value *
									getFieldValue("sl_xuat") *
									(getFieldValue("ty_le_ck") || 0)) /
									100
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			{/* Tổng tiền hàng */}
			<Col span={24}>
				<Form.Item
					label="Tiền hàng VNĐ"
					name="tien_hang_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						disabled
					/>
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item
					label="Chiết khấu (%)"
					name="ty_le_ck"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"t_tt_nt",
								(getFieldValue("sl_xuat") || 0) *
									(getFieldValue("gia_ban_nt") || 0) -
									((getFieldValue("gia_ban_nt") || 0) *
										(getFieldValue("sl_xuat") || 0) *
										value) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								((getFieldValue("gia_ban_nt") || 0) *
									(getFieldValue("sl_xuat") || 0) *
									value) /
									100
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">%</div>
						}
						min={0}
						max={100}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Chiết khấu (tiền)"
					name="tien_ck_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"t_tt_nt",
								(getFieldValue("sl_xuat") || 0) *
									(getFieldValue("gia_ban_nt") || 0) -
									value
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>

			{/* Doanh thu */}
			<Col span={24}>
				<Form.Item
					label="Doanh thu VNĐ"
					name="t_tt_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						disabled
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
