import { Button, Popconfirm } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { request } from "../../../../utils/request";
import TableManagerCus from "../../../components/table/tableManagerCus";
import ModalTdttnosPt from "../Modal/Item/ModalTdttnosPt";

export default function ItemTdttnosPt({
	setFieldsValue,
	getFieldValue,
	messageApi,
}) {
	const [dataDetailItem, setDataDetailItem] = useState({});
	const [isOpenFormItem, setIsOpenFormItem] = useState(false);

	const [dataModalTdttnos, setDataModalTdttnos] = useState([]);
	useEffect(() => {
		setDataModalTdttnos(getFieldValue("tdttnos") || []);
	}, [getFieldValue("tdttnos")]);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenFormItem("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenFormItem("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (data) => {
				handleDeleteData(data);
			},
			label: "Delete",
		},
	];
	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Ngày hoá đơn",
			dataIndex: "ngay_hd",
			width: 150,
			render: (ngay_hd) => {
				if (!ngay_hd) return null;
				else return dayjs(ngay_hd).format("DD/MM/YYYY");
			},
		},
		{
			title: "Tổng tiền (VND)",
			dataIndex: "tien_hd_nt",
			width: 150,
			render: (tien_hd_nt) => {
				if (!tien_hd_nt) {
					return 0;
				} else
					return tien_hd_nt.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
			},
			align: "right",
		},
		{
			title: "Đã thu (VND)",
			dataIndex: "da_thanh_toan_nt",
			width: 150,
			render: (da_thanh_toan_nt) => {
				if (!da_thanh_toan_nt) {
					return 0;
				} else
					return da_thanh_toan_nt.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
			},
			align: "right",
		},
		{
			title: "Còn lại (VND)",
			dataIndex: "con_lai_nt",
			width: 150,
			render: (con_lai_nt) => {
				if (!con_lai_nt) {
					return 0;
				} else
					return con_lai_nt.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
			},
			align: "right",
		},
		{
			title: "Tiền thu (VND)",
			dataIndex: "thanh_toan_qd",
			width: 150,
			render: (tien_nt) => {
				if (!tien_nt) {
					return 0;
				} else
					return tien_nt.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
			},
			align: "right",
		},
		{
			title: "Diễn giải",
			dataIndex: "dien_giai",
			width: 150,
		},
	];

	const handleSaveData = (dataModal) => {
		setDataModalTdttnos([dataModal,...dataModalTdttnos]);
		setFieldsValue("tdttnos", [dataModal,...dataModalTdttnos]);
	};

	const handleUpdateData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalTdttnos];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalTdttnos(updaData);
				setFieldsValue("tdttnos", updaData);
			}
		} else {
			const updaData = [...dataModalTdttnos];
			const indexToUpdate = updaData.findIndex(
				(item) =>
					JSON.stringify(item) === JSON.stringify(dataDetailItem)
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalTdttnos(updaData);
				setFieldsValue("tdttnos", updaData);
			}
		}
	};

	const handleManagerDataItem = (dataModal) => {
		if (isOpenFormItem === "create") {
			handleSaveData(dataModal);
		} else {
			handleUpdateData(dataModal);
		}
	};

	const handleDeleteData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalTdttnos];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalTdttnos(updaData);
				setFieldsValue("tdttnos", updaData);
			}
		} else {
			const updaData = [...dataModalTdttnos];
			const indexToUpdate = updaData.findIndex(
				(item) => JSON.stringify(item) === JSON.stringify(dataModal)
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalTdttnos(updaData);
				setFieldsValue("tdttnos", updaData);
			}
		}
	};

	const handleGetDataTable = async () => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem(
					"id_app"
				)}/getinvoice2receive?ma_kh=${getFieldValue("ma_kh")}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setDataModalTdttnos(response.data);
			setFieldsValue("tdttnos", response.data);
			messageApi.open({
				type: "success",
				content:
					response.data.length > 0
						? "Lấy hoá đơn thành công"
						: "Không có hoá đơn",
			});
		} catch (error) {
			messageApi.open({
				type: "error",
				content: error.response.data.error || "Thất bại",
			});
			console.log(error);
		}
	};

	return (
		<div className="relative">
			<TableManagerCus
				id="dataItemTdttnosPt-tab"
				data={dataModalTdttnos}
				columns={columns}
				scroll={{ x: 600, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenFormItem("edit");
						setDataDetailItem(record);
					},
				})}
			/>
			<Button
				onClick={() => {
					handleGetDataTable();
				}}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Lấy hoá đơn
			</Button>
			<ModalTdttnosPt
				getFieldValue={getFieldValue}
				isOpenFormItem={isOpenFormItem}
				setIsOpenFormItem={setIsOpenFormItem}
				dataDetailItem={dataDetailItem}
				handleManagerDataItem={handleManagerDataItem}
			/>
		</div>
	);
}
