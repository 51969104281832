import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Option } from "antd/es/mentions";
import SelectAdd from "../../../components/inputAnt/selectAdd";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function Upgrade({ dataAll, data, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [nhacLich, setNhacLich] = useState();

	useEffect(() => {
		if (data?.nhac_lich === null) {
			setNhacLich(null);
		} else {
			setNhacLich(dayjs(data?.nhac_lich).format(dateFormatList[0]));
		}
	}, [data?.nhac_lich]);

	return (
		<Row className="!m-0" gutter={[8, 8]}>
			{/* Độ nóng - Nguồn khách */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Độ nóng"
					name="do_nong"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Select placeholder="nhập nguồn" allowClear>
						<Option value="Rất nóng" key="Rất nóng">
							Rất nóng
						</Option>
						<Option value="Chăm sóc nóng" key="Chăm sóc nóng">
							Chăm sóc nóng
						</Option>
						<Option value="Chăm sóc sau" key="Chăm sóc sau">
							Chăm sóc sau
						</Option>
						<Option value="Không tiềm năng" key="Không tiềm năng">
							Không tiềm năng
						</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Nguồn khách"
					name="nguon"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Select placeholder="nhập nguồn" allowClear>
						<Option value="online" key="online">
							online
						</Option>
						<Option value="offline" key="offline">
							offline
						</Option>
						<Option value="ads" key="ads">
							ads
						</Option>
						<Option value="website" key="website">
							website
						</Option>
					</Select>
				</Form.Item>
			</Col>

			{/* Giao NV chắm sóc - Nhắc lịch */}
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dmnv"
					fieldsAdd={["ma_nv", "ten_nv"]}
					label="Giao NV chăm sóc"
					name="nv_cham_soc"
					labelCol={{
						...layout.labelCol,
						className: "min-w-[120px]",
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn nv chăm sóc"
				/>
			</Col>
			<Form.Item name="nhac_lich"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Nhắc lịch"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<DatePicker
						placeholder="chọn ngày nhắc"
						value={
							nhacLich ? dayjs(nhacLich, dateFormatList[0]) : null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNhacLich(dayjs(date).format(dateFormatList[0]));
							setFieldsValue("nhac_lich", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
			</Col>

			{/* Đánh giá - kết quả */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Đánh giá"
					name="danh_gia"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập đánh giá" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Kết quả"
					name="ket_qua"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập kết quả" />
				</Form.Item>
			</Col>

			{/* Ngày tạo - Người tạo */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Ngày tạo"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<span className="text-[#BBB4B4] text-xs">
						{dayjs(dataAll?.date_created).format(dateFormatList[0])}
					</span>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Người tạo"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<span className="text-[#BBB4B4] text-xs">
						{dataAll?.name_user_created}
					</span>
				</Form.Item>
			</Col>
		</Row>
	);
}
