import { Button, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
	handleExportFile,
	handleGetData,
	handleGetDataById,
	handleUpdate,
} from "../../../utils/utilsApi";
import FilterReport from "../../components/filter/FilterReport";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import "../managerReports.scss";
import ModalPtVoucher from "../../ManagerVouchers/components/Modal/ModalPtVoucher";
import FormInfomation from "../../ManagerVouchers/components/Form/FormInfomation";
import ItemDetailPt from "../../ManagerVouchers/components/Item/ItemDetailPt";
import ItemTdttnosPt from "../../ManagerVouchers/components/Item/ItemTdttnosPt";
import ModalHd1 from "../../ManagerServices/components/modal/ModalHd1";
import { handleTotal, theNextDay } from "../../../utils/utilsFunc";

export default function NhanVienReport() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: "Ngày chứng từ",
			dataIndex: "ngay_ct",
			width: 100,
			render: (ngay_ct) => {
				if (!ngay_ct) return null;
				else return dayjs(ngay_ct).format("DD/MM/YYYY");
			},
		},
		{
			title: "Số chứng từ",
			dataIndex: "so_ct",
			width: 100,
		},
		{
			title: "Mã nhân viên",
			dataIndex: "ma_nv",
			width: 100,
			render: (ma_nv, record) => {
				if (record.bold) {
					return <span className="font-bold">{ma_nv}</span>;
				} else {
					return ma_nv;
				}
			},
		},
		{
			title: "Mã khách hàng",
			dataIndex: "ma_kh_no",
			width: 100,
		},
		{
			title: "Tiền VNĐ",
			dataIndex: "tien",
			width: 100,
			render: (tien, record) => {
				if (record.bold) {
					if (!tien) return null;
					return (
						<span className="font-bold">
							{tien?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					if (!tien) return null;
					return tien?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Tiền",
			dataIndex: "tien_nt",
			width: 100,
			render: (tien_nt, record) => {
				if (record.bold) {
					if (!tien_nt) return null;
					return (
						<span className="font-bold">
							{tien_nt?.toLocaleString()}
						</span>
					);
				} else {
					if (!tien_nt) return null;
					return tien_nt?.toLocaleString();
				}
			},
			align: "right",
		},
		{
			title: "Ngoại tệ",
			dataIndex: "ma_nt",
			width: 100,
		},
	];

	const [dataFilter, setDataFilter] = useState({});
	const [ngaythieplap, setNgaythieplap] = useState({
		tu_ngay: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		den_ngay: new Date(),
	});
	const handleGetDataTable = async (newParams) => {
		const data = await handleGetData({
			api: "query/socai/find",
			q: {
				ma_nv: newParams?.ma_nv,
				ma_ct: "PT1",
				ngay_ct: {
					$gte: dayjs(ngaythieplap.tu_ngay),
					$lte: dayjs(theNextDay(ngaythieplap.den_ngay)),
				},
			},
			setLoading,
		});
		if (data.data.length > 0) {
			setDataTable([
				...data.data,
				{
					ma_nv: "Tổng cộng",
					tien: handleTotal({
						arr: data.data,
						name: "tien",
					}),
					bold: true,
				},
			]);
		} else {
			setDataTable([]);
		}
	};

	const handleManagerFilter = (value) => {
		setDataFilter({
			ma_nv: value?.ma_nv,
			ten_nv: value?.ten_nv,
		});
		handleGetDataTable({
			ma_nv: value?.ma_nv,
			ten_nv: value?.ten_nv,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
	};

	const handleExportExcel = async () => {
		alert("Chức năng đang phát triển");
		// try {
		// 	const response = await handleExportFile({
		// 		api: "sctcnkh",
		// 		params: {
		// 			id_rpt: dataDefault?.dataRpt?._id,
		// 			...dataFilter,
		// 			tu_ngay: ngaythieplap.tu_ngay,
		// 			den_ngay: ngaythieplap.den_ngay,
		// 		},
		// 		messageApi,
		// 	});

		// 	const fileType =
		// 		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		// 	const fileExtension = ".xlsx";

		// 	if (response) {
		// 		const blob = new Blob([response], { type: fileType });
		// 		const url = window.URL.createObjectURL(blob);

		// 		// Create a hidden <a> element
		// 		const a = document.createElement("a");
		// 		a.href = url;
		// 		a.download =
		// 			`DoanhThuKhachHang-${dayjs(new Date()).format(
		// 				"DD-MM-YYYY"
		// 			)}` + fileExtension;

		// 		document.body.appendChild(a);
		// 		a.click();
		// 		document.body.removeChild(a);

		// 		window.URL.revokeObjectURL(url);
		// 	} else {
		// 		console.error("No data found in the response");
		// 	}
		// } catch (error) {
		// 	console.error(error);
		// }
	};

	const [isOpenFormItemVoucher, setIsOpenFormItemVoucher] = useState(false);
	const [dataItemVoucher, setDataItemVoucher] = useState({});
	const [loadingItem, setLoadingItem] = useState(false);
	const handleOnRowClick = async (record) => {
		const dataVoucher = await handleGetDataById({
			api: record.ma_ct.toLowerCase(),
			id: record.id_ct,
			setLoading: setLoadingItem,
		});
		setDataItemVoucher(dataVoucher);
		setIsOpenFormItemVoucher(record.ma_ct.toLowerCase());
	};

	const handleManagerData = async (data) => {
		await handleUpdate({
			api: data?.collection_name,
			data,
			messageApi,
			setLoading: setLoadingItem,
		});
		await handleGetDataTable({
			...dataFilter,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
		setIsOpenFormItemVoucher(false);
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
										shape="circle"
										size="small"
										className="btn_ternary !border-none"
									>
										<TfiReload />
									</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[250px]">
									<FilterReport
										ngaythieplap={ngaythieplap}
										setNgaythieplap={setNgaythieplap}
										handleManager={handleManagerFilter}
										dmnv={true}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<Button onClick={handleExportExcel} className="btn_ternary">
						<CiExport />
						<span className="max-lg:!hidden">Export</span>
					</Button>
					<span>
						Kỳ đang xem:{" "}
						{dayjs(ngaythieplap.tu_ngay).format("DD/MM/YYYY")} -{" "}
						{dayjs(ngaythieplap.den_ngay).format("DD/MM/YYYY")}
					</span>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Doanh thu{" "}
						{dataFilter?.ten_nv ? (
							<span className="text-[#186b9e] underline">
								{dataFilter?.ten_nv}
							</span>
						) : (
							"Nhân viên"
						)}{" "}
						(VND)
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 850,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
					rowSelection: null,
				}}
				onRow={(record) => ({
					onClick: () => {
						handleOnRowClick(record);
					},
				})}
			/>
			<ModalPtVoucher
				SoQuy={true}
				dataDetail={dataItemVoucher}
				handleManagerData={handleManagerData}
				isOpenForm={isOpenFormItemVoucher === "pt1" ? "edit" : false}
				setIsOpenForm={setIsOpenFormItemVoucher}
				formSections={[
					{
						id: "thongtin1",
						title: "Thông tin",
						content: <FormInfomation pt1={true} />,
					},
					{
						id: "thutheokhachhang",
						title: "Thu theo khách hàng",
						content: <ItemDetailPt />,
					},
					{
						id: "thutheohoadon",
						title: "Thu theo hoá đơn",
						content: <ItemTdttnosPt messageApi={messageApi} />,
					},
				]}
			/>
		</div>
	);
}
