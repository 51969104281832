import DefaultPage from "../DefaultPage/DefaultPage";
import CalendarBooking from "../ManagerActions/CalendarBooking/CalendarBooking";
import DMProducts from "../ManagerCategorys/DMProducts/DMProducts";
import DmLab from "../ManagerCategorys/DmLab/DmLab";
import DmMedicine from "../ManagerCategorys/DmMedicine/DmMedicine";
import ManagerServices from "../ManagerCategorys/services/manager/ManagerServices";
import Services from "../ManagerCategorys/services/services";
import DMImplant from "../ManagerImplant/DMImplant/DMImplant";
import PnImplants from "../ManagerImplant/PnImplant/PnImplants";
import PxkImplants from "../ManagerImplant/PxkImplant/PxkImplants";
import CustomerReport from "../ManagerReports/Customer/CustomerReport";
import DoanhSoReport from "../ManagerReports/DoanhSo/DoanhSoReport";
import HoaDonReport from "../ManagerReports/HoaDon/HoaDonReport";
import HoaDonReportChi from "../ManagerReports/HoaDon/HoaDonReportChi";
import NhanVienReport from "../ManagerReports/NhanVien/NhanVienReport";
import Booking from "../ManagerServices/Booking/Booking";
import Consultants from "../ManagerServices/Consultants/Consultants";
import Hd1 from "../ManagerServices/Hd1/Hd1";
import HoaDonChi from "../ManagerServices/HoaDonChi/HoaDonChi";
import ScreenQuayLeTan from "../ManagerServices/ScreenQuayLeTan/ScreenQuayLeTan";
import ScreenServices from "../ManagerServices/ScreenServices/ScreenServices";
import PcVoucher from "../ManagerVouchers/PcVoucher/PcVoucher";
import PtVoucher from "../ManagerVouchers/PtVoucher/PtVoucher";
import SoQuy from "../ManagerVouchers/SoQuy/SoQuy";
import CompanyApp from "../companyApp/CompanyApp";
import ThongKeCustomer from "../customer/ThongKeCustomer/ThongKeCustomer";
import Contact from "../customer/contact/contact";
import CustomersStopUsing from "../customer/customersStopUsing/customersStopUsing";
import ExistingGuests from "../customer/existingGuests/existingGuests";
import PotentialGuests from "../customer/potentialGuests/potentialGuests";
import HomeCMS from "../home/HomeCMS";
import SignIn from "../signIn/SignIn";
import ManagerRole from "../user/ManagerRole/ManagerRole";
import Participant from "../user/Participant";
import User from "../user/User";
import UserGroup from "../user/UserGroup";

const publicRouter = [{ path: "/sign-in", component: SignIn, layout: null }];
const privateRouter = [
	{ name: "Dashboard", path: "/", component: HomeCMS, layout: "" },
	{
		name: "Thông tin đơn vị, doanh nghiệp",
		path: "/app",
		component: CompanyApp,
		layout: "",
	},
	{
		name: "Lịch xem đặt lịch hẹn",
		path: "/calendar-booking",
		component: CalendarBooking,
		layout: "",
	},
	{
		name: "Potential",
		path: "/leads",
		component: PotentialGuests,
		layout: "",
	},
	{
		name: "Exist",
		path: "/customers",
		component: ExistingGuests,
		layout: "",
	},
	{
		name: "Stop",
		path: "/customers-stop",
		component: CustomersStopUsing,
		layout: "",
	},
	{
		name: "Contact",
		path: "/contacts",
		component: Contact,
		layout: "",
	},
	{
		name: "ThongKeCustomer",
		path: "/thong-ke-customer",
		component: ThongKeCustomer,
		layout: "",
	},
	{
		name: "Booking",
		path: "/booking",
		component: Booking,
		layout: "",
	},
	{
		name: "Consultants",
		path: "/consultants",
		component: Consultants,
		layout: "",
	},
	{
		name: "Hd1",
		path: "/hd1",
		component: Hd1,
		layout: "",
	},
	{
		name: "Pn2",
		path: "/pn2",
		component: HoaDonChi,
		layout: "",
	},
	{
		name: "Quầy Lễ Tân",
		path: "/screen-quayletan",
		component: ScreenQuayLeTan,
		layout: "",
	},
	{
		name: "Màn hình dịch vụ",
		path: "/screen-services",
		component: ScreenServices,
		layout: "",
	},
	{
		name: "PT1",
		path: "/pt1",
		component: PtVoucher,
		layout: "",
	},
	{
		name: "PC1",
		path: "/pc1",
		component: PcVoucher,
		layout: "",
	},
	{
		name: "SoQuy",
		path: "/soquy",
		component: SoQuy,
		layout: "",
	},
	{
		name: "DMImplant",
		path: "/dmvt-implant",
		component: DMImplant,
		layout: "",
	},
	{
		name: "PnImplant",
		path: "/pn1-implant",
		component: PnImplants,
		layout: "",
	},
	{
		name: "PxkImplant",
		path: "/pxk-implant",
		component: PxkImplants,
		layout: "",
	},
	{
		name: "Services",
		path: "/services",
		component: Services,
		layout: "",
	},
	{
		name: "Services Manager",
		path: "/services/managerServices/:id",
		component: ManagerServices,
		layout: "",
	},
	{
		name: "Products",
		path: "/products",
		component: DMProducts,
		layout: "",
	},
	{
		name: "Medicines",
		path: "/medicines",
		component: DmMedicine,
		layout: "",
	},
	{
		name: "Labs",
		path: "/labs",
		component: DmLab,
		layout: "",
	},
	{
		name: "Báo cáo doanh số",
		path: "/bao-cao-doanh-so",
		component: DoanhSoReport,
		layout: "",
	},
	{
		name: "Báo cáo khách hàng",
		path: "/bao-cao-khach-hang",
		component: CustomerReport,
		layout: "",
	},
	{
		name: "Báo cáo hoá đơn thu",
		path: "/bao-cao-hoa-don-thu",
		component: HoaDonReport,
		layout: "",
	},
	{
		name: "Báo cáo hoá đơn chi",
		path: "/bao-cao-hoa-don-chi",
		component: HoaDonReportChi,
		layout: "",
	},
	{
		name: "Báo cáo nhân viên",
		path: "/bao-cao-nhan-vien",
		component: NhanVienReport,
		layout: "",
	},
	{ name: "Users", path: "/users", component: User, layout: "" },
	{
		name: "Nhóm người dùng và phân quyền",
		path: "/usergroup",
		component: UserGroup,
		layout: "",
	},
	{
		name: "Người sử dụng chương trình",
		path: "/participant",
		component: Participant,
		layout: "",
	},
	{
		name: "Phân Quyền",
		path: "/manager-role/:right",
		component: ManagerRole,
		layout: "",
	},
	{
		name: "Sections",
		path: "/:id",
		component: DefaultPage,
		layout: "",
	},
];

export { privateRouter, publicRouter };
