import { Button, Input, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { BsPersonPlus } from "react-icons/bs";
import { HiOutlineFilter } from "react-icons/hi";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { LuEye, LuPencil, LuX } from "react-icons/lu";
import { TbCornerUpRightDouble } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";
import { request } from "../../../utils/request";
import ModalBasic from "../../components/modal/ModalBasic";
import TableManager from "../../components/table/tableManager";
import ContactInformation from "../components/modal/modalItem/ContactInformation";
import PopoverModel from "../../components/modal/popoverModel";
import { debounce } from "lodash";
import FilterCustomer from "../../components/filter/FilterCustomer";
import dayjs from "dayjs";
import {
	getFirstDayOfMonth,
	getStartOfDay,
	getStartOfWeek,
	handelKhoangNgayTheo,
} from "../../../utils/utilsFunc";
const { Search } = Input;

export default function Contact() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const [messageApi, contextHolder] = message.useMessage();

	//modal
	const [isOpenContactInformation, setIsOpenContactInformation] =
		useState(false);

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							<Popconfirm
								title={`Bạn muốn ${record.ten_lien_he} thành khách hàng tiềm năng?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleChangePotential(record);
								}}
								onCancel={(e) => {
									e.stopPropagation();
									return;
								}}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<TbCornerUpRightDouble size={18} />
								</Button>
							</Popconfirm>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenContactInformation("edit");
									setDetailLienHe(record);
								}}
							>
								<LuPencil size={18} />
							</Button>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenContactInformation("edit");
									setDetailLienHe(record);
								}}
							>
								<LuEye size={18} />
							</Button>
							<Popconfirm
								title={`Bạn muốn xoá ${record.ten_lien_he} ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleDeleteLienHe(record._id);
								}}
								onCancel={(e) => {
									e.stopPropagation();
									return;
								}}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<LuX size={18} />
								</Button>
							</Popconfirm>
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		// {
		// 	title: "Họ",
		// 	dataIndex: "ho_ten_dem",
		// 	width: 150,
		// },
		{
			title: "Tên",
			dataIndex: "ten_lien_he",
			width: 150,
		},
		{
			title: "Quan hệ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.quan_he}</span>;
			},
		},
		{
			title: "Giới tính",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.gioi_tinh}</span>;
			},
		},
		{
			title: "Độ tuổi",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return <span>{record?.exfields?.do_tuoi}</span>;
			},
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},
		{
			title: "Đánh giá tiềm năng",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return <span>{record?.exfields?.tiem_nang}</span>;
			},
		},
	];

	const [loading, setLoading] = useState(false);
	const [detailLienHe, setDetailLienHe] = useState({});
	const [dataLienHe, setDataLienHe] = useState([]);
	const [dataRowSelected, setDataRowSelected] = useState([]);

	// Save data
	const handleSaveDataLienHe = async (data) => {
		setLoading(true);
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/lienhe`,
				{
					id_kh: "",
					ten_lien_he: data.ten_lien_he,
					dien_thoai: data.dien_thoai,
					exfields: {
						quan_he: data.quan_he,
						gioi_tinh: data.gioi_tinh,
						do_tuoi: data.do_tuoi,
						tiem_nang: data.tiem_nang,
						ghi_chu: data.ghi_chu,
					},
					isbuyer: true,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			await handleGetDataLienHe();
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		}
	};

	// potential data
	const handleChangePotential = async (data) => {
		setLoading(true);
		try {
			await request.post(
				`api/${localStorage.getItem("id_app")}/customer`,
				{
					trang_thai: 0,
					// ma_kh: data._id,
					dien_thoai: data.dien_thoai,
					isbuyer: true,
					gioi_tinh: data?.exfields?.gioi_tinh,
					ten_kh: data.ten_lien_he,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			await request.delete(
				`api/${localStorage.getItem("id_app")}/lienhe/${data._id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			messageApi.open({
				type: "success",
				content: "Chuyển thành công",
			});
			await handleGetDataLienHe();
			setLoading(false);
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Chuyển thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		}
	};

	// change data
	const handleChangeDataLienHe = async (data) => {
		setLoading(true);
		try {
			const response = await request.put(
				`api/${localStorage.getItem("id_app")}/lienhe/${
					detailLienHe._id
				}`,
				{
					...detailLienHe,
					ten_lien_he: data.ten_lien_he,
					dien_thoai: data.dien_thoai,
					exfields: {
						...detailLienHe.exfields,
						quan_he: data.quan_he || null,
						gioi_tinh: data.gioi_tinh || null,
						do_tuoi: data.do_tuoi || null,
						tiem_nang: data.tiem_nang || null,
						ghi_chu: data.ghi_chu,
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			setDetailLienHe(response.data);
			await handleGetDataLienHe();
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
			setIsOpenContactInformation(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			setIsOpenContactInformation(false);
			console.log(error);
		}
	};

	// delete data
	const handleDeleteLienHe = async (id) => {
		setLoading(true);
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/lienhe/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			await handleGetDataLienHe();
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};

	// delete array data
	const handleDeleteArrayLienHe = async (data) => {
		setLoading(true);
		try {
			await Promise.all(
				data.map(async (item) => {
					await request.delete(
						`api/${localStorage.getItem("id_app")}/lienhe/${item}`,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
				})
			);
			setLoading(false);
			await handleGetDataLienHe();
			setDataRowSelected([]);
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};

	// get data
	const handleGetDataLienHe = async (q) => {
		setLoading(true);
		try {
			const [response, totalRecord] = await Promise.all([
				request.get(`api/${localStorage.getItem("id_app")}/lienhe`, {
					params: {
						q: JSON.stringify({ ...q }),
						limit: params.limit,
						page: params.page,
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}),
				request.get(`api/${localStorage.getItem("id_app")}/lienhe`, {
					params: {
						q: JSON.stringify({ ...q }),
						count: 1,
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}),
			]);
			setTotalRows(totalRecord.data.rows_number);
			setDataLienHe(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	useEffect(() => {
		handleGetDataLienHe({ ...params.q });
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		if (value) {
			setParams({
				...params,
				page: 1,
				q: {
					$or: [
						{ ma_kh: { $regex: value, $options: "i" } },
						{ ten_lien_he: { $regex: value, $options: "i" } },
						// { ho_ten_dem: { $regex: value, $options: "i" } },
						{ dien_thoai: { $regex: value, $options: "i" } },
					],
				},
			});
			handleGetDataLienHe({
				$or: [
					{ ma_kh: { $regex: value, $options: "i" } },
					{ ten_lien_he: { $regex: value, $options: "i" } },
					// { ho_ten_dem: { $regex: value, $options: "i" } },
					{ dien_thoai: { $regex: value, $options: "i" } },
				],
			});
		} else {
			params.q.$or && delete params.q.$or;
			handleGetDataLienHe({
				...params.q,
			});
		}
	};

	const handleFilter = (value) => {
		const filterConditions = {};

		if (
			(value?.thang_sinh_nhat === undefined ||
				value?.thang_sinh_nhat === null) &&
			(value?.ngay_sinh_nhat === undefined ||
				value?.ngay_sinh_nhat === null)
		) {
			console.log(3);
			filterConditions?.$expr && delete filterConditions?.$expr;
			params.q?.$expr && delete params.q?.$expr;
		} else if (
			value?.ngay_sinh_nhat === undefined ||
			value?.ngay_sinh_nhat === null
		) {
			console.log(1);
			filterConditions.$expr = {
				$eq: [{ $month: "$ngay_sinh" }, value?.thang_sinh_nhat],
			};
		} else if (
			value?.thang_sinh_nhat === undefined ||
			value?.thang_sinh_nhat === null
		) {
			console.log(2);
			filterConditions.$expr = {
				$eq: [{ $dayOfMonth: "$ngay_sinh" }, value?.ngay_sinh_nhat],
			};
		} else
			filterConditions.$expr = {
				$and: [
					{
						$eq: [
							{ $dayOfMonth: "$ngay_sinh" },
							value?.ngay_sinh_nhat,
						],
					},
					{
						$eq: [{ $month: "$ngay_sinh" }, value?.thang_sinh_nhat],
					},
				],
			};

		if (value?.khach_moi !== undefined) {
			if (value?.khach_moi === 1) {
				filterConditions.date_created = {
					$gte: getStartOfDay(new Date()),
					$lt: new Date(),
				};
			} else if (value?.khach_moi === 2) {
				filterConditions.date_created = {
					$gte: getStartOfWeek(new Date()),
					$lt: new Date(),
				};
			} else if (value?.khach_moi === 3) {
				filterConditions.date_created = {
					$gte: getFirstDayOfMonth(new Date()),
					$lt: new Date(),
				};
			}
		} else {
			filterConditions?.date_created &&
				delete filterConditions?.date_created;
			params.q?.date_created && delete params.q?.date_created;
		}

		const khoang_ngay = handelKhoangNgayTheo(value);

		if (khoang_ngay === undefined) {
			filterConditions?.$or && delete filterConditions?.$or;
			params.q?.$or && delete params.q?.$or;
		}

		handleGetDataLienHe({
			...params.q,
			...filterConditions,
			...khoang_ngay,
		});
		setParams({
			...params,
			page: 1,
			q: {
				...params.q,
				...filterConditions,
				...khoang_ngay,
			},
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center h-full">
						<PopoverModel
							placement="bottomLeft"
							title={null}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<FilterCustomer
										data={{}}
										handleManager={handleFilter}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenContactInformation("create");
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataRowSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataRowSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayLienHe(
											dataRowSelected
										);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>{" "}
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên hoặc số điện thoại"
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>

				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Mối liên hệ khách hàng ({totalRows || 0})
					</span>
				</div>
			</div>

			<TableManager
				loading={loading}
				data={dataLienHe}
				columns={columns}
				scroll={{
					x: 600,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataRowSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenContactInformation("edit");
						setDetailLienHe(record);
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>
			<ModalBasic
				id={"modalContactInformation"}
				title={"Liên hệ với bệnh nhân"}
				open={isOpenContactInformation === "create" ? true : false}
				onCancel={() => {
					setIsOpenContactInformation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleSaveDataLienHe(data);
					setIsOpenContactInformation(false);
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<ContactInformation />
			</ModalBasic>

			<ModalBasic
				id={"modalContactInformation-edit"}
				title={"Liên hệ với bệnh nhân"}
				open={isOpenContactInformation === "edit" ? true : false}
				onCancel={() => {
					setIsOpenContactInformation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleChangeDataLienHe(data);
					setIsOpenContactInformation(false);
				}}
				data={{
					ten_lien_he: detailLienHe?.ten_lien_he,
					dien_thoai: detailLienHe?.dien_thoai,
					quan_he: detailLienHe?.exfields?.quan_he,
					gioi_tinh: detailLienHe?.exfields?.gioi_tinh,
					do_tuoi: detailLienHe?.exfields?.do_tuoi,
					tiem_nang: detailLienHe?.exfields?.tiem_nang,
					ghi_chu: detailLienHe?.exfields?.ghi_chu,
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<ContactInformation />
			</ModalBasic>
		</div>
	);
}
