import { Button, Checkbox, Dropdown, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { request } from "../../utils/request";
const onChange = (e) => {
	console.log(`checked = ${e.target.checked}`);
};

const items = [
	{
		key: "1",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Sao chép
			</div>
		),
	},
	{
		key: "2",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Sửa
			</div>
		),
	},
	{
		key: "3",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Xoá
			</div>
		),
	},
	{
		key: "4",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Lịch sử
			</div>
		),
	},
	{
		key: "5",
		label: (
			<div
				onClick={() => {
					alert("Đang cập nhật");
				}}
			>
				Gộp...
			</div>
		),
	},
];

const columns = [
	{
		title: "",
		dataIndex: "",
		key: "checkbox",
		render: () => (
			<div className="flex justify-center w-full">
				<Dropdown
					menu={{
						items,
					}}
					placement="bottomLeft"
				>
					<Button
						className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
						shape="circle"
						size="small"
					>
						<AiFillSetting size={18} />
					</Button>
				</Dropdown>
			</div>
		),
		width: 50,
	},
	{
		title: "Người sử dụng",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "Tên đầy đủ",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Quản trị",
		dataIndex: "admin",
		key: "quantri",
		render: (checked) => (
			<Checkbox onChange={onChange} checked={checked} disabled></Checkbox>
		),
	},
	{
		title: "Nhóm",
		dataIndex: "group_name",
		key: "group_name",
	},
	{
		title: "Hotline",
		dataIndex: "hotline",
		key: "hotline",
	},
	{
		title: "Số nội bộ",
		dataIndex: "sdt_noibo",
		key: "sdt_noibo",
	},
	{
		title: "",
		dataIndex: "",
		key: "action",
		render: () => (
			<div className="flex w-full justify-center">
				<Button
					type="primary"
					htmlType="submit"
					className="btn_primary"
				>
					Khôi phục mật khẩu
				</Button>
			</div>
		),
	},
];

export default function Participant() {
	const [data, setData] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const onSelectChange = (newSelectedRowKeys, selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const handleGetParticipant = async () => {
		const group_id = window.location.href.split("=")[1];
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/participant`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 30,
						q: JSON.stringify({
							group_id: group_id,
						}),
					},
				}
			);
			setData(response.data);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		handleGetParticipant();
	}, []);
	return (
		<div>
			<div className="h-12 flex justify-between items-center my-2">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					Participant
				</div>
			</div>
			<Table
				columns={columns}
				dataSource={data?.map((item) => {
					return {
						key: item._id,
						email: item.email,
						name: item.name,
						admin: item.admin,
						group_name: item.group_name,
						hotline: item.hotline,
						sdt_noibo: item.sdt_noibo,
					};
				})}
				size="small"
				rowSelection={rowSelection}
			/>
		</div>
	);
}
