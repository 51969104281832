import { Button, Form } from "antd";
import React, { useEffect, useRef } from "react";
import PopupModel from "./popupModel";
import { useMediaQuery } from "react-responsive";

export default function ModalBasic({
	zIndex,
	id,
	children,
	title,
	open,
	onCancel,
	footer,
	footerGrBtns = true,
	height = "auto",
	width,
	labelAlign,
	style,
	styleBodyForm,
	handleManager,
	data = {},
	groupButton,
	itemFooter,
}) {
	const formBasicRef = useRef(null);

	const getFieldValue = (name) => {
		return formBasicRef?.current?.getFieldValue(name);
	};

	const setFieldsValue = (name, value) => {
		formBasicRef.current?.setFieldsValue({ [name]: value });
	};

	useEffect(() => {
		formBasicRef?.current?.setFieldsValue(data);
	}, [data]);

	const onFinish = (values) => {
		handleManager(values);
		onReset();
	};
	const onReset = () => {
		formBasicRef.current?.resetFields();
	};
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	return (
		<PopupModel
			zIndex={zIndex}
			title={title}
			open={open}
			onCancel={onCancel}
			footer={footer}
			width={width || "calc(95vw - 220px)"}
			style={style}
			height={height}
		>
			<Form
				id={id || "form-create-customer"}
				ref={formBasicRef}
				name="control-ref"
				onFinish={onFinish}
				layout={isTabletOrMobile ? "vertical" : "horizontal"}
				labelCol={{
					span: 4,
				}}
				labelAlign={labelAlign}
			>
				<div
					style={{ ...styleBodyForm }}
					className="pt-4 h-fit max-h-[calc(100vh-150px)] overflow-y-auto"
				>
					{React.cloneElement(children, {
						getFieldValue,
						setFieldsValue,
					})}
				</div>
				{footerGrBtns && (
					<div className="w-full justify-between flex items-center flex-row-reverse">
						<div className="flex justify-end items-center gap-4 py-2 px-10 max-sm:px-1">
							{groupButton && groupButton?.length > 0 ? (
								groupButton.map((item, index) => (
									<div key={index}>{item}</div>
								))
							) : (
								<>
									<Button
										className="btn_error"
										onClick={onReset}
									>
										Làm mới
									</Button>
									<Button
										htmlType="submit"
										className="btn_primary"
									>
										Lưu
									</Button>
								</>
							)}
						</div>
						{itemFooter}
					</div>
				)}
			</Form>
		</PopupModel>
	);
}
