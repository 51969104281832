import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { handleGetDataAndRows } from "../../../utils/utilsApi";
import TableManager from "../../components/table/tableManager";
import { FullLogoHoaCuoi } from "../../../utils/images";
import "../managerServices.scss";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";

export default function ScreenQuayLeTan() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: "Mã",
			dataIndex: "",
			width: 50,
			render: (record) => {
				return record?.exfields?.ma_lich_hen;
			},
			align: "center",
		},
		{
			title: "Thời gian hẹn",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return dayjs(
					record?.exfields?.thoi_gian_hen,
					"YYYY-MM-DD HH:mm"
				).format("DD/MM/YYYY HH:mm");
			},
		},
		{
			title: "Họ tên",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return (
					(record?.exfields?.ho_ten_dem || "") + " " + record?.ten_kh
				);
			},
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return record?.exfields?.trang_thai_hen;
			},
		},
		// {
		// 	title: "Điện thoại",
		// 	dataIndex: "dien_thoai",
		// 	render: (dien_thoai) => {
		// 		return (
		// 			<React.Fragment>
		// 				{dien_thoai ? (
		// 					<a
		// 						href={`tel:${dien_thoai}`}
		// 						onClick={(e) => {
		// 							e.stopPropagation();
		// 						}}
		// 						className="contact_cell"
		// 					>
		// 						<HiPhoneArrowUpRight />
		// 						{dien_thoai}
		// 					</a>
		// 				) : (
		// 					<div className="empty-text">(Trống)</div>
		// 				)}
		// 			</React.Fragment>
		// 		);
		// 	},
		// 	width: 150,
		// },
		{
			title: "ID",
			dataIndex: "ma_kh",
			width: 80,
			align: "center",
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 0,
				...q,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	useEffect(() => {
		handleGetDataTable({ ...params.q });
	}, [params.page, params.limit]);

	const [isFullScreen, setFullScreen] = useState(false);

	const toggleFullScreen = () => {
		const mainContent = document.querySelector('[id="fullscreen"]');

		if (mainContent) {
			if (!isFullScreen) {
				if (mainContent.requestFullscreen) {
					mainContent.requestFullscreen();
				} else if (mainContent.mozRequestFullScreen) {
					mainContent.mozRequestFullScreen();
				} else if (mainContent.webkitRequestFullscreen) {
					mainContent.webkitRequestFullscreen();
				} else if (mainContent.msRequestFullscreen) {
					mainContent.msRequestFullscreen();
				}
			} else {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}

			setFullScreen(!isFullScreen);
		}
	};

	useEffect(() => {
		toggleFullScreen();
	}, []);

	return !isTabletOrMobile ? (
		<div id="fullscreen" className="w-full flex relative">
			<div
				onClick={toggleFullScreen}
				className="absolute top-2 right-2 hover:text-[#186b9e] cursor-pointer bg-white"
			>
				{isFullScreen ? (
					<AiOutlineFullscreenExit className="w-8 h-8" />
				) : (
					<AiOutlineFullscreen className="w-8 h-8" />
				)}
			</div>
			<div className="w-1/2 bg-white rounded-md pr-1">
				<div className="flex justify-center text-4xl text-[#254F74] font-normal italic leading-[88.5px] uppercase">
					Lịch hẹn khách hàng
				</div>
				<TableManager
					data={dataTable}
					columns={columns}
					loading={loading}
					rowKey={"_id"}
					scroll={{
						x: 750,
						y: "calc(100vh - 275px)",
					}}
					tableProps={{
						size: "middle",
						rowSelection: false,
					}}
					// handleDataSelectKey={(newSelectedRowKeys) => {
					// 	setDataSelected(newSelectedRowKeys);
					// }}
					// onRow={(record) => ({
					// 	onClick: () => {
					// 		setDataDetail(record);
					// 		setNgayThietLap({
					// 			thoi_gian_hen:
					// 				record?.exfields?.thoi_gian_hen ||
					// 				new Date(),
					// 		});
					// 		setIsOpenForm("edit");
					// 	},
					// })}
					isPage={params.page}
					isLimit={params.limit}
					totalRows={totalRows}
					handlePaginationChange={handlePaginationChange}
				/>
			</div>
			<div className="w-1/2 flex flex-col justify-between items-center pl-1">
				<div className="flex justify-center w-full">
					<img
						className="w-[200px] h-[200px] max-xl:w-[150px] max-xl:h-[150px] my-8 max-xl:my-4 rounded-full object-cover"
						src={FullLogoHoaCuoi}
						alt="logo_hoacuoi"
					/>
				</div>

				<div className="flex flex-col w-full">
					<div className="px-10 mb-2 w-full text-[#D1AA0A] text-3xl max-2xl:text-2xl max-2xl:px-2 max-xl:text-lg max-lg:text-base flex justify-between">
						<span>Hotline: 0907 327 787</span>
						<span>https://nhakhoahoacuoi.vn</span>
					</div>
					<div class="video-container w-full">
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube.com/embed/6KC-DYqsLJw"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							className="rounded-lg"
						></iframe>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className="bg-gradient-to-r from-[#254f749f]">
			<div className="w-full h-[calc(100vh-48px)] flex justify-center items-center">
				<h1 className="text-[#326b97] text-3xl text-center">
					Vui lòng chuyển sang thiết bị có màn hình lớn hơn
				</h1>
			</div>
		</div>
	);
}
