import { Button } from "antd";
import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormThanhToan from "../form/formItem/FormThanhToan";

export default function ModalThanhToan({
	isOpenForm,
	setIsOpenForm,
	isTabletOrMobile,
	handleManager,
	data,
}) {
	return (
		<ModalBasic
			zIndex={1035}
			id={"thanhtoan-edit"}
			title={
				<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
					<span>Thanh toán hoá đơn</span>
				</div>
			}
			open={isOpenForm === "thanhtoan" ? true : false}
			onCancel={() => {
				setIsOpenForm(false);
			}}
			footer={null}
			width={"450px"}
			styleBodyForm={{ paddingTop: "0" }}
			labelAlign="left"
			handleManager={handleManager}
			data={data}
			groupButton={[
				<Button htmlType="submit" className="btn_primary">
					Lưu
				</Button>,
			]}
		>
			<FormThanhToan />
		</ModalBasic>
	);
}
