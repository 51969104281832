import { Button, Input, Popconfirm, message } from "antd";
import "dayjs/locale/vi";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import {
	HiOutlineFilter,
	HiOutlineMail,
	HiOutlineSearch,
} from "react-icons/hi";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { TbCornerUpRightDouble } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";
import { request } from "../../../utils/request";
import ModalFull from "../../components/modal/ModalFull";
import TableManager from "../../components/table/tableManager";
import Information from "../components/form/Information";
import Upgrade from "../components/form/Upgrade";
import "../customer.scss";
import PopoverModel from "../../components/modal/popoverModel";
import { debounce } from "lodash";
import FilterCustomer from "../../components/filter/FilterCustomer";
import dayjs from "dayjs";
import {
	getFirstDayOfMonth,
	getStartOfDay,
	getStartOfWeek,
	handelKhoangNgayTheo,
} from "../../../utils/utilsFunc";
const { Search } = Input;

export default function PotentialGuests() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [dataTable, setDataTable] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [dataSelected, setDataSelected] = useState([]);
	const [dataCustomer, setDataCustomer] = useState({});
	const [isOpenCustomer, setIsOpenCustomer] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setIsOpenCustomer("edit");
				setDataCustomer(data);
				setNgayThietLap({
					...ngaythieplap,
					ngay_sinh: data?.ngay_sinh,
					ngay_kham_moi: data?.ngay_kham_moi,
					ngay_kham_dau: data?.ngay_kham_dau,
					nhac_lich: data?.exfields?.khach_tiem_nang?.nhac_lich,
				});
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				console.log(data, "copy data");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDelete(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá khách ${record.ten_kh}`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Họ",
			dataIndex: "ho_ten_dem",
			width: 150,
		},
		{
			title: "Tên",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Giới tính",
			dataIndex: "gioi_tinh",
			width: 100,
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},
		{
			title: "Email",
			dataIndex: "email",
			render: (email) => {
				return email ? (
					<a
						href={`mailto:${email}`}
						onClick={(e) => {
							e.stopPropagation();
						}}
						className="contact_cell"
					>
						<HiOutlineMail />
						{email}
					</a>
				) : (
					<div className="empty-text">(Trống)</div>
				);
			},
			width: 250,
		},

		{
			title: "Địa chỉ",
			dataIndex: "dia_chi",
			width: 250,
		},
		{
			title: "ID",
			dataIndex: "ma_kh",
			width: 100,
		},
		// {
		// 	title: "ID 2",
		// 	dataIndex: "ten_kh2",
		// 	width: 150,
		// },
	];

	const [ngaythieplap, setNgayThietLap] = useState(null);

	const formSections = [
		{
			id: "thongtin",
			title: "Thông tin",
			content: <Information />,
		},
		{
			id: "phattrien",
			title: "Phát triển",
			content: (
				<Upgrade
					dataAll={{
						name_user_created: dataCustomer.name_user_created,
						date_created: dataCustomer.date_created,
					}}
					data={ngaythieplap}
				/>
			),
		},
	];

	//loading
	const [loading, setLoading] = useState(false);

	// Save data
	const handleSaveData = async (data) => {
		setLoading(true);
		try {
			const response = await request.post(
				`api/${localStorage.getItem("id_app")}/customer`,
				{
					trang_thai: 0,
					ma_kh: data.ma_kh,
					gioi_tinh: data.gioi_tinh,
					ten_kh: data.ten_kh,
					ngay_sinh: data?.ngay_sinh,
					ngay_kham_dau: data?.ngay_kham_dau,
					ngay_kham_moi: data?.ngay_kham_moi,
					dien_thoai: data.dien_thoai,
					email: data.email,
					dia_chi: data.dia_chi,
					tinh_thanh: data.tinh_thanh,
					quan_huyen: data.quan_huyen,
					xa_phuong: data.xa_phuong,
					ten_tinh_thanh: data.ten_tinh_thanh,
					ten_quan_huyen: data.ten_quan_huyen,
					ten_xa_phuong: data.ten_xa_phuong,
					ghi_chu: data.ghi_chu,
					ho_ten_dem: data.ho_ten_dem,
					nh_kh: data.nh_kh,
					exfields: {
						khach_tiem_nang: {
							nguon: data.nguon,
							do_nong: data.do_nong,
							nhac_lich: data?.nhac_lich,
							nv_cham_soc: data.nv_cham_soc,
							danh_gia: data.danh_gia,
							ket_qua: data.ket_qua,
						},
					},
					isbuyer: true,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			console.log(response.data);
			handleGetData();
			messageApi.open({
				type: "success",
				content: "Tạo thành công",
			});
			setIsOpenCustomer(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Tạo thất bại",
			});
			setIsOpenCustomer(false);
			console.log(error);
		}
	};

	// change data
	const handleChangeData = async (data) => {
		setLoading(true);
		try {
			const response = await request.put(
				`api/${localStorage.getItem("id_app")}/customer/${
					dataCustomer._id
				}`,
				{
					...dataCustomer,
					ma_kh: data.ma_kh,
					gioi_tinh: data.gioi_tinh || null,
					ten_kh: data.ten_kh,
					ngay_sinh: data?.ngay_sinh,
					ngay_kham_dau: data?.ngay_kham_dau,
					ngay_kham_moi: data?.ngay_kham_moi,
					dien_thoai: data.dien_thoai,
					email: data.email,
					dia_chi: data.dia_chi,
					tinh_thanh: data.tinh_thanh || null,
					quan_huyen: data.quan_huyen || null,
					xa_phuong: data.xa_phuong || null,
					ten_tinh_thanh: data.ten_tinh_thanh || null,
					ten_quan_huyen: data.ten_quan_huyen || null,
					ten_xa_phuong: data.ten_xa_phuong || null,
					ghi_chu: data.ghi_chu,
					ho_ten_dem: data.ho_ten_dem,
					nh_kh: data.nh_kh || null,
					exfields: {
						...dataCustomer.exfields,
						khach_tiem_nang: {
							nguon: data.nguon,
							do_nong: data.do_nong,
							nhac_lich: data?.nhac_lich,
							nv_cham_soc: data.nv_cham_soc || null,
							danh_gia: data.danh_gia,
							ket_qua: data.ket_qua,
						},
					},
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			handleGetData();
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
			setDataCustomer(response.data);
			setNgayThietLap({
				...ngaythieplap,
				ngay_sinh: response?.data?.ngay_sinh || null,
				ngay_kham_dau: response?.data?.ngay_kham_dau || null,
				ngay_kham_moi: response?.data?.ngay_kham_moi || null,
				nhac_lich:
					response?.data?.exfields?.khach_tiem_nang?.nhac_lich ||
					null,
			});
			setIsOpenCustomer(false);
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			setIsOpenCustomer(false);
			console.log(error);
		}
	};

	// delete data
	const handleDelete = async (id) => {
		setLoading(true);
		try {
			await request.delete(
				`api/${localStorage.getItem("id_app")}/customer/${id}`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			handleGetData();
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};

	// delete array data
	const handleDeleteArray = async (data) => {
		setLoading(true);
		try {
			await Promise.all(
				data.map(async (item) => {
					await request.delete(
						`api/${localStorage.getItem(
							"id_app"
						)}/customer/${item}`,
						{
							headers: {
								"X-Access-Token": localStorage.getItem("token"),
								"Content-Type": "application/json",
							},
						}
					);
				})
			);
			setLoading(false);
			await handleGetData();
			setDataSelected([]);
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Xoá thất bại",
			});
			console.log(error);
		}
	};

	// Get data
	const handleGetData = async (q) => {
		setLoading(true);
		try {
			const [response, totalRecord] = await Promise.all([
				request.get(`api/${localStorage.getItem("id_app")}/customer`, {
					params: {
						q: JSON.stringify({
							trang_thai: 0,
							status: true,
							isbuyer: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
							...q,
						}),
						limit: params.limit,
						page: params.page,
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}),
				request.get(`api/${localStorage.getItem("id_app")}/customer`, {
					params: {
						q: JSON.stringify({
							trang_thai: 0,
							status: true,
							isbuyer: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
							...q,
						}),
						count: 1,
					},
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}),
			]);
			setTotalRows(totalRecord.data.rows_number);
			setDataTable(response.data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	// Chuyển khách hiện hữu
	const hanleChangeStatus = async (id) => {
		setLoading(true);
		try {
			await request.put(
				`api/${localStorage.getItem("id_app")}/customer/${
					dataCustomer._id
				}`,
				{
					...dataCustomer,
					trang_thai: 1,
				},
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setLoading(false);
			handleGetData();
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thay đổi thất bại",
			});
			console.log(error);
		}
	};

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	useEffect(() => {
		handleGetData({ ...params.q });
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		if (value) {
			setParams({
				...params,
				page: 1,
				q: {
					...params.q,
					$or: [
						{ ma_kh: { $regex: value, $options: "i" } },
						{ ten_kh: { $regex: value, $options: "i" } },
						{ ten_kh2: { $regex: value, $options: "i" } },
						// { ho_ten_dem: { $regex: value, $options: "i" } },
						{ dien_thoai: { $regex: value, $options: "i" } },
					],
				},
			});
			handleGetData({
				...params.q,
				$or: [
					{ ma_kh: { $regex: value, $options: "i" } },
					{ ten_kh: { $regex: value, $options: "i" } },
					{ ten_kh2: { $regex: value, $options: "i" } },
					// { ho_ten_dem: { $regex: value, $options: "i" } },
					{ dien_thoai: { $regex: value, $options: "i" } },
				],
			});
		} else {
			params.q.$or && delete params.q.$or;
			handleGetData({
				...params.q,
			});
		}
	};

	const handleFilter = (value) => {
		const filterConditions = {};

		if (
			(value?.thang_sinh_nhat === undefined ||
				value?.thang_sinh_nhat === null) &&
			(value?.ngay_sinh_nhat === undefined ||
				value?.ngay_sinh_nhat === null)
		) {
			console.log(3);
			filterConditions?.$expr && delete filterConditions?.$expr;
			params.q?.$expr && delete params.q?.$expr;
		} else if (
			value?.ngay_sinh_nhat === undefined ||
			value?.ngay_sinh_nhat === null
		) {
			console.log(1);
			filterConditions.$expr = {
				$eq: [{ $month: "$ngay_sinh" }, value?.thang_sinh_nhat],
			};
		} else if (
			value?.thang_sinh_nhat === undefined ||
			value?.thang_sinh_nhat === null
		) {
			console.log(2);
			filterConditions.$expr = {
				$eq: [{ $dayOfMonth: "$ngay_sinh" }, value?.ngay_sinh_nhat],
			};
		} else
			filterConditions.$expr = {
				$and: [
					{
						$eq: [
							{ $dayOfMonth: "$ngay_sinh" },
							value?.ngay_sinh_nhat,
						],
					},
					{
						$eq: [{ $month: "$ngay_sinh" }, value?.thang_sinh_nhat],
					},
				],
			};

		if (value?.khach_moi !== undefined) {
			if (value?.khach_moi === 1) {
				filterConditions.date_created = {
					$gte: getStartOfDay(new Date()),
					$lt: new Date(),
				};
			} else if (value?.khach_moi === 2) {
				filterConditions.date_created = {
					$gte: getStartOfWeek(new Date()),
					$lt: new Date(),
				};
			} else if (value?.khach_moi === 3) {
				filterConditions.date_created = {
					$gte: getFirstDayOfMonth(new Date()),
					$lt: new Date(),
				};
			}
		} else {
			filterConditions?.date_created &&
				delete filterConditions?.date_created;
			params.q?.date_created && delete params.q?.date_created;
		}

		const khoang_ngay = handelKhoangNgayTheo(value);

		if (khoang_ngay === undefined) {
			filterConditions?.$or && delete filterConditions?.$or;
			params.q?.$or && delete params.q?.$or;
		}

		handleGetData({
			...params.q,
			...filterConditions,
			...khoang_ngay,
		});
		setParams({
			...params,
			page: 1,
			q: {
				...params.q,
				...filterConditions,
				...khoang_ngay,
			},
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={null}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<FilterCustomer
										data={{}}
										handleManager={handleFilter}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenCustomer("create");
								setNgayThietLap({
									...ngaythieplap,
									ngay_sinh: null,
									ngay_kham_dau: null,
									ngay_kham_moi: null,
									nhac_lich: null,
								});
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArray(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên hoặc số điện thoại"
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>

				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Khách tiềm năng ({totalRows || 0})
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenCustomer("edit");
						setDataCustomer(record);
						setNgayThietLap({
							...ngaythieplap,
							ngay_sinh: record?.ngay_sinh,
							ngay_kham_moi: record?.ngay_kham_moi,
							ngay_kham_dau: record?.ngay_kham_dau,
							nhac_lich:
								record?.exfields?.khach_tiem_nang?.nhac_lich,
						});
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>
			<ModalFull
				idModal={"leads-edit"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Khách tiềm năng</span>
							<div className="hidden justify-center items-center gap-2 max-lg:flex">
								<Popconfirm
									title="Chuyển sang khách hiện hữu?"
									onConfirm={() => {
										hanleChangeStatus(dataCustomer?._id);
										setIsOpenCustomer(false);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e]">
										<TbCornerUpRightDouble className="w-5 h-5" />
									</Button>
								</Popconfirm>
							</div>
						</div>
						<div className="flex justify-end items-center gap-6">
							<div className="flex justify-center items-center gap-2 max-lg:hidden">
								<Popconfirm
									title="Chuyển sang khách hiện hữu?"
									onConfirm={() => {
										hanleChangeStatus(dataCustomer?._id);
										setIsOpenCustomer(false);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e]">
										<TbCornerUpRightDouble className="w-5 h-5" />
									</Button>
								</Popconfirm>
							</div>
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenCustomer === "edit" ? true : false}
				onCancel={() => {
					setIsOpenCustomer(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				height={"90vh"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleChangeData(data);
				}}
				data={{
					ngay_sinh: dataCustomer?.ngay_sinh,
					ngay_kham_moi: dataCustomer?.ngay_kham_moi,
					ngay_kham_dau: dataCustomer?.ngay_kham_dau,
					ho_ten_dem: dataCustomer?.ho_ten_dem,
					gioi_tinh: dataCustomer?.gioi_tinh,
					ma_kh: dataCustomer?.ma_kh,
					ten_kh: dataCustomer?.ten_kh,
					ten_kh2: dataCustomer?.ten_kh2,
					dien_thoai: dataCustomer?.dien_thoai,
					email: dataCustomer?.email,
					dia_chi: dataCustomer?.dia_chi,
					tinh_thanh: dataCustomer?.tinh_thanh,
					quan_huyen: dataCustomer?.quan_huyen,
					xa_phuong: dataCustomer?.xa_phuong,
					ten_tinh_thanh: dataCustomer?.ten_tinh_thanh,
					ten_quan_huyen: dataCustomer?.ten_quan_huyen,
					ten_xa_phuong: dataCustomer?.ten_xa_phuong,
					ghi_chu: dataCustomer?.ghi_chu,
					nh_kh: dataCustomer?.nh_kh,
					nguon: dataCustomer?.exfields?.khach_tiem_nang?.nguon,
					do_nong: dataCustomer?.exfields?.khach_tiem_nang?.do_nong,
					ket_qua: dataCustomer?.exfields?.khach_tiem_nang?.ket_qua,
					danh_gia: dataCustomer?.exfields?.khach_tiem_nang?.danh_gia,
					nv_cham_soc:
						dataCustomer?.exfields?.khach_tiem_nang?.nv_cham_soc,
					nhac_lich:
						dataCustomer?.exfields?.khach_tiem_nang?.nhac_lich,
				}}
			/>
			<ModalFull
				idModal={"leads-create"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Khách tiềm năng</span>
							<div className="hidden justify-center items-center gap-2 max-lg:flex">
								<Button
									onClick={() =>
										alert("Tài khoảng không tồn tại")
									}
									className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e]"
								>
									<TbCornerUpRightDouble className="w-5 h-5" />
								</Button>
							</div>
						</div>
						<div className="flex justify-end items-center gap-6">
							<div className="flex justify-center items-center gap-2 max-lg:hidden">
								<Button
									onClick={() =>
										alert("Tài khoảng không tồn tại")
									}
									className="btn_ternary !border-none flex justify-center items-center !text-[#186b9e]"
								>
									<TbCornerUpRightDouble className="w-5 h-5" />
								</Button>
							</div>
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenCustomer === "create" ? true : false}
				onCancel={() => {
					setIsOpenCustomer(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				height={"90vh"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleSaveData(data);
				}}
			/>
		</div>
	);
}
