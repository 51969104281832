import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../components/inputAnt/selectAdd";
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import { LiaCheckDoubleSolid } from "react-icons/lia";
import ModalChietKhau from "../modal/ModalChietKhau";
import ModalThanhToan from "../modal/ModalThanhToan";
import { handleTotal } from "../../../../utils/utilsFunc";
dayjs.extend(customParseFormat);
export default function FormHd1({ children, getFieldValue, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
	const [isOpenForm, setIsOpenForm] = useState(false);

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 22 : 18 },
	};

	const [ngayHoaDon, setNgayHoaDon] = useState();
	useEffect(() => {
		if (getFieldValue("ngay_hoa_don") === null) {
			setNgayHoaDon(null);
		} else {
			setNgayHoaDon(
				dayjs(getFieldValue("ngay_hoa_don")).format("DD/MM/YYYY")
			);
		}
	}, [getFieldValue("ngay_hoa_don")]);

	const [dataChietKhau, setDataChietKhau] = useState({});
	const [dataThanhToan, setDataThanhToan] = useState({});

	return (
		<div>
			<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
				{/* field hidden */}
				<Form.Item name="details"></Form.Item>
				<Form.Item name="ghi_so_quy"></Form.Item>

				{/* FormChietKhau */}
				<Form.Item name="ty_le_ck_hd"></Form.Item>
				<Form.Item name="tien_ck_hd"></Form.Item>
				<Form.Item name="t_tien_ck_hd"></Form.Item>
				{/* FormThanhToan */}
				<Form.Item name="da_thanh_toan"></Form.Item>
				<Form.Item name="tien_con_lai"></Form.Item>

				<Form.Item name="ten_kh"></Form.Item>
				<Form.Item name="ho_ten_dem"></Form.Item>
				<Form.Item name="ma_kh"></Form.Item>
				{/* Số ID - Số hoá đơn */}
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="customer"
						q={{
							status: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
						}}
						label="Số ID"
						name="customer_kh"
						valueOption={[
							"ho_ten_dem",
							"ten_kh",
							"ma_kh",
							"dien_thoai",
						]}
						lableOption={["ma_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng chọn Id khách",
							},
						]}
						placeholder="nhập tên khách"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
								setFieldsValue(
									"ho_ten_dem",
									parsedValue?.ho_ten_dem
								);
								setFieldsValue(
									"dien_thoai",
									parsedValue?.dien_thoai ||
										getFieldValue("dien_thoai")
								);
							}
						}}
					/>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Số hoá đơn"
						name="so_ct"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input disabled placeholder="SO_HD" />
					</Form.Item>
				</Col>
				{/* Tên khách - Ngày hoá đơn */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Tên khách"
						name="ten_kh"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input disabled />
					</Form.Item>
				</Col>
				<Form.Item name="ngay_hoa_don"></Form.Item>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Ngày hoá đơn"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<DatePicker
							placeholder="chọn thời gian"
							value={
								ngayHoaDon
									? dayjs(ngayHoaDon, "DD/MM/YYYY")
									: null
							}
							format="DD/MM/YYYY"
							onChange={(date, dateString) => {
								setNgayHoaDon(dayjs(date).format("DD/MM/YYYY"));
								setFieldsValue("ngay_hoa_don", date);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>
				{/* Điện thoại - Mã lịch hẹn */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Điện thoại"
						name="dien_thoai"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						// rules={[
						// 	{
						// 		validator: (_, value, callback) => {
						// 			if (
						// 				value === undefined ||
						// 				value === null ||
						// 				value === ""
						// 			) {
						// 				// Cho phép ô nhập liệu để rỗng
						// 				callback();
						// 			} else if (
						// 				/[0-9]{10,11}/.test(value.toString())
						// 			) {
						// 				// Kiểm tra điều kiện cho số điện thoại (10 hoặc 11 chữ số)
						// 				callback();
						// 			} else {
						// 				callback("Số điện thoại không hợp lệ");
						// 			}
						// 		},
						// 	},
						// ]}
					>
						<Input disabled />
					</Form.Item>
				</Col>
				{/* Nhân viên/bác sĩ */}
				<Form.Item name="ma_nv"></Form.Item>
				<Form.Item name="ten_nv"></Form.Item>
				<Col className="max-sm:hidden" span={isMobile ? 24 : 12}></Col>
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="dmnv"
						fieldsAdd={["ma_nv", "ten_nv"]}
						lableOption={["ten_nv", "ma_nv"]}
						valueOption={["ma_nv", "ten_nv"]}
						label="Bác sĩ"
						name="dmnv_data"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn bác sĩ"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ma_nv", parsedValue?.ma_nv);
								setFieldsValue("ten_nv", parsedValue?.ten_nv);
							}
						}}
					/>
				</Col>
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						label="Mã lịch hẹn"
						name="ma_lich_hen"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input placeholder="Mã chạy tự động" disabled />
					</Form.Item>
				</Col>
				{/* (Chiết khấu - Thanh toán) - trạng thái */}
				{/* <Col span={isMobile ? 24 : 12}>
					<div className="w-full h-full flex justify-end max-lg:justify-center items-center gap-4">
						<Button
							className="!text-[#C63B9F] !border-[#C63B9F] btn_change w-2/5"
							onClick={() => {
								setIsOpenForm("chietkhau");
								setDataChietKhau({
									t_tien_nt:
										handleTotal({
											arr: getFieldValue("details"),
											name: "tien_hang_nt",
										}) || 0,
									ty_le_ck_hd:
										getFieldValue("ty_le_ck_hd") || 0,
									tien_ck_hd:
										(getFieldValue("ty_le_ck_hd") *
											(handleTotal({
												arr: getFieldValue("details"),
												name: "tien_hang_nt",
											}) || 0)) /
											100 || 0,
									t_tien_ck_hd:
										((100 - getFieldValue("ty_le_ck_hd")) *
											(handleTotal({
												arr: getFieldValue("details"),
												name: "tien_hang_nt",
											}) || 0)) /
											100 || 0,
								});
							}}
						>
							<MdOutlineMoneyOffCsred /> Chiết khấu
						</Button>
						<Button
							className="!text-[#0057FF] !border-[#0057FF] btn_change w-2/5"
							onClick={() => {
								setIsOpenForm("thanhtoan");
								setDataThanhToan({
									t_tt_nt:
										handleTotal({
											arr: getFieldValue("details"),
											name: "tien_hang_nt",
										}) || 0,
									da_thanh_toan:
										getFieldValue("da_thanh_toan") || 0,
									tien_con_lai:
										(handleTotal({
											arr: getFieldValue("details"),
											name: "tien_hang_nt",
										}) || 0) -
										(getFieldValue("da_thanh_toan") || 0),
								});
							}}
						>
							<LiaCheckDoubleSolid /> Thanh toán
						</Button>
					</div>
				</Col> */}
				{/* ghi chú */}
				<Col span={isMobile ? 24 : 12}>
					<Form.Item
						name="dien_giai"
						label="Ghi chú khác"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input.TextArea rows={3} placeholder="nhập ghi chú" />
					</Form.Item>
				</Col>
				<Form.Item name="ten_pt_thanh_toan"></Form.Item>
				<Form.Item name="pt_thanh_toan"></Form.Item>
				<Form.Item name="tk_dt"></Form.Item>
				<Col span={isMobile ? 24 : 12} className="!p-0">
					<Col span={24}>
						<Form.Item
							label="Trạng thái"
							name="trang_thai_hd"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Select
								defaultValue={"Chuyển sổ sách"}
								allowClear
								placeholder="Chọn trạng thái"
							>
								{[
									{
										key: "Lập chứng từ",
										value: "Lập chứng từ",
									},
									{
										key: "Chuyển sổ sách",
										value: "Chuyển sổ sách",
									},
								].map((item) => (
									<Option key={item.key} value={item.value}>
										{item.value}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					{/* ptthanhtoan */}
					<Col span={24}>
						<SelectAdd
							api="ptthanhtoan"
							q={{ status: true }}
							label="PTTT"
							name="ptthanhtoan_data"
							lableOption={["ten"]}
							valueOption={["_id", "ten", "tk_dt"]}
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
							placeholder="chọn pttt"
							onChange={(value) => {
								if (value) {
									const parsedValue = JSON.parse(value);
									setFieldsValue(
										"pt_thanh_toan",
										parsedValue?._id
									);
									setFieldsValue(
										"ten_pt_thanh_toan",
										parsedValue?.ten
									);
									setFieldsValue("tk_dt", parsedValue?.tk_dt);
								}
							}}
						/>
					</Col>
				</Col>
			</Row>
			{React.cloneElement(children, { getFieldValue, setFieldsValue })}
			<ModalChietKhau
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				isTabletOrMobile={isTabletOrMobile}
				handleManager={(data) => {
					setIsOpenForm(false);
					setFieldsValue("ty_le_ck_hd", data?.ty_le_ck_hd || 0);
					setFieldsValue("tien_ck_hd", data?.tien_ck_hd || 0);
					setFieldsValue("t_tien_ck_hd", data?.t_tien_ck_hd || 0);
				}}
				data={dataChietKhau}
			/>
			<ModalThanhToan
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				isTabletOrMobile={isTabletOrMobile}
				handleManager={(data) => {
					setIsOpenForm(false);
					setFieldsValue("da_thanh_toan", data?.da_thanh_toan || 0);
					setFieldsValue("tien_con_lai", data?.tien_con_lai || 0);
				}}
				data={dataThanhToan}
			/>
		</div>
	);
}
