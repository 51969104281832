import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isIdApp from "../../utils/isIdApp";

export default function IdApp({ children }) {
	const navigator = useNavigate();
	useEffect(() => {
		if (!isIdApp()) {
			navigator("/app");
		}
	}, []);
	return children;
}
