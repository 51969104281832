import { Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../../components/inputAnt/selectAdd";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function Consultation({ data, setFieldsValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	const [ngayTuVan, setNgayTuVan] = useState();

	useEffect(() => {
		if (data?.ngay_kham === null) {
			setNgayTuVan(null);
		} else {
			setNgayTuVan(dayjs(data?.ngay_kham).format(dateFormatList[0]));
		}
	}, [data?.ngay_kham]);

	return (
		<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
			{/* ngày - người tư vấn */}
			<Form.Item name="ngay_kham"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Ngày"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<DatePicker
						placeholder="chọn ngày"
						value={
							ngayTuVan
								? dayjs(ngayTuVan, dateFormatList[0])
								: null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNgayTuVan(dayjs(date).format(dateFormatList[0]));
							setFieldsValue("ngay_kham", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dmnv"
					fieldsAdd={["ma_nv", "ten_nv"]}
					label="Người tư vấn"
					name="nguoi_tu_van"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn người tư vấn"
				/>
			</Col>

			{/* Điện thoại - Tên  */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Số điện thoại"
					name="dien_thoai"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input
						placeholder="nhập số điện thoại"
					/>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Tên khách"
					name="ten_kh"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input
						placeholder="nhập tên khách"
					/>
				</Form.Item>
			</Col>

			{/* Chẩn đoán - đề xuất */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Chẩn đoán"
					name="chuan_doan"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập chẩn đoán" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Đề xuất"
					name="de_xuat"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập đề xuất" />
				</Form.Item>
			</Col>

			{/* ghi chú */}
			<Col span={24}>
				<Form.Item
					name="ghi_chu"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
						span: 3,
					}}
					wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
