import { Button, Input, Popconfirm, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";
import {
	handleCreate,
	handleDelete,
	handleDeleteArray,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../utils/utilsApi";
import { getVNDateTime, randomChars } from "../../../utils/utilsFunc";
import ModalBasic from "../../components/modal/ModalBasic";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import FormBooking from "../components/form/FormBooking";
import ItemDetail from "../components/item/ItemDetail";
import "../managerServices.scss";
import { debounce } from "lodash";
const { Search } = Input;

export default function Booking() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [trangthai, setTrangthai] = useState(0);
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Họ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ho_ten_dem;
			},
		},
		{
			title: "Tên",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return record?.exfields?.trang_thai_hen;
			},
		},
		{
			title: "Thời gian hẹn",
			dataIndex: "",
			width: 150,
			render: (record) => {
				if (!record?.exfields?.thoi_gian_hen) {
					return "";
				}
				return dayjs(
					getVNDateTime(record?.exfields?.thoi_gian_hen),
					"YYYY-MM-DD HH:mm:ss"
				).format("DD/MM/YYYY HH:mm:ss");
			},
		},
		{
			title: "Mã đặt hẹn",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ma_lich_hen;
			},
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},

		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 100,
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 0,
				...q,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
		setTrangthai(0);
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
		setTrangthai(0);
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "so1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "so1",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataTable({});
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		const dataPTTT = await handleGetDataAndRows({
			api: "ptthanhtoan",
			q: {
				status: true,
				stt: 0,
			},
			setLoading,
		});
		const dataDVCS = await handleGetDataAndRows({
			api: "dvcs",
			q: {
				status: true,
				ten_dvcs: "Công ty",
			},
			setLoading,
		});
		if (dataPTTT) {
			setDataDefault({
				...dataDefault,
				ptthanhtoan: { ...dataPTTT.data[0] },
				dvcs: { ...dataDVCS.data[0] },
			});
		}
	};
	useEffect(() => {
		handleGetDataDefault();
	}, []);

	useEffect(() => {
		handleGetDataTable({ ...params.q });
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ ma_kh: { $regex: value, $options: "i" } },
					{ ten_kh: { $regex: value, $options: "i" } },
					// { ho_ten_dem: { $regex: value, $options: "i" } },
					{ dien_thoai: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataTable({
			$or: [
				{ ma_kh: { $regex: value, $options: "i" } },
				{ ten_kh: { $regex: value, $options: "i" } },
				// { ho_ten_dem: { $regex: value, $options: "i" } },
				{ dien_thoai: { $regex: value, $options: "i" } },
			],
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
											shape="circle"
											size="small"
											className="btn_ternary !border-none"
										>
											<TfiReload />
										</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<p>Tìm</p>
									<Search
										placeholder="Nhập Id, tên hoặc số điện thoại"
										onChange={(e) =>
											DebouncedSearch(e.target.value)
										}
										allowClear
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenForm("create");
								setDataDetail({});
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayId(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên hoặc số điện thoại"
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>

				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Đặt lịch hẹn
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setIsOpenForm("edit");
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>

			<ModalBasic
				zIndex={1035}
				id={"Booking-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen || "Chưa đến",
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							trang_thai_hd: "Lập chứng từ",
						},
						pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
						ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
						ma_kho: "KCTY",
						ten_kho: "Kho công ty",
						ma_dvcs: dataDefault?.dvcs?._id,
						tk_cn_thanhtoan: dataDefault?.ptthanhtoan?.tk_cn,
						trang_thai: trangthai,
					});
					setIsOpenForm(false);
				}}
				data={{
					ma_lich_hen: randomChars(4),
					thoi_gian_hen: new Date().toISOString(),
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"Booking-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						trang_thai: trangthai,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: data?.nguon_tao || "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							// ten_dvcs: data?.ten_dvcs,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					ptthanhtoan_data: dataDetail?.ten_pt_thanh_toan,
					customer_kh:
						(dataDetail?.ten_kh || "") +
						" - " +
						(dataDetail?.ma_kh || ""),
					dmkho_data: dataDetail?.ten_kho,
					dvcs_data: dataDetail?.exfields?.ten_dvcs,
					trang_thai_hen:
						dataDetail?.exfields?.trang_thai_hen || "Chưa đến",
					loai_lich_hen: dataDetail?.exfields?.loai_lich_hen,
					nguoi_dat: dataDetail?.exfields?.nguoi_dat,
					nguon_tao: dataDetail?.exfields?.nguon_tao,
					ho_ten_dem: dataDetail?.exfields?.ho_ten_dem,
					ma_lich_hen:
						dataDetail?.exfields?.ma_lich_hen || randomChars(4),
					ten_dvcs: dataDetail?.exfields?.ten_dvcs,
					thoi_gian_hen: dataDetail?.exfields?.thoi_gian_hen || null,
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>
		</div>
	);
}
