import { Button, Collapse, Form, Menu } from "antd";
import React, { useEffect, useRef } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import PopupModel from "./popupModel";
import "./model.scss";

export default function ModalFull({
	idModal,
	title,
	open,
	onCancel,
	formSections,
	footer,
	width,
	height = "auto",
	style,
	handleManager,
	data = {},
	groupButton,
	itemFooter,
}) {
	const formRef = useRef(null);

	useEffect(() => {
		formRef?.current?.setFieldsValue(data);
	}, [data]);

	const onFinish = (values) => {
		handleManager(values);
		onReset();
	};
	const onReset = () => {
		formRef.current?.resetFields();
	};
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const scrollTo = (id) => {
		const el = document.getElementById(id);
		el.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};

	const getFieldValue = (name) => {
		return formRef?.current?.getFieldValue(name);
	};
	const setFieldsValue = (name, value) => {
		formRef?.current?.setFieldsValue({ [name]: value });
	};

	return (
		<PopupModel
			title={title}
			open={open}
			onCancel={onCancel}
			footer={footer}
			width={width}
			style={style}
			height={height}
		>
			<Form
				ref={formRef}
				name="control-ref-full"
				onFinish={onFinish}
				layout={isTabletOrMobile ? "vertical" : "horizontal"}
				// onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} // prevent submit on enter
				labelWrap
				className="mdf_content"
			>
				<div className="mdf_container">
					<Menu
						defaultSelectedKeys={[formSections[0].id] + idModal}
						mode={isTabletOrMobile ? "horizontal" : "vertical"}
						style={{ width: isTabletOrMobile ? "100%" : 200 }}
						items={formSections.map(({ id, title }) => ({
							key: id + idModal,
							label: title,
						}))}
						onClick={({ key }) => scrollTo(key)}
					/>

					<div className="mdf_content">
						{formSections.map(({ id, title, content }) => (
							<Collapse
								key={id + idModal}
								defaultActiveKey={["1"]}
								size="small"
								expandIcon={({ isActive }) => (
									<HiChevronDown
										style={{
											transition: "all 0.3s ease-in-out",
											transform: `rotate(${
												isActive ? 0 : -90
											}deg)`,
										}}
									/>
								)}
							>
								<Collapse.Panel
									header={title}
									key="1"
									id={id + idModal}
								>
									{React.cloneElement(content, {
										getFieldValue,
										setFieldsValue,
									})}
								</Collapse.Panel>
							</Collapse>
						))}
					</div>
				</div>
				<div className="w-full justify-between flex items-center flex-row-reverse">
					<div className="flex justify-end items-center gap-4 py-2 px-10 max-sm:px-1">
						{groupButton && groupButton?.length > 0 ? (
							groupButton.map((item, index) => (
								<div key={index}>{item}</div>
							))
						) : (
							<>
								<Button className="btn_error" onClick={onReset}>
									Làm mới
								</Button>
								<Button
									htmlType="submit"
									className="btn_primary"
								>
									Lưu
								</Button>
							</>
						)}
					</div>
					{itemFooter}
				</div>
			</Form>
		</PopupModel>
	);
}
