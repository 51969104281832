import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { BsPersonPlus } from "react-icons/bs";
import { LuEye, LuPencil, LuX } from "react-icons/lu";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import Consultation from "../modal/modalItem/Consultation";
import {
	handleCreate,
	handleDelete,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../../utils/utilsApi";
import { useMediaQuery } from "react-responsive";

export default function History({ dataModal, messageApi }) {
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	// modal
	const [isOpenConsultation, setIsOpenConsultation] = useState(false);
	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenConsultation("edit");
									setDetailHistory({
										...record,
									});
									setNgayThietLap({
										...ngaythieplap,
										ngay_kham: record.ngay_kham || null,
									});
								}}
							>
								<LuEye size={18} />
							</Button>
							<Button
								className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
								shape="circle"
								size="small"
								onClick={(e) => {
									e.preventDefault();
									setIsOpenConsultation("edit");
									setDetailHistory({
										...record,
									});
									setNgayThietLap({
										...ngaythieplap,
										ngay_kham: record.ngay_kham || null,
									});
								}}
							>
								<LuPencil size={18} />
							</Button>
							<Popconfirm
								title={`Bạn muốn xoá lịch sử tư vấn ?`}
								onConfirm={(e) => {
									e.stopPropagation();
									handleDeleteId(record._id);
								}}
								onCancel={(e) => e.stopPropagation()}
								okText="Có"
								cancelText="Không"
							>
								<Button
									className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
									shape="circle"
									size="small"
									onClick={(e) => e.stopPropagation()}
								>
									<LuX size={18} />
								</Button>
							</Popconfirm>
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Ngày",
			dataIndex: "ngay_kham",
			render: (datetime) => {
				return datetime
					? new Date(datetime).toLocaleDateString("vi-VN")
					: null;
			},
			width: 100,
		},
		{
			title: "Người tư vấn",
			dataIndex: "bac_si",
			width: 150,
		},
		{
			title: "Chẩn đoán",
			dataIndex: "chuan_doan",
			width: 150,
		},
		{
			title: "Đề xuất",
			dataIndex: "dien_giai",
			width: 150,
		},
		{
			title: "Ghi chú",
			dataIndex: "ly_do",
			width: 150,
		},
	];

	const [loading, setLoading] = useState(false);
	const [dataHistory, setDataHistory] = useState([]);
	const [detailHistory, setDetailHistory] = useState({});
	const [ngaythieplap, setNgayThietLap] = useState(null);

	const handleGetData = async (q) => {
		const data = await handleGetDataAndRows({
			api: "salelog",
			q: {
				...q,
			},
			page: 1,
			limit: 100,
			setLoading,
		});
		if (data) {
			setDataHistory(data.data);
		}
	};

	const handleSaveData = async (data) => {
		console.log(1);
		await handleCreate({
			api: "salelog",
			data,
			messageApi,
			setLoading,
		});
		await handleGetData({ id_kh: dataModal?._id });
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "salelog",
			data,
			messageApi,
			setLoading,
		});
		await handleGetData({ id_kh: dataModal?._id });
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "salelog",
			id,
			messageApi,
			setLoading,
		});
		await handleGetData({ id_kh: dataModal?._id });
	};

	useEffect(() => {
		if (dataModal?._id && dataModal?._id !== "") {
			handleGetData({
				id_kh: dataModal?._id,
			});
		}
	}, [dataModal?._id]);

	return (
		<div className="relative">
			<TableManagerCus
				id="datahistory-tab"
				loading={loading}
				data={dataHistory}
				columns={columns}
				scroll={{ x: 900, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenConsultation("edit");
						setDetailHistory({
							...record,
						});
						setNgayThietLap({
							...ngaythieplap,
							ngay_kham: record.ngay_kham || new Date(),
						});
					},
				})}
			/>
			<Button
				onClick={() => {
					setIsOpenConsultation("create");
					setNgayThietLap({
						ngay_kham: new Date(),
					});
				}}
				className="btn_error !text-[#F92759] absolute bottom-3"
			>
				<BsPersonPlus />
				Thêm
			</Button>
			<ModalBasic
				id={"modalHistory-edit"}
				title={"Phiếu tư vấn bệnh nhân"}
				open={isOpenConsultation === "edit" ? true : false}
				onCancel={() => {
					setIsOpenConsultation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleUpdateData({
						_id: detailHistory._id,
						bac_si: data.nguoi_tu_van,
						chuan_doan: data.chuan_doan,
						dien_giai: data.de_xuat,
						ly_do: data.ghi_chu,
						ten_kh: data.ten_kh,
						ngay_kham: ngaythieplap.ngay_kham || new Date(),
						exfields: {
							...detailHistory.exfields,
							dien_thoai: data?.dien_thoai,
						},
					});
					setIsOpenConsultation(false);
				}}
				data={{
					nguoi_tu_van: detailHistory?.bac_si,
					chuan_doan: detailHistory?.chuan_doan,
					de_xuat: detailHistory?.dien_giai,
					ghi_chu: detailHistory?.ly_do,
					dien_thoai: detailHistory?.exfields?.dien_thoai,
					ten_kh: detailHistory?.ten_kh,
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<Consultation data={ngaythieplap} />
			</ModalBasic>

			<ModalBasic
				id={"modalHistory"}
				title={"Phiếu tư vấn bệnh nhân"}
				open={isOpenConsultation === "create" ? true : false}
				onCancel={() => {
					setIsOpenConsultation(false);
				}}
				footer={null}
				handleManager={(data) => {
					handleSaveData({
						bac_si: data.nguoi_tu_van,
						chuan_doan: data.chuan_doan,
						dien_giai: data.de_xuat,
						ly_do: data.ghi_chu,
						id_kh: dataModal?._id,
						ten_kh: data?.ten_kh,
						exfields: {
							ma_kh: dataModal?.ma_kh,
							dien_thoai: data?.dien_thoai,
						},
						ngay_kham: ngaythieplap.ngay_kham || new Date(),
					});
					setIsOpenConsultation(false);
				}}
				data={{
					ten_kh: dataModal?.ten_kh,
					dien_thoai: dataModal?.dien_thoai,
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
			>
				<Consultation data={ngaythieplap} />
			</ModalBasic>
		</div>
	);
}
