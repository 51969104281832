import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import { randomChars } from "../../../../utils/utilsFunc";
import { Button } from "antd";
import FormHd1 from "../form/FormHd1";
import ItemDetail from "../item/ItemDetail";
import dayjs from "dayjs";

export default function ModalHd1({
	isOpenForm,
	setIsOpenForm,
	isTabletOrMobile,
	dataDefault,
	dataDetail,
	handleSaveData,
	handleUpdateData,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"hd1-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ngay_hoa_don: data.ngay_hoa_don || new Date(),
							trang_thai_hd:
								data?.trang_thai_hd || "Chuyển sổ sách",
							ghi_so_quy: data?.ghi_so_quy || null,
							pt_thanh_toan: data?.pt_thanh_toan,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							tk_dt: data?.tk_dt,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ma_lich_hen: data?.ma_lich_hen,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							da_thanh_toan: data?.da_thanh_toan,
							t_tien_ck_hd: data?.t_tien_ck_hd,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
						},
						ma_kho: "KCTY",
						ten_kho: "Kho công ty",
						ma_dvcs: dataDefault?.dvcs?._id,
						tk_cn_thanhtoan: dataDefault?.ptthanhtoan?.tk_cn,
						trang_thai: 8,
					});
					setIsOpenForm(false);
				}}
				data={{
					ma_lich_hen: randomChars(4),
					trang_thai_hd: "Chuyển sổ sách",
					ptthanhtoan_data: dataDefault?.ptthanhtoan?.ten,
					ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
					tk_dt: dataDefault?.ptthanhtoan?.tk_dt,
					pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
					ngay_hoa_don: new Date(),
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>
								{
									JSON.parse(
										localStorage.getItem("current_user")
									)?.name
								}
							</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(new Date()).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormHd1>
					<ItemDetail />
				</FormHd1>
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"hd1-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn dịch vụ</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ngay_hoa_don: data.ngay_hoa_don,
							trang_thai_hd: data?.trang_thai_hd,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: data?.nguon_tao || "Lập phiếu tay",
							ghi_so_quy: data?.ghi_so_quy || null,
							ho_ten_dem: data?.ho_ten_dem,
							ma_lich_hen: data?.ma_lich_hen,
							da_thanh_toan: data?.da_thanh_toan,
							t_tien_ck_hd: data?.t_tien_ck_hd,
							ma_nv: data?.ma_nv,
							ten_nv: data?.ten_nv,
							// ten_dvcs: data?.ten_dvcs,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					customer_kh: dataDetail?.ma_kh,
					dmkho_data: dataDetail?.ten_kho,
					dvcs_data: dataDetail?.exfields?.ten_dvcs,
					trang_thai_hd:
						dataDetail?.exfields?.trang_thai_hd || "Chuyển sổ sách",
					ptthanhtoan_data: dataDetail?.exfields?.ten_pt_thanh_toan,
					loai_lich_hen: dataDetail?.exfields?.loai_lich_hen,
					nguoi_dat: dataDetail?.exfields?.nguoi_dat,
					nguon_tao: dataDetail?.exfields?.nguon_tao,
					ho_ten_dem: dataDetail?.exfields?.ho_ten_dem,
					ma_lich_hen: dataDetail?.exfields?.ma_lich_hen,
					ten_dvcs: dataDetail?.exfields?.ten_dvcs,
					ngay_hoa_don: dataDetail?.exfields?.ngay_hoa_don,
					da_thanh_toan: dataDetail?.exfields?.da_thanh_toan,
					t_tien_ck_hd: dataDetail?.exfields?.t_tien_ck_hd,
					ghi_so_quy: dataDetail?.exfields?.ghi_so_quy || null,
					dmnv_data:
						(dataDetail?.ten_nv || "") +
						" - " +
						(dataDetail?.ma_nv || ""),
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 max-md:ml-0 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>{dataDetail?.name_user_created}</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(
									dataDetail?.date_created,
									"YYYY-MM-DD"
								).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormHd1>
					<ItemDetail />
				</FormHd1>
			</ModalBasic>
		</div>
	);
}
