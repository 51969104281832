import { Button, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
	handleExportFile,
	handleGetData,
	handleGetDataById,
	handleUpdate,
} from "../../../utils/utilsApi";
import ModalHd1Voucher from "../../ManagerServices/components/modal/ModalHd1Voucher";
import FormInfomation from "../../ManagerVouchers/components/Form/FormInfomation";
import ItemDetailPt from "../../ManagerVouchers/components/Item/ItemDetailPt";
import ItemTdttnosPt from "../../ManagerVouchers/components/Item/ItemTdttnosPt";
import ModalPtVoucher from "../../ManagerVouchers/components/Modal/ModalPtVoucher";
import FilterReport from "../../components/filter/FilterReport";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import "../managerReports.scss";

export default function CustomerReport() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: "Loại chứng từ",
			dataIndex: "ma_ct",
			width: 100,
			render: (ma_ct) => {
				if (ma_ct === "PC1") return <span>Chi</span>;
				else if (ma_ct === "PT1") return <span>Thu</span>;
				else return <span>{ma_ct}</span>;
			},
		},
		{
			title: "Ngày chứng từ",
			dataIndex: "ngay_ct",
			width: 100,
			render: (ngay_ct) => {
				if (!ngay_ct) return null;
				else return dayjs(ngay_ct).format("DD/MM/YYYY");
			},
		},
		{
			title: "Số chứng từ",
			dataIndex: "so_ct",
			width: 100,
		},
		{
			title: "Mã khách hàng",
			dataIndex: "ma_kh",
			width: 100,
		},
		{
			title: "Diễn giải",
			dataIndex: "dien_giai",
			width: 200,
			render: (dien_giai, record) => {
				if (record.bold)
					return <span className="font-bold">{dien_giai}</span>;
				else {
					if (!dien_giai)
						return (
							<span className="empty-text">
								Chưa nhập diễn giải
							</span>
						);
					return <div>{dien_giai}</div>;
				}
			},
		},
		{
			title: "PS nợ",
			dataIndex: "ps_no",
			width: 100,
			render: (ps_no, record) => {
				if (record.bold) {
					if (!ps_no) return null;
					return (
						<span className="font-bold">
							{ps_no?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					if (!ps_no) return null;
					return ps_no?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "PS có",
			dataIndex: "ps_co",
			width: 100,
			render: (ps_co, record) => {
				if (record.bold) {
					if (!ps_co) return null;
					return (
						<span className="font-bold">
							{ps_co?.toLocaleString("vi-VN", {
								style: "currency",
								currency: "VND",
							})}
						</span>
					);
				} else {
					if (!ps_co) return null;
					return ps_co?.toLocaleString("vi-VN", {
						style: "currency",
						currency: "VND",
					});
				}
			},
			align: "right",
		},
		{
			title: "Ngoại tệ",
			dataIndex: "ma_nt",
			width: 100,
		},
	];

	const [dataFilter, setDataFilter] = useState({});
	const [ngaythieplap, setNgaythieplap] = useState({
		tu_ngay: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		den_ngay: new Date(),
	});
	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataTable = async (newParams) => {
		console.log("newParams", newParams);
		const data = await handleGetData({
			api: "sctcnkh",
			params: {
				...newParams,
			},
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
		}
	};

	const handleManagerFilter = (value) => {
		setDataFilter({
			tk: value?.tk,
			ten_tk: value?.ten_tk,
			ma_kh: value?.ma_kh,
			ten_kh: value?.ten_kh,
		});
		handleGetDataTable({
			tk: value?.tk,
			ten_tk: value?.ten_tk,
			ma_kh: value?.ma_kh,
			ten_kh: value?.ten_kh,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
	};
	const handleGetDataDefault = async () => {
		const dataAccount = await handleGetData({
			api: "account",
			q: {
				tk: "131",
			},
			setLoading,
		});
		const dataRpt = await handleGetData({
			api: "rpt",
			q: {
				ma_cn: "SCTCNKH",
			},
			setLoading,
		});
		if (dataAccount) {
			setDataDefault({
				...dataDefault,
				account: { ...dataAccount.data[0] },
				dataRpt: { ...dataRpt.data[0] },
			});
			setDataFilter({
				...dataFilter,
				ten_tk: dataAccount.data[0]?.ten_tk,
				tk: dataAccount.data[0]?.tk,
			});
		}
	};

	useEffect(() => {
		handleGetDataDefault();
	}, []);

	const [isOpenFormItemVoucher, setIsOpenFormItemVoucher] = useState(false);
	const [dataItemVoucher, setDataItemVoucher] = useState({});
	const [loadingItem, setLoadingItem] = useState(false);
	const handleOnRowClick = async (record) => {
		const dataVoucher = await handleGetDataById({
			api: record.ma_ct.toLowerCase(),
			id: record.id_ct,
			setLoading: setLoadingItem,
		});
		setDataItemVoucher(dataVoucher);
		setIsOpenFormItemVoucher(record.ma_ct.toLowerCase());
	};

	const handleExportExcel = async () => {
		try {
			const response = await handleExportFile({
				api: "sctcnkh",
				params: {
					id_rpt: dataDefault?.dataRpt?._id,
					...dataFilter,
					tu_ngay: ngaythieplap.tu_ngay,
					den_ngay: ngaythieplap.den_ngay,
				},
				messageApi,
			});

			const fileType =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";

			if (response) {
				const blob = new Blob([response], { type: fileType });
				const url = window.URL.createObjectURL(blob);

				// Create a hidden <a> element
				const a = document.createElement("a");
				a.href = url;
				a.download =
					`DoanhThuKhachHang-${dayjs(new Date()).format(
						"DD-MM-YYYY"
					)}` + fileExtension;

				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);

				window.URL.revokeObjectURL(url);
			} else {
				console.error("No data found in the response");
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleManagerData = async (data) => {
		await handleUpdate({
			api: data?.collection_name,
			data,
			messageApi,
			setLoading: setLoadingItem,
		});
		await handleGetDataTable({
			...dataFilter,
			tu_ngay: ngaythieplap.tu_ngay,
			den_ngay: ngaythieplap.den_ngay,
		});
		setIsOpenFormItemVoucher(false);
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
										shape="circle"
										size="small"
										className="btn_ternary !border-none"
									>
										<TfiReload />
									</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[250px]">
									<FilterReport
										data={{
											tk:
												dataFilter?.tk ||
												dataDefault.account?.tk,
											ten_tk:
												dataFilter?.ten_tk ||
												dataDefault.account?.ten_tk,
											tk_co_data:
												(dataFilter?.tk ||
													dataDefault.account?.tk) +
												" - " +
												(dataFilter?.ten_tk ||
													dataDefault.account
														?.ten_tk),
											// ma_kh: dataFilter?.ma_kh,
											// ten_kh: dataFilter?.ten_kh,
											// customer_data:
											// 	dataFilter?.ma_kh +
											// 	" - " +
											// 	dataFilter?.ten_kh,
										}}
										ngaythieplap={ngaythieplap}
										setNgaythieplap={setNgaythieplap}
										handleManager={handleManagerFilter}
										account={true}
										customer={true}
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<Button onClick={handleExportExcel} className="btn_ternary">
						<CiExport />
						<span className="max-lg:!hidden">Export</span>
					</Button>
					<span>
						Kỳ đang xem:{" "}
						{dayjs(ngaythieplap.tu_ngay).format("DD/MM/YYYY")} -{" "}
						{dayjs(ngaythieplap.den_ngay).format("DD/MM/YYYY")}
					</span>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Doanh thu{" "}
						{dataFilter?.ten_kh ? (
							<span className="text-[#186b9e] underline">
								{dataFilter?.ten_kh}
							</span>
						) : (
							"khách hàng"
						)}{" "}
						(VND)
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
					rowSelection: null,
				}}
				onRow={(record) => ({
					onClick: () => {
						handleOnRowClick(record);
					},
				})}
			/>
			<ModalPtVoucher
				SoQuy={true}
				dataDetail={dataItemVoucher}
				handleManagerData={handleManagerData}
				isOpenForm={isOpenFormItemVoucher === "pt1" ? "edit" : false}
				setIsOpenForm={setIsOpenFormItemVoucher}
				formSections={[
					{
						id: "thongtin1",
						title: "Thông tin",
						content: <FormInfomation pt1={true} />,
					},
					{
						id: "thutheokhachhang",
						title: "Thu theo khách hàng",
						content: <ItemDetailPt />,
					},
					{
						id: "thutheohoadon",
						title: "Thu theo hoá đơn",
						content: <ItemTdttnosPt messageApi={messageApi} />,
					},
				]}
			/>
			<ModalHd1Voucher
				isOpenForm={isOpenFormItemVoucher === "hd1" ? "edit" : false}
				setIsOpenForm={setIsOpenFormItemVoucher}
				isTabletOrMobile={isTabletOrMobile}
				dataDefault={dataDefault}
				dataDetail={dataItemVoucher}
				handleUpdateData={handleManagerData}
			/>
		</div>
	);
}
