import { Button } from "antd";
import dayjs from "dayjs";
import React from "react";
import { randomChars } from "../../../../utils/utilsFunc";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormPn2 from "../form/FormPn2";
import ItemDetailPn2 from "../item/ItemDetailPn2";

export default function ModalPn2({
	isOpenForm,
	setIsOpenForm,
	isTabletOrMobile,
	dataDefault,
	dataDetail,
	handleSaveData,
	handleUpdateData,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"hd1-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn chi</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...data,
						exfields: {
							ten_kho: data?.ten_kho,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					currency_data: "VND - 1",
					ma_nt: "VND",
					ty_gia: 1,
					ten_nt: "VND",
					tk_co: "331",
					ten_tk_co: "Phải trả cho người bán",
					dvcs_data: dataDefault?.dvcs?.ten_dvcs,
					ma_dvcs: dataDefault?.dvcs?._id,
					ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
					ma_kho: dataDefault?.dmkho?.ma_kho,
					ten_kho: dataDefault?.dmkho?.ten_kho,
					dmkho_data:
						dataDefault?.dmkho?.ten_kho +
						" - " +
						dataDefault?.dmkho?.ma_kho,
					ngay_ct: new Date(),
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>
								{
									JSON.parse(
										localStorage.getItem("current_user")
									)?.name
								}
							</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(new Date()).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormPn2>
					<ItemDetailPn2 />
				</FormPn2>
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"hd1-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu hoá đơn chi</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							ten_kho: data?.ten_kho,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					currency_data:
						dataDetail?.ma_nt + " - " + dataDetail?.ty_gia,
					dvcs_data: dataDetail?.ten_dvcs,
					dmkho_data:
						(dataDetail?.exfields?.ten_kho || "") +
						" - " +
						dataDetail?.ma_kho,
					ten_kho: dataDetail?.exfields?.ten_kho,
					customer_data:
						dataDetail?.ten_kh + " - " + dataDetail?.ma_kh,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				itemFooter={
					<div className="ml-20 max-md:ml-0 text-xs flex flex-col justify-center items-start">
						<div>
							<span>Người tạo: </span>
							<span>{dataDetail?.name_user_created}</span>
						</div>
						<div>
							<span>Ngày tạo: </span>
							<span>
								{dayjs(
									dataDetail?.date_created,
									"YYYY-MM-DD"
								).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				}
			>
				<FormPn2>
					<ItemDetailPn2 />
				</FormPn2>
			</ModalBasic>
		</div>
	);
}
