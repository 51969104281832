import React from "react";
import { DefaultCompany } from "../../utils/images";
import { Button, Dropdown } from "antd";
import { LiaEllipsisHSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { request } from "../../utils/request";

export default function ItemCompany({ item, key }) {
	const navigate = useNavigate();
	const handleChooseApp = async () => {
		localStorage.setItem("id_app", item._id);
		localStorage.setItem("name_app", item.name);
		try {
			const reponse = await request.get(
				`api/clienturl/?access_token=flex.public.token&q={"idclient":"${item._id}"}`,
				{
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "application/json",
					},
				}
			);
			console.log(reponse?.data[0]?.url);
			localStorage.setItem(item._id, reponse?.data[0]?.url);
		} catch (error) {
			console.log(error.message);
		}
		navigate("/");
	};
	const items = [
		{
			key: "1",
			label: (
				<div
					onClick={() => {
						alert("Đang cập nhật");
					}}
				>
					Chi tiết công ty
				</div>
			),
		},
		{
			key: "2",
			label: (
				<div
					onClick={() => {
						alert("Đang cập nhật");
					}}
				>
					Rời công ty
				</div>
			),
		},
		{
			key: "3",
			label: (
				<div
					onClick={() => {
						alert("Đang cập nhật");
					}}
				>
					Xoá
				</div>
			),
		},
	];
	return (
		<div
			key={key}
			className="bg-white shadow-lg rounded-md w-[calc(100%/5-16px)] max-lg:w-[calc(100%/3-16px)] max-md:w-[calc(100%/2-16px)] max-sm:w-4/5 max-sm:justify-center min-w-[250px] mx-2 my-4 flex flex-col justify-center items-center p-4 cursor-pointer hover:scale-105 transition-all border border-[#fff] hover:border-[#09395e]"
			onClick={handleChooseApp}
		>
			<div className="flex justify-end w-full">
				<Dropdown
					menu={{
						items,
					}}
					placement="bottomRight"
				>
					<Button
						className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
						shape="circle"
					>
						<LiaEllipsisHSolid size={22} />
					</Button>
				</Dropdown>
			</div>
			<div>
				<img
					src={
						item?.logo
							? `https://api.fostech.vn/${item?.logo}`
							: DefaultCompany
					}
					alt="Logo company"
					className="w-2/3 mx-auto object-contain min-h-[200px]"
				/>
			</div>
			<hr className="py-2 w-full h-[1px]" />
			<div className="text-center text-[#09395e] text-lg font-semibold">
				{item?.name}
			</div>
		</div>
	);
}
