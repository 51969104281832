import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../../components/inputAnt/selectAdd";
import UploadImageAuto from "../../../../components/upLoad/uploadImageAuto";
import UploadImage from "../../../../components/upLoad/uploadImage";
import ItemImage from "../../item/itemImage";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function PatientDataExtraction({
	setFieldsValue,
	getFieldValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [ngayKham, setNgayKham] = useState();
	const [dataImage, setDataImage] = useState([]);

	useEffect(() => {
		setDataImage(getFieldValue("picture_xquang") || []);
		if (getFieldValue("ngay_kham") === null) {
			setNgayKham(null);
		} else {
			setNgayKham(
				dayjs(getFieldValue("ngay_kham")).format(dateFormatList[0])
			);
		}
	}, [getFieldValue("so_phieu")]);

	// Save image
	const handleSaveImage = (data) => {
		setDataImage([
			...dataImage,
			{
				link: `https://api.goodapp.vn${data}`,
			},
		]);
		setFieldsValue("picture_xquang", [
			...dataImage,
			{
				link: `https://api.goodapp.vn${data}`,
			},
		]);
	};

	//delete image
	const handleDeleteImage = (data) => {
		setDataImage(dataImage.filter((item) => item.link !== data));
		setFieldsValue(
			"picture_xquang",
			dataImage.filter((item) => item.link !== data)
		);
	};

	return (
		<Row className="px-10 !m-0 max-lg:px-0" gutter={[8, 8]}>
			{/* lý do khám - ngày khám - số phiếu - bác sĩ */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Lý do khám"
					name="ly_do_kham"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập lý do khám" />
				</Form.Item>
			</Col>
			<Form.Item name="ngay_kham"></Form.Item>
			<Col span={isMobile ? 24 : 12} className="!px-0">
				<Col span={24}>
					<Form.Item
						label="Ngày khám"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<DatePicker
							placeholder="chọn ngày"
							value={
								ngayKham
									? dayjs(ngayKham, dateFormatList[0])
									: null
							}
							format={dateFormatList}
							onChange={(date, dateString) => {
								setNgayKham(
									dayjs(date).format(dateFormatList[0])
								);
								setFieldsValue("ngay_kham", date);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						label="Số phiếu"
						name="so_phieu"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input placeholder="Số phiếu tự động" disabled={true} />
					</Form.Item>
				</Col>
				<Col span={24}>
					<SelectAdd
						api="dmnv"
						fieldsAdd={["ma_nv", "ten_nv"]}
						label="Bác sĩ"
						name="bac_si"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn bác sĩ"
					/>
				</Col>
			</Col>

			{/* Chẩn đoán - điều trị */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Chẩn đoán"
					name="chuan_doan"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập chẩn đoán" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Điều trị"
					name="dieu_tri"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập điều trị" />
				</Form.Item>
			</Col>

			{/* Kế hoạch - Lab - Implant */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Kế hoạch"
					name="ke_hoach"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập kế hoạch" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12} className="!p-0">
				<Col span={24}>
					{/* <Form.Item
						label="Lab"
						name="lab"
						labelCol={{
							className: "min-w-[115px]",
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input.TextArea rows={1} placeholder="nhập lab" />
					</Form.Item> */}
					<SelectAdd
						api="dmvt"
						valueOption={["ten_vt"]}
						lableOption={["ten_vt"]}
						q={{
							status: true,
							"exfields.loai_vat_tu": "Lab",
						}}
						label="Lab"
						name="lab"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn lab"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("lab", parsedValue?.ten_vt);
							}
						}}
					/>
				</Col>
				<Col span={24}>
					{/* <Form.Item
						label="Implant"
						name="implant"
						labelCol={{
							className: "min-w-[115px]",
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Input.TextArea rows={1} placeholder="nhập implant" />
					</Form.Item> */}
					<SelectAdd
						api="dmvt"
						valueOption={["ten_vt"]}
						lableOption={["ten_vt"]}
						q={{
							status: true,
							"exfields.loai_vat_tu": "Implant",
						}}
						label="Implant"
						name="implant"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn implant"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("implant", parsedValue?.ten_vt);
							}
						}}
					/>
				</Col>
			</Col>
			{/* <Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Phim X-quang"
					name="picture_xquang"
					labelCol={{
						className: "min-w-[115px]",
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<UploadImageAuto
						dataImage={dataImage}
						handleSetFieldsValue={handleSetFieldsValue}
					/>
				</Form.Item>
			</Col> */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Báo giá"
					name="bao_gia"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VNĐ</div>
						}
						min={0}
					/>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Thực thu"
					name="thuc_thu"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VNĐ</div>
						}
						min={0}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Phim X-quang"
					name="picture_xquang"
					labelCol={{
						...layout.labelCol,
						span: 3,
					}}
					wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
				>
					<div className="flex flex-wrap justify-start items-start gap-4">
						<UploadImage handleGetPictureModal={handleSaveImage} />
						{dataImage?.map((item, index) => {
							return (
								<ItemImage
									key={index}
									data={item.link}
									handleDelete={handleDeleteImage}
								/>
							);
						})}
					</div>
				</Form.Item>
			</Col>
		</Row>
	);
}
