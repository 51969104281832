import { Input } from "antd";
import React from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ModalFull from "../../../components/modal/ModalFull";

export default function ModalPcVoucher({
	dataDetail,
	handleManagerData,
	isOpenForm,
	setIsOpenForm,
	formSections,
	dataDefault,
}) {
	return (
		<div>
			<ModalFull
				idModal={"pcvoucher-edit"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu chi</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail?.exfields,
							ten_trang_thai_moi: data?.ten_trang_thai_moi,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							pt_thanh_toan: data?.pt_thanh_toan,
						},
					});
				}}
				data={{
					...dataDetail,
					trangthai_data: dataDetail?.exfields?.ten_trang_thai_moi,
					ten_trang_thai_moi:
						dataDetail?.exfields?.ten_trang_thai_moi,
					dvcs_data: dataDetail?.ten_dvcs,
					customer_data:
						dataDetail?.ten_kh + " - " + dataDetail?.ma_kh,
					dmkho_data:
						(dataDetail?.ten_kho || "") +
						" - " +
						(dataDetail?.ma_kho || ""),
					currency_data:
						(dataDetail?.ma_nt || "") +
						" - " +
						(dataDetail?.ty_gia || ""),
					ma_nt: dataDetail?.ma_nt,
					ty_gia: dataDetail?.ty_gia,
					ten_nt: dataDetail?.ten_nt,
					ptthanhtoan_data: dataDetail?.exfields?.ten_pt_thanh_toan,
					ten_pt_thanh_toan: dataDetail?.exfields?.ten_pt_thanh_toan,
					pt_thanh_toan: dataDetail?.exfields?.pt_thanh_toan,
				}}
			/>

			<ModalFull
				idModal={"pcvoucher-create"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu chi</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...data,
						exfields: {
							ten_trang_thai_moi: data?.ten_trang_thai_moi,
							ten_pt_thanh_toan: data?.ten_pt_thanh_toan,
							pt_thanh_toan: data?.pt_thanh_toan,
						},
					});
				}}
				data={{
					trangthai_data: "Ghi sổ quỹ",
					trang_thai: "5",
					ten_trang_thai_moi: "Ghi sổ quỹ",
					currency_data: "VND - 1",
					ma_nt: "VND",
					ty_gia: 1,
					ten_nt: "VND",
					tk_co: "1111",
					ten_tk_co: "Tiền Việt Nam",
					dvcs_data: dataDefault?.dvcs?.ten_dvcs,
					ma_dvcs: dataDefault?.dvcs?._id,
					ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
					ngay_ct: new Date(),
					ptthanhtoan_data: dataDefault?.ptthanhtoan?.ten,
					ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
					pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
				}}
			/>
		</div>
	);
}
