import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { getVNDateTime, randomChars } from "../../../../utils/utilsFunc";
import FormBooking from "../../../ManagerServices/components/form/FormBooking";
import ItemDetail from "../../../ManagerServices/components/item/ItemDetail";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import { HiPhoneArrowUpRight } from "react-icons/hi2";
import {
	handleCreate,
	handleDelete,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../../utils/utilsApi";
import { BsPersonPlus } from "react-icons/bs";
import { TfiReload } from "react-icons/tfi";

export default function CustomerBookings({ dataModal, messageApi }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [trangthai, setTrangthai] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Họ",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ho_ten_dem;
			},
		},
		{
			title: "Tên",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return record?.exfields?.trang_thai_hen;
			},
		},
		{
			title: "Thời gian hẹn",
			dataIndex: "",
			width: 150,
			render: (record) => {
				if (!record?.exfields?.thoi_gian_hen) {
					return "";
				}
				return dayjs(
					getVNDateTime(record?.exfields?.thoi_gian_hen),
					"YYYY-MM-DD HH:mm:ss"
				).format("DD/MM/YYYY HH:mm:ss");
			},
		},
		{
			title: "Mã đặt hẹn",
			dataIndex: "",
			width: 100,
			render: (record) => {
				return record?.exfields?.ma_lich_hen;
			},
		},
		{
			title: "Điện thoại",
			dataIndex: "dien_thoai",
			render: (dien_thoai) => {
				return (
					<React.Fragment>
						{dien_thoai ? (
							<a
								href={`tel:${dien_thoai}`}
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="contact_cell"
							>
								<HiPhoneArrowUpRight />
								{dien_thoai}
							</a>
						) : (
							<div className="empty-text">(Trống)</div>
						)}
					</React.Fragment>
				);
			},
			width: 150,
		},

		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 100,
		},
	];

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "so1",
			q: {
				trang_thai: 0,
				...q,
			},
			page: 1,
			limit: 100,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
		}
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({
			ma_kh: dataModal?.ma_kh,
		});
		setTrangthai(0);
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "so1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({
			ma_kh: dataModal?.ma_kh,
		});
		setTrangthai(0);
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "so1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable({
			ma_kh: dataModal?.ma_kh,
		});
	};

	const [dataDefault, setDataDefault] = useState({});
	const handleGetDataDefault = async () => {
		const dataPTTT = await handleGetDataAndRows({
			api: "ptthanhtoan",
			q: {
				status: true,
				stt: 0,
			},
			setLoading,
		});
		const dataDVCS = await handleGetDataAndRows({
			api: "dvcs",
			q: {
				status: true,
				ten_dvcs: "Công ty",
			},
			setLoading,
		});
		if (dataPTTT) {
			setDataDefault({
				...dataDefault,
				ptthanhtoan: { ...dataPTTT.data[0] },
				dvcs: { ...dataDVCS.data[0] },
			});
		}
	};

	useEffect(() => {
		if (dataModal?.ma_kh && dataModal?.ma_kh !== "") {
			handleGetDataDefault();
			handleGetDataTable({
				ma_kh: dataModal?.ma_kh,
			});
		}
	}, [dataModal?.ma_kh]);

	return (
		<div className="relative">
			<TableManagerCus
				id="Booking-customer"
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{ x: 1200, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setIsOpenForm("edit");
					},
				})}
			/>

			<div className="absolute bottom-3">
				<div className="flex justify-start gap-2">
					<Button
						onClick={() => {
							setIsOpenForm("create");
						}}
						className="btn_error !text-[#F92759]"
					>
						<BsPersonPlus />
						Thêm
					</Button>
				</div>
			</div>
			<ModalBasic
				zIndex={1035}
				id={"Booking-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleSaveData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen || "Chưa đến",
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
							trang_thai_hd: "Lập chứng từ",
						},
						pt_thanh_toan: dataDefault?.ptthanhtoan?._id,
						ten_pt_thanh_toan: dataDefault?.ptthanhtoan?.ten,
						ma_kho: "KCTY",
						ten_kho: "Kho công ty",
						ma_dvcs: dataDefault?.dvcs?._id,
						tk_cn_thanhtoan: dataDefault?.ptthanhtoan?.tk_cn,
						trang_thai: trangthai,
					});
					setIsOpenForm(false);
				}}
				data={{
					ma_lich_hen: randomChars(4),
					thoi_gian_hen: new Date().toISOString(),
					customer_kh:
						(dataModal?.ten_kh || "") +
						" - " +
						(dataModal?.ma_kh || ""),
					ten_kh: dataModal?.ten_kh || "",
					ma_kh: dataModal?.ma_kh || "",
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>
			<ModalBasic
				zIndex={1035}
				id={"Booking-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Phiếu đặt hẹn</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				width={isTabletOrMobile ? "95vw" : ""}
				handleManager={(data) => {
					handleUpdateData({
						...dataDetail,
						...data,
						trang_thai: trangthai,
						exfields: {
							...dataDetail.exfields,
							thoi_gian_hen: data.thoi_gian_hen,
							trang_thai_hen: data?.trang_thai_hen,
							loai_lich_hen: data?.loai_lich_hen,
							nguoi_dat: data?.nguoi_dat,
							nguon_tao: data?.nguon_tao || "Lập phiếu tay",
							ho_ten_dem: data?.ho_ten_dem,
							ngay_hoa_don: trangthai === 8 ? new Date() : null,
							ma_lich_hen: data?.ma_lich_hen,
							// ten_dvcs: data?.ten_dvcs,
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetail,
					ptthanhtoan_data: dataDetail?.ten_pt_thanh_toan,
					customer_kh:
						(dataDetail?.ten_kh || "") +
						" - " +
						(dataDetail?.ma_kh || ""),
					dmkho_data: dataDetail?.ten_kho,
					dvcs_data: dataDetail?.exfields?.ten_dvcs,
					trang_thai_hen:
						dataDetail?.exfields?.trang_thai_hen || "Chưa đến",
					loai_lich_hen: dataDetail?.exfields?.loai_lich_hen,
					nguoi_dat: dataDetail?.exfields?.nguoi_dat,
					nguon_tao: dataDetail?.exfields?.nguon_tao,
					ho_ten_dem: dataDetail?.exfields?.ho_ten_dem,
					ma_lich_hen:
						dataDetail?.exfields?.ma_lich_hen || randomChars(4),
					ten_dvcs: dataDetail?.exfields?.ten_dvcs,
					thoi_gian_hen: dataDetail?.exfields?.thoi_gian_hen,
				}}
				groupButton={[
					<Button
						className="btn_error"
						htmlType="submit"
						onClick={() => setTrangthai(8)}
					>
						Tạo hoá đơn
					</Button>,
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormBooking>
					<ItemDetail />
				</FormBooking>
			</ModalBasic>
		</div>
	);
}
