import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SelectAdd from "../../../components/inputAnt/selectAdd";
import SelectAddStaffVendor from "../../../components/inputAnt/selectAddStaffVendor";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function FormInfomation({
	setFieldsValue,
	getFieldValue,
	pc1 = false,
	pt1 = false,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isMobile ? 24 : isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [ngayChungTu, setNgayChungTu] = useState();

	useEffect(() => {
		if (getFieldValue("ngay_ct") === null) {
			setNgayChungTu(null);
		} else {
			setNgayChungTu(
				dayjs(getFieldValue("ngay_ct")).format(dateFormatList[0])
			);
		}
	}, [getFieldValue("ngay_ct")]);

	return (
		<Row className="!m-0" gutter={[8, 8]}>
			{/* Trạng thái - Ngoại tệ */}
			<Form.Item name="details"></Form.Item>
			<Form.Item name="tdttcos"></Form.Item>
			<Form.Item name="tdttnos"></Form.Item>
			<Form.Item name="ten_trang_thai_moi"></Form.Item>
			<Form.Item name="trang_thai"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Trạng thái"
					name="trangthai_data"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn trạng thái",
						},
					]}
				>
					<Select
						placeholder="nhập trạng thái"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue(
									"trang_thai",
									parsedValue?.ma_trang_thai
								);
								setFieldsValue(
									"ten_trang_thai_moi",
									parsedValue?.ten_trang_thai
								);
							}
						}}
						allowClear
					>
						<Option
							value={JSON.stringify({
								ma_trang_thai: "0",
								ten_trang_thai: "Tạo mới",
							})}
							key={JSON.stringify({
								ma_trang_thai: "0",
								ten_trang_thai: "Tạo mới",
							})}
						>
							Tạo mới
						</Option>
						<Option
							value={JSON.stringify({
								ma_trang_thai: "5",
								ten_trang_thai: "Ghi sổ quỹ",
							})}
							key={JSON.stringify({
								ma_trang_thai: "5",
								ten_trang_thai: "Ghi sổ quỹ",
							})}
						>
							Ghi sổ quỹ
						</Option>
					</Select>
				</Form.Item>
			</Col>

			{/* Số chứng từ - Ngày chứng từ */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Số chứng từ"
					name="so_ct"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="SO_CT" />
				</Form.Item>
			</Col>
			<Form.Item name="ngay_ct"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Ngày chứng từ"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<DatePicker
						placeholder="chọn ngày chứng từ"
						value={
							ngayChungTu
								? dayjs(ngayChungTu, dateFormatList[0])
								: null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNgayChungTu(
								dayjs(date).format(dateFormatList[0])
							);
							setFieldsValue("ngay_ct", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
			</Col>

			{/* TK nợ - Khách hàng  */}
			<Form.Item name="tk_no"></Form.Item>
			<Form.Item name="ten_tk_no"></Form.Item>

			{/* TK có - Khách hàng  */}
			<Form.Item name="tk_co"></Form.Item>
			<Form.Item name="ten_tk_co"></Form.Item>

			<Form.Item name="ten_kh"></Form.Item>
			<Form.Item name="ma_kh"></Form.Item>
			{pt1 && (
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="customer"
						q={{
							status: true,
							kh_yn: true,
							loai_kh: {
								$nin: ["Vendor", "Staff"],
							},
						}}
						label="Khách hàng"
						name="customer_data"
						lableOption={["ten_kh", "ma_kh"]}
						valueOption={["ma_kh", "ten_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng chọn khách hàng",
							},
						]}
						placeholder="chọn khách hàng"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>
			)}

			{pc1 && (
				<Col span={isMobile ? 24 : 12}>
					<SelectAddStaffVendor
						api="customer"
						q={{
							status: true,
							kh_yn: false,
							$or: [
								{
									loai_kh: {
										$in: ["Vendor", "Staff"],
									},
								},
								{
									iscarrier: true,
								},
								{
									ncc_yn: true,
								},
							],
						}}
						label="Bác sĩ/NCC"
						name="customer_data"
						lableOption={["ten_kh", "ma_kh"]}
						valueOption={["ma_kh", "ten_kh"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						rules={[
							{
								required: true,
								message: "vui lòng chọn bác sĩ",
							},
						]}
						placeholder="chọn bác sĩ"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kh", parsedValue?.ten_kh);
								setFieldsValue("ma_kh", parsedValue?.ma_kh);
							}
						}}
					/>
				</Col>
			)}

			{/* Đơn vị - ngoại tệ */}
			<Form.Item name="ten_dvcs"></Form.Item>
			<Form.Item name="ma_dvcs"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="dvcs"
					q={{ status: true }}
					label="Đơn vị"
					name="dvcs_data"
					lableOption={["ten_dvcs"]}
					valueOption={["ten_dvcs", "_id"]}
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng chọn đợn vị",
						},
					]}
					placeholder="chọn đơn vị"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_dvcs", parsedValue?._id);
							setFieldsValue("ten_dvcs", parsedValue?.ten_dvcs);
						}
					}}
				/>
			</Col>
			<Form.Item name="ma_nt"></Form.Item>
			<Form.Item name="ty_gia"></Form.Item>
			<Form.Item name="ten_nt"></Form.Item>
			<Col span={isMobile ? 24 : 12}>
				<SelectAdd
					api="currency"
					q={{
						status: true,
					}}
					label="Ngoại tệ"
					name="currency_data"
					lableOption={["ma_nt", "ty_gia"]}
					valueOption={["ma_nt", "ty_gia", "ten_nt"]}
					labelCol={{
						...layout.labelCol,
					}}
					rules={[
						{
							required: true,
							message: "vui lòng chọn ngoại tệ",
						},
					]}
					wrapperCol={{ ...layout.wrapperCol }}
					placeholder="chọn ngoại tệ"
					onChange={(value) => {
						if (value) {
							const parsedValue = JSON.parse(value);
							setFieldsValue("ma_nt", parsedValue?.ma_nt);
							setFieldsValue("ty_gia", parsedValue?.ty_gia);
							setFieldsValue("ten_nt", parsedValue?.ten_nt);
						}
					}}
					disabled={getFieldValue("so_ct") ? true : false}
				/>
			</Col>

			{/* Nhân viên/bác sĩ */}
			<Form.Item name="ma_nv"></Form.Item>
			<Form.Item name="ten_nv"></Form.Item>
			{pt1 && (
				<Col span={isMobile ? 24 : 12}>
					<SelectAdd
						api="dmnv"
						fieldsAdd={["ma_nv", "ten_nv"]}
						lableOption={["ten_nv", "ma_nv"]}
						valueOption={["ma_nv", "ten_nv"]}
						label="Bác sĩ"
						name="dmnv_data"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn bác sĩ"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ma_nv", parsedValue?.ma_nv);
								setFieldsValue("ten_nv", parsedValue?.ten_nv);
							}
						}}
					/>
				</Col>
			)}
			{pt1 && (
				<Col className="max-sm:hidden" span={isMobile ? 24 : 12}></Col>
			)}

			{/* ghi chú  - chi nhánh */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
			<Form.Item name="ten_kho"></Form.Item>
			<Form.Item name="ma_kho"></Form.Item>
			<Form.Item name="ten_pt_thanh_toan"></Form.Item>
			<Form.Item name="pt_thanh_toan"></Form.Item>
			<Col span={isMobile ? 24 : 12} className="!p-0">
				<Col span={24}>
					<SelectAdd
						api="dmkho"
						label="Chi nhánh"
						name="dmkho_data"
						lableOption={["ten_kho", "ma_kho"]}
						valueOption={["ten_kho", "ma_kho"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn chi nhánh"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue("ten_kho", parsedValue?.ten_kho);
								setFieldsValue("ma_kho", parsedValue?.ma_kho);
							}
						}}
					/>
				</Col>
				<Col span={24}>
					<SelectAdd
						api="ptthanhtoan"
						q={{ status: true }}
						label="PTTT"
						name="ptthanhtoan_data"
						lableOption={["ten"]}
						valueOption={["_id", "ten"]}
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
						placeholder="chọn chi nhánh"
						onChange={(value) => {
							if (value) {
								const parsedValue = JSON.parse(value);
								setFieldsValue(
									"pt_thanh_toan",
									parsedValue?._id
								);
								setFieldsValue(
									"ten_pt_thanh_toan",
									parsedValue?.ten
								);
							}
						}}
					/>
				</Col>
			</Col>
		</Row>
	);
}
