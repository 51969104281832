import { Input } from "antd";
import React from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ModalFull from "../../../components/modal/ModalFull";

export default function ModalPxkImplant({
	dataDetail,
	handleManagerData,
	isOpenForm,
	setIsOpenForm,
	formSections,
	dataDefault,
}) {
	return (
		<div>
			<ModalFull
				idModal={"pxkvoucher-edit"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu xuất kho</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...dataDetail,
						...data,
						exfields: {
							loai_vat_tu: "Implant",
						},
					});
				}}
				data={{
					...dataDetail,
					trangthai_data: dataDetail?.ten_trang_thai,
					currency_data:
						dataDetail?.ma_nt + " - " + dataDetail?.ty_gia,
					dvcs_data: dataDetail?.ten_dvcs,
					tk_du_data:
						dataDetail?.tk_du + " - " + dataDetail?.ten_tk_du,
					customer_data:
						(dataDetail?.ten_kh || "") + " - " + dataDetail?.ma_kh,
					dmkho_data:
						dataDetail?.ten_kho + " - " + dataDetail?.ma_kho,
				}}
			/>

			<ModalFull
				idModal={"pxkvoucher-create"}
				title={
					<div className="flex justify-between max-lg:flex-col items-center max-lg:items-start w-[calc(100%-20px)]">
						<div className="flex justify-center items-center max-lg:pb-2 gap-2">
							<span>Phiếu xuất kho</span>
						</div>
						<div className="flex justify-end items-center gap-6">
							<Input
								placeholder="Tìm kiếm..."
								prefix={<HiOutlineSearch />}
								className="w-[300px] max-sm:w-[200px]"
							/>
						</div>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				formSections={formSections}
				footer={null}
				width={"95vw"}
				style={{
					top: "5px",
					"max-height": "90vh",
				}}
				handleManager={(data) => {
					handleManagerData({
						...data,
						exfields: {
							loai_vat_tu: "Implant",
						},
					});
				}}
				data={{
					trangthai_data: dataDefault?.trangthai?.ten_trang_thai,
					ma_trang_thai: dataDefault?.trangthai?.ma_trang_thai,
					ten_trang_thai: dataDefault?.trangthai?.ten_trang_thai,
					currency_data:
						dataDefault?.currency?.ma_nt +
						" - " +
						dataDefault?.currency?.ty_gia,
					dmkho_data:
						dataDefault?.dmkho?.ten_kho +
						" - " +
						dataDefault?.dmkho?.ma_kho,
					ma_nt: dataDefault?.currency?.ma_nt,
					ty_gia: dataDefault?.currency?.ty_gia,
					ten_nt: dataDefault?.currency?.ten_nt,
					dvcs_data: dataDefault?.dvcs?.ten_dvcs,
					ma_dvcs: dataDefault?.dvcs?._id,
					ten_dvcs: dataDefault?.dvcs?.ten_dvcs,
					ten_kho: dataDefault?.dmkho?.ten_kho,
					ma_kho: dataDefault?.dmkho?.ma_kho,
					ngay_ct: new Date(),
				}}
			/>
		</div>
	);
}
