import React, { useState } from "react";
import { Button, Popconfirm, Modal } from "antd";
import { LuEye, LuX } from "react-icons/lu";
import ModalBasic from "../../../components/modal/ModalBasic";
import { useMediaQuery } from "react-responsive";
import { FaFile, FaFilePdf } from "react-icons/fa";
// import TIFFViewer from "./itemTiff";

export default function ItemFiles({ key, data, handleDelete }) {
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
	const [isOpenPDF, setIsOpenPDF] = useState(false);
	const handleLogoFiles = (data) => {
		const fileExtension = data.split(".").pop();
		if (fileExtension === "pdf") {
			return <FaFilePdf size={50} className="text-red-500" />;
		} else {
			return <FaFile size={50} className="text-yellow-500" />;
		}
	};
	const handleViewFiles = (data) => {
		const fileExtension = data.split(".").pop();
		if (fileExtension === "pdf") {
			return (
				<iframe
					src={data}
					title="File PDF"
					className="h-[calc(100vh-200px)] w-full"
				></iframe>
			);
		} else {
			return (
				<iframe
					src={data}
					title="File PDF"
					className="h-[calc(100vh-200px)] w-full"
				></iframe>
			);
		}
	};
	return (
		<div
			key={key}
			className="flex flex-col justify-start items-start gap-2"
		>
			<div className="w-[100px] h-[100px] flex justify-center items-center pb-1 border-b-2 border-dashed">
				{handleLogoFiles(data)}
			</div>
			<div className="flex justify-center w-full">
				<Button
					className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#4d91b1]"
					shape="circle"
					size="small"
					onClick={() => setIsOpenPDF(true)}
				>
					<LuEye size={18} />
				</Button>
				{/* <Button
					className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#189e40]"
					shape="circle"
					size="small"
					onClick={() => {
						alert(
							"Vui lòng xoá và tải ảnh mới!!! Chức năng đang phát triển."
						);
					}}
				>
					<LuPencil size={18} />
				</Button> */}
				<Popconfirm
					title={`Bạn muốn xoá file này`}
					onConfirm={() => handleDelete(data)} // Sử dụng item.onClick thay vì handleDelete(row._id)
					okText="Có"
					cancelText="Không"
				>
					<Button
						className="btn_setting mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:!bg-slate-100 hover:!text-[#9e1818]"
						shape="circle"
						size="small"
					>
						<LuX size={18} />
					</Button>
				</Popconfirm>
			</div>
			<ModalBasic
				id={key}
				title="File điều trị"
				open={isOpenPDF}
				footer={null}
				onCancel={() => {
					setIsOpenPDF(false);
				}}
				width={isMobile ? "90vw" : ""}
				height={"95vh"}
				style={{
					top: "5px",
					"max-height": "95vh",
				}}
				footerGrBtns={false}
			>
				{handleViewFiles(data)}
			</ModalBasic>
		</div>
	);
}
