import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import React, { useEffect, useState } from "react";
import UploadImage from "../../../components/upLoad/uploadImage";
import ItemImage from "../item/itemImage";
import ItemFiles from "../item/itemFiles";

export default function LibraryImage({ setFieldsValue, getFieldValue }) {
	const [dataImage, setDataImage] = useState({});

	useEffect(() => {
		setDataImage(getFieldValue("hinh_anh") || {});
	}, [getFieldValue("hinh_anh")]);

	// Save image
	const handleSaveImageTruoc = (data) => {
		setDataImage({
			...dataImage,
			truoc_tri_lieu: [
				...(dataImage?.truoc_tri_lieu || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			truoc_tri_lieu: [
				...(dataImage?.truoc_tri_lieu || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
	};
	const handleSaveImageSau = (data) => {
		setDataImage({
			...dataImage,
			sau_tri_lieu: [
				...(dataImage?.sau_tri_lieu || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			sau_tri_lieu: [
				...(dataImage?.sau_tri_lieu || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
	};
	const handleSaveFiles = (data) => {
		setDataImage({
			...dataImage,
			files_dieu_tri: [
				...(dataImage?.files_dieu_tri || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			files_dieu_tri: [
				...(dataImage?.files_dieu_tri || []),
				{
					link: `https://api.goodapp.vn${data}`,
				},
			],
		});
	};

	//delete image
	const handleDeleteTruoc = (data) => {
		setDataImage({
			...dataImage,
			truoc_tri_lieu: dataImage?.truoc_tri_lieu.filter(
				(item) => item.link !== data
			),
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			truoc_tri_lieu: dataImage?.truoc_tri_lieu.filter(
				(item) => item.link !== data
			),
		});
	};
	const handleDeleteSau = (data) => {
		setDataImage({
			...dataImage,
			sau_tri_lieu: dataImage?.sau_tri_lieu.filter(
				(item) => item.link !== data
			),
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			sau_tri_lieu: dataImage?.sau_tri_lieu.filter(
				(item) => item.link !== data
			),
		});
	};
	const handleDeleteFiles = (data) => {
		setDataImage({
			...dataImage,
			files_dieu_tri: dataImage?.files_dieu_tri.filter(
				(item) => item.link !== data
			),
		});
		setFieldsValue("hinh_anh", {
			...dataImage,
			files_dieu_tri: dataImage?.files_dieu_tri.filter(
				(item) => item.link !== data
			),
		});
	};

	const getItems = (panelStyle) => [
		{
			key: "1",
			label: "Ảnh trước điều trị",
			children: (
				<div className="flex flex-wrap justify-start items-start gap-4">
					<UploadImage handleGetPictureModal={handleSaveImageTruoc} />
					{dataImage?.truoc_tri_lieu?.map((item, index) => {
						return (
							<ItemImage
								key={index}
								data={item.link}
								handleDelete={handleDeleteTruoc}
							/>
						);
					})}
				</div>
			),
			style: panelStyle,
		},
		{
			key: "2",
			label: "Ảnh sau điều trị",
			children: (
				<div className="flex flex-wrap justify-start items-start gap-4">
					<UploadImage handleGetPictureModal={handleSaveImageSau} />
					{dataImage?.sau_tri_lieu?.map((item, index) => {
						return (
							<ItemImage
								key={index}
								data={item.link}
								handleDelete={handleDeleteSau}
							/>
						);
					})}
				</div>
			),
			style: panelStyle,
		},
		{
			key: "3",
			label: "Files điều trị",
			children: (
				<div className="flex flex-wrap justify-start items-start gap-4">
					<UploadImage handleGetPictureModal={handleSaveFiles} />
					{dataImage?.files_dieu_tri?.map((item, index) => {
						return (
							<ItemFiles
								key={index}
								data={item.link}
								handleDelete={handleDeleteFiles}
							/>
						);
					})}
				</div>
			),
			style: panelStyle,
		},
	];
	const { token } = theme.useToken();
	const panelStyle = {
		marginBottom: 24,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: "none",
	};
	return (
		<Collapse
			bordered={false}
			defaultActiveKey={[]}
			expandIcon={({ isActive }) => (
				<CaretRightOutlined rotate={isActive ? 90 : 0} />
			)}
			style={{
				background: token.colorBgContainer,
			}}
			items={getItems(panelStyle)}
		/>
	);
}
