import { Col, DatePicker, Form, Input, Row, Select, Checkbox } from "antd";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isNaN } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { request } from "../../../../utils/request";
import SelectAdd from "../../../components/inputAnt/selectAdd";
import { optionsTieuSuBenh } from "../../../../utils/utilsOptionSelect";
dayjs.extend(customParseFormat);
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export default function InfomationScreen({
	dataModal,
	getFieldValue,
	setFieldsValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isMobile ? 24 : isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const [cities, setCities] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [wards, setWards] = useState([]);
	const [selectedCity, setSelectedCity] = useState("");
	const [selectedDistrict, setSelectedDistrict] = useState("");
	const [selectedWard, setSelectedWard] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				getFieldValue("ten_tinh_thanh");
				const response = await request.get(`api/tinhthanh`, {
					headers: {
						"X-Access-Token": "flex.public.token",
						"Content-Type": "application/json",
					},
				});
				setCities(response.data);
			} catch (error) {
				console.error("Error fetching cities:", error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (!isNaN(parseInt(getFieldValue("tinh_thanh"), 10))) {
			callApiDistrict(getFieldValue("tinh_thanh"));
		}
	}, [getFieldValue("tinh_thanh")]);

	useEffect(() => {
		if (!isNaN(parseInt(getFieldValue("quan_huyen"), 10))) {
			callApiWard(getFieldValue("quan_huyen"));
		}
	}, [getFieldValue("quan_huyen")]);

	const callApiDistrict = async (cityCode) => {
		try {
			const response = await request.get(`api/quanhuyen`, {
				params: {
					ma_tinh_thanh: cityCode,
				},
				headers: {
					"X-Access-Token": "flex.public.token",
					"Content-Type": "application/json",
				},
			});
			setDistricts(response.data);
		} catch (error) {
			console.error("Error fetching districts:", error);
		}
	};

	const callApiWard = async (districtCode) => {
		try {
			const response = await request.get(`api/xaphuong`, {
				params: {
					ma_quan_huyen: districtCode,
				},
				headers: {
					"X-Access-Token": "flex.public.token",
					"Content-Type": "application/json",
				},
			});
			setWards(response.data);
		} catch (error) {
			console.error("Error fetching wards:", error);
		}
	};

	const handleCityChange = (value, option) => {
		setFieldsValue("tinh_thanh", option.key);
		setFieldsValue("quan_huyen", null);
		setFieldsValue("xa_phuong", null);
		setFieldsValue("ten_quan_huyen", null);
		setFieldsValue("ten_xa_phuong", null);
		setSelectedCity(option.children);
		callApiDistrict(option.key);
		setSelectedDistrict("");
		setSelectedWard("");
		printResult();
	};

	const handleDistrictChange = (value, option) => {
		setFieldsValue("quan_huyen", option.key);
		setFieldsValue("xa_phuong", null);
		setFieldsValue("ten_xa_phuong", null);
		setSelectedDistrict(option.children);
		callApiWard(option.key);
		setSelectedWard("");
		printResult();
	};

	const handleWardChange = (value, option) => {
		setFieldsValue("xa_phuong", option.key);
		setSelectedWard(option.children);
		printResult();
	};

	const printResult = () => {
		if (
			selectedCity !== "" &&
			selectedDistrict !== "" &&
			selectedWard !== ""
		) {
			const result = `${selectedCity} | ${selectedDistrict} | ${selectedWard}`;
			console.log(result); // You can use this result as needed
		}
	};

	const [ngaySinh, setNgaySinh] = useState();

	useEffect(() => {
		if (
			dataModal?.ngay_sinh === null ||
			dataModal?.ngay_sinh === undefined
		) {
			setNgaySinh(null);
		} else {
			setNgaySinh(dayjs(dataModal?.ngay_sinh).format(dateFormatList[0]));
		}
	}, [dataModal?.ngay_sinh]);

	const [ngayKhamDau, setNgayKhamDau] = useState();

	useEffect(() => {
		if (
			dataModal?.ngay_kham_dau === null ||
			dataModal?.ngay_kham_dau === undefined
		) {
			setNgayKhamDau(null);
		} else {
			setNgayKhamDau(
				dayjs(dataModal?.ngay_kham_dau).format(dateFormatList[0])
			);
		}
	}, [dataModal?.ngay_kham_dau]);

	const [ngayKhamMoi, setNgayKhamMoi] = useState();

	useEffect(() => {
		if (
			dataModal?.ngay_kham_moi === null ||
			dataModal?.ngay_kham_moi === undefined
		) {
			setNgayKhamMoi(null);
		} else {
			setNgayKhamMoi(
				dayjs(dataModal?.ngay_kham_moi).format(dateFormatList[0])
			);
		}
	}, [dataModal?.ngay_kham_moi]);

	const [tieuSuBenh, setTieuSuBenh] = useState([]);
	const [tsbDienGiai, setTsbDienGiai] = useState({});
	useEffect(() => {
		setTieuSuBenh(dataModal?.tieu_su_benh);
		setTsbDienGiai(dataModal?.tsb_dien_giai);
	}, [dataModal?.ma_kh]);

	const [isFieldsVisible, setIsFieldsVisible] = useState(false);

	const handleShowFields = () => {
		setIsFieldsVisible(true);
	};

	const handleHideFields = () => {
		setIsFieldsVisible(false);
	};

	return (
		<Row className="!m-0" gutter={[8, 8]}>
			{/* field hidden */}
			<Form.Item name="trich_xuat_bn"></Form.Item>
			<Form.Item name="hinh_anh"></Form.Item>
			<Form.Item name="tinh_thanh"></Form.Item>
			<Form.Item name="quan_huyen"></Form.Item>
			<Form.Item name="xa_phuong"></Form.Item>
			{/* Họ - giới tính - Id */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Họ"
					name="ho_ten_dem"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="nhập Họ" />
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 6}>
				<Form.Item
					label="Giới tính"
					name="gioi_tinh"
					labelCol={{
						span: 12,
						className: "min-w-[80px]",
					}}
					wrapperCol={{
						span: isMobile ? 24 : isTabletOrMobile ? 20 : 18,
					}}
				>
					<Select placeholder="chọn giới tính" allowClear>
						<Option value="nam" key="nam">
							nam
						</Option>
						<Option value="nữ" key="nữ">
							nữ
						</Option>
					</Select>
				</Form.Item>
			</Col>
			<Col span={isMobile ? 24 : 6}>
				<Form.Item
					label="Số ID"
					name="ma_kh"
					labelCol={{
						span: 12,
						className: "min-w-[80px]",
					}}
					wrapperCol={{
						span: isMobile ? 24 : isTabletOrMobile ? 20 : 18,
					}}
				>
					<Input placeholder="Id chạy tự động" />
				</Form.Item>
			</Col>
			{/* Tên - ngày sinh - tuổi */}
			<Col span={isMobile ? 24 : 12}>
				<Form.Item
					label="Tên"
					name="ten_kh"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
					rules={[
						{
							required: true,
							message: "vui lòng điền tên",
						},
					]}
				>
					<Input placeholder="nhập tên" />
				</Form.Item>
			</Col>
			<Form.Item name="ngay_sinh"></Form.Item>
			<Col span={isMobile ? 10 : 6}>
				<Form.Item
					label="Ngày sinh"
					labelCol={{
						span: 12,
						className: "min-w-[80px]",
					}}
					wrapperCol={{ span: isTabletOrMobile ? 20 : 18 }}
				>
					<DatePicker
						placeholder="chọn ngày sinh"
						value={
							ngaySinh ? dayjs(ngaySinh, dateFormatList[0]) : null
						}
						format={dateFormatList}
						onChange={(date, dateString) => {
							setNgaySinh(dayjs(date).format(dateFormatList[0]));
							setFieldsValue("ngay_sinh", date);
						}}
						allowClear={false}
					/>
				</Form.Item>
				{/* <Col
					span={24}
					className="flex justify-start items-center gap-2 w-full max-lg:flex-col max-lg:items-start"
				>
					<span className="min-h-[22px] text-end text-xs text-[#b4b4b4] w-[calc(50%-8px)] max-lg:text-start">
						Số tuổi
					</span>
					<span className="text-red-500 text-lg font-semibold rounded-full w-8 h-8 flex items-center justify-center bg-slate-200">
						{dayjs().diff(getFieldValue("ngay_sinh"), "years")}
					</span>
				</Col> */}
			</Col>
			<Col span={isMobile ? 10 : 6}>
				{/* <Form.Item
					label="Số ID 2"
					name="ten_kh2"
					labelCol={{
						span: 12,
						className: "min-w-[80px]",
					}}
					wrapperCol={{
						span: isMobile ? 24 : isTabletOrMobile ? 20 : 18,
					}}
				>
					<Input placeholder="Id 2" />
				</Form.Item> */}
				<Col
					span={24}
					className="flex justify-start items-center gap-2 w-full max-lg:flex-col max-lg:items-start"
				>
					<span className="min-h-[22px] text-end text-xs text-[#b4b4b4] w-[calc(50%-8px)] max-lg:text-start">
						Số tuổi
					</span>
					<span className="text-red-500 text-lg font-semibold rounded-full w-8 h-8 flex items-center justify-center bg-slate-200">
						{dayjs().diff(getFieldValue("ngay_sinh"), "years")}
					</span>
				</Col>
			</Col>

			{isFieldsVisible && (
				<>
					{/* Điện thoại - Email  */}
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							label="Số điện thoại"
							name="dien_thoai"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input
								placeholder="nhập số điện thoại"
								addonAfter={
									<AiOutlinePlus className="cursor-pointer w-4 h-4 hover:text-[#1890FF]" />
								}
							/>
						</Form.Item>
					</Col>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							label="Email"
							name="email"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input
								placeholder="nhập Email"
								addonAfter={
									<AiOutlinePlus className="cursor-pointer w-4 h-4 hover:text-[#1890FF]" />
								}
							/>
						</Form.Item>
					</Col>
					{/* Nhóm khách - Tỉnh thành */}
					<Col span={isMobile ? 24 : 12}>
						<SelectAdd
							api="dmnhkh"
							label="Nhóm khách hàng"
							name="nh_kh"
							lableOption={["group_name"]}
							valueOption={["group_name"]}
							labelCol={{
								...layout.labelCol,
								className: "min-w-[120px]",
							}}
							wrapperCol={{ ...layout.wrapperCol }}
							placeholder="chọn nhóm khách hàng"
						/>
					</Col>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							name="ten_tinh_thanh"
							label="Tỉnh/thành"
							labelCol={{
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Select
								allowClear
								onClear={() => {
									setDistricts([]);
									setWards([]);
									setFieldsValue("ten_tinh_thanh", null);
									setFieldsValue("tinh_thanh", null);
									setFieldsValue("ten_quan_huyen", null);
									setFieldsValue("quan_huyen", null);
									setFieldsValue("ten_xa_phuong", null);
									setFieldsValue("xa_phuong", null);
								}}
								placeholder="Chọn tỉnh thành"
								onChange={handleCityChange}
							>
								{cities.map((city) => (
									<Option
										key={city.ma_tinh_thanh}
										value={city.ten_tinh_thanh}
									>
										{city.ten_tinh_thanh}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					{/* Địa chi - Tỉnh/thành - quận/huyện - phường */}
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							label="Số nhà - Đường"
							name="dia_chi"
							labelCol={{
								className: "min-w-[115px]",
								...layout.labelCol,
							}}
							wrapperCol={{ ...layout.wrapperCol }}
						>
							<Input.TextArea
								rows={3}
								placeholder="nhập số nhà - đường"
							/>
						</Form.Item>
					</Col>
					<Col span={isMobile ? 24 : 12} className="!px-0">
						<Col span={24}>
							<Form.Item
								name="ten_quan_huyen"
								label="Quận huyện"
								labelCol={{
									...layout.labelCol,
								}}
								wrapperCol={{ ...layout.wrapperCol }}
							>
								<Select
									allowClear
									onClear={() => {
										setDistricts([]);
										setFieldsValue("ten_quan_huyen", null);
										setFieldsValue("quan_huyen", null);
										setFieldsValue("ten_xa_phuong", null);
										setFieldsValue("xa_phuong", null);
									}}
									placeholder="Chọn quận huyện"
									onChange={handleDistrictChange}
								>
									{districts.map((district) => (
										<Option
											key={district.ma_quan_huyen}
											value={district.ten_quan_huyen}
										>
											{district.ten_quan_huyen}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="ten_xa_phuong"
								label="Phường"
								labelCol={{
									...layout.labelCol,
								}}
								wrapperCol={{ ...layout.wrapperCol }}
							>
								<Select
									allowClear
									onClear={() => {
										setFieldsValue("ten_xa_phuong", null);
										setFieldsValue("xa_phuong", null);
									}}
									placeholder="Chọn phường xã"
									onChange={handleWardChange}
								>
									{wards.map((ward) => (
										<Option
											key={ward.ma_xa_phuong}
											value={ward.ten_xa_phuong}
										>
											{ward.ten_xa_phuong}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Col>
					{/* Tiểu sử bệnh */}
					<Form.Item name="tsb_dien_giai"></Form.Item>
					<Col span={24}>
						<Form.Item
							name="tieu_su_benh"
							label="Tiểu sử bệnh"
							labelCol={{
								...layout.labelCol,
								span: 3,
							}}
							wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
						>
							<Checkbox.Group
								style={{
									width: "100%",
								}}
								onChange={(e) => {
									setFieldsValue("tieu_su_benh", e);
									setTieuSuBenh(e);
								}}
								value={tieuSuBenh}
							>
								<Row>
									{optionsTieuSuBenh.map((item, index) => (
										<Col
											key={index}
											span={isMobile ? 24 : 12}
										>
											<Checkbox value={item.value}>
												{item.label}
											</Checkbox>
											{tieuSuBenh &&
												tieuSuBenh.includes(
													item.value
												) && (
													<Col
														className="pl-5 my-2"
														span={24}
													>
														<Form.Item
															name={item.value}
															labelCol={{
																...layout.labelCol,
															}}
															wrapperCol={{
																...layout.wrapperCol,
															}}
														>
															<Input.TextArea
																rows={1}
																placeholder="ghi chú thêm"
																onChange={(
																	e
																) => {
																	setFieldsValue(
																		"tsb_dien_giai",
																		{
																			...tsbDienGiai,
																			[item.value]:
																				e
																					.target
																					.value,
																		}
																	);
																}}
															/>
														</Form.Item>
													</Col>
												)}
										</Col>
									))}
								</Row>
							</Checkbox.Group>
						</Form.Item>
					</Col>
					{/* ghi chú */}
					<Col span={24}>
						<Form.Item
							name="ghi_chu"
							label="Ghi chú khác"
							labelCol={{
								...layout.labelCol,
								span: 3,
							}}
							wrapperCol={{ span: isTabletOrMobile ? 24 : 21 }}
						>
							<Input.TextArea
								rows={3}
								placeholder="nhập ghi chú"
							/>
						</Form.Item>
					</Col>
					{/* Ngày khám đầu tiên - Ngày khám mới nhất  */}
					<Form.Item name="ngay_kham_dau"></Form.Item>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							label="Ngày khám đầu tiên"
							labelCol={{
								span: isTabletOrMobile ? 24 : 12,
							}}
							wrapperCol={{ span: 12 }}
						>
							<DatePicker
								placeholder="ngày khám đầu tiên"
								value={
									ngayKhamDau
										? dayjs(ngayKhamDau, dateFormatList[0])
										: null
								}
								format={dateFormatList}
								onChange={(date, dateString) => {
									setNgayKhamDau(
										dayjs(date).format(dateFormatList[0])
									);
									setFieldsValue("ngay_kham_dau", date);
								}}
								allowClear={false}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
					<Form.Item name="ngay_kham_moi"></Form.Item>
					<Col span={isMobile ? 24 : 12}>
						<Form.Item
							label="Ngày khám mới nhất"
							labelCol={{
								span: isTabletOrMobile ? 24 : 12,
							}}
							wrapperCol={{ span: 12 }}
						>
							<DatePicker
								placeholder="ngày khám mới nhất"
								value={
									ngayKhamMoi
										? dayjs(ngayKhamMoi, dateFormatList[0])
										: null
								}
								format={dateFormatList}
								onChange={(date, dateString) => {
									setNgayKhamMoi(
										dayjs(date).format(dateFormatList[0])
									);
									setFieldsValue("ngay_kham_moi", date);
								}}
								allowClear={false}
								style={{
									width: "100%",
								}}
							/>
						</Form.Item>
					</Col>
				</>
			)}
			{/* Button to show/hide Ngay Kham */}
			<Col span={24}>
				<div
					onClick={
						isFieldsVisible ? handleHideFields : handleShowFields
					}
					className="hover:text-[#1890FF] cursor-pointer text-[#1890FF] text-sm font-semibold"
				>
					{isFieldsVisible ? "- Ẩn bớt" : "+ Xem thêm"}
				</div>
			</Col>
		</Row>
	);
}
