import React from "react";
import { handleTotal } from "../../../../utils/utilsFunc";
import dayjs from "dayjs";

export default function ItemTotalHd1Voucher({ getFieldValue }) {
	return (
		<div className="w-full bg-slate-50 py-2">
			<div className="w-4/5 mx-auto flex flex-wrap justify-between items-center">
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng số lượng: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "sl_xuat",
							}) || 0
						)?.toLocaleString()}
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng tiền: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "tien_nt",
							}) || 0
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng nợ đơn: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "tien_nt",
							}) || 0
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng chiết khấu: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "tien_ck_nt",
							}) || 0
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng thanh toán: </span>
					<span>
						{(
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_nt",
							}) || 0) -
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_ck_nt",
							}) || 0)
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Ngày hẹn tt: </span>
					<span>
						{getFieldValue("ngay_hoa_don") &&
							dayjs(getFieldValue("ngay_hoa_don")).format(
								"DD/MM/YYYY"
							)}
					</span>
				</div>
			</div>
		</div>
	);
}
