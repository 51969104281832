import React, { useEffect, useState } from "react";
import ItemUserGroup from "./components/itemUserGroup";
import "./user.scss";
import { request } from "../../utils/request";

export default function UserGroup() {
	const [data, setData] = useState([]);
	const handleGetUserGroup = async () => {
		try {
			const response = await request.get(
				`api/${localStorage.getItem("id_app")}/usergroup`,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
					params: {
						t: 1,
						page: 1,
						limit: 30,
						q: {},
					},
				}
			);
			setData(response.data);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		handleGetUserGroup();
	}, []);
	return (
		<React.Fragment>
			<div className="h-12 flex justify-between items-center my-2">
				<div className="flex items-center font-bold text-xl text-[#09395e] gap-5">
					User Group
				</div>
			</div>
			<div className="box_usergroup">
				{data.map((item, index) => {
					return <ItemUserGroup key={index} data={item} />;
				})}
			</div>
		</React.Fragment>
	);
}
