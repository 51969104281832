import { Button, Image, Input, Popconfirm, message } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineFilter } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import {
    handleCreate,
    handleDelete,
    handleDeleteArray,
    handleGetDataAndRows,
    handleUpdate,
} from "../../../utils/utilsApi";
import PopoverModel from "../../components/modal/popoverModel";
import TableManager from "../../components/table/tableManager";
import ModalDmMedicines from "../components/Modal/ModalDmMedicines";
const { Search } = Input;

export default function DmMedicine() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [messageApi, contextHolder] = message.useMessage();
	const [dataTable, setDataTable] = useState([]);
	const [dataDetail, setDataDetail] = useState({});
	const [dataSelected, setDataSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isOpenForm, setIsOpenForm] = useState(false);
	const [dataImage, setDataImage] = useState("");

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetail(data);
				setDataImage(data?.picture);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetail(data);
				setDataImage(data?.picture);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Hình ảnh",
			dataIndex: "picture",
			width: 50,
			render: (picture) => {
				if (!picture) return null;
				return (
					<Image
						src={picture}
						alt="Picture of the author"
						width={24}
						height={24}
					/>
				);
			},
		},
		{
			title: "Sản phẩm",
			dataIndex: "ma_vt",
			width: 100,
		},
		{
			title: "Tên sản phẩm",
			dataIndex: "ten_vt",
			width: 100,
		},
		{
			title: "Giá niêm yết (VND)",
			dataIndex: "gia_ban_le",
			width: 150,
			render: (gia_ban_le) => {
				if (!gia_ban_le) return null;
				return gia_ban_le?.toLocaleString("vi-VN", {
					style: "currency",
					currency: "VND",
				});
			},
			align: "right",
		},
		{
			title: "Ngày tạo",
			dataIndex: "date_created",
			width: 150,
			render: (date_created) => {
				if (!date_created) return null;
				else return dayjs(date_created).format("DD/MM/YYYY");
			},
		},
	];

	// handle pagination
	const [totalRows, setTotalRows] = useState();
	const [params, setParams] = useState({
		limit: 50,
		page: 1,
	});
	const handlePaginationChange = (page, limit) => {
		setParams({
			...params,
			page,
			limit,
		});
	};

	const handleGetDataTable = async (q) => {
		const data = await handleGetDataAndRows({
			api: "dmvt",
			q: {
				status: true,
				"exfields.loai_vat_tu": "Medicines",
				...q,
			},
			page: params.page,
			limit: params.limit,
			setLoading,
		});
		if (data) {
			setDataTable(data.data);
			setTotalRows(data.total);
		}
	};

	const handleSaveData = async (data) => {
		await handleCreate({
			api: "dmvt",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "dmvt",
			data,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "dmvt",
			id,
			messageApi,
			setLoading,
		});
		await handleGetDataTable();
	};

	const handleDeleteArrayId = async (ids) => {
		await handleDeleteArray({
			api: "dmvt",
			ids,
			messageApi,
			setLoading,
		});
		setDataSelected([]);
		await handleGetDataTable({});
	};

	const handleManagerData = async (data) => {
		if (data?._id) {
			await handleUpdateData({
				...data,
			});
		} else {
			await handleSaveData({
				...data,
			});
		}
	};

	// const [dataDefault, setDataDefault] = useState({});
	// const handleGetDataDefault = async () => {
	// 	const dataPTTT = await handleGetDataAndRows({
	// 		api: "ptthanhtoan",
	// 		q: {
	// 			status: true,
	// 			stt: 0,
	// 		},
	// 		setLoading,
	// 	});
	// 	const dataDVCS = await handleGetDataAndRows({
	// 		api: "dvcs",
	// 		q: {
	// 			status: true,
	// 			ten_dvcs: "Công ty",
	// 		},
	// 		setLoading,
	// 	});
	// 	if (dataPTTT) {
	// 		setDataDefault({
	// 			...dataDefault,
	// 			ptthanhtoan: { ...dataPTTT.data[0] },
	// 			dvcs: { ...dataDVCS.data[0] },
	// 		});
	// 	}
	// };
	// useEffect(() => {
	// 	handleGetDataDefault();
	// }, []);

	useEffect(() => {
		handleGetDataTable({ ...params.q });
	}, [params.page, params.limit]);

	const DebouncedSearch = debounce((value) => {
		handleSearch(value);
	}, 500);

	const handleSearch = (value) => {
		setParams({
			...params,
			page: 1,
			q: {
				$or: [
					{ ten_vt: { $regex: value, $options: "i" } },
					{ ma_vt: { $regex: value, $options: "i" } },
					// { gia_ban_le: { $regex: value, $options: "i" } },
				],
			},
		});
		handleGetDataTable({
			$or: [
				{ ten_vt: { $regex: value, $options: "i" } },
				{ ma_vt: { $regex: value, $options: "i" } },
				// { gia_ban_le: { $regex: value, $options: "i" } },
			],
		});
	};

	return (
		<div>
			<div className="h-14 flex justify-between items-center max-lg:flex-col-reverse max-lg:h-[88px] max-lg:items-start">
				{contextHolder}
				<div className="h-[48px] flex justify-start items-center gap-4">
					<div className="flex justify-start items-center gap-4 h-full">
						<PopoverModel
							placement="bottomLeft"
							title={
								<div className="flex justify-between items-center">
									<p>Lọc và tìm kiếm</p>
									{/* <Button
											shape="circle"
											size="small"
											className="btn_ternary !border-none"
										>
											<TfiReload />
										</Button> */}
								</div>
							}
							content={
								<div className="flex flex-col gap-2 w-[300px] max-sm:w-[200px]">
									<p>Tìm</p>
									<Search
										placeholder="Nhập Id, tên vật tư..."
										onChange={(e) =>
											DebouncedSearch(e.target.value)
										}
										allowClear
									/>
								</div>
							}
							trigger={"click"}
						>
							<Button className="btn_ternary">
								<HiOutlineFilter />
								<span className="max-lg:!hidden">Tất cả</span>
							</Button>
						</PopoverModel>
					</div>
					<div className="flex justify-start items-center gap-4 h-full">
						<Button
							onClick={() => {
								setIsOpenForm("create");
								setDataDetail({});
								setDataImage("");
							}}
							className="btn_primary hover:!text-white"
						>
							<BsPersonPlus />
							<span className="max-lg:!hidden">Tạo mới</span>
						</Button>
						{dataSelected.length > 0 && (
							<div className="flex justify-center items-center h-[32px] bg-white rounded-md shadow-sm">
								<Popconfirm
									title={`Bạn muốn xoá ${dataSelected.length} dòng ?`}
									onConfirm={() => {
										handleDeleteArrayId(dataSelected);
									}}
									onCancel={() => {
										return;
									}}
									okText="Có"
									cancelText="Không"
								>
									<Button className="btn_error">
										<AiOutlineDelete />
									</Button>
								</Popconfirm>
							</div>
						)}
					</div>
					<Search
						placeholder="Nhập Id, tên vật tư..."
						onChange={(e) => DebouncedSearch(e.target.value)}
						allowClear
					/>
				</div>
				<div className="h-full max-lg:h-fit py-2 flex items-center">
					<span className="text-[#186b9e] font-semibold uppercase">
						Danh mục thuốc
					</span>
				</div>
			</div>

			<TableManager
				data={dataTable}
				columns={columns}
				loading={loading}
				rowKey={"_id"}
				scroll={{
					x: 1250,
					y: isTabletOrMobile
						? "calc(100vh - 248px)"
						: "calc(100vh - 220px)",
				}}
				tableProps={{
					size: "middle",
				}}
				handleDataSelectKey={(newSelectedRowKeys) => {
					setDataSelected(newSelectedRowKeys);
				}}
				onRow={(record) => ({
					onClick: () => {
						setDataDetail(record);
						setDataImage(record?.picture);
						setIsOpenForm("edit");
					},
				})}
				isPage={params.page}
				isLimit={params.limit}
				totalRows={totalRows}
				handlePaginationChange={handlePaginationChange}
			/>

			<ModalDmMedicines
				dataImage={dataImage}
				isOpenForm={isOpenForm}
				setIsOpenForm={setIsOpenForm}
				dataDetail={dataDetail}
				handleManagerData={handleManagerData}
				// dataDefault={dataDefault}
			/>
		</div>
	);
}
