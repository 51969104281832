import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import ModalBasic from "../../../components/modal/ModalBasic";
import TableManagerCus from "../../../components/table/tableManagerCus";
import FormDetailHd1Voucher from "../form/formItem/FormDetailHd1Voucher";
import ItemTotalHd1Voucher from "./itemTotalHd1Voucher";

export default function ItemDetailHd1Voucher({
	setFieldsValue,
	getFieldValue,
}) {
	const [dataDetailItem, setDataDetailItem] = useState({});
	const [isOpenForm, setIsOpenForm] = useState(false);

	const [dataModalDetail, setDataModalDetail] = useState([]);
	useEffect(() => {
		setDataModalDetail(getFieldValue("details") || []);
	}, [getFieldValue("details")]);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (data) => {
				handleDeleteData(data);
			},
			label: "Delete",
		},
	];
	const columnsTabItem = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ${record.ten_vt}`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "#",
			dataIndex: "",
			width: 20,
		},

		{
			title: "Tên dịch vụ/sản phẩm",
			dataIndex: "dien_giai",
			width: 200,
		},
		{
			title: "Số lượng",
			dataIndex: "sl_xuat",
			width: 100,
			render: (sl_xuat) => {
				return `${(sl_xuat || 0)?.toLocaleString()}`;
			},
		},
		{
			title: "Đơn giá (đ)",
			dataIndex: "gia_ban_nt",
			width: 150,
			render: (gia_ban_nt) => {
				return `${(gia_ban_nt || 0)?.toLocaleString()}`;
			},
		},
		{
			title: "Chiết khấu (đ)",
			dataIndex: "tien_ck_nt",
			width: 150,
			render: (tien_ck_nt) => {
				return `${(tien_ck_nt || 0)?.toLocaleString()}`;
			},
		},
		{
			title: "Thành tiền (đ)",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return `${(
					(record?.tien_nt || 0) - (record?.tien_ck_nt || 0)
				)?.toLocaleString()}`;
			},
		},
	];

	const handleUpdateData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) =>
					JSON.stringify(item) === JSON.stringify(dataDetailItem)
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	const handleDeleteData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => JSON.stringify(item) === JSON.stringify(dataModal)
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	const handleSaveData = (dataModal) => {
		setDataModalDetail([...dataModalDetail, dataModal]);
		setFieldsValue("details", [...dataModalDetail, dataModal]);
	};

	return (
		<div className="py-2">
			<div className="flex justify-start items-center gap-2 px-5 pb-2 ">
				<span className="text-lg">Dịch vụ/ sản phẩm quan tâm</span>
				<Button
					onClick={() => {
						setIsOpenForm("create");
					}}
					className="btn_secondary hover:!text-white"
				>
					<MdOutlinePlaylistAdd />
				</Button>
			</div>
			<TableManagerCus
				id="dataItemDetail-tab"
				data={dataModalDetail}
				columns={columnsTabItem}
				scroll={{
					x: 900,
					y: "30vh",
				}}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenForm("edit");
						setDataDetailItem(record);
					},
				})}
			/>
			<ItemTotalHd1Voucher getFieldValue={getFieldValue} />

			<ModalBasic
				zIndex={1035}
				id={"itemDetail-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleSaveData(data);
					setIsOpenForm(false);
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
				data={{ sl_xuat: 1 }}
			>
				<FormDetailHd1Voucher />
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"itemDetail-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleUpdateData({
						...dataDetailItem,
						...data,
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetailItem,
					dmvt_vt:
						dataDetailItem.ten_vt + " - " + dataDetailItem.ma_vt,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormDetailHd1Voucher />
			</ModalBasic>
		</div>
	);
}
