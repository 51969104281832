import React from "react";
import { handleTotal } from "../../../../utils/utilsFunc";

export default function ItemTotalPn({ getFieldValue }) {
	return (
		<div className="w-full bg-slate-50 py-2">
			<div className="w-4/5 mx-auto flex flex-wrap justify-between items-center">
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng số lượng: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "sl_nhap",
							}) || 0
						)?.toLocaleString()}
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng tiền: </span>
					<span>
						{(
							handleTotal({
								arr: getFieldValue("details"),
								name: "tien_hang_nt",
							}) || 0
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng nợ đơn: </span>
					<span>
						{(
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_hang_nt",
							}) || 0) -
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_ck_nt",
							}) || 0) -
							(getFieldValue("tien_ck_hd") || 0) -
							(getFieldValue("da_thanh_toan") || 0)
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
				<div className="w-1/3 max-lg:w-1/2 max-sm:w-4/5 ">
					<span>Tổng thanh toán: </span>
					<span>
						{(
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_hang_nt",
							}) || 0) -
							(handleTotal({
								arr: getFieldValue("details"),
								name: "tien_ck_nt",
							}) || 0) -
							(getFieldValue("tien_ck_hd") || 0)
						)?.toLocaleString()}{" "}
						đ
					</span>
				</div>
			</div>
		</div>
	);
}
