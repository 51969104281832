import { request } from "./request";

const handleGetDataAndRows = async ({
	api,
	params,
	q,
	IdApp = true,
	page,
	limit,
	headers,
	setLoading,
}) => {
	setLoading(true);
	try {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		const [response, totalRecord] = await Promise.all([
			request.get(`api/${newIdApp}${api}`, {
				params: {
					...params,
					page: page,
					limit: limit,
					q: JSON.stringify(q),
				},
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...headers,
				},
			}),
			request.get(`api/${localStorage.getItem("id_app")}/${api}`, {
				params: { ...params, q: JSON.stringify(q), count: 1 },
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...headers,
				},
			}),
		]);
		setLoading(false);
		return {
			data: response.data,
			total: totalRecord.data.rows_number,
		};
	} catch (error) {
		console.log(error);
		return error;
	} finally {
		setLoading(false);
	}
};

const handleGetData = async ({
	api,
	params,
	q,
	IdApp = true,
	page,
	limit,
	headers,
	setLoading = () => {},
}) => {
	setLoading(true);
	try {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		const response = await request.get(`api/${newIdApp}${api}`, {
			params: {
				...params,
				page: page,
				limit: limit,
				q: JSON.stringify(q),
			},
			headers: {
				"X-Access-Token": localStorage.getItem("token"),
				"Content-Type": "application/json",
				...headers,
			},
		});
		setLoading(false);
		return {
			data: response.data,
		};
	} catch (error) {
		console.log(error);
		return error;
	} finally {
		setLoading(false);
	}
};

const handleGetDataById = async ({
	api,
	id,
	q,
	params,
	headers,
	setLoading,
}) => {
	setLoading(true);
	try {
		const response = await request.get(
			`api/${localStorage.getItem("id_app")}/${api}/${id}`,
			{
				params: {
					...params,
					q: JSON.stringify(q),
				},
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...headers,
				},
			}
		);
		setLoading(false);
		return response.data;
	} catch (error) {
		setLoading(false);
		return error;
	}
};

const handleGetDataNoId = async ({ api, q, params, headers, setLoading }) => {
	setLoading(true);
	try {
		const response = await request.get(
			`api/${localStorage.getItem("id_app")}/${api}`,
			{
				params: {
					...params,
					q: JSON.stringify(q),
				},
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...headers,
				},
			}
		);
		setLoading(false);
		return response.data;
	} catch (error) {
		setLoading(false);
		return error;
	}
};

const handleCreate = async ({
	api,
	data,
	headers,
	messageApi = {},
	setLoading = () => {},
}) => {
	setLoading(true);
	try {
		const response = await request.post(
			`api/${localStorage.getItem("id_app")}/${api}`,
			data,
			{
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...headers,
				},
			}
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Tạo thành công",
		});
		return response;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Tạo thất bại",
		});
		return error;
	} finally {
		setLoading(false);
	}
};

const handleCreateFile = async ({ data, messageApi, setLoading }) => {
	setLoading(true);
	try {
		const formData = new FormData();
		formData.append("file", data);
		const response = await request.post(
			`api/uploadfile?json=1&folder=files`,
			formData,
			{
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "multipart/form-data",
				},
			}
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Thành công",
		});
		return response.data;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Thất bại",
		});
		return error;
	}
};

const handleExportFile = async ({ api, params, messageApi }) => {
	try {
		const response = await request.get(
			`api/${localStorage.getItem("id_app")}/${api}/excel`,
			{
				params: {
					...params,
				},
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
				responseType: "arraybuffer",
			}
		);
		messageApi.open({
			type: "success",
			content: "Thành công",
		});
		return response.data;
	} catch (error) {
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Thất bại",
		});
		return error;
	}
};

const handleUpdate = async ({
	api,
	data,
	params,
	header,
	messageApi = {},
	setLoading = () => {},
}) => {
	setLoading(true);
	try {
		const response = await request.put(
			`api/${localStorage.getItem("id_app")}/${api}/${data._id}`,
			data,
			{
				params: {
					...params,
				},
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
					...header,
				},
			}
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Thay đổi thành công",
		});
		return response;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Thay đổi thất bại",
		});
		return error;
	}
};

const handleUpdateArray = async ({
	api,
	ids,
	dataChange,
	params,
	messageApi = {},
	setLoading = () => {},
}) => {
	setLoading(true);
	try {
		await Promise.all(
			ids.map(async (item) => {
				await request.put(
					`api/${localStorage.getItem("id_app")}/${api}/${item}`,
					dataChange,
					{
						params: {
							...params,
						},
						headers: {
							"X-Access-Token": localStorage.getItem("token"),
							"Content-Type": "application/json",
						},
					}
				);
			})
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Xoá thành công",
		});
		return true;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Xoá thất bại",
		});
		return error;
	}
};

const handleDelete = async ({ api, id, messageApi, setLoading }) => {
	setLoading(true);
	try {
		await request.delete(
			`api/${localStorage.getItem("id_app")}/${api}/${id}`,
			{
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			}
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Xoá thành công",
		});
		return true;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Xoá thất bại",
		});
		return error;
	}
};

const handleDeleteArray = async ({ api, ids, messageApi, setLoading }) => {
	setLoading(true);
	try {
		await Promise.all(
			ids.map(async (item) => {
				await request.delete(
					`api/${localStorage.getItem("id_app")}/${api}/${item}`,
					{
						headers: {
							"X-Access-Token": localStorage.getItem("token"),
							"Content-Type": "application/json",
						},
					}
				);
			})
		);
		setLoading(false);
		messageApi.open({
			type: "success",
			content: "Xoá thành công",
		});
		return true;
	} catch (error) {
		setLoading(false);
		messageApi.open({
			type: "error",
			content: error.response.data.error || "Xoá thất bại",
		});
		return error;
	}
};

const handleDeleteDataAll = async ({
	api,
	params,
	q,
	IdApp = true,
	headers,
	messageApi,
	setLoading,
}) => {
	setLoading(true);
	try {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		const response = await request.get(`api/${newIdApp}${api}`, {
			params: {
				...params,
				q: JSON.stringify(q),
				limit: 500,
			},
			headers: {
				"X-Access-Token": localStorage.getItem("token"),
				"Content-Type": "application/json",
				...headers,
			},
		});
		const deletePromises = response?.data?.map(async (item) => {
			try {
				await request.delete(
					`api/${localStorage.getItem("id_app")}/${api}/${item?._id}`,
					{
						headers: {
							"X-Access-Token": localStorage.getItem("token"),
							"Content-Type": "application/json",
						},
					}
				);
				return { success: true };
			} catch (error) {
				return {
					success: false,
					error: error.response.data.error || "Xoá thất bại",
				};
			}
		});

		const results = await Promise.all(deletePromises);

		const failedDeletes = results.filter((result) => !result.success);

		setLoading(false);

		if (failedDeletes.length === 0) {
			messageApi.open({
				type: "success",
				content: "Xoá thành công",
			});
		} else {
			const errorMessage = `Có ${
				failedDeletes.length
			} phần tử không thể xoá. Chi tiết lỗi: ${failedDeletes
				.map((result) => result.error)
				.join(", ")}`;
			messageApi.open({
				type: "error",
				content: errorMessage,
			});
		}
		return true;
	} catch (error) {
		console.log(error);
		messageApi.open({
			type: "error",
			content: "Xoá thất bại",
		});
		return error;
	} finally {
		setLoading(false);
	}
};

const handleUpdateDataAll = async ({
	api,
	params,
	q,
	dataChange,
	IdApp = true,
	headers,
	messageApi = {},
	setLoading = () => {},
}) => {
	setLoading(true);
	try {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		const response = await request.get(`api/${newIdApp}${api}`, {
			params: {
				...params,
				q: JSON.stringify(q),
				limit: 500,
			},
			headers: {
				"X-Access-Token": localStorage.getItem("token"),
				"Content-Type": "application/json",
				...headers,
			},
		});
		const deletePromises = response?.data?.map(async (item) => {
			try {
				await request.put(
					`api/${localStorage.getItem("id_app")}/${api}/${item?._id}`,
					{
						...item,
						...dataChange,
					},
					{
						headers: {
							"X-Access-Token": localStorage.getItem("token"),
							"Content-Type": "application/json",
						},
					}
				);
				return { success: true };
			} catch (error) {
				return {
					success: false,
					error: error.response.data.error || "Xoá thất bại",
				};
			}
		});

		const results = await Promise.all(deletePromises);

		const failedDeletes = results.filter((result) => !result.success);

		setLoading(false);

		if (failedDeletes.length === 0) {
			messageApi.open({
				type: "success",
				content: "Thay đổi thành công",
			});
		} else {
			const errorMessage = `Có ${
				failedDeletes.length
			} phần tử không thể thay đổi. Chi tiết lỗi: ${failedDeletes
				.map((result) => result.error)
				.join(", ")}`;
			messageApi.open({
				type: "error",
				content: errorMessage,
			});
		}
		return true;
	} catch (error) {
		console.log(error);
		messageApi.open({
			type: "error",
			content: "Thay đổi thất bại",
		});
		return error;
	} finally {
		setLoading(false);
	}
};
export {
	handleGetDataAndRows,
	handleGetData,
	handleGetDataById,
	handleGetDataNoId,
	handleCreate,
	handleCreateFile,
	handleExportFile,
	handleUpdate,
	handleUpdateArray,
	handleDelete,
	handleDeleteArray,
	handleDeleteDataAll,
	handleUpdateDataAll,
};
