import { Button, Collapse, Form } from "antd";
import React, { useEffect, useRef } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useMediaQuery } from "react-responsive";
import "../../managerServices.scss";

export default function ModalScreen({
	idModal,
	formSections,
	handleManager,
	data = {},
	groupButton,
	itemFooter,
}) {
	const formRef = useRef(null);

	useEffect(() => {
		formRef?.current?.setFieldsValue(data);
	}, [data]);

	const onFinish = (values) => {
		handleManager(values);
		onReset();
	};
	const onReset = () => {
		formRef.current?.resetFields();
	};
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const getFieldValue = (name) => {
		return formRef?.current?.getFieldValue(name);
	};
	const setFieldsValue = (name, value) => {
		formRef?.current?.setFieldsValue({ [name]: value });
	};

	return (
		<Form
			ref={formRef}
			name="control-ref-full"
			onFinish={onFinish}
			layout={isTabletOrMobile ? "vertical" : "horizontal"}
			labelWrap
			className="mdf_content"
		>
			<div className="mdf_container">
				<div className="mdf_content">
					{formSections.map(({ id, title, content }) => (
						<Collapse
							key={id + idModal}
							defaultActiveKey={["1"]}
							size="small"
							expandIcon={({ isActive }) => (
								<HiChevronDown
									style={{
										transition: "all 0.3s ease-in-out",
										transform: `rotate(${
											isActive ? 0 : -90
										}deg)`,
									}}
								/>
							)}
						>
							<Collapse.Panel
								header={title}
								key="1"
								id={id + idModal}
							>
								{React.cloneElement(content, {
									getFieldValue,
									setFieldsValue,
								})}
							</Collapse.Panel>
						</Collapse>
					))}
				</div>
			</div>
			<div className="w-full justify-between flex items-center flex-row-reverse">
				<div className="flex justify-end items-center gap-4 py-2 px-10 max-sm:px-1">
					{groupButton && groupButton?.length > 0 ? (
						groupButton.map((item, index) => (
							<div key={index}>{item}</div>
						))
					) : (
						<>
							{/* <Button className="btn_error" onClick={onReset}>
								Làm mới
							</Button> */}
							<Button htmlType="submit" className="btn_primary">
								Lưu
							</Button>
						</>
					)}
				</div>
				{itemFooter}
			</div>
		</Form>
	);
}
