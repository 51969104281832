import { Col, Form, Input, InputNumber, Row, Checkbox } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import UploadImageAuto from "../../../components/upLoad/uploadImageAuto";
dayjs.extend(customParseFormat);

export default function FormDmProduct({
	isCheckBoxServices = true,
	isServices = false,
	dataImage,
	setFieldsValue,
	getFieldValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 20 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};

	const handleSetFieldsValue = (value) => {
		setFieldsValue("picture", value);
	};

	const [isLoaiVatTu, setIsLoaiVatTu] = useState();
	useEffect(() => {
		if (getFieldValue("loai_vat_tu")) {
			setIsLoaiVatTu(getFieldValue("loai_vat_tu"));
		}
	}, [getFieldValue("ma_vt")]);

	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* Tên Sản phẩm  */}
			<Col span={24}>
				<Form.Item
					label="Tên Sản phẩm"
					name="ten_vt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input placeholder="Nhập tên sản phẩm" allowClear />
				</Form.Item>
			</Col>

			{/* Giá niêm yết - Giá ưu đãi */}
			<Col span={24}>
				<Form.Item
					label="Giá niêm yết"
					name="gia_ban_le"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Giá ưu đãi"
					name="gia_uu_dai"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>

			{/* Ghi chú khác */}
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={5} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>

			{isCheckBoxServices && (
				<Col span={24}>
					<Form.Item
						label="Loại"
						name="loai_vat_tu"
						labelCol={{
							...layout.labelCol,
						}}
						wrapperCol={{ ...layout.wrapperCol }}
					>
						<Checkbox
							defaultChecked={isServices}
							checked={isLoaiVatTu}
							onChange={(e) => {
								setFieldsValue("loai_vat_tu", e.target.checked);
								setIsLoaiVatTu(e.target.checked);
							}}
						>
							Là dịch vụ
						</Checkbox>
					</Form.Item>
				</Col>
			)}
			<Form.Item name="picture"></Form.Item>
			<Col span={24}>
				<div className="w-full flex flex-col justify-center items-center gap-2">
					<span className="w-full text-center mr-2">
						Hình ảnh đại diện
					</span>
					<UploadImageAuto
						dataImage={dataImage}
						handleSetFieldsValue={handleSetFieldsValue}
					/>
				</div>
			</Col>
		</Row>
	);
}
