import React from "react";
import { Button } from "antd";
import FormItemDetailPn from "../Form/FormItemDetailPn";
import ModalBasic from "../../../components/modal/ModalBasic";

export default function ModalItemDetailPn({
	isOpenForm,
	setIsOpenForm,
	dataDetailItem,
	handleManagerDataItem,
	isTabletOrMobile,
}) {
	return (
		<div>
			<ModalBasic
				zIndex={1035}
				id={"itemDetailpn1-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerDataItem({
						...data,
						// thêm cho đủ field
						tien_ck_nt: 0,
						tien_phi_nt: 0,
					});
					setIsOpenForm(false);
				}}
				data={{
					tien_hang_nt: 0,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormItemDetailPn isTabletOrMobile={isTabletOrMobile} />
			</ModalBasic>

			<ModalBasic
				zIndex={1035}
				id={"itemDetailpn1-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Chi tiết</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"450px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerDataItem({
						...dataDetailItem,
						...data,
					});
					setIsOpenForm(false);
				}}
				data={{
					...dataDetailItem,
					dmvt_vt:
						dataDetailItem.ten_vt + " - " + dataDetailItem.ma_vt,
					ma_vt: dataDetailItem?.ma_vt,
					ten_vt: dataDetailItem?.ten_vt,
					ma_dvt: dataDetailItem?.ma_dvt,
					tk_vt: dataDetailItem?.tk_vt,
				}}
				groupButton={[
					<Button htmlType="submit" className="btn_primary">
						Lưu
					</Button>,
				]}
			>
				<FormItemDetailPn isTabletOrMobile={isTabletOrMobile} />
			</ModalBasic>
		</div>
	);
}
