import { Col, Form, Row, InputNumber, Input } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

export default function FormDetailHd1Voucher({
	getFieldValue,
	setFieldsValue,
}) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[120px]",
			span: isTabletOrMobile ? 10 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 22 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			{/* Sản phẩm */}
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Dịch vụ/sản phẩm"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={1} placeholder="nhập sản phẩm" />
				</Form.Item>
			</Col>

			{/* Số lượng - giá bán */}
			<Col span={24}>
				<Form.Item
					label="Số lượng"
					name="sl_xuat"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								value * (getFieldValue("gia_ban_nt") || 0) -
									((getFieldValue("gia_ban_nt") || 0) *
										value *
										(getFieldValue("ty_le_ck") || 0)) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								((getFieldValue("gia_ban_nt") || 0) *
									value *
									(getFieldValue("ty_le_ck") || 0)) /
									100
							);
						}}
						defaultValue={0}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Giá bán VNĐ"
					name="gia_ban_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								(getFieldValue("sl_xuat") || 0) * value -
									(value *
										getFieldValue("sl_xuat") *
										(getFieldValue("ty_le_ck") || 0)) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								(value *
									getFieldValue("sl_xuat") *
									(getFieldValue("ty_le_ck") || 0)) /
									100
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item
					label="Chiết khấu (%)"
					name="ty_le_ck"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								(getFieldValue("sl_xuat") || 0) *
									(getFieldValue("gia_ban_nt") || 0) -
									((getFieldValue("gia_ban_nt") || 0) *
										(getFieldValue("sl_xuat") || 0) *
										value) /
										100
							);
							setFieldsValue(
								"tien_ck_nt",
								((getFieldValue("gia_ban_nt") || 0) *
									(getFieldValue("sl_xuat") || 0) *
									value) /
									100
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">%</div>
						}
						min={0}
						max={100}
					/>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					label="Chiết khấu (tiền)"
					name="tien_ck_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						onChange={(value) => {
							setFieldsValue(
								"tien_hang_nt",
								(getFieldValue("sl_xuat") || 0) *
									(getFieldValue("gia_ban_nt") || 0) -
									value
							);
						}}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
					/>
				</Form.Item>
			</Col>

			{/* Doanh thu */}
			<Col span={24}>
				<Form.Item
					label="Doanh thu VNĐ"
					name="tien_hang_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						disabled
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
