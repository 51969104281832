import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Spin, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { request } from "../../../utils/request";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function UploadImageAuto({ dataImage, handleSetFieldsValue }) {
	const [loading, setLoading] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();

	useEffect(() => {
		if (dataImage !== "" && dataImage !== undefined) {
			setFileList([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: dataImage,
				},
			]);
		} else {
			setFileList([]);
		}
	}, [dataImage]);

	const handleSavePicture = async (fileImage) => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", fileImage);
			const response = await request.post(
				`api/uploadfile?json=1&folder=files`,
				formData,
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "multipart/form-data",
					},
				}
			);
			const imageUrl = response.data.fileUrl;
			handleSetFieldsValue(`https://api.goodapp.vn${imageUrl}`);
			setLoading(false);
			messageApi.open({
				type: "success",
				content: "Thành công",
			});
		} catch (error) {
			setLoading(false);
			messageApi.open({
				type: "error",
				content: "Thất bại",
			});
			console.log(error);
		}
	};

	const beforeUpload = (file) => {
		return false;
	};

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		handleSavePicture(newFileList[0].originFileObj);
	};

	const uploadButton = (
		<div className="flex items-center justify-center">
			{loading ? (
				<LoadingOutlined className="text-4xl" />
			) : (
				<PlusOutlined className="text-4xl" />
			)}
		</div>
	);

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};

	return (
		<Spin spinning={loading}>
			<div className="flex flex-col justify-start items-start gap-3">
				{contextHolder}
				<div className="flex flex-col justify-center items-start gap-2 mb-5">
					<div className="w-[200px] h-[100px] pb-1">
						<Upload
							beforeUpload={beforeUpload}
							listType="picture-card"
							onPreview={handlePreview}
							fileList={fileList}
							onChange={handleChange}
							className="!w-full !flex justify-center"
						>
							{fileList.length >= 1 ? null : uploadButton}
						</Upload>
					</div>
				</div>{" "}
				<Modal
					open={previewOpen}
					title={previewTitle}
					footer={null}
					onCancel={handleCancel}
					zIndex={10000}
				>
					<img
						alt="example"
						style={{
							width: "100%",
						}}
						src={previewImage}
					/>
				</Modal>
			</div>
		</Spin>
	);
}
