import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { FullLogo } from "../../utils/images";
import isIdApp from "../../utils/isIdApp";
import PopoverModel from "../components/modal/popoverModel";
import { ItemMenuLeft } from "../routers/menu";
import "./leftMenu.scss";
import CustomMenu from "./menu";

export default function LeftMenu() {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [open, setOpen] = useState(false);
	const [placement, setPlacement] = useState();
	const [tabMenu, setTabMenu] = useState([]);
	const anchorRef = useRef(null);

	const [isChangeMenu1, setIsChangeMenu1] = useState(1);
	const [isParent, setIsParent] = useState(1);
	const [isTabItem, setIsTabItem] = useState(1);

	const handleClickOutside = (event) => {
		if (anchorRef.current && !anchorRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	// const pathName = window.location.pathname;
	// useEffect(() => {
	// 	const pathName1 = "/" + pathName.split("/")[1];
	// 	setIsChangeMenu1(pathName1);
	// 	setIsParent(pathName1);
	// 	setIsTabItem(pathName1);
	// 	document.addEventListener("mousedown", handleClickOutside);
	// 	return () => {
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, [pathName]);

	const href = window.location.href;
	useEffect(() => {
		const pathName1 = "/" + href.split("/")[href.split("/").length - 1];
		setIsChangeMenu1(pathName1);
		setIsParent(pathName1);
		setIsTabItem(pathName1);
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [href]);

	const handleClick = (newPlacement, data) => (event) => {
		if (event.currentTarget === anchorRef.current || open === false) {
			setOpen((prev) => placement !== newPlacement || !prev);
		}
		setPlacement(newPlacement);
		setTabMenu(data);
	};
	const navigator = useNavigate();
	const handleNavigation = (item) => () => {
		if (!isIdApp()) {
			navigator("/app");
			alert("Vui lòng chọn công ty");
		} else {
			if (item?.tabItem) {
				return;
			}
			navigator(item?.path);
			setIsChangeMenu1(item?.path);

			// item tab
			setIsParent(item?.path);
			setIsTabItem(item?.path);
		}
	};
	const title =
		tabMenu?.tabItem && tabMenu?.tabItem?.length > 0 ? (
			<div className="flex items-center justify-center border-b">
				<div className="text-lg font-semibold">{tabMenu?.name}</div>
			</div>
		) : (
			false
		);

	const content =
		tabMenu?.tabItem && tabMenu?.tabItem?.length > 0 ? (
			<div className="flex flex-col items-center space-y-1">
				{tabMenu?.tabItem?.map((item, index) => {
					return (
						<div
							key={index}
							className={`flex items-center w-full h-[40px] px-2 rounded-md hover:bg-[#f4f5f7] cursor-pointer ${
								item?.path === isTabItem
									? "bg-[#EBF2FF] text-[#1A68FA] hover:!bg-[#EBF2FF]"
									: ""
							}`}
							onClick={handleNavigation(item)}
						>
							{item.icon}{" "}
							<div className="ml-3 h-full flex items-center">
								{item.title}
							</div>
						</div>
					);
				})}
			</div>
		) : (
			false
		);

	return !isTabletOrMobile ? (
		<React.Fragment>
			<div
				id="left_menu_full"
				className="left_menu_full_container h-full hidden transition-all"
			>
				<div className="fixed h-12 bg-white w-[260px] border flex justify-center z-10">
					<img src={FullLogo} alt="logo" />
				</div>
				<div className="menu_left h-[calc(100vh)] overflow-x-hidden overflow-y-auto pt-12 border-r-[1px]">
					<CustomMenu isTabItem={isTabItem} />
				</div>
			</div>
			<div id="left_menu_mini" className="fixed h-full transition-all">
				<div className="h-12 bg-white w-[80px] border flex justify-center p-3">
					<img src={FullLogo} alt="logo" />
				</div>
				<div className="h-full border-r-[1px]">
					<div className="flex flex-col items-center py-2 space-y-2">
						{ItemMenuLeft?.map((item, index) => {
							return (
								<PopoverModel
									placement="rightTop"
									content={content}
									title={title}
									arrow={false}
									trigger="hover"
									zIndex={9999}
									key={index}
								>
									<Tooltip
										title={item.title}
										placement="right"
										trigger={
											item?.tabItem ? "click" : "hover"
										}
										open={tabMenu?.tabItem && false}
									>
										<div
											ref={anchorRef}
											className={`flex items-center justify-center w-[60%] h-[40px] py-1 px-2 rounded-md hover:bg-[#f4f5f7] cursor-pointer ${
												item?.path === isChangeMenu1 ||
												item?.path ===
													item?.tabItem?.filter(
														(item) =>
															item?.path ===
															isParent
													)[0]?.parent
													? "bg-[#EBF2FF] text-[#1A68FA] hover:!bg-[#EBF2FF]"
													: ""
											}`}
											onClick={handleNavigation(item)}
											onMouseEnter={
												item?.tabItem
													? handleClick(
															"right-start",
															item
													  )
													: handleClick(
															"right-start",
															[]
													  )
											}
										>
											{item.icon}
										</div>
									</Tooltip>
								</PopoverModel>
							);
						})}
					</div>
				</div>
			</div>
		</React.Fragment>
	) : (
		""
	);
}
