import React from "react";
import ModalBasic from "../../../components/modal/ModalBasic";
import FormDmProduct from "../Form/FormDmProduct";

export default function ModalDMProducts({
	dataImage,
	dataDetail,
	handleManagerData,
	isOpenForm,
	setIsOpenForm,
}) {
	return (
		<div>
			<ModalBasic
				// zIndex={1035}
				id={"product-create"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Sản phẩm</span>
					</div>
				}
				open={isOpenForm === "create" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"550px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerData({
						...data,
						ma_dvt: "Bộ",
						exfields: {
							gia_uu_dai: data?.gia_uu_dai,
							dien_giai: data?.dien_giai,
							loai_vat_tu: data?.loai_vat_tu
								? "Services"
								: "Products",
						},
					});
					setIsOpenForm(false);
				}}
			>
				<FormDmProduct />
			</ModalBasic>

			<ModalBasic
				// zIndex={1035}
				id={"product-edit"}
				title={
					<div className="flex justify-start gap-2 items-center w-[calc(100%-20px)]">
						<span>Sản phẩm</span>
					</div>
				}
				open={isOpenForm === "edit" ? true : false}
				onCancel={() => {
					setIsOpenForm(false);
				}}
				footer={null}
				width={"550px"}
				styleBodyForm={{ paddingTop: "0" }}
				labelAlign="left"
				handleManager={(data) => {
					handleManagerData({
						...dataDetail,
						...data,
						exfields: {
							...dataDetail.exfields,
							gia_uu_dai: data?.gia_uu_dai,
							dien_giai: data?.dien_giai,
							loai_vat_tu: data?.loai_vat_tu
								? "Services"
								: "Products",
						},
					});
					setIsOpenForm(false);
				}}
				data={{
					picture: dataDetail?.picture,
					ten_vt: dataDetail?.ten_vt,
					gia_ban_le: dataDetail?.gia_ban_le,
					gia_uu_dai: dataDetail?.exfields?.gia_uu_dai,
					dien_giai: dataDetail?.exfields?.dien_giai,
				}}
			>
				<FormDmProduct dataImage={dataImage} />
			</ModalBasic>
		</div>
	);
}
