import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import TableManagerCus from "../../../components/table/tableManagerCus";
import ModalItemDetailPn from "../Modal/ModalItemDetailPn";
import ItemTotalPn from "./ItemTotalPn";

export default function ItemDetailPn({
	isTabletOrMobile,
	setFieldsValue,
	getFieldValue,
}) {
	const [dataDetailItem, setDataDetailItem] = useState({});
	const [isOpenForm, setIsOpenForm] = useState(false);

	const [dataModalDetail, setDataModalDetail] = useState([]);

	useEffect(() => {
		setDataModalDetail(getFieldValue("details") || []);
	}, [getFieldValue("details")]);

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenForm("edit");
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setDataDetailItem(data);
				setIsOpenForm("edit");
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (data) => {
				handleDeleteData(data);
			},
			label: "Delete",
		},
	];
	const columnsTabItem = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key !== "3" ? (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								) : (
									<Popconfirm
										title={`Bạn muốn xoá ${record.ten_vt}`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "#",
			dataIndex: "",
			width: 20,
		},

		{
			title: "Tên dịch vụ/sản phẩm",
			dataIndex: "ten_vt",
			width: 200,
		},
		// {
		// 	title: "đvt",
		// 	dataIndex: "ma_dvt",
		// 	width: 100,
		// },
		{
			title: "Số lượng",
			dataIndex: "sl_nhap",
			width: 100,
			render: (sl_nhap) => {
				return `${(sl_nhap || 0)?.toLocaleString()}`;
			},
		},
		{
			title: "Đơn giá (đ)",
			dataIndex: "gia_von_nt",
			width: 150,
			render: (gia_von_nt) => {
				return `${(gia_von_nt || 0)?.toLocaleString()}`;
			},
		},
		{
			title: "Thành tiền (đ)",
			dataIndex: "tien_hang_nt",
			width: 150,
			render: (tien_hang_nt) => {
				return `${(tien_hang_nt || 0)?.toLocaleString()}`;
			},
		},
	];
	const handleSaveData = (dataModal) => {
		setDataModalDetail([...dataModalDetail, dataModal]);
		setFieldsValue("details", [...dataModalDetail, dataModal]);
	};
	const handleUpdateData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) =>
					JSON.stringify(item) === JSON.stringify(dataDetailItem)
			);
			if (indexToUpdate !== -1) {
				updaData[indexToUpdate] = dataModal;
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	const handleManagerDataItem = (dataModal) => {
		if (isOpenForm === "create") {
			handleSaveData(dataModal);
		} else {
			handleUpdateData(dataModal);
		}
	};

	const handleDeleteData = (dataModal) => {
		if (dataModal._id) {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => item._id === dataModal._id
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		} else {
			const updaData = [...dataModalDetail];
			const indexToUpdate = updaData.findIndex(
				(item) => JSON.stringify(item) === JSON.stringify(dataModal)
			);
			if (indexToUpdate !== -1) {
				updaData.splice(indexToUpdate, 1);
				setDataModalDetail(updaData);
				setFieldsValue("details", updaData);
			}
		}
	};

	return (
		<div>
			<div className="py-2 relative">
				<TableManagerCus
					id="dataDetailPn-tab"
					data={dataModalDetail}
					columns={columnsTabItem}
					scroll={{
						x: 900,
						y: "30vh",
					}}
					tableProps={{
						size: "middle",
					}}
					onRow={(record) => ({
						onClick: () => {
							setIsOpenForm("edit");
							setDataDetailItem(record);
						},
					})}
				/>
				<Button
					onClick={() => {
						setIsOpenForm("create");
					}}
					className="btn_secondary hover:!text-white absolute bottom-4"
				>
					<span className="max-sm:!hidden">Tạo mới</span>{" "}
					<MdOutlinePlaylistAdd />
				</Button>

				<ModalItemDetailPn
					isOpenForm={isOpenForm}
					setIsOpenForm={setIsOpenForm}
					dataDetailItem={dataDetailItem}
					isTabletOrMobile={isTabletOrMobile}
					handleManagerDataItem={handleManagerDataItem}
				/>
			</div>
			<ItemTotalPn getFieldValue={getFieldValue} />
		</div>
	);
}
