import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button, Form } from "antd";
import { request } from "../../../utils/request";
import { debounce } from "lodash";

const SelectAdd = ({
	label = "",
	name,
	labelCol,
	wrapperCol,
	rules,
	disabled = false,
	api = "dmnv",
	q,
	IdApp = true,
	fieldsAdd = [],
	dataAdd,
	lableOption = ["ten_nv"],
	valueOption = ["ten_nv"],
	placeholder = "custom dropdown render",
	style,
	dropdownHeight = 300,
	onChange,
}) => {
	// const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [isName, setIsName] = useState("");
	const inputRef = useRef(null);
	const selectRef = useRef(null);
	// const [page, setPage] = useState(1);

	useEffect(() => {
		// setPage(1);
	}, [q]);

	const onNameChange = (event) => {
		setIsName(event.target.value);
		debouncedHandleSearch(event.target.value);
	};

	const addItem = async (e) => {
		e.preventDefault();
		await handleAddData(isName);
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};

	const handelGetData = async ({ endpoint, params }) => {
		const newIdApp = IdApp ? `${localStorage.getItem("id_app")}/` : "";
		try {
			// setLoading(true);
			const response = await request.get(`api/${newIdApp}${endpoint}`, {
				headers: {
					"X-Access-Token": localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
				params: {
					page: 1,
					limit: 100,
					q: JSON.stringify(params.q),
				},
			});
			const data = response.data.map((item) => item);
			setItems(data);
			// setItems(page === 1 ? data : [...items, ...data]);
			// setPage(page + 1);
			// setLoading(false);
			return response;
		} catch (error) {
			console.log(error.message);
			// setLoading(false);
		}
	};

	const handleAddData = async (name) => {
		try {
			const requestData = {};
			fieldsAdd.forEach((field) => {
				requestData[field] = name;
			});
			const response = await request.post(
				`api/${localStorage.getItem("id_app")}/${api}`,
				{ ...requestData, ...dataAdd },
				{
					headers: {
						"X-Access-Token": localStorage.getItem("token"),
						"Content-Type": "application/json",
					},
				}
			);
			setItems([response.data, ...items]);
			setIsName("");
			return response;
		} catch (error) {
			console.log(error.message);
		}
	};

	const handleDropdownVisibleChange = async (open) => {
		const query = {
			...q,
		};
		if (open) {
			await handelGetData({ endpoint: api, params: { q: query } });
		}
	};

	const handleSearch = async (value) => {
		const query = {
			...q,
		};

		if (value) {
			if (!query.$or) query.$or = [];
			valueOption.forEach((fieldName) => {
				const fieldQuery = {};
				fieldQuery[fieldName] = {
					$regex: value,
					$options: "i",
				};
				query.$or.push(fieldQuery);
			});
		}

		await handelGetData({ endpoint: api, params: { q: query } });
	};
	const debouncedHandleSearch = useRef(debounce(handleSearch, 500)).current;

	const handleScroll = () => {
		const selectNode = selectRef.current;
		if (selectNode) {
			const { scrollTop, scrollHeight, clientHeight } = selectNode;
			if (scrollTop + clientHeight === scrollHeight) {
				// Đã cuộn đến cuối, gọi hàm để load thêm dữ liệu
				handelGetData({ endpoint: api, params: q });
			}
		}
	};

	return (
		<Form.Item
			name={name}
			label={label}
			labelCol={labelCol}
			wrapperCol={wrapperCol}
			rules={rules}
		>
			<Select
				allowClear
				disabled={disabled}
				showSearch
				style={style}
				placeholder={placeholder}
				dropdownRender={(menu) => (
					<>
						<div
							ref={selectRef}
							style={{
								maxHeight: dropdownHeight,
								overflowY: "auto",
								overflowX: "hidden",
							}}
							onScroll={handleScroll}
						>
							{/* {loading && <div>Loading...</div>} */}
							{menu}
						</div>
						<Divider
							style={{
								margin: "8px 0",
							}}
						/>
						{fieldsAdd.length > 0 && (
							<Space
								style={{
									padding: "0 8px 4px",
								}}
							>
								<Input
									placeholder="Nhập nội dung..."
									ref={inputRef}
									value={isName}
									onChange={onNameChange}
									onKeyDown={(e) => e.stopPropagation()}
								/>
								<Button
									type="text"
									icon={<PlusOutlined />}
									onClick={addItem}
								>
									Tạo nhanh
								</Button>
							</Space>
						)}
					</>
				)}
				options={items.map((item) => ({
					value:
						valueOption && valueOption.length > 1
							? JSON.stringify(
									valueOption.reduce((acc, option) => {
										acc[option] = item[option];
										return acc;
									}, {})
							  )
							: item[valueOption[0]],
					label:
						lableOption && lableOption.length > 1
							? lableOption
									.map((option) => item[option])
									.join(" - ")
							: item[lableOption[0]],
				}))}
				onDropdownVisibleChange={handleDropdownVisibleChange}
				onSearch={debouncedHandleSearch}
				onChange={onChange}
			/>
		</Form.Item>
	);
};

export default SelectAdd;
