import { Badge, Button, Divider, Dropdown } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { CgMenuCheese } from "react-icons/cg";
import { FcCalendar, FcPlus } from "react-icons/fc";
import { MdOutlineNotifications } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../SocketProvider";
import { FullLogo } from "../../utils/images";
import { handleGetData, handleUpdateArray } from "../../utils/utilsApi";
import { formatDateTime } from "../../utils/utilsFunc";
import DrawerModel from "../components/modal/drawerModel";
import CustomMenu from "../menu/menu";
import "./header.scss";

export default function Header() {
	const { totalNotify, newNotify } = useContext(SocketContext);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const [openMenuMobile, setOpenMenuMobile] = useState(false);
	const [isTabItem, setIsTabItem] = useState(1);

	// const pathName = window.location.pathname;
	// useEffect(() => {
	// 	const pathName1 = "/" + pathName.split("/")[1];
	// 	setIsTabItem(pathName1);
	// 	setOpenMenuMobile(false);
	// }, [pathName]);

	const href = window.location.href;
	useEffect(() => {
		const pathName1 = "/" + href.split("/")[href.split("/").length - 1];
		setIsTabItem(pathName1);
		setOpenMenuMobile(false);
	}, [href]);

	const navigate = useNavigate();
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("id_app");
		localStorage.removeItem("name_app");
		navigate("/sign-in");
	};

	const handleMenu = () => {
		const left_menu = document.getElementById("left_menu");
		const body_content = document.getElementById("body_content");
		const header_content = document.getElementById("header_content");
		const left_menu_full = document.getElementById("left_menu_full");
		const left_menu_mini = document.getElementById("left_menu_mini");
		const table_user = document.getElementById("table_user");
		const table_rank = document.getElementById("table_rank");

		if (left_menu?.classList.contains("w-[80px]")) {
			left_menu?.classList.remove("w-[80px]");
			left_menu?.classList.add("w-[260px]");
			body_content?.classList.remove("w-[calc(100%-80px)]");
			body_content?.classList.add("w-[calc(100%-260px)]");
			body_content?.classList.add("transition-all");
			header_content?.classList.remove("w-[calc(100%-80px)]");
			header_content?.classList.add("w-[calc(100%-260px)]");
			left_menu_full?.classList.remove("hidden");
			left_menu_mini?.classList.add("hidden");
			table_user?.classList.remove("w-[65%]");
			table_user?.classList.add("w-[75%]");
			table_rank?.classList.remove("w-[30%]");
			table_rank?.classList.add("w-[25%]");
		} else {
			left_menu?.classList.remove("w-[260px]");
			left_menu?.classList.add("w-[80px]");
			body_content?.classList.remove("w-[calc(100%-260px)]");
			body_content?.classList.add("w-[calc(100%-80px)]");
			header_content?.classList.remove("w-[calc(100%-260px)]");
			header_content?.classList.add("w-[calc(100%-80px)]");
			left_menu_full?.classList.add("hidden");
			left_menu_mini?.classList.remove("hidden");
			table_user?.classList.remove("w-[75%]");
			table_user?.classList.add("w-[65%]");
			table_rank?.classList.remove("w-[25%]");
			table_rank?.classList.add("w-[30%]");
		}
	};

	const handleMenuMobile = () => {
		const left_menu = document.getElementById("left_menu");
		const body_content = document.getElementById("body_content");
		const header_content = document.getElementById("header_content");
		const left_menu_full = document.getElementById("left_menu_full");
		const left_menu_mini = document.getElementById("left_menu_mini");
		const table_user = document.getElementById("table_user");
		const table_rank = document.getElementById("table_rank");

		if (left_menu?.classList.contains("w-[80px]")) {
			left_menu?.classList.remove("w-[80px]");
			left_menu?.classList.add("w-[0px]");
			body_content?.classList.remove("w-[calc(100%-80px)]");
			body_content?.classList.add("w-[calc(100%-0px)]");
			body_content?.classList.add("transition-all");
			header_content?.classList.remove("w-[calc(100%-80px)]");
			header_content?.classList.add("w-[calc(100%-0px)]");
			left_menu_mini?.classList.add("hidden");
			table_user?.classList.remove("w-[65%]");
			table_user?.classList.add("w-[75%]");
			table_rank?.classList.remove("w-[30%]");
			table_rank?.classList.add("w-[25%]");
		} else {
			left_menu?.classList.remove("w-[260px]");
			left_menu?.classList.add("w-[0px]");
			body_content?.classList.remove("w-[calc(100%-260px)]");
			body_content?.classList.add("w-[calc(100%-0px)]");
			header_content?.classList.remove("w-[calc(100%-260px)]");
			header_content?.classList.add("w-[calc(100%-0px)]");
			left_menu_full?.classList.add("hidden");
			table_user?.classList.remove("w-[75%]");
			table_user?.classList.add("w-[65%]");
			table_rank?.classList.remove("w-[25%]");
			table_rank?.classList.add("w-[30%]");
		}
	};

	const handleMenu2 = () => {
		const left_menu = document.getElementById("left_menu");
		const body_content = document.getElementById("body_content");
		const header_content = document.getElementById("header_content");
		const left_menu_full = document.getElementById("left_menu_full");
		const left_menu_mini = document.getElementById("left_menu_mini");
		const table_user = document.getElementById("table_user");
		const table_rank = document.getElementById("table_rank");

		left_menu?.classList.remove("w-[260px]");
		left_menu?.classList.add("w-[80px]");
		body_content?.classList.remove("w-[calc(100%-260px)]");
		body_content?.classList.add("w-[calc(100%-80px)]");
		header_content?.classList.remove("w-[calc(100%-260px)]");
		header_content?.classList.add("w-[calc(100%-80px)]");
		left_menu_full?.classList.add("hidden");
		left_menu_mini?.classList.remove("hidden");
		table_user?.classList.remove("w-[75%]");
		table_user?.classList.add("w-[65%]");
		table_rank?.classList.remove("w-[25%]");
		table_rank?.classList.add("w-[30%]");
	};

	const getNameUser = () => {
		const current_user = JSON.parse(localStorage.getItem("current_user"));
		return current_user?.name;
	};

	const getNameCompany = () => {
		const current_company = localStorage.getItem("name_app");
		return current_company;
	};

	useEffect(() => {
		if (isTabletOrMobile) {
			handleMenuMobile();
		} else {
			handleMenu2();
		}
	}, [isTabletOrMobile]);

	const items = [
		{
			key: "1",
			label: (
				<div className="border-b pb-1 lg:hidden">{getNameUser()}</div>
			),
		},
		{
			key: "2",
			label: (
				<div
					onClick={() => {
						navigate("/app");
					}}
				>
					Cấu hình công ty
				</div>
			),
		},
		{
			key: "3",
			label: <div onClick={handleLogout}>Đăng xuất</div>,
		},
		{
			key: "4",
			label: (
				<div className="flex items-center space-x-5 lg:hidden border-t pt-4">
					<Button
						size="small"
						className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
						onClick={() => {
							console.log(1);
						}}
					>
						{<FcPlus size={24} />}
					</Button>
					<Button
						size="small"
						className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
						onClick={() => {
							navigate("/calendar-booking");
						}}
					>
						{<FcCalendar size={24} />}
					</Button>
					<Badge size="small" count={totalNotify} className="!m-0">
						<Button
							size="small"
							className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
							onClick={() => {
								console.log(1);
							}}
						>
							{<MdOutlineNotifications size={24} />}
						</Button>
					</Badge>
				</div>
			),
		},
	];

	useEffect(() => {
		if (newNotify) {
			setItemsNotify([]);
			setPageNotify(0);
			setIsDataNotify(true);
		}
	}, [newNotify]);

	const [pageNotify, setPageNotify] = useState(0);
	const [itemsNotify, setItemsNotify] = useState([]);
	const [isDataNotify, setIsDataNotify] = useState(true);
	const handleDataNotify = async ({ pageNotify }) => {
		const dataNotify = await handleGetData({
			api: "notification",
			page: pageNotify,
			limit: 10,
			IdApp: false,
			// q: {
			// 	"exfields.id_app": localStorage.getItem("id_app"),
			// },
		});
		if (dataNotify?.data?.length > 0 || itemsNotify?.length > 0) {
			const newRead = [];
			const newDataNotify = dataNotify?.data?.map((item) => {
				if (item?.read === false) {
					newRead.push(item?._id);
				}
				return {
					key: item?._id,
					label: (
						<div
							onClick={(e) => {
								e.stopPropagation();
								console.log(item);
							}}
							className="flex flex-col items-start pb-1 border-b"
						>
							<div className="font-medium">{item?.title}</div>
							<div>{item?.content}</div>
							<div className="text-xs text-right w-full">
								{item?.exfields?.company}
							</div>
							<div className="text-xs text-right w-full">
								{formatDateTime(item?.date_created)}
							</div>
						</div>
					),
				};
			});
			setPageNotify(pageNotify);
			setItemsNotify([...itemsNotify, ...newDataNotify]);

			// if (newNotify && pageNotify !== 1) {
			// 	setItemsNotify([
			// 		{
			// 			key: newNotify?._id,
			// 			label: (
			// 				<div
			// 					onClick={(e) => {
			// 						e.stopPropagation();
			// 						console.log(newNotify);
			// 					}}
			// 					className="flex flex-col newNotifys-start pb-1 border-b"
			// 				>
			// 					<div className="font-medium">
			// 						{newNotify?.title}
			// 					</div>
			// 					<div>{newNotify?.content}</div>
			// 					<div className="text-xs text-right w-full">
			// 						{newNotify?.exfields?.company || "-"}
			// 					</div>
			// 					<div className="text-xs text-right w-full">
			// 						{formatDateTime(newNotify?.date_created) ||
			// 							"-"}
			// 					</div>
			// 				</div>
			// 			),
			// 		},
			// 		...itemsNotify,
			// 		...newDataNotify,
			// 	]);
			// } else {
			// 	setItemsNotify([...itemsNotify, ...newDataNotify]);
			// }

			if (dataNotify?.data?.length < 10) {
				setIsDataNotify(false);
			} else {
				setIsDataNotify(true);
			}
			// await handleUpdateArray({
			// 	api: "notification",
			// 	ids: newRead,
			// 	dataChange: {
			// 		read: true,
			// 	},
			// });
		} else {
			setItemsNotify([]);
		}
	};

	return (
		<div
			id="header_content"
			className="fixed h-12 bg-white border-b-[1px] flex-col transition-all z-10 w-[calc(100%-80px)]"
		>
			<div className="h-full flex justify-between items-center px-2">
				<div className="flex items-center w-[calc(100%-160px)] max-md:w-[calc(100%-60px)]">
					<Button
						className="mx-[8px!important] max-lg:!mx-0 !px-1 border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
						onClick={() => {
							if (!isTabletOrMobile) {
								handleMenu();
							} else {
								setOpenMenuMobile(true);
							}
						}}
					>
						<CgMenuCheese size={26} />
					</Button>
					<div className="pl-5 flex items-center w-[calc(100%-60px)]">
						<span className="max-lg:!hidden">
							Công ty hiện tại:&nbsp;
						</span>{" "}
						<span className="overflow-ellipsis !max-w-[80%]">
							{getNameCompany()}
						</span>
					</div>
				</div>
				<div className="flex items-center space-x-5 pr-2">
					<div className="flex items-center space-x-5 max-lg:hidden">
						<Button
							size="small"
							className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
							onClick={() => {
								console.log(1);
							}}
						>
							{<FcPlus size={24} />}
						</Button>
						{/* <Button
							size="small"
							className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
							onClick={() => {
								console.log(1);
							}}
						>
							{<FcSms size={24} />}
						</Button> */}
						<Button
							size="small"
							className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
							onClick={() => {
								navigate("/calendar-booking");
							}}
						>
							{<FcCalendar size={24} />}
						</Button>
						<Badge
							size="small"
							count={totalNotify}
							className="!m-0"
						>
							<Dropdown
								menu={{
									items: [
										...itemsNotify,
										{
											key: 1,
											label: isDataNotify ? (
												<div className="w-full flex justify-center items-center gap-2">
													({itemsNotify.length} thông
													báo){" "}
													<Button
														onClick={(e) => {
															e.stopPropagation();
															handleDataNotify({
																pageNotify:
																	pageNotify +
																	1,
															});
														}}
													>
														Xem thêm
													</Button>
												</div>
											) : (
												<div className="w-full flex justify-center">
													({itemsNotify.length}) Hết
													thông báo
												</div>
											),
										},
									],
								}}
								placement="bottomCenter"
								trigger={["click"]}
								overlayStyle={{
									maxHeight: "calc(100vh - 150px)",
									overflowY: "auto",
									boxShadow: "0 0 10px #ccc",
									borderRadius: "5px",
									width: "350px",
								}}
							>
								<Button
									size="small"
									className="!mx-[2px] !px-[2px] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
									onClick={() => {
										isDataNotify &&
											handleDataNotify({
												pageNotify: pageNotify + 1,
											});
									}}
								>
									{<MdOutlineNotifications size={24} />}
								</Button>
							</Dropdown>
						</Badge>
						<Divider type="vertical" className="!h-7" />
					</div>
					<div className="flex items-center cursor-pointer max-md:hidden">
						<span className="overflow-ellipsis !max-w-[200px]">
							{getNameUser()}
						</span>
					</div>
					<Divider type="vertical" className="!h-7" />
					<div className="flex items-center">
						<Dropdown
							menu={{
								items,
							}}
							placement="bottomLeft"
						>
							<Button
								className="mx-[8px!important] border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
								shape="circle"
							>
								<img
									src={
										"https://api.fostech.vn/images/avatar.jpg"
									}
									alt="avatar"
									className="w-8 h-8 rounded-full"
								/>
							</Button>
						</Dropdown>
					</div>
				</div>
			</div>
			<React.Fragment>
				{/* Menu mobile */}
				<DrawerModel
					title={
						<header className="h-[40px] w-[240px] px-5 flex justify-between items-center">
							<div className="h-full w-[calc(100%-50px)] bg-white flex justify-center">
								<img
									src={FullLogo}
									alt="logo"
									className="h-full object-contain"
								/>
							</div>
							<div className="flex justify-end items-center gap-4">
								<Button
									className="mx-[8px!important] !px-1 border-none flex !text-cyan-900 justify-center items-center hover:bg-slate-200"
									onClick={() => setOpenMenuMobile(false)}
								>
									<AiOutlineClose size={26} />
								</Button>
							</div>
						</header>
					}
					placement={"left"}
					height={"fit-content"}
					width={280}
					open={!isTabletOrMobile ? false : openMenuMobile}
					onClose={() => setOpenMenuMobile(false)}
				>
					<div className="left_menu_mobile max-h-[calc(95vh)] overflow-hidden">
						<div className="max-h-[90vh] overflow-y-auto">
							<div className="h-fit">
								<CustomMenu isTabItem={isTabItem} />
							</div>
						</div>
					</div>
				</DrawerModel>
			</React.Fragment>
		</div>
	);
}
