import { Col, Form, Input, Row, InputNumber } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";

export default function FormTdttnosPt({ setFieldsValue, getFieldValue }) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

	const layout = {
		labelCol: {
			className: "min-w-[140px]",
			span: isTabletOrMobile ? 20 : 6,
		},
		wrapperCol: { span: isTabletOrMobile ? 24 : 18 },
	};
	return (
		<Row className="!m-0 border-t pt-4" gutter={[8, 8]}>
			<Form.Item name="thanh_toan_qd"></Form.Item>

			<Col span={24}>
				<Form.Item
					label="Tiền VNĐ"
					name="tien_nt"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<InputNumber
						formatter={(value) =>
							`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						defaultValue={0}
						addonAfter={
							<div className="w-[30px] text-center">VND</div>
						}
						onChange={(value) => {
							if (value > getFieldValue("con_lai_nt")) {
								setFieldsValue(
									"thanh_toan_qd",
									getFieldValue("con_lai_nt")
								);
								setFieldsValue(
									"tien_nt",
									getFieldValue("con_lai_nt")
								);
							}
							setFieldsValue("thanh_toan_qd", value);
						}}
					/>
				</Form.Item>
			</Col>

			{/* ghi chú */}
			<Col span={24}>
				<Form.Item
					name="dien_giai"
					label="Ghi chú khác"
					labelCol={{
						...layout.labelCol,
					}}
					wrapperCol={{ ...layout.wrapperCol }}
				>
					<Input.TextArea rows={3} placeholder="nhập ghi chú" />
				</Form.Item>
			</Col>
		</Row>
	);
}
