import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { BsPersonPlus } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import {
	handleCreate,
	handleDelete,
	handleGetDataAndRows,
	handleUpdate,
} from "../../../../utils/utilsApi";
import FormInfomation from "../../../ManagerVouchers/components/Form/FormInfomation";
import ItemDetailPt from "../../../ManagerVouchers/components/Item/ItemDetailPt";
import TableManagerCus from "../../../components/table/tableManagerCus";
import ModalCustomerPt1 from "../item/ModalCustomerPt1";
import ItemTdttnosPt from "../../../ManagerVouchers/components/Item/ItemTdttnosPt";
import { TfiReload } from "react-icons/tfi";
import { TiPrinter } from "react-icons/ti";
import { request } from "../../../../utils/request";

export default function CustomerPt1({
	setPrintFrame,
	dataDefault,
	dataModal,
	messageApi,
}) {
	// modal
	const [isOpenCustomerPt1, setIsOpenCustomerPt1] = useState(false);
	const [loading, setLoading] = useState(false);
	const [dataCustomerPt1, setDataCustomerPt1] = useState([]);
	const [detailCustomerPt1, setDetailCustomerPt1] = useState({});

	const printDefault = (voucher) => {
		setLoading(true);
		let stopRunning = () => {
			setLoading(false);
		};
		let domain = window.location.origin;
		let urlPrintDefault = `${domain}/#/print/${
			voucher.id_app
		}/${localStorage.getItem("token")}/${voucher._id}`;
		console.log(urlPrintDefault);
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				<iframe
					onLoad={() => {
						setTimeout(() => {
							let fr = window.frames["printframe"];
							fr.focus();
							stopRunning();
							fr.print();
							resolve();
						}, 3000);
					}}
					name="printframe"
					title="Print Frame"
					style={{ display: "none", width: "100%", height: "100%" }}
					src={urlPrintDefault}
				></iframe>
			);
			setPrintFrame(printFrame);
		});
	};
	const printLocal = async (url) => {
		setLoading(true);
		let stopRunning = () => {
			setLoading(false);
		};
		let content;
		try {
			const resp = await request.get(url);
			if (resp && resp.status === 200) {
				content = resp.data;
			}
		} catch (e) {
			return alert("info", e.message || "Server error");
		}

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await setPrintFrame(null);
			let printFrame = (
				// eslint-disable-next-line jsx-a11y/iframe-has-title
				<iframe
					onLoad={() => {
						let fr = window.frames["printframe"];
						fr.focus();
						stopRunning();
						fr.print();
						setTimeout(() => {
							resolve();
						}, 10);
					}}
					name="printframe"
					style={{ display: "none", width: "100%", height: "100%" }}
					srcDoc={content}
				></iframe>
			);
			console.log(printFrame);
			setPrintFrame(printFrame);
		});
	};
	const print = async (
		data,
		loai_bill,
		onSuccess,
		onError,
		useDefaultTempleteIfNotFound = true
	) => {
		setLoading(true);
		let printers = ((dataDefault?.dmkho[0] || {}).printers || []).filter(
			(printer) =>
				printer.id_mau_in &&
				(printer.loai_bill == undefined ||
					printer.loai_bill == loai_bill) &&
				printer.ma_cn === "pt1"
		);
		if (printers.length > 0) {
			await Promise.all(
				printers.map((printer) => {
					let url =
						"/api/" +
						localStorage.getItem("id_app") +
						"/pt1/excel/" +
						printer.id_mau_in +
						"?_id=" +
						data._id +
						`&print=1&access_token=` +
						localStorage.getItem("token");
					const print_service_url = printer.dia_chi_may_in;

					return (async () => {
						if (print_service_url) {
							let url_print = `${print_service_url}?url=${btoa(
								url
							)}&printer=${printer.ten_may_in}&width=${
								printer.do_rong_kho_giay || 0
							}&height=${printer.chieu_dai_kho_giay || 0}`;
							try {
								// await asyncGetList({ endpoint: url_print });
							} catch (e) {
								await printLocal(url);
							}
						} else {
							await printLocal(url);
						}
					})();
				})
			);
			setLoading(false);
			if (onSuccess) onSuccess();
		} else {
			//default template print
			if (useDefaultTempleteIfNotFound) {
				try {
					await printDefault(data);
					if (onSuccess) onSuccess();
				} catch (e) {
					if (onError) {
						alert("error", e.message || "Server error");
					} else {
						alert("error", e.message || "Server error");
					}
				}
			} else {
				if (onError) {
					alert("error", "Không tìm thấy mẫu in phù hợp");
				} else {
					if (onSuccess) onSuccess();
				}
			}
		}
	};
	const tables = () => {
		return;
	};

	const items = [
		{
			key: "2",
			icon: <FiEdit3 />,
			onClick: (data) => {
				setIsOpenCustomerPt1("edit");
				setDetailCustomerPt1(data);
			},
			label: "Edit",
		},
		{
			key: "1",
			icon: <BiCopyAlt />,
			onClick: (data) => {
				setIsOpenCustomerPt1("edit");
				setDetailCustomerPt1(data);
			},
			label: "Copy",
		},

		{
			key: "3",
			icon: <AiOutlineDelete />,
			onClick: (_id) => {
				handleDeleteId(_id);
			},
			label: "Delete",
		},

		{
			key: "4",
			icon: <TiPrinter />,
			onClick: (data) => {
				print(
					data,
					1,
					() => {
						tables();
					},
					() => {
						tables();
					}
				);
			},
			label: "Print",
		},
	];

	const columns = [
		{
			title: "",
			dataIndex: "",
			width: 5,
			render: (record) => {
				return (
					<div className="relative">
						<div className="gr_btn_table hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 bg-white rounded-md py-1">
							{items.map((item, index) =>
								item.key === "3" ? (
									<Popconfirm
										title={`Bạn muốn xoá ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record?._id);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								) : item.key === "4" ? (
									<Popconfirm
										title={`In hoá đơn số ${record?.so_ct} ?`}
										onConfirm={(e) => {
											e.stopPropagation();
											item.onClick(record);
										}}
										onCancel={(e) => e.stopPropagation()}
										okText="Có"
										cancelText="Không"
										key={index}
									>
										<Button
											shape="circle"
											size="small"
											className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
											key={index}
											onClick={(e) => e.stopPropagation()}
										>
											{item.icon}
										</Button>
									</Popconfirm>
								) : (
									<Button
										shape="circle"
										size="small"
										className="mx-[8px!important] border-none flex !text-[#186b9e] justify-center items-center hover:bg-slate-200"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											item.onClick(record);
										}}
									>
										{item.icon}
									</Button>
								)
							)}
						</div>
					</div>
				);
			},
			fixed: "left",
		},
		{
			title: "Ngày chứng từ",
			dataIndex: "ngay_ct",
			width: 150,
			render: (ngay_ct) => {
				return ngay_ct
					? new Date(ngay_ct).toLocaleDateString("vi-VN")
					: null;
			},
		},
		{
			title: "Số chứng từ",
			dataIndex: "so_ct",
			width: 100,
		},
		{
			title: "ID Khách",
			dataIndex: "ma_kh",
			width: 150,
		},
		{
			title: "Khách hàng",
			dataIndex: "ten_kh",
			width: 150,
		},
		{
			title: "Tiền (Ngoại tệ)",
			dataIndex: "t_tt_nt",
			width: 150,
			render: (t_tt_nt) => {
				return t_tt_nt?.toLocaleString();
			},
			align: "right",
		},
		{
			title: "Ngoại tệ",
			dataIndex: "ten_nt",
			width: 100,
		},
		{
			title: "Tiền (VND)",
			dataIndex: "t_tt",
			width: 150,
			render: (t_tt) => {
				return t_tt?.toLocaleString("vi-VN", {
					style: "currency",
					currency: "VND",
				});
			},
			align: "right",
		},
		{
			title: "Trạng thái",
			dataIndex: "",
			width: 150,
			render: (record) => {
				return <span>{record?.exfields?.ten_trang_thai_moi}</span>;
			},
		},
	];

	const handleGetData = async (q) => {
		const data = await handleGetDataAndRows({
			api: "pt1",
			q: {
				...q,
			},
			page: 1,
			limit: 100,
			setLoading,
		});
		if (data) {
			setDataCustomerPt1(data.data);
		}
	};

	const handleSaveData = async (data) => {
		const dataCreate = await handleCreate({
			api: "pt1",
			data,
			messageApi,
			setLoading,
		});
		setDetailCustomerPt1(dataCreate?.data);
		await handleGetData({ ma_kh: dataModal?.ma_kh });
	};

	const handleUpdateData = async (data) => {
		await handleUpdate({
			api: "pt1",
			data,
			messageApi,
			setLoading,
		});
		await handleGetData({ ma_kh: dataModal?.ma_kh });
	};

	const handleDeleteId = async (id) => {
		await handleDelete({
			api: "pt1",
			id,
			messageApi,
			setLoading,
		});
		await handleGetData({ ma_kh: dataModal?.ma_kh });
	};

	const handleManagerData = async (data) => {
		if (isOpenCustomerPt1 === "create") {
			await handleSaveData(data);
		} else {
			await handleUpdateData(data);
		}
		await setIsOpenCustomerPt1(false);
	};

	useEffect(() => {
		if (dataModal?.ma_kh && dataModal?.ma_kh !== "") {
			handleGetData({
				ma_kh: dataModal?.ma_kh,
			});
		}
	}, [dataModal?.ma_kh]);

	const formSections = [
		{
			id: "thongtin",
			title: "Thông tin",
			content: <FormInfomation pt1={true} />,
		},
		{
			id: "thutheokhachhang",
			title: "Thu theo khách hàng",
			content: <ItemDetailPt />,
		},
		{
			id: "thutheohoadon",
			title: "Thu theo hoá đơn",
			content: <ItemTdttnosPt messageApi={messageApi} />,
		},
	];

	return (
		<div className="relative">
			<TableManagerCus
				id="datahistory-tab"
				loading={loading}
				data={dataCustomerPt1}
				columns={columns}
				scroll={{ x: 1000, y: "80vh" }}
				tableProps={{
					size: "middle",
				}}
				onRow={(record) => ({
					onClick: () => {
						setIsOpenCustomerPt1("edit");
						setDetailCustomerPt1({
							...record,
						});
					},
				})}
			/>
			<div className="absolute bottom-3">
				<div className="flex justify-start gap-2">
					<Button
						onClick={() => {
							setIsOpenCustomerPt1("create");
						}}
						className="btn_error !text-[#F92759]"
					>
						<BsPersonPlus />
						Thêm
					</Button>
					<Button
						onClick={() => {
							handleGetData({
								ma_kh: dataModal?.ma_kh,
							});
						}}
						className="btn_ternary !border-none"
					>
						<TfiReload />
					</Button>
				</div>
			</div>

			<ModalCustomerPt1
				setPrintFrame={setPrintFrame}
				khoSelected={dataDefault?.dmkho}
				dataDefault={dataDefault}
				dataModal={dataModal}
				dataDetail={detailCustomerPt1}
				handleManagerData={handleManagerData}
				isOpenForm={isOpenCustomerPt1}
				setIsOpenForm={setIsOpenCustomerPt1}
				formSections={formSections}
			/>
		</div>
	);
}
