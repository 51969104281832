import { BsArrowReturnRight } from "react-icons/bs";
import {
	FcBullish,
	FcConferenceCall,
	FcDataConfiguration,
	FcHome,
	FcInspection,
	FcMultipleDevices,
	FcStackOfPhotos,
} from "react-icons/fc";
import { ImplantIcon } from "../../utils/icons";
import { MdMonetizationOn } from "react-icons/md";

const ItemMenuLeft = [
	{
		name: "Màn hình chính",
		path: "/",
		title: "Màn hình chính",
		icon: <FcHome size={24} />,
	},
	{
		name: "Quản lý Khách hàng",
		path: "/customer",
		title: "Quản lý Khách hàng",
		icon: <FcConferenceCall size={24} />,
		tabItem: [
			{
				parent: "/customer",
				name: "Khách tiềm năng",
				path: "/leads",
				title: "Khách tiềm năng",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/customer",
				name: "Khách hiện hữu",
				path: "/customers",
				title: "Khách hiện hữu",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/customer",
				name: "Khách bỏ dùng",
				path: "/customers-stop",
				title: "Khách bỏ dùng",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/customer",
				name: "Liên hệ",
				path: "/contacts",
				title: "Liên hệ",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/customer",
				name: "Khách trong tháng",
				path: "/thong-ke-customer",
				title: "Khách trong tháng",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/customer",
				name: "Hạng thành viên",
				path: "/membership",
				title: "Hạng thành viên",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Quản lý dịch vụ",
		path: "/service",
		title: "Quản lý dịch vụ",
		icon: <FcInspection size={24} />,
		tabItem: [
			{
				parent: "/service",
				name: "Đặt lịch hẹn",
				path: "/booking",
				title: "Đặt lịch hẹn",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Lịch sử tư vấn",
				path: "/consultants",
				title: "Lịch sử tư vấn",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Hoá đơn dịch vụ",
				path: "/hd1",
				title: "Hoá đơn dịch vụ",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Hoá đơn chi",
				path: "/pn2",
				title: "Hoá đơn chi",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Phiếu khiếu nại",
				path: "/complains",
				title: "Phiếu khiếu nại",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Đánh giá dịch vụ",
				path: "/service-evaluation",
				title: "Đánh giá dịch vụ",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Màn hình quầy lễ tân",
				path: "/screen-quayletan",
				title: "Màn hình quầy lễ tân",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/service",
				name: "Màn hình phòng dịch vụ",
				path: "/screen-services",
				title: "Màn hình phòng dịch vụ",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Quản lý thu chi",
		path: "/expense-management",
		title: "Quản lý công việc",
		icon: <MdMonetizationOn size={24} color="red" />,
		tabItem: [
			{
				parent: "/expense-management",
				name: "Phiếu thu",
				path: "/pt1",
				title: "Phiếu thu",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/expense-management",
				name: "Phiếu chi",
				path: "/pc1",
				title: "Phiếu chi",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/expense-management",
				name: "Sổ quỹ tiền",
				path: "/soquy",
				title: "Sổ quỹ tiền",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Quản lý Implant",
		path: "/implant-management",
		title: "Quản lý Implant",
		icon: (
			<div>
				<img src={ImplantIcon} alt="ImplantIcon" />
			</div>
		),
		tabItem: [
			{
				parent: "/implant-management",
				name: "Danh mục hãng Implant",
				path: "/dmvt-implant",
				title: "Danh mục hãng Implant",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/implant-management",
				name: "Phiếu nhập Implant",
				path: "/pn1-implant",
				title: "Phiếu nhập Implant",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/implant-management",
				name: "Phiếu xuất Implant",
				path: "/pxk-implant",
				title: "Phiếu xuất Implant",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/implant-management",
				name: "Vật tư tiêu hao",
				path: "/bangketieuhaovattu",
				title: "Vật tư tiêu hao",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/implant-management",
				name: "Báo cáo NXT Implant",
				path: "/thnxt-implant",
				title: "Báo cáo NXT Implant",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Danh mục",
		path: "/category",
		title: "Danh mục",
		icon: <FcStackOfPhotos size={24} />,
		tabItem: [
			{
				parent: "/category",
				name: "Danh mục sản phẩm",
				path: "/products",
				title: "Danh mục sản phẩm",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/category",
				name: "Danh mục dịch vụ",
				path: "/services",
				title: "Danh mục dịch vụ",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/category",
				name: "Danh mục thuốc",
				path: "/medicines",
				title: "Danh mục thuốc",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/category",
				name: "Danh mục Lab",
				path: "/labs",
				title: "Danh mục Lab",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Marketing",
		path: "/marketing",
		title: "Marketing",
		icon: <FcMultipleDevices size={24} />,
		tabItem: [
			{
				parent: "/marketing",
				name: "Nguồn Website",
				path: "/website",
				title: "Nguồn Website",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Nguồn Zalo OA",
				path: "/zalo",
				title: "Nguồn Zalo OA",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/marketing",
				name: "Email Marketing",
				path: "/email-marketing",
				title: "Email Marketing",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Báo cáo quản trị",
		path: "/report",
		title: "Báo cáo quản trị",
		icon: <FcBullish size={24} />,
		tabItem: [
			{
				parent: "/report",
				name: "Báo cáo khách hàng",
				path: "/bao-cao-khach-hang",
				title: "Báo cáo khách hàng",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo doanh số",
				path: "/bao-cao-doanh-so",
				title: "Báo cáo doanh số",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo hoá đơn thu",
				path: "/bao-cao-hoa-don-thu",
				title: "Báo cáo hoá đơn thu",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo hoá đơn chi",
				path: "/bao-cao-hoa-don-chi",
				title: "Báo cáo hoá đơn chi",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo nhân viên",
				path: "/bao-cao-nhan-vien",
				title: "Báo cáo nhân viên",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo hỗ trợ",
				path: "/report-support",
				title: "Báo cáo hỗ trợ",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo công việc",
				path: "/report-work",
				title: "Báo cáo công việc",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/report",
				name: "Báo cáo khiếu nại",
				path: "/report-complain",
				title: "Báo cáo khiếu nại",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
	{
		name: "Thiết lập hệ thống",
		path: "/settings",
		title: "Settings",
		icon: <FcDataConfiguration size={24} />,
		tabItem: [
			{
				parent: "/settings",
				name: "Danh sách công ty",
				path: "/app",
				title: "Danh sách công ty",
				icon: <BsArrowReturnRight size={16} />,
			},
			// {
			// 	parent: "/settings",
			// 	name: "Thông tin công ty",
			// 	path: "/app",
			// 	title: "Thông tin công ty",
			// 	icon: <BsArrowReturnRight size={16} />,
			// },
			{
				parent: "/settings",
				name: "Nhóm người dùng",
				path: "/usergroup",
				title: "Nhóm người dùng",
				icon: <BsArrowReturnRight size={16} />,
			},
			{
				parent: "/settings",
				name: "Danh sách người dùng",
				path: "/participant",
				title: "Danh sách người dùng",
				icon: <BsArrowReturnRight size={16} />,
			},
		],
	},
];

export { ItemMenuLeft };
